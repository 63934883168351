import React, {useEffect} from 'react';
import HeaderSection from "../../../../components/HeaderSection";
import MainLayout from "../../../../Layouts/MainLayout";
import {useQuery} from "react-query";
import {proxyUrl} from "../../../../constants/proxyUrl";
import axios from "axios";
import TaskBanner from "./TaskBanner";


export const getTaskUtente = async (path) => {

    const url = path !== "" ? `${proxyUrl}/lavori/task-utente/${path}` : `${proxyUrl}/lavori/task-utente/`;

    const options = {
        url: url,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

const TaskProfiloOggi = () => {

    const [task, setTask] = React.useState([]);
    const [reload, setReload] = React.useState(false);


    useEffect(() => {
        getTaskUtente("oggi").then((r) => {
            setTask(r.data);
        }).catch(
            () => {
                console.log("Errore");
            }
        )
    }, [reload]);

    return (
        <MainLayout margin={"mt-16"}>
            <div className={"flex flex-col space-y-4"}>
                <div className={"flex flex-col justify-center items-center"}>
                    <HeaderSection title={"I Task di oggi"}/>
                    <div className={"flex flex-col space-y-4 my-10 w-3/5"}>
                        {
                            task.length !== 0 ? (
                                task.map((t, index) => (
                                    <TaskBanner key={index} t={t} reload={reload} setReload={setReload}/>
                                ))
                            ) : (
                                <div className={"flex flex-col justify-center items-center"}>
                                    <h1 className={"text-2xl font-bold text-gray-700"}>Nessun Task Oggi</h1>
                                    <h1 className={"text-xl font-bold text-gray-700"}>Ripassa più tardi</h1>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default TaskProfiloOggi;
