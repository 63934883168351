import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const getEventiLavoro = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/eventi/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const addEventoLavoro = async (formData) => {
    const options = {
        url: `${proxyUrl}/lavori/eventi/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
export const deleteEventoLavoro = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/eventi/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


//ANAGRAFICHE


export const getAnagraficheEventiLavoro = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/eventi/${id}/anagrafiche`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const addAnagraficaEventiLavoro = async (formData) => {
    const options = {
        url: `${proxyUrl}/lavori/eventi/anagrafiche/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
export const deleteAnagraficaEvento = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/eventi/anagrafiche/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
