import React, {useEffect} from 'react';
import HeaderSection from "../../../../components/HeaderSection";
import MainLayout from "../../../../Layouts/MainLayout";
import {getTaskUtente} from "./TaskProfiloOggi";
import TaskBanner from "./TaskBanner";

const TaskProfiloIncompleti = () => {

    const [eventi, setEventi] = React.useState([]);

    const [reload, setReload] = React.useState(false);

    useEffect(() => {
        getTaskUtente("incompleti").then((r) => {
            setEventi(r.data);
            console.log(r.data)
        }).catch(
            () => {
                console.log("Errore");
            }
        )
    }, [reload]);


    return (
        <MainLayout margin={"mt-16"}>
            <div className={"flex flex-col space-y-4"}>
                <div className={"flex flex-col justify-center items-center"}>
                    <HeaderSection title={"I Tuoi Task da completare"}/>
                    <div className={"flex flex-col space-y-4 my-10"}>
                        {
                            eventi.length !== 0 ? (
                                eventi.map((t, index) => (
                                    <TaskBanner key={index} t={t} setReload={setReload} reload={reload}/>
                                ))
                            ) : (
                                <div className={"flex flex-col justify-center items-center"}>
                                    <h1 className={"text-2xl font-bold text-gray-700"}>Nessun Evento Incompleto</h1>
                                    <h1 className={"text-xl font-bold text-gray-700"}>Ripassa più tardi</h1>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default TaskProfiloIncompleti;
