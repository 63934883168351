import {useState} from 'react'
import {Combobox} from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export const MyCombobox = ({valueList, selectedItem, setSelectedItem}) => {
    const [query, setQuery] = useState('')


    const filteredList = query === '' ? valueList : valueList.filter(
        item => item.nome.toLowerCase().includes(query.toLowerCase()) ||
            item.cognome.toLowerCase().includes(query.toLowerCase())
    )

    return (
        <Combobox as="div" value={selectedItem} onChange={setSelectedItem}>
            <div className="relative">
                <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-my-blue focus:outline-none focus:ring-1 focus:ring-my-blue sm:text-sm"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={() => selectedItem.nome + " " + selectedItem.cognome}
                />
                <Combobox.Button
                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"/>
                    </svg>

                </Combobox.Button>

                {
                    filteredList.length > 0 && (
                        <Combobox.Options
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {
                                filteredList.map((item, index) => (
                                    <Combobox.Option
                                        key={index}
                                        value={item}
                                        className={({active}) =>
                                            classNames(
                                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                                active ? 'bg-blue-100' : 'text-gray-900'
                                            )
                                        }
                                    >
                                        {({active, selected}) => (
                                            <>
                                                <span
                                                    className={classNames('block truncate', selected && 'font-semibold')}>{item.nome !== "N/A" ? item.nome : ""} {item.cognome !== "N/A" ? item.cognome : ""}</span>
                                                {
                                                    selected && (
                                                        <span
                                                            className={classNames(
                                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                active ? 'text-white' : 'text-my-blue'
                                                            )}
                                                        >
                                                        </span>
                                                    )
                                                }
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))}
                        </Combobox.Options>
                    )}
            </div>
        </Combobox>
    )
}
