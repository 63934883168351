import React, {useState} from 'react';
import {sleep} from "../../../../constants/sleep";
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import BackButton from "../../../../components/BackButton";
import HeaderSection from "../../../../components/HeaderSection";
import MainLayout from "../../../../Layouts/MainLayout";
import {creaManutenzioni} from "../../../../fetch/fetchRecurring";
import {useQuery} from "react-query";
import {getBeniAziendali} from "../../../../fetch/fetchBeniAziendali";

const AggiungiManutenzione = () => {
    const [alert, setAlert] = useState(0)

    const {data: beni} = useQuery({
        queryKey: 'beni-aziendali',
        queryFn: () => getBeniAziendali().then(
            (res) => res.data
        )
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)

        creaManutenzioni(formData).then(() => {
            setAlert(1)
            sleep(3000).then(() =>
                setAlert(0)
            )
        }).catch(() => {
            setAlert(-1)
        })

    };

    const frequenze = [
        "Giornaliero",
        "Settimanale",
        "Mensile",
        "Bimestrale",
        "Trimestrale",
        "Quadrimestrale",
        "Semestrale",
        "Annuale",
        "Più"
    ]


    return (
        <MainLayout>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"Il Protocollo è stato aggiunto con successo"}/>
                        ) : null
                    }
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi Nuova Manutenzione"}/>
                    <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                        <div
                            className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                            <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                {/*Nome*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Nome
                                        </div>
                                        <div className="relative">
                                            <input
                                                type={"text"}
                                                name={"nome"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Nome"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Documentazione*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Documentazione
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="file"
                                                    name={"documentazione"}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Scadenza*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Scadenza
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="date"
                                                    name={"scadenza"}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Frequenza*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Frequenza
                                        </div>
                                        <select
                                            name={"frequenza"}
                                            defaultValue={frequenze[0]}
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                frequenze.map((f, index) => (
                                                    <option
                                                        key={index}
                                                        value={f}
                                                    >
                                                        {f}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*Bene Aziendale*/}
                                <div className="col-span-2">
                                    <div className={"flex flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Bene Aziendale
                                        </div>
                                        <select
                                            name={"bene"}
                                            defaultValue={beni[0]?.id}
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                beni.map((f, index) => (
                                                    <option
                                                        key={index}
                                                        value={f.id}
                                                    >
                                                        {f.tipo} - {f.modello}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </MainLayout>
    )
}

export default AggiungiManutenzione
