import React from 'react';
import {Link} from "react-router-dom";
import MainLayout from "../Layouts/MainLayout";

const NotFoundPage = () => {
    return (
        <MainLayout>
            <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <p className="text-5xl font-semibold text-primary-blue">404</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Pagina Non
                        Trovata</h1>
                    <p className="mt-6 text-base leading-7 text-gray-600">Ci dispiace ma la pagina che cercavi non
                        esiste</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link
                            to={"/"}
                            href="#"
                            className="rounded-md bg-primary-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm "
                        >
                            Torna Alla Home
                        </Link>
                    </div>
                </div>
            </main>
        </MainLayout>
    );
};

export default NotFoundPage;
