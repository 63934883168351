import React, {useState} from 'react';
import Navbar from "../../../../../components/Navbar";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import HeaderSection from "../../../../../components/HeaderSection";
import Footer from "../../../../../components/Footer";
import {sleep} from "../../../../../constants/sleep";
import {addGruppo} from "../../../../../fetch/fetchLavori";
import BackButton from "../../../../../components/BackButton";

const AggiungiGruppo = () => {

    const [alert, setAlert] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('nome', e.target.nome.value);
        postData.append('acronimo', e.target.acronimo.value.toUpperCase());

        addGruppo(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    return (
        <div className={"flex flex-col h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"Il Gruppo è stato aggiunto con successo"}/>
                        ) : (
                            <div/>
                        )
                    }
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi Nuovo Gruppo"}/>
                    <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                        <div
                            className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                            <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                {/*Nome*/}
                                <div className="col-span-2">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Nome
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name={"nome"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Nome"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Acronimo*/}
                                <div className="col-span-2">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Acronimo
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name={"acronimo"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Acronimo"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default AggiungiGruppo;
