import React, {useEffect, useState} from 'react';
import {sleep} from "../../../../constants/sleep";
import Navbar from "../../../../components/Navbar";
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import HeaderSection from "../../../../components/HeaderSection";
import Footer from "../../../../components/Footer";
import {
    addCategorieMateriali,
    getCategorieMateriali,
    removeCategorieMateriali
} from "../../../../fetch/fetchMateriali";
import BackButton from "../../../../components/BackButton";

const CategoriaMateriale = () => {

    const [alert, setAlert] = useState(0)
    const [categoriaMateriale, setCategoriaMateriale] = useState([])
    const [filter, setFilter] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('nome', e.target.nome.value);

        addCategorieMateriali(postData).then(() => {
            setAlert(1)
            support()
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    const handleDelete = (id) => {
        removeCategorieMateriali(id).then(() => {
            support()
        }).catch(e =>
            console.log(e)
        )
    }

    const support = () => {
        getCategorieMateriali().then(r => {
            setCategoriaMateriale(r.data)
        }).catch(
            e => console.log(e)
        )
    }

    useEffect(() => {
        support()
    }, []);


    return (
        <div className={"flex flex-col h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"}
                                          message={"La Task Predefinita è stato aggiunto con successo"}/>
                        ) : (
                            <div/>
                        )
                    }
                    <BackButton/>
                    <HeaderSection title={"Aggiungi Categoria Materiale"}/>
                    <div className={"flex flex-col lg:flex-row"}>
                        <div className={"flex flex-col md:flex-row gap-10 w-full my-10"}>
                            <form className="flex flex-1 space-x-3 h-40" onSubmit={handleSubmit}>
                                <div className="w-full max-w-2xl px-5 py-10 m-auto bg-my-white rounded-sm shadow-sm">
                                    <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                        {/*Commento*/}
                                        <div className="col-span-2">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Nome Categoria Materiale
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="text"
                                                        name={"nome"}
                                                        placeholder={"Nome Categoria Materiale"}
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Bottone*/}
                                        <div className="col-span-2 text-center">
                                            <button
                                                type="submit"
                                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                            >
                                                Aggiungi
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div
                                className={"flex flex-1 w-full max-w-2xl p-5 m-auto bg-my-white rounded-sm shadow-sm "}>
                                <div className={"flex flex-col space-y-4 w-full"}>
                                    <div className={"text-2xl font-bold"}>
                                        Categoria Materiale
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name={"filter"}
                                            placeholder={"Cerca"}
                                            className={"w-full rounded outline-none p-2"}
                                            onChange={(e) => setFilter(e.target.value)}
                                        />
                                    </div>
                                    <div className={"flex flex-col w-full rounded overflow-auto h-40"}>
                                        {
                                            categoriaMateriale.sort(
                                                (a, b) => a.nome.localeCompare(b.nome)
                                            ).filter(
                                                categoria => categoria.nome.toLowerCase().includes(filter.toLowerCase())
                                            ).map((categoria, index) => (
                                                <div key={index} className={"bg-white px-2 py-2 cursor-pointer"}>
                                                    <div className={"flex justify-between items-center"}>
                                                        <div>
                                                            {categoria.nome}
                                                        </div>
                                                        <div
                                                            onClick={() => handleDelete(categoria.id)}
                                                            className={"bg-my-red px-1 rounded text-white hover:bg-red-600"}>
                                                            Elimina
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CategoriaMateriale;
