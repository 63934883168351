import React, {useEffect} from 'react';
import ImageBanner from "../../components/ImageBanner";
import img from "../../assets/sfondi/LagoGiungla.jpg";
import GridBlog from '../../components/GridBlog';
import MainLayout from "../../Layouts/MainLayout";

const ElettrotecnicaPage = () => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <MainLayout>
            <ImageBanner
                image={img}
                title={"Settore Elettrotecnica"}
                description={"Domesticus nix cito gratias tata est. Sunt lacteaes aperto azureus, grandis extumes. Grandis, fatalis stellas callide consumere de secundus, varius elevatus."}
            />

            <GridBlog
                title={"le nostre idee in azione"}
                desc1={"Ogni progetto rappresenta una sfida unica, un'opportunità di eccellere e di dimostrare il nostro impegno a costruire un futuro migliore per tutti."}
                desc2={"superare i limiti dell'ingegneria e creare soluzioni innovative"}/>
        </MainLayout>
    );
};

export default ElettrotecnicaPage;
