import React from 'react';

const GridBlog = ({title,desc1,desc2}) => {
    return (
        <div className={"grid place-items-center min-h-screen"}>
            <div className={"p-4 max-w-5xl grid gap-4 grid-cols-2 p-8 md:grid-cols-4 lg:gap-6"}>
                <h1 className={"text-4xl font-extrabold col-span-2 grid gap-4 grid-cols-2 md:col-span-3 md:text-5xl md:grid-cols-3 lg:text-6xl"}>
                    <span className={"md:col-span-2"}>{title}</span>
                </h1>
                <p className={"row-start-2 col-start-2 self-center md:col-start-1 md:col-span-2 md:pr-12 md:text-lg"}>{desc1}</p>
                <div className={"h-16 bg-blue-500 h-auto square"}></div>
                <div className={"h-16 bg-blue-500 h-auto square"}></div>
                <div className={"h-16 bg-pink-500 h-auto square"}></div>
                <div className={"h-16 bg-blue-500 h-auto square md:col-start-2"}></div>
                <div className={"h-16 bg-pink-500 h-auto square"}></div>
                <div className={"h-16 bg-blue-500 h-auto square"}></div>
                <div className={"h-16 bg-blue-500 h-auto square"}></div>
                <div className={"h-16 bg-pink-500 h-auto square"}></div>
                <p className={"self-center md:text-lg md:col-span-2 md:text-center md:px-4"}>{desc2}</p>
            </div>
        </div>
    );
};

export default GridBlog;
