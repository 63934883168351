import React, {useEffect, useState} from 'react';
import {getRelazione} from "../../../../../fetch/fetchRelazioni";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import {getAnagrafiche} from "../../../../../fetch/fetchAnagrafica";
import BackButton from "../../../../../components/BackButton";
import {MyCombobox} from "../../../../../components/combobox/MyCombobox";
import MainLayout from "../../../../../Layouts/MainLayout";

const RelazioniPage = () => {


    const [anagrafiche, setAnagrafiche] = useState([])

    const [relazioni, setRelazioni] = useState([])

    const [selectedAnagrafica, setSelectedAnagrafica] = useState()

    const getSupporto = () => {
        getAnagrafiche().then(r => {
            setAnagrafiche(r.data)
            setSelectedAnagrafica(r.data[0])
        }).catch(e => {
            console.log(e)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        getRelazione(selectedAnagrafica.id).then(
            r => setRelazioni(r.data)
        ).catch(
            e => console.log(e)
        )
    }

    useEffect(() => {
        getSupporto()
    }, [])

    return (
        <MainLayout>
            <div className={"p-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSectionAdd titolo={"Cerca Relazioni"} link={"/aggiungi_relazioni"}
                                  esporta={"dipendenti/relazioni"}/>
                <div className={"flex flex-col lg:flex-row"}>
                    <div className={"flex flex-1 flex-col lg:flex-row"}>
                        <form className="flex container space-x-3 mb-10" onSubmit={handleSubmit}>
                            <div
                                className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                    {/*Dipendente*/}
                                    <div className="col-span-2 lg:col-span-2">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Anagrafica
                                            </div>
                                            <MyCombobox
                                                selectedItem={selectedAnagrafica}
                                                setSelectedItem={setSelectedAnagrafica}
                                                valueList={anagrafiche}
                                            />
                                        </div>
                                    </div>
                                    {/*Bottone*/}
                                    <div className="col-span-2 text-center">
                                        <button
                                            type="submit"
                                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                        >
                                            Cerca
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={"flex flex-1 my-10"}>
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Anagrafica 1
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Anagrafica 2
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Descrizione
                                </th>
                            </tr>
                            </thead>

                            <tbody className="bg-white divide-y divide-gray-200">
                            {
                                relazioni.map((relazione, i) => (
                                    <tr key={i}>
                                        {/*A1*/}
                                        <td className="px-6 py-4 ">
                                            <div className="flex items-center">
                                                <div className="">
                                                    <div
                                                        className="text-sm font-medium text-gray-900">{relazione.anagrafica1.nome} {relazione.anagrafica1.cognome}</div>
                                                </div>
                                            </div>
                                        </td>
                                        {/*A2*/}
                                        <td className="px-6 py-4 ">
                                            <div className="flex items-center">
                                                <div className="">
                                                    <div
                                                        className="text-sm font-medium text-gray-900">{relazione.anagrafica2.nome} {relazione.anagrafica2.cognome}</div>
                                                </div>
                                            </div>
                                        </td>

                                        {/*Descrizione*/}
                                        <td className="px-6 py-4 ">
                                            <div className="flex items-center">
                                                <div className="">
                                                    <div
                                                        className="text-sm font-medium text-gray-900">{relazione.descrizione}</div>
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </MainLayout>
    );
};

export default RelazioniPage;
