import React, {useEffect, useState} from 'react';
import DangerAlert from "../../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../../components/SuccessAlert";
import HeaderSection from "../../../../../../components/HeaderSection";
import {creaNotaSpese} from "../../../../../../fetch/fetchNotaSpese";
import BackButton from "../../../../../../components/BackButton";
import MainLayout from "../../../../../../Layouts/MainLayout";
import {getSocieta} from "../../../../../../fetch/fetchSocieta";
import {getAnagrafiche} from "../../../../../../fetch/fetchAnagrafica";
import {MyCombobox} from "../../../../../../components/combobox/MyCombobox";

const AggiungiNotaSpese = () => {

    const [alert, setAlert] = useState(0)

    const [selectedAnagrafica, setSelectedAnagrafica] = useState()
    const [anagrafiche, setAnagrafiche] = useState([])

    const [formData, setFormData] = useState({
        titolo: "",
        societa: 3
    })

    const [societa, setSocieta] = useState([])

    const {titolo} = formData

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value})

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            ...formData,
            anagrafica: selectedAnagrafica.id
        }

        creaNotaSpese(data).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        })
    };

    useEffect(() => {
        getAnagrafiche().then(
            r => {
                setAnagrafiche(r.data)
                setSelectedAnagrafica(r.data[0])
            }
        ).catch(
            e => console.log(e)
        )
        getSocieta().then(
            r => setSocieta(r.data)
        ).catch(
            e => console.log(e)
        )
    }, [])

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"La Nota Spese è stato aggiunta con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Nota Spese"}/>
                <div className={"flex flex-col lg:flex-row justify-evenly"}>
                    <div className={"flex flex-col lg:flex-row"}>
                        <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                            <div
                                className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                    {/*Titolo*/}
                                    <div className="col-span-2">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Titolo
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    placeholder={"Titolo"}
                                                    value={titolo}
                                                    onChange={e => onChange(e)}
                                                    name={"titolo"}
                                                    required={true}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Anagrafica*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Anagrafica
                                            </div>
                                            <MyCombobox
                                                valueList={anagrafiche}
                                                selectedItem={selectedAnagrafica}
                                                setSelectedItem={setSelectedAnagrafica}
                                            />
                                        </div>
                                    </div>
                                    {/*Società*/}
                                    <div className="w-full">
                                        <div className={"flex flex-col lg:flex-col w-full"}>
                                            <div className={"text-my-gray"}>
                                                Società
                                            </div>
                                            <select
                                                onChange={e => onChange(e)}
                                                name={"societa"}
                                                defaultValue={societa.find(soc => soc.id === 3)}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            >
                                                {
                                                    societa.map((soc, index) => (
                                                        <option
                                                            key={index}
                                                            value={soc.id}
                                                            selected={soc.id === 3}
                                                        >
                                                            {soc.nome}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    {/*Bottone*/}
                                    <div className="col-span-2 text-center">
                                        <button
                                            type="submit"
                                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                        >
                                            Aggiungi
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </MainLayout>
    );
};

export default AggiungiNotaSpese;
