import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const addPostLavoro = async (formData) => {
    const options = {
        url: `${proxyUrl}/post-progetti/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const getPostLavori = async () => {
    let token = localStorage.getItem("token")
    if (token) {
        const options = {
            url: `${proxyUrl}/post-progetti`,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            }
        };
        return axios(options)
    }

    const options = {
        url: `${proxyUrl}/post-progetti`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    };
    return axios(options)
}

export const eliminaPostLavoro = async (id) => {
    const options = {
        url: `${proxyUrl}/post-progetti/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    };

    return axios(options)
}


export const infoProgetto = async (id) => {
    const options = {
        url: `${proxyUrl}/post-progetti/info/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    };

    return axios(options)
}
