import React, {useEffect, useState} from 'react';
import Footer from "../../../../components/Footer";
import HeaderSection from "../../../../components/HeaderSection";
import BackButton from "../../../../components/BackButton";
import Navbar from "../../../../components/Navbar";
import {getSocieta} from "../../../../fetch/fetchSocieta";
import {addTax, getTipoTax} from "../../../../fetch/fetchTax";

const AggiungiTax = () => {

    const handleSubmit = (event) => {
        event.preventDefault()
        const data = new FormData(event.target)

        addTax(data).then(() => {
            event.target.reset()
        }).catch(
            e => {
                console.log(e)
            }
        )
    }

    const [societa, setSocieta] = useState([])

    const [tipi, setTipi] = useState([])


    useEffect(() => {

        getTipoTax().then(r => {
            setTipi(r.data)
        }).catch(
            e => {
                console.log(e)
            }
        )

        getSocieta().then(
            r => setSocieta(r.data)
        ).catch(
            e => console.log(e)
        )

    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Tax"}/>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Società*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Società
                                    </div>
                                    <select
                                        name={"societa"}
                                        defaultValue={societa[0]}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            societa.map((soc, index) => (
                                                <option
                                                    key={index}
                                                    value={soc.id}
                                                >
                                                    {soc.nome}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Tipo*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Tipi
                                    </div>
                                    <select
                                        name={"tipo"}
                                        defaultValue={tipi[0]}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            tipi.map((tipo, index) => (
                                                <option
                                                    key={index}
                                                    value={tipo.id}
                                                >
                                                    {tipo.nome}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Data*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Data Ricezione
                                    </div>
                                    <input
                                        type="date"
                                        name="data_ricezione"
                                        required
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                            {/*Data scadenza*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Data Scadenza
                                    </div>
                                    <input
                                        type="date"
                                        name="data_scadenza"
                                        required
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                            {/*Importo*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Importo
                                    </div>
                                    <input
                                        type="number"
                                        step="0.01"
                                        min={"0.01"}
                                        placeholder={"0.00"}
                                        name="importo"
                                        required
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                            {/*Note*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Note
                                    </div>
                                    <textarea
                                        name={"nota"}
                                        placeholder={"Inserisci le note"}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Aggiungi
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer/>
        </div>
    );
};

export default AggiungiTax;
