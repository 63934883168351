import React, {Fragment, useEffect, useState} from 'react';
import Navbar from "../../../../components/Navbar";
import BackButton from "../../../../components/BackButton";
import Footer from "../../../../components/Footer";
import HeaderSection from "../../../../components/HeaderSection";
import {useParams} from "react-router-dom";
import {getPreventivo, modificaPreventivo} from "../../../../fetch/fetchPreventivo";
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import {Dialog, Transition} from '@headlessui/react'

const ModificaPreventivo = () => {
    const {id} = useParams();

    const [alert, setAlert] = useState(0)

    const [preventivo, setPreventivo] = useState({
        descrizione: "",
    })

    const [statoOfferta, setStatoOfferta] = useState("")

    useEffect(() => {
        getPreventivo(id).then(
            (response) => {
                setPreventivo(response.data)
                setStatoOfferta(response.data.is_accettato)
            }
        ).catch(
            (error) => {
                console.log(error)
            }
        )
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault()

        const formData = new FormData();
        formData.append("descrizione", preventivo.descrizione);

        if (e.target.preventivo.files[0] !== undefined) {
            formData.append("copia_preventivo", e.target.preventivo.files[0], e.target.preventivo.files[0].name);
            formData.append("is_accettato", "In Attesa Cliente");
        }

        modificaPreventivo(id, formData).then(
            () => {
                setAlert(1)
            }
        ).catch(
            () => {
                setAlert(-1)
            }
        )
    }


    const handleAccettaPreventivo = (e) => {
        e.preventDefault()

        const formData = new FormData();
        formData.append("is_accettato", "Accettato");
        formData.append("copia_preventivo", offertaFirmata, offertaFirmata.name);


        modificaPreventivo(id, formData).then(
            () => {
                setAlert(1)
                setStatoOfferta("Accettato")
            }
        ).catch(
            () => {
                setAlert(-1)
            }
        )
    }

    const handleRifiutaPreventivo = (e) => {
        e.preventDefault()
        modificaPreventivo(id, {is_accettato: "Rifiutato"}).then(
            () => {
                setAlert(1)
                setStatoOfferta("Rifiutato")
            }
        ).catch(
            () => {
                setAlert(-1)
            }
        )
    }
    const handleInAttesaCliente = (e) => {
        e.preventDefault()
        modificaPreventivo(id, {is_accettato: "In Attesa Cliente"}).then(
            () => {
                setAlert(1)
                setStatoOfferta("In Attesa Cliente")
            }
        ).catch(
            () => {
                setAlert(-1)
            }
        )
    }

    const [rifiutaOffertaPopup, setRifiutaOffertaPopup] = useState(false)
    const [accettaOffertaPopup, setAccettaOffertaPopup] = useState(false)
    const [offertaFirmata, setOffertaFirmata] = useState(null)

    const showRifiutaOffertaPopup = () => (
        <Transition.Root show={rifiutaOffertaPopup} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setRifiutaOffertaPopup}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div
                                        className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                        </svg>

                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3"
                                                      className="text-base font-semibold leading-6 text-gray-900">
                                            Rifiuta offerta
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <div className="text-sm text-gray-500">
                                                Sei sicuro di voler rifiutare l'offerta?
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={(e) => {
                                            handleRifiutaPreventivo(e)
                                            setRifiutaOffertaPopup(false)
                                        }}
                                    >
                                        Rifiuta Offerta
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setRifiutaOffertaPopup(false)}
                                    >
                                        Annulla
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )

    const showAccettaOffertaPopup = () => (
        <Transition.Root show={accettaOffertaPopup} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {
                setAccettaOffertaPopup(false)
                setOffertaFirmata(null)
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div
                                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-base font-semibold leading-6 text-gray-900">
                                            Accetta offerta
                                        </Dialog.Title>
                                        <div className="mt-2 flex flex-col gap-2">
                                            <div className={"text-sm italic"}>
                                                Inserisci l'offerta firmata dal cliente
                                            </div>
                                            <input
                                                type="file"
                                                name="offertaFirmata"
                                                onChange={(e) => setOffertaFirmata(e.target.files[0])}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 flex gap-4">
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => {
                                            setOffertaFirmata(null)
                                            setAccettaOffertaPopup(false)
                                        }}
                                    >
                                        Annulla
                                    </button>
                                    {
                                        offertaFirmata === null ? (
                                            <div
                                                className="inline-flex w-full justify-center rounded-md bg-gray-300 cursor-pointer px-3 py-2 text-sm font-semibold shadow-ss"
                                            >
                                                Salva
                                            </div>
                                        ) : (
                                            <button
                                                type={"button"}
                                                className="inline-flex w-full justify-center rounded-md bg-primary-blue cursor-pointer px-3 py-2 text-sm font-semibold text-white shadow-ss"
                                                onClick={(e) => {
                                                    handleAccettaPreventivo(e)
                                                    setOffertaFirmata(null)
                                                    setAccettaOffertaPopup(false)
                                                }}
                                            >
                                                Salva
                                            </button>
                                        )
                                    }

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"L'offerta è stata modificata con successo"}/>
                    ) : (
                        <div/>
                    )
                }

                {
                    rifiutaOffertaPopup ? showRifiutaOffertaPopup() : null
                }

                {
                    accettaOffertaPopup ? showAccettaOffertaPopup() : null
                }

                <HeaderSection title={"Modifica Preventivo"}/>
                {
                    statoOfferta === "In Attesa Cliente" ? (
                        <div className={"w-full flex items-center justify-center mt-6 gap-4"}>
                            <div
                                onClick={() => setAccettaOffertaPopup(true)}
                                className={"bg-my-green text-white rounded cursor-pointer px-4 py-2 text-center"}>
                                Accetta Offerta
                            </div>
                            <div
                                onClick={() => setRifiutaOffertaPopup(true)}
                                className={"bg-red-600 text-white rounded cursor-pointer px-4 py-2 text-center"}>
                                Rifiuta Offerta
                            </div>
                        </div>
                    ) : statoOfferta === "Accettato" ? (
                        <div className={"w-full flex items-center justify-center mt-6 gap-4"}>
                            <div
                                onClick={handleInAttesaCliente}
                                className={"bg-primary-blue text-white rounded cursor-pointer px-4 py-2 text-center"}>
                                In Attesa Cliente
                            </div>
                            <div
                                onClick={() => setRifiutaOffertaPopup(true)}
                                className={"bg-red-600 text-white rounded cursor-pointer px-4 py-2 text-center"}>
                                Rifiuta Offerta
                            </div>
                        </div>
                    ) : statoOfferta === "Rifiutato" ? (
                        <div className={"w-full flex items-center justify-center mt-6 gap-4"}>
                            <div
                                onClick={() => setAccettaOffertaPopup(true)}
                                className={"bg-my-green text-white rounded cursor-pointer px-4 py-2 text-center"}>
                                Accetta Offerta
                            </div>
                            <div
                                onClick={handleInAttesaCliente}
                                className={"bg-primary-blue text-white rounded cursor-pointer px-4 py-2 text-center"}>
                                In Attesa Cliente
                            </div>
                        </div>
                    ) : null

                }

                <div className={"mb-10 flex w-full justify-center items-center"}>
                    <div className={"w-11/12 sm:w-4/5"}>
                        <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                            <div
                                className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">

                                    {/*Nome*/}
                                    <div className="col-span-2 lg:col-span-2 ">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Modifica Descrizione Offerta
                                            </div>
                                            <div className="relative">
                                            <textarea
                                                name={"descrizione"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Descrizione Preventivo"
                                                maxLength={255}
                                                value={preventivo.descrizione}
                                                onChange={e => setPreventivo({
                                                    ...preventivo,
                                                    descrizione: e.target.value
                                                })}
                                                required
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    {/*File*/}
                                    <div className="col-span-2">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Modifica File o Aggiungi Nuovo
                                            </div>
                                            <label className="text-gray-700" htmlFor="name">
                                                <input
                                                    type="file"
                                                    name="preventivo"
                                                    accept="application/pdf"
                                                    className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-sm focus:outline-none"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    {/*Bottone*/}
                                    <div className="col-span-2 text-center">
                                        <button
                                            type="submit"
                                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                        >
                                            Aggiungi
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default ModificaPreventivo;
