import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import Navbar from "../../../../../components/Navbar";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import Footer from "../../../../../components/Footer";
import {getCommentiTask} from "../../../../../fetch/fetchCommenti";
import {useQuery} from "react-query";
import {formatDateAndTime} from "../../../../../utils/string-formatter";

const TaskInfo = () => {
    const {id} = useParams()

    const [commenti, setCommenti] = useState([])

    useQuery({
        queryKey: ['commenti', id],
        queryFn: () => getCommentiTask(id),
        onSuccess: (data) => {
            setCommenti(data.data)
        }
    })

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"flex-grow mt-10"}>
                    <HeaderSectionAdd titolo={"Commenti Task"} link={`/aggiungi_commento/${id}`}/>
                    <div className={"my-10"}>
                        <div className="flex flex-col container">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className={"text-3xl font-semibold mb-4"}>Commenti Relativi alla Task</div>
                                    <div className={"space-y-4 flex flex-col"}>

                                        {
                                            commenti.length === 0 ? "Non ci sono commenti" : (
                                                commenti.map((c, i) => (
                                                    <div key={i}
                                                         className={"flex flex-col border p-2 rounded"}>
                                                        <div className={"flex items-center justify-between"}>
                                                            <div className={"text-xl font-semibold"}>
                                                                {c.owner.username}
                                                            </div>
                                                            <div>
                                                                {formatDateAndTime(c.data)}
                                                            </div>
                                                        </div>

                                                        <p className={"mt-4"}>
                                                            {c.commento}
                                                        </p>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default TaskInfo;
