import React from 'react';
import {editEventiFormazioneUtente, listaAllegatiEventoFormazione} from "../../../fetch/fetchFormazione";

const statuses = {
    'Completato': 'text-green-700 bg-green-50 ring-green-600/20',
    'In Corso': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    'Non Iniziato': 'text-red-600 bg-red-50 ring-red-500/10',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const FormazioneCard = ({event: x}) => {

    const [event, setEvent] = React.useState(x);

    const [allegatiRecurring, setAllegatiRecurring] = React.useState([]);

    const getAllegati = () => {
        listaAllegatiEventoFormazione(event.evento_formazione.id).then((response) => {
            setAllegatiRecurring(response.data);
        }).catch(
            (error) => {
                console.log(error);
            }
        )
    }

    const handleChangeStatus = (currentState) => {

        let newState

        switch (currentState) {
            case 'Completato':
                newState = 'Non Iniziato';
                break
            case 'In Corso':
                newState = 'Completato';
                break
            case 'Non Iniziato':
                newState = 'In Corso';
                break
            default:
                newState = 'Non Iniziato';
                break
        }

        setEvent({...event, stato: newState})

        editEventiFormazioneUtente(event.id, {stato: newState}).then((response) => {
            console.log(response);
        }).catch(
            (error) => {
                console.log(error);
            }
        )


    }

    const extractFileName = (url) => {
        const name = url.substring(url.lastIndexOf('/') + 1);
        return name.split('.').slice(0, -1).join('.')
    }


    React.useEffect(() => {
        getAllegati();
    }, []);

    return (
        <div className="flex flex-col items-start justify-between gap-x-6 py-5">
            <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                    <div
                        className="text-sm font-semibold leading-6 text-gray-900">{event.evento_formazione.nome}</div>
                    <div
                        className={classNames(
                            statuses[event.stato],
                            'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset cursor-pointer'
                        )}
                        onClick={() => handleChangeStatus(event.stato)}

                    >
                        {event.stato}
                    </div>
                </div>
            </div>
            {
                allegatiRecurring.length > 0 && (
                    <div className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full"}>
                        {
                            allegatiRecurring.map((allegato, index) => (
                                <div className={"mt-2 flex"} key={index}>
                                    <div className={"font-bold text-gray-600"}>
                                        {extractFileName(allegato.allegato)}:
                                    </div>
                                    <a
                                        href={allegato.allegato}
                                        target={"_blank"}
                                        className={"ml-2 "}
                                    >
                                        Scarica
                                    </a>
                                </div>
                            ))
                        }
                    </div>
                )
            }

        </div>
    );
};

export default FormazioneCard;
