import React, {useEffect, useState} from 'react';
import {MyCombobox} from "../combobox/MyCombobox";
import {getAnagrafiche} from "../../fetch/fetchAnagrafica";

const AnagraficaInput = ({setSelectedAnagrafica, isLong}) => {

    const [anagrafiche, setAnagrafiche] = useState([])

    useEffect(() => {
        getAnagrafiche().then(
            r => {
                setAnagrafiche(r.data)
                setSelectedAnagrafica(r.data[0])
            }
        ).catch(
            e => console.log(e)
        )
    }, []);


    return (
        <div className={isLong ? "col-span-2" : "col-span-2 sm:col-span-1"}>
            <div className={"flex flex-col lg:flex-col"}>
                <div className={"text-my-gray"}>
                    Anagrafica
                </div>
                <MyCombobox
                    valueList={anagrafiche}
                    selectedItem={anagrafiche[0]}
                    setSelectedItem={setSelectedAnagrafica}
                />
            </div>
        </div>
    );
};

export default AnagraficaInput;
