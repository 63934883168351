import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    filtroSoc: "",
}

export const filterSocietaSlice = createSlice({
    name: 'filtroSoc',
    initialState,
    reducers: {
        setFilterSocieta: (state, action) => {
            state.filtroSoc = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setFilterSocieta} = filterSocietaSlice.actions

export default filterSocietaSlice.reducer
