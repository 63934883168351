import axios from "axios";
import {proxyUrl} from "../constants/proxyUrl";

export const esportaExcelHTTP = async (url) => {


    const options = {
        url: `${proxyUrl}/${url}/esporta`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        responseType: 'blob', // Important

    };

    return axios(options)
}
