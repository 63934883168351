import React from 'react';

const FoundedInBanner = () => {
    return (
        <div
            className={"text-black divide-black divide-opacity-20 lg:divide-x text-center flex flex-col gap-20 lg:flex-row items-center lg:justify-evenly px-20 lg:py-20 py-10"}>
            <div className={"text-2xl font-bold flex-1"}>
                FOUNDED IN <p className={"text-6xl"}>1988</p>
                <p className={"text-sm font-light"}>
                Eleviamo insieme i tuoi obiettivi finanziari a nuovi livelli
                </p>
            </div>

        </div>
    );
};

export default FoundedInBanner;
