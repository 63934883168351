import React, {useEffect, useState} from 'react';
import FiltroSocieta from "../../../../../components/FiltroSocieta";
import {useSelector} from "react-redux";
import {eliminaFattura, getFatture} from "../../../../../fetch/fetchFattura";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import BackButton from "../../../../../components/BackButton";
import {Link} from "react-router-dom";
import MainLayout from "../../../../../Layouts/MainLayout";
import {formatFloat} from "../../../../../utils/number-formatter";

const FatturaPage = () => {


    const [filter, setFilter] = useState("")


    const {filtroSoc} = useSelector(state => state.societa)
    const [fatture, setFatture] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [eliminazione, setEliminazione] = useState(-1)

    const delFattura = (e, id) => {
        e.preventDefault()
        eliminaFattura(id).then(() => {
            getSupporto()
        }).catch(e => {
            console.log(e)
        })
    }

    const getSupporto = () => {
        getFatture().then(r => {
            setFatture(r.data)
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        getSupporto()
    }, [])

    return (
        <MainLayout>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Eliminazione
                                    </h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        Sicuro di voler eliminare?
                                    </p>
                                </div>
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-my-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Annulla
                                    </button>
                                    <button
                                        className="bg-my-red text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(e) => {
                                            delFattura(e, eliminazione)
                                            setShowModal(false)
                                        }}
                                    >
                                        Elimina
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSectionAdd titolo={"Fatture"} link={"/aggiungi_fattura"} esporta={"fatture"}/>
                <FiltroSocieta/>
                <div className={"my-10 flex items-center justify-center"}>
                    <div className="flex flex-col w-11/12">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={"Cerca..."}
                                            className={"w-full py-2 px-4 outline-none border-none"}
                                            onChange={event => setFilter(event.target.value)}
                                        />
                                    </div>
                                    <div className={"max-h-96 overflow-auto"}>
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50 sticky top-0 z-10">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Info
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Numero Fattura
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Anagrafica
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Data Emissione
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Data Scadenza
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Soggetto Contabile 1
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Soggetto Contabile 2
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Documento
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Incassata
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Lordo
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Saldato
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Rimanente
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Tipo Fattura
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Paese
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Elimina
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                fatture.filter(fattura => {
                                                    if (fattura.anagrafica) {
                                                        return fattura.anagrafica.nome.toLowerCase().includes(filter.toLowerCase()) ||
                                                            fattura.anagrafica.cognome.toLowerCase().includes(filter.toLowerCase()) ||
                                                            fattura.data_emissione.toLowerCase().includes(filter.toLowerCase()) ||
                                                            fattura.data_scadenza.toLowerCase().includes(filter.toLowerCase()) ||
                                                            fattura.numero_fattura.toLowerCase().includes(filter.toLowerCase())
                                                    }
                                                    return fattura.data_emissione.toLowerCase().includes(filter.toLowerCase()) ||
                                                        fattura.data_scadenza.toLowerCase().includes(filter.toLowerCase()) ||
                                                        fattura.numero_fattura.toLowerCase().includes(filter.toLowerCase())
                                                }).map((fattura, i) => (
                                                    <tr key={i}>

                                                        {/*Info*/}
                                                        <td
                                                            className="px-6 py-4  text-sm text-gray-500"
                                                        >
                                                            <Link to={"/movimenti/" + fattura.id}
                                                                  className="text-my-green font-bold hover:text-green-700 cursor-pointer">Info</Link>
                                                        </td>
                                                        {/*Numero fattura*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{fattura.numero_fattura}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Anagrafica*/}
                                                        <td className="px-6 py-4 ">
                                                            {
                                                                fattura.anagrafica === null ? (
                                                                    <div
                                                                        className="text-sm text-gray-400"
                                                                    >
                                                                        Non Presente
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className="text-sm text-gray-900"
                                                                    >
                                                                        {fattura.anagrafica.nome} {fattura.anagrafica.cognome === "N/A" ? "" : fattura.anagrafica.cognome}
                                                                    </div>
                                                                )

                                                            }
                                                        </td>
                                                        {/*Data Emissione*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{fattura.data_emissione}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Data Scadenza*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{fattura.data_scadenza}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Società*/}
                                                        <td className="px-6 py-4 ">
                                                            <div
                                                                className="text-sm text-gray-900">{fattura.societa.nome}</div>
                                                        </td>
                                                        {/*Società*/}
                                                        <td className="px-6 py-4 ">
                                                            {
                                                                fattura.is_autofattura || fattura.is_infragruppo ? (
                                                                    <div
                                                                        className="text-sm text-gray-900">{fattura.societa.nome}
                                                                    </div>

                                                                ) : (
                                                                    <div
                                                                        className="text-sm text-gray-400"
                                                                    >
                                                                        Non Presente
                                                                    </div>
                                                                )
                                                            }
                                                        </td>
                                                        {/*Documento*/}
                                                        <td className="px-6 py-4  text-sm text-gray-500">
                                                            <a href={fattura.documento}
                                                               target={"_blank"}
                                                               className={"text-my-green font-bold hover:text-green-700"}>Visualizza</a>
                                                        </td>
                                                        {/*Incasso*/}
                                                        <td className="px-6 py-4 text-sm text-white font-semibold flex items-center">
                                                            {
                                                                fattura.is_incassata === "Saldata" ? (
                                                                    <div className={"bg-my-green px-2 rounded w-full"}>
                                                                        {fattura.is_incassata}
                                                                    </div>
                                                                ) : fattura.is_incassata === "Parziale" ? (
                                                                    <div
                                                                        className={"bg-yellow-400 text-black px-2 rounded w-full"}>
                                                                        {fattura.is_incassata}
                                                                    </div>
                                                                ) : (
                                                                    <div className={"bg-my-red px-2 rounded w-full"}>
                                                                        {fattura.is_incassata}
                                                                    </div>
                                                                )
                                                            }
                                                        </td>
                                                        {/*Lordo*/}
                                                        <td className="px-6 py-3 text-sm text-gray-900">
                                                            {formatFloat(fattura.importo_netto + fattura.cassa_previdenziale + fattura.iva + fattura.ritenuta_acconto)}€
                                                        </td>
                                                        {/*Saldato*/}
                                                        <td className="px-6 py-3 text-sm text-gray-900">
                                                            {formatFloat(fattura.importo_saldato)}€
                                                        </td>
                                                        {/*Rimanente*/}
                                                        <td className="px-6 py-3 text-sm text-gray-900">
                                                            {
                                                                formatFloat((
                                                                    fattura.importo_netto +
                                                                    fattura.cassa_previdenziale +
                                                                    fattura.iva +
                                                                    fattura.ritenuta_acconto
                                                                )
                                                                - fattura.importo_saldato)
                                                            } €
                                                        </td>
                                                        {/*Tipo*/}
                                                        <td className="px-6 py-3 text-sm text-gray-900">
                                                            {fattura.is_attiva}
                                                        </td>
                                                        {/*Tipo*/}
                                                        <td className="px-6 py-3 text-sm text-gray-900">
                                                            {fattura.paese}
                                                        </td>

                                                        {/*Elimina*/}
                                                        <td
                                                            className="px-6 py-4  text-sm text-gray-500"
                                                            onClick={() => {
                                                                setShowModal(true)
                                                                setEliminazione(fattura.id)
                                                            }}
                                                        >
                                                            <p className="text-my-red font-bold hover:text-red-700 cursor-pointer">Elimina</p>
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default FatturaPage;
