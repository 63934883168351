import React, {useEffect, useState} from 'react';
import Navbar from "../../../../components/Navbar";
import {Link} from "react-router-dom";
import Footer from "../../../../components/Footer";
import {delLavoro, getLavori} from "../../../../fetch/fetchLavori";
import {useSelector} from "react-redux";
import FiltroSocieta from "../../../../components/FiltroSocieta";
import HeaderSectionAdd from "../../../../components/HeaderSectionAdd";
import BackButton from "../../../../components/BackButton";

const LavoriPage = () => {
    const [filter, setFilter] = useState("")

    const {filtroSoc} = useSelector(state => state.societa)

    const [showModal, setShowModal] = useState(false)
    const [eliminazione, setEliminazione] = useState(-1)
    const [lavori, setLavori] = useState([])

    const eliminaLavoro = (e, id) => {
        e.preventDefault()
        delLavoro(id).then((r) => {
            console.log(r)
            getLavoriSupport()
        }).catch(e => {
            console.log(e)
        })
    }

    const getLavoriSupport = () => {
        getLavori().then(r => {
            setLavori(r.data)
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        getLavoriSupport()
    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Eliminazione
                                    </h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        Sicuro di voler eliminare?
                                    </p>
                                </div>
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-my-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Annulla
                                    </button>
                                    <button
                                        className="bg-my-red text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(e) => {
                                            eliminaLavoro(e, eliminazione)
                                            setShowModal(false)
                                        }}
                                    >
                                        Elimina
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <Navbar/>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSectionAdd titolo={"Commesse"} link={"/add_lavoro"} esporta={"lavori"}/>
                <FiltroSocieta/>
                <div className={"my-10 flex justify-center items-center"}>
                    <div className="flex flex-col w-11/12">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={"Cerca..."}
                                            className={"w-full py-2 px-4 outline-none border-none"}
                                            onChange={event => setFilter(event.target.value)}
                                        />
                                    </div>
                                    <div className={"max-h-96 overflow-auto"}>
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50 sticky top-0 z-10">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    INFO
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Eventi
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Materiali
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Codice
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Nome
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Luogo
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Gruppo
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Cliente
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Società
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Tag
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Inizio
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Fine
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Elimina
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                lavori.filter(lavoro => {
                                                    if (filter === "") {
                                                        return lavoro
                                                    } else if (
                                                        lavoro.nome.toLowerCase().includes(filter.toLowerCase()) ||
                                                        lavoro.codice.toLowerCase().includes(filter.toLowerCase())
                                                    ) {
                                                        return lavoro
                                                    }
                                                }).filter(lavoro => {
                                                    if (filtroSoc === "") {
                                                        return lavoro
                                                    }
                                                    if (filtroSoc === lavoro.societa.nome) {
                                                        return lavoro
                                                    }
                                                }).map((lavoro, i) => (
                                                    <tr key={i}>
                                                        {/*INFO*/}
                                                        <td className="px-6 py-4  text-sm text-gray-500">
                                                            <Link to={`/modifica_lavoro/${lavoro.id}`}
                                                                  className="text-my-green font-bold hover:text-green-700 cursor-pointer">Info</Link>
                                                        </td>
                                                        {/*Commenti*/}
                                                        <td className="px-6 py-4  text-sm text-gray-500">
                                                            <Link to={`eventi/${lavoro.id}`}
                                                                  className="text-purple-500 font-bold hover:text-purple-700 cursor-pointer">Eventi</Link>
                                                        </td>
                                                        {/*Materiali*/}
                                                        <td className="px-6 py-4  text-sm text-gray-500">
                                                            <Link to={`/materiale_commessa/${lavoro.id}`}
                                                                  className="text-blue-500 font-bold hover:text-blue-700 cursor-pointer">Materiali</Link>
                                                        </td>
                                                        {/*Codice*/}
                                                        <td className="px-6 py-4">
                                                            <div className="text-sm text-gray-900">{lavoro.codice}</div>
                                                        </td>
                                                        {/*Nome*/}
                                                        <td className="px-6 py-4">
                                                            <div className="text-sm text-gray-900">{lavoro.nome}</div>
                                                        </td>
                                                        {/*Luogo*/}
                                                        <td className="px-6 py-4">
                                                            <div className="text-sm text-gray-900">{lavoro.luogo}</div>
                                                        </td>
                                                        {/*Gruppo*/}
                                                        <td className="px-6 py-4">
                                                            <div
                                                                className="text-sm text-gray-900">{lavoro.gruppo.nome}</div>
                                                        </td>
                                                        {/*Cliente*/}
                                                        <td className="px-6 py-4">
                                                            <div
                                                                className="text-sm text-gray-900">{lavoro.cliente.anagrafica.nome} {lavoro.cliente.anagrafica.cognome}</div>
                                                        </td>
                                                        {/*Società*/}
                                                        <td className="px-6 py-4">
                                                            <div
                                                                className="text-sm text-gray-900">{lavoro.societa.nome}</div>
                                                        </td>
                                                        {/*Tag*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="text-sm text-gray-900">{lavoro.tag}</div>
                                                        </td>
                                                        {/*Inizio*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="text-sm text-gray-900">{lavoro.inizio}</div>
                                                        </td>
                                                        {/*Fine*/}
                                                        <td className="px-6 py-4 ">
                                                            <div
                                                                className="text-sm text-gray-900">{lavoro.fine === null ? "In Corso" : lavoro.fine}</div>
                                                        </td>
                                                        {/*Elimina*/}
                                                        <td className="px-6 py-4  text-sm text-gray-500">
                                                            <p
                                                                onClick={() => {
                                                                    setShowModal(true)
                                                                    setEliminazione(lavoro.id)
                                                                }}
                                                                className="text-my-red font-bold hover:text-red-700 cursor-pointer">Elimina</p>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default LavoriPage;
