import React from 'react';
import {setFilterSocieta} from "../features/filterSocietaSlice";
import {useDispatch} from "react-redux";
import {getSocieta} from "../fetch/fetchSocieta";

const FiltroSocieta = () => {

    const [societa, setSocieta] = React.useState([])

    React.useEffect(() => {
        getSocieta().then((res) => {
            const societa = res.data
            societa.unshift({id: "", nome: "Tutte le società"})
            setSocieta(societa)
        }).catch(
            (err) => {
                console.log(err)
            }
        )
    }, [])

    const dispatch = useDispatch()

    const handleChange = (e) => {
        dispatch(setFilterSocieta(e.target.value))
    }

    return (
        <div>
            <div className={"container mt-4"}>
                <select
                    onChange={handleChange}
                    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                >
                    {
                        societa.map((societa, index) => {
                            return (
                                <option
                                    key={index}
                                    value={societa.nome !== "Tutte le società" ? societa.nome : ""}
                                >
                                    {societa.nome}
                                </option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
    );
};

export default FiltroSocieta;
