import React, {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import axios from "axios";
import {proxyUrl} from "../../constants/proxyUrl";

export default function ChangePasswordModal({open, setOpen}) {

    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
        if (e.target.name === "new_password") {
            setControlliPassword({
                lunghezza: e.target.value.length >= 6,
                maiuscole: /[A-Z]/.test(e.target.value),
                minuscole: /[a-z]/.test(e.target.value),
                numeri: /[0-9]/.test(e.target.value),
                caratteriSpeciali: /[!@#$%^&*(),.?":{}|<>]/.test(e.target.value)
            })
        }
    }

    const [controlliPassword, setControlliPassword] = useState({
        lunghezza: false,
        maiuscole: false,
        minuscole: false,
        numeri: false,
        caratteriSpeciali: false
    })

    const parametriPassword = [
        "Almeno 6 caratteri",
        "Almeno una lettera maiuscola",
        "Almeno una lettera minuscola",
        "Almeno un numero",
        "Almeno un carattere speciale"
    ]

    const [formData, setFormData] = useState({
        password_attuale: "",
        new_password: "",
        new_re_password: "",
    })

    const [message, setMessage] = useState("")


    const onSubmit = (e) => {
        e.preventDefault();
        console.log(formData)

        if (Object.values(controlliPassword).every((value) => value)) {
            if (formData.new_password === formData.new_re_password) {
                axios.put(`${proxyUrl}/auth/change-password/`, formData, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        "Authorization": "Bearer " + localStorage.getItem('token')
                    }
                }).then(
                    () => {
                        setOpen(false)
                    }
                ).catch(
                    () => {
                        setMessage("C'è stato un errore")
                    }
                )
            } else {
                setMessage("Le password non coincidono")
            }
        } else {
            setMessage("La password non valida")
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                {
                                    message !== "" ? (
                                        <div className={"text-my-red"}>
                                            {message}
                                        </div>
                                    ) : null
                                }

                                <form onSubmit={onSubmit} className={"space-y-4"}>
                                    <div className={"w-full"}>
                                        <div className="flex justify-between">
                                            <label htmlFor="email"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Password Attuale
                                            </label>
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="password"
                                                onChange={onChange}
                                                name="password_attuale"
                                                className="border-transparent focus:border-transparent focus:ring-0 block w-full rounded-sm py-1.5 text-gray-900 bg-my-white"
                                                placeholder="cambiami"
                                            />
                                        </div>
                                        <div className="flex justify-between">
                                            <label htmlFor="email"
                                                   className="block text-sm font-medium leading-6 text-gray-900 mt-2">
                                                Nuova Password
                                            </label>
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="password"
                                                name="new_password"
                                                onChange={onChange}
                                                className="border-transparent focus:border-transparent focus:ring-0 block w-full rounded-sm py-1.5 text-gray-900 bg-my-white"
                                                placeholder="nuova password"
                                            />
                                        </div>
                                        <div className="flex justify-between">
                                            <label htmlFor="email"
                                                   className="block text-sm font-medium leading-6 text-gray-900 mt-2">
                                                Ripeti nuova password
                                            </label>
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="password"
                                                name="new_re_password"
                                                onChange={onChange}
                                                className="border-transparent focus:border-transparent focus:ring-0 block w-full rounded-sm py-1.5 text-gray-900 bg-my-white"
                                                placeholder="ripeti nuova password"
                                            />
                                        </div>
                                    </div>
                                    <div className={"w-full"}>
                                        {
                                            Object.entries(controlliPassword).map(([key, value], index) => {
                                                return (
                                                    <div className={"flex items-center gap-2"} key={index}>
                                                        <div className={"w-4 h-4 rounded-full"}>
                                                            {
                                                                value ?
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                         viewBox="0 0 24 24" strokeWidth={1.5}
                                                                         stroke="currentColor"
                                                                         className="w-4 h-4 text-green-500">
                                                                        <path strokeLinecap="round"
                                                                              strokeLinejoin="round"
                                                                              d="M4.5 12.75l6 6 9-13.5"/>
                                                                    </svg>
                                                                    :
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                         viewBox="0 0 24 24" strokeWidth={1.5}
                                                                         stroke="currentColor"
                                                                         className="w-4 h-4 text-red-500">
                                                                        <path strokeLinecap="round"
                                                                              strokeLinejoin="round"
                                                                              d="M6 18L18 6M6 6l12 12"/>
                                                                    </svg>

                                                            }
                                                        </div>
                                                        <div
                                                            className={"text-sm text-gray-500 dark:text-secondary-300"}>
                                                            {parametriPassword[index]}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div
                                        className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="submit"
                                            className="inline-flex w-full justify-center rounded-md bg-primary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm sm:col-start-2"
                                            onClick={(e) => {
                                                onSubmit(e)
                                            }}
                                        >
                                            Cambia Password
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={() => setOpen(false)}
                                        >
                                            Annulla
                                        </button>
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
