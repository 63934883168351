import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {
    eliminaRimborsoNotaSpese,
    eliminaSpesaNotaSpese,
    getNotaSpeseInfo,
    getResoconto,
    getSpeseNotaSpese,
    rimborsaSpesa
} from "../../../../../../fetch/fetchNotaSpese";
import BackButton from "../../../../../../components/BackButton";
import MainLayout from "../../../../../../Layouts/MainLayout";

const NotaSpeseInfo = () => {

    const {id} = useParams()

    const [speseNotaSpese, setSpeseNotaSpese] = useState([])
    const [showModal, setShowModal] = useState(false)

    const [info, setInfo] = useState({})

    const [rimborso, setRimborso] = useState({
        nota_spese: id,
        metodo_pagamento: 'Bonifico',
    })

    const handleDeleteSpesa = (id) => {
        eliminaSpesaNotaSpese(id).then(
            () => support()
        ).catch(
            (error) => console.log(error)
        )
    }

    const support = () => {
        getSpeseNotaSpese(id).then(
            r => {
                setSpeseNotaSpese(r.data)
            }
        ).catch(
            e => console.log(e)
        )
        getNotaSpeseInfo(id).then(
            r => {
                setInfo(r.data)
            }
        ).catch(
            e => console.log(e)
        )

    }

    const handleRimborso = (e, rimborso) => {
        e.preventDefault()

        rimborsaSpesa(rimborso).then(
            () => support()
        ).catch(
            (error) => console.log(error)
        )
    }

    useEffect(() => {
        support()
    }, [])

    const handleRimuoviRimborso = (e) => {
        e.preventDefault()
        eliminaRimborsoNotaSpese(id).then(
            () => support()
        ).catch(
            (error) => console.log(error)
        )
    }

    const handleGetResoconto = (e) => {
        e.preventDefault()
        getResoconto(id).then(
            r => {
                const url = window.URL.createObjectURL(new Blob([r.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `resoconto_nota_spese_${id}.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        ).catch(
            e => console.log(e)
        )
    }


    return (
        <MainLayout>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-4/5 my-6 mx-auto">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Rimborso
                                    </h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto flex flex-col gap-4"
                                >
                                    <div>
                                        <label className="block text-my-gray text-sm font-bold mb-2">
                                            Metodo di pagamento
                                        </label>
                                        <select
                                            onChange={(e) => setRimborso({
                                                ...rimborso,
                                                metodo_pagamento: e.target.value
                                            })}
                                            className={"flex w-full rounded border border-gray-300 p-2"}
                                        >
                                            <option value="Bonifico">Bonifico</option>
                                            <option value="Assegno">Assegno</option>
                                            <option value="Contanti">Contanti</option>
                                            <option value="RIBA">RIBA</option>
                                            <option value="SSD">SSD</option>
                                            <option value="Carta di Credito">Carta di Credito</option>
                                        </select>
                                    </div>
                                </div>
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-my-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Annulla
                                    </button>
                                    <button
                                        className="bg-primary-blue text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(e) => {
                                            handleRimborso(e, rimborso)
                                            setShowModal(false)
                                        }}
                                    >
                                        Salva
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>

                <div
                    className="sm:w-full lg:w-5/6 mx-auto px-2 flex flex flex-col md:flex-row space-y-4 md:space-y-0 items-center justify-evenly">
                    <div className={"flex flex-col items-center"}>
                        <h1 className={"text-3xl text-center text-black uppercase font-bold "}>Nota Spese Info</h1>
                        <div className={"w-20 h-1 bg-primary-blue mt-2"}/>
                    </div>
                    <Link to={`/nota_spese/spesa/aggiungi/${id}`}
                          className={"flex bg-my-green px-4 py-2 rounded text-white font-semibold hover:bg-green-600 cursor-pointer"}>
                        Aggiungi
                    </Link>
                    {
                        speseNotaSpese.length > 0 ? (
                            info.is_rimborsata ? (
                                <div
                                    onClick={(e) => handleRimuoviRimborso(e)}
                                    className={"flex bg-my-red px-4 py-2 rounded text-white font-semibold hover:bg-red-600 cursor-pointer"}>
                                    Rimborsata
                                </div>
                            ) : (
                                <div
                                    onClick={() => setShowModal(true)}
                                    className={"flex bg-primary-blue px-4 py-2 rounded text-white font-semibold hover:bg-blue-600 cursor-pointer"}>
                                    Rimborsa
                                </div>
                            )
                        ) : null
                    }
                    <div
                        onClick={(e) => handleGetResoconto(e)}
                        className={"flex bg-yellow-600 px-4 py-2 rounded text-white font-semibold hover:bg-yellow-700 cursor-pointer"}>
                        Resoconto
                    </div>
                </div>

                {
                    speseNotaSpese.length > 0 ? (
                        <div className={"flex flex-col items-center justify-center gap-8 my-8"}>
                            {
                                speseNotaSpese.map((spesa, i) => (
                                    <div className={"py-4 bg-my-white w-4/5 rounded"} key={i}>
                                        <div className="flex flex-col container">
                                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div
                                                    className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                    <div
                                                        className={"flex flex-col sm:flex-row justify-between items-center"}>
                                                        <div className={"font-bold text-3xl"}>
                                                            Spesa n° {i + 1}
                                                        </div>
                                                        <div
                                                            className={"grid grid-cols-2 gap-4 text-center"}>
                                                            <div
                                                                onClick={() => handleDeleteSpesa(spesa.id)}
                                                                className={"bg-my-red font-semibold text-white border-my-red border-2 px-4 p-2 rounded cursor-pointer hover:bg-my-white hover:text-my-red "}>
                                                                Elimina
                                                            </div>
                                                            <Link to={`/allegati/${spesa.id}`}
                                                                  className={"bg-primary-blue font-semibold text-white border-primary-blue border-2 px-4 p-2 rounded cursor-pointer hover:bg-my-white hover:text-primary-blue "}>
                                                                Allegati
                                                            </Link>
                                                        </div>

                                                    </div>
                                                    <div className={"my-4"}>
                                                        <p className={"font-semibold"}>Descrizione: </p>
                                                        <p className={"italic"}>
                                                            {spesa.descrizione}
                                                        </p>
                                                    </div>
                                                    <div className={"flex flex-col sm:flex-row justify-evenly"}>
                                                        <div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Data: </p>
                                                                <p className={"italic"}>
                                                                    {spesa.data}
                                                                </p>
                                                            </div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Seriale: </p>
                                                                <p className={"italic"}>
                                                                    {info.seriale_rimborso || "Non ancora rimborsata"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Costo Sostenuto: </p>
                                                                <p className={"italic"}>
                                                                    {spesa.costo_sostenuto} €
                                                                </p>
                                                            </div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Metodo rimborso: </p>
                                                                <p className={"italic"}>
                                                                    {info.metodo_rimborso || "Non ancora rimborsata"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Società: </p>
                                                                <p className={"italic"}>
                                                                    {spesa.nota_spese.societa.nome}
                                                                </p>
                                                            </div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Categoria: </p>
                                                                <p className={"italic"}>
                                                                    {spesa.categoria.nome}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Anagrafica: </p>
                                                                <p className={"italic"}>
                                                                    {info.anagrafica}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ) : (
                        <div className={"flex flex-col items-center justify-center gap-8 my-8"}>
                            <div className={"py-4 bg-my-white w-4/5 rounded"}>
                                <div className="flex flex-col container">
                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className={"flex justify-between items-center"}>
                                                <div className={"font-bold text-3xl"}>
                                                    Nessuna spesa
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            </div>
        </MainLayout>
    );
};

export default NotaSpeseInfo;
