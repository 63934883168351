import React, {useEffect, useState} from 'react';
import {deleteAnagraficaEvento, getAnagraficheEventiLavoro} from "../../../../../fetch/fetchEventiLavoro";
import {Link} from "react-router-dom";

const BannerEventoCommessa = ({evento, handleDelete, index}) => {
    const dateParser = (date) => {
        const dateSplit = date.split("T");
        const dateSplit2 = dateSplit[0].split("-");
        const dateSplit3 = dateSplit[1].split(":");
        const dateSplit4 = dateSplit3[2].split(".");
        return dateSplit2[2] + "/" + dateSplit2[1] + "/" + dateSplit2[0] + " " + dateSplit3[0] + ":" + dateSplit3[1] + ":" + dateSplit4[0];
    }


    const [anagraficheEventi, setAnagraficheEventi] = useState([]);

    const supporto = () => {
        getAnagraficheEventiLavoro(evento.id).then((response) => {
            setAnagraficheEventi(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        supporto()
    }, []);

    const handleDeleteAnagraficaEvento = (id) => {
        deleteAnagraficaEvento(id).then(() => {
            supporto()
        }).catch((error) => {
            console.log(error);
        })
    }


    return (
        <div className={"border-2 rounded-md"}>
            {/*Head*/}
            <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
                <div
                    className="-ml-4 -mt-4 flex flex-col items-center justify-between sm:flex-row">
                    <div className="ml-4 mt-4 w-full">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{index + 1})
                            Evento {evento.commessa.nome}</h3>
                        <p className="mt-1 text-sm text-gray-500">
                            {evento.commento}
                        </p>
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0">
                        <div
                            onClick={() => handleDelete(evento.id)}
                            className="ml-6 rounded bg-my-red text-white py-2 px-4 text-sm font-semibold cursor-pointer"
                        >
                            Elimina
                        </div>
                    </div>
                </div>
            </div>
            {/*Info*/}
            <div
                className={"px-4 border-b py-5 sm:px-6 grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5"}>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Data:
                    </div>
                    {dateParser(evento.data)}
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Creatore:
                    </div>
                    {evento.creatore.username}
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Luogo:
                    </div>
                    {evento.luogo ? evento.luogo : "Non specificato"}
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Foto:
                    </div>
                    {
                        evento.foto ? (
                            <a className={""} href={evento.foto} target={"_blank"}>
                                Visualizza Foto
                            </a>
                        ) : (
                            "Non Disponibile"
                        )
                    }
                </div>
            </div>
            {/*Anagrafiche*/}
            <div className={"px-4 py-5 sm:px-6 grid grid-cols-1  md:grid-cols-3 gap-5"}>
                {
                    anagraficheEventi.length > 0 ? (
                        <div className={"col-span-3 flex flex-col"}>
                            <div className={"grid grid-cols-1 lg:grid-cols-3 gap-5"}>
                                {
                                    anagraficheEventi.map((anagrafica, index) => (
                                        <div className={"col-span-1"}>
                                            <div className={"flex items-center justify-start"} key={index}>
                                                <div
                                                    onClick={() => handleDeleteAnagraficaEvento(anagrafica.id)}
                                                    className={"font-semibold mr-1 text-red-500 cursor-pointer hover:underline"}> Rimuovi:
                                                </div>
                                                {anagrafica.anagrafica.nome} {anagrafica.anagrafica.cognome}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <Link
                                to={"/lavori/eventi/" + evento.id + "/anagrafiche/crea"}
                                className="mt-4 items-center justify-center flex rounded bg-my-green text-white py-2 px-4 text-sm font-semibold cursor-pointer"
                            >
                                Aggiungi
                            </Link>
                        </div>

                    ) : (
                        <div className={"col-span-3 flex justify-between items-center"}>
                            <div>
                                Non ci sono anagrafiche coinvolte
                            </div>
                            <Link
                                to={"/lavori/eventi/" + evento.id + "/anagrafiche/crea"}
                                className="ml-6 items-center justify-center flex rounded bg-my-green text-white py-2 px-4 text-sm font-semibold cursor-pointer"
                            >
                                Aggiungi
                            </Link>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default BannerEventoCommessa;
