import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const getProgetti = async () => {
    return axios.get(proxyUrl + "/post-progetti/")
}

export const getProgettiGestionale = async (token) => {
    try {
        const response = await fetch(proxyUrl + "/api/lavori/progetti_gestionale/", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await response.json()
    } catch (error) {
        console.log(error.message)
    }
}

export const getProgetto = async (id, token) => {
    try {
        const response = await fetch(proxyUrl + `/api/lavori/progetti/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await response.json()
    } catch (error) {
        console.log(error.message)
    }
}

export const deleteProgetto = async (id, token) => {
    try {
        await fetch(proxyUrl + `/api/lavori/progetti/${id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
    } catch (error) {
        console.log(error.message)
    }
}
