import React, {useEffect, useState} from 'react';
import {getAnagrafiche} from "../../../../../fetch/fetchAnagrafica";
import Navbar from "../../../../../components/Navbar";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import BackButton from "../../../../../components/BackButton";
import HeaderSection from "../../../../../components/HeaderSection";
import {MyCombobox} from "../../../../../components/combobox/MyCombobox";
import Footer from "../../../../../components/Footer";
import {sleep} from "../../../../../constants/sleep";
import {addAnagraficaEventiLavoro} from "../../../../../fetch/fetchEventiLavoro";
import {useParams} from "react-router-dom";


const AggiungiAnagraficaEvento = () => {

    const {id} = useParams();

    const [alert, setAlert] = useState(0)
    const [anagrafica, setAnagrafica] = useState([])
    const [selectedAnagrafica, setSelectedAnagrafica] = useState()

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('anagrafica', selectedAnagrafica.id);
        postData.append('evento', id);

        addAnagraficaEventiLavoro(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    }

    useEffect(() => {
        getAnagrafiche().then(r => {
            setAnagrafica(r.data)
            setSelectedAnagrafica(r.data[0])
        }).catch(e => {
            console.log(e)
        })
    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"}
                                          message={"Anagrafica Evento è stata aggiunta con successo"}/>
                        ) : (
                            <div/>
                        )
                    }
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi Anagrafica Evento"}/>
                    <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                        <div
                            className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                            <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                {/*Anagrafica*/}
                                <div className="col-span-2 lg:col-span-2">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Anagrafica
                                        </div>
                                        <MyCombobox
                                            valueList={anagrafica}
                                            selectedItem={selectedAnagrafica}
                                            setSelectedItem={setSelectedAnagrafica}
                                        />
                                    </div>
                                </div>

                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default AggiungiAnagraficaEvento;
