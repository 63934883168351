import React, {useEffect, useState} from 'react';
import Navbar from "../../../../components/Navbar";
import HeaderSection from "../../../../components/HeaderSection";
import Footer from "../../../../components/Footer";
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import {sleep} from "../../../../constants/sleep";
import {getClienti} from "../../../../fetch/fetchClienti";
import {creaPreventivo} from "../../../../fetch/fetchPreventivo";
import {getSocieta} from "../../../../fetch/fetchSocieta";
import BackButton from "../../../../components/BackButton";
import {ComboboxCliente} from "../../../../components/combobox/ComboboxCliente";

const PreventiviForm = () => {
    const [alert, setAlert] = useState(0)
    const [clienti, setClienti] = useState([])
    const [societa, setSocieta] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();

        if (e.target.preventivo.files[0] !== undefined) {
            postData.append('copia_preventivo', e.target.preventivo.files[0], e.target.preventivo.files[0].name);
            postData.append('is_accettato', "In Attesa Cliente");
        } else {
            postData.append('is_accettato', "In Compilazione");
        }

        postData.append('descrizione', e.target.descrizione.value);
        postData.append('cliente', selectedCliente.id);
        postData.append('societa', e.target.societa.value);

        creaPreventivo(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    }

    const [selectedCliente, setSelectedCliente] = useState()

    useEffect(() => {
        getClienti().then(r => {
            setClienti(r.data)
            setSelectedCliente(r.data[0])
        }).catch(e => {
            console.log(e)
        })
        getSocieta().then(r => {
            setSocieta(r.data)
        }).catch(e => {
            console.log(e)
        })
    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"L'offerta è stata aggiunta con successo"}/>
                        ) : (
                            <div/>
                        )
                    }
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi Offerta"}/>
                    <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                        <div
                            className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                            <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">

                                {/*Nome*/}
                                <div className="col-span-2 lg:col-span-2 ">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Descrizione Preventivo
                                        </div>
                                        <div className="relative">
                                            <textarea
                                                name={"descrizione"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Descrizione Preventivo"
                                                maxLength={255}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Societa*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Società
                                        </div>
                                        <select
                                            name={"societa"}
                                            defaultValue={societa[0]}
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                societa.map((soc, index) => (
                                                    <option
                                                        key={index}
                                                        value={soc.id}
                                                    >
                                                        {soc.nome}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Cliente
                                        </div>
                                        {/*Cliente*/}
                                        <ComboboxCliente
                                            valueList={clienti}
                                            selectedItem={selectedCliente}
                                            setSelectedItem={setSelectedCliente}
                                        />
                                    </div>
                                </div>

                                {/*Foto*/}
                                <div className="col-span-2">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Copia Preventivo (Facoltativo)
                                        </div>
                                        <label className="text-gray-700" htmlFor="name">
                                            <input
                                                type="file"
                                                name="preventivo"
                                                accept="application/pdf"
                                                className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-sm focus:outline-none"
                                            />
                                        </label>
                                    </div>
                                </div>
                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default PreventiviForm;
