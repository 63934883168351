import axios from "axios";
import {proxyUrl} from "../constants/proxyUrl";

export const addBustaPaga = async (formData) => {
    const options = {
        url: `${proxyUrl}/buste-paga/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const getBustePaga = async () => {
    const options = {
        url: `${proxyUrl}/buste-paga/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getBustePagaPersonali = async () => {
    const options = {
        url: `${proxyUrl}/buste-paga/personali/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const eliminaBustePaga = async (id) => {
    const options = {
        url: `${proxyUrl}/buste-paga/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const editBustePaga = async (id, data) => {
    const options = {
        url: `${proxyUrl}/buste-paga/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: data
    };

    return axios(options)
}
