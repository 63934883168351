import React, {useEffect, useState} from 'react';
import {getMateriale} from "../../../../../fetch/fetchMateriali";
import {sleep} from "../../../../../constants/sleep";
import Navbar from "../../../../../components/Navbar";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import BackButton from "../../../../../components/BackButton";
import HeaderSection from "../../../../../components/HeaderSection";
import Footer from "../../../../../components/Footer";
import {getFornitori} from "../../../../../fetch/fetchFornitori";
import {getProduttori} from "../../../../../fetch/fetchProduttori";
import {aggiungiCaricoMagazzino, getBolleTrasporto, getSediMagazzino} from "../../../../../fetch/fetchMagazzino";
import {useQuery} from "react-query";

const AggiungiCaricoMagazzino = () => {
    const [alert, setAlert] = useState(0)

    const [fornitori, setFornitori] = useState([]);
    const [produttori, setProduttori] = useState([]);

    const [bolle, setBolle] = useState([])
    useQuery({
        queryKey: "bolle-trasporto",
        queryFn: getBolleTrasporto,
        onSuccess: (data) => {
            setBolle(data.data)
        }
    })

    const [magazzini, setMagazzini] = React.useState([
        {
            id: 0,
        }
    ]);

    useQuery({
        queryKey: "magazzini",
        queryFn: getSediMagazzino,
        onSuccess: (data) => {
            setMagazzini(data.data)
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)

        aggiungiCaricoMagazzino(formData).then(() => {
            setAlert(1)
            sleep(3000).then(() =>
                setAlert(0)
            )
        }).catch(() => {
            setAlert(-1)
        })

    };

    const [materiali, setMateriali] = useState([]);

    useEffect(() => {
        getMateriale().then(r => {
            setMateriali(r.data)
        }).catch(e => {
            console.log(e)
        })

        getFornitori().then(r => {
            setFornitori(r.data)
        }).catch(e => {
            console.log(e)
        })
        getProduttori().then(r => {
            setProduttori(r.data)
        }).catch(e => {
            console.log(e)
        })
    }, [])


    return (
        <div className={"flex flex-col h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"Il Materiale è stato aggiunto con successo"}/>
                        ) : null
                    }
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi Nuovo Carico"}/>
                    <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                        <div
                            className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                            <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                {/*Materiale*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Materiale
                                        </div>
                                        <select
                                            name={"materiale"}
                                            defaultValue={materiali[0]}
                                            required
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                materiali.map((prod, index) => (
                                                    <option
                                                        key={index}
                                                        value={prod.id}
                                                    >
                                                        {prod.materiale}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*InventarioMagazzino*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Magazzino
                                        </div>
                                        <select
                                            name={"magazzino"}
                                            defaultValue={magazzini[0].id}
                                            required
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                magazzini.map((prod, index) => (
                                                    <option
                                                        key={index}
                                                        value={prod.id}
                                                    >
                                                        {prod.nome}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*Quantità*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Quantità
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="number"
                                                step={1}
                                                name={"quantita"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Quantità"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Costo Articolo*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Costo Singolo Articolo
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="number"
                                                step={0.01}
                                                name={"costo_singolo_articolo"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Costo Singolo Articolo"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Fornitore*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Fornitore
                                        </div>
                                        <select
                                            name={"fornitore"}
                                            defaultValue={fornitori[0]}
                                            required
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                fornitori.map((prod, index) => (
                                                    <option
                                                        key={index}
                                                        value={prod.id}
                                                    >
                                                        {prod.anagrafica.nome}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*Produttore*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Produttore
                                        </div>
                                        <select
                                            name={"produttore"}
                                            defaultValue={produttori[0]}
                                            required
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                produttori.map((s, index) => (
                                                    <option
                                                        key={index}
                                                        value={s.id}
                                                    >
                                                        {s.anagrafica.nome}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*Bolla Trasporto*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Bolla Trasporto
                                        </div>
                                        <select
                                            name={"bolla_trasporto"}
                                            defaultValue={bolle[0]}
                                            required
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                bolle.map((bolla, index) => (
                                                    <option
                                                        key={index}
                                                        value={bolla.id}
                                                    >
                                                        {bolla.numero_bolla}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer/>
        </div>

    );
};

export default AggiungiCaricoMagazzino;
