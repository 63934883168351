import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const getPercentualeRicarico = async () => {
    const options = {
        url: `${proxyUrl}/lavori/percentuale/1`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const modificaPercentualeRicarico = async (data) => {
    const options = {
        url: `${proxyUrl}/lavori/percentuale/1`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: data
    };

    return axios(options)
}

export const addLavoro = async (formData) => {
    const options = {
        url: `${proxyUrl}/lavori/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const delLavoro = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getLavori = async () => {
    const options = {
        url: `${proxyUrl}/lavori/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getUltimoLavoro = async () => {
    const options = {
        url: `${proxyUrl}/lavori/ultimo`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const editLavoro = async (formData, id) => {
    const options = {
        url: `${proxyUrl}/lavori/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


export const getInfoLavoro = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/info/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const getGruppi = async () => {
    const options = {
        url: `${proxyUrl}/lavori/gruppi`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const addGruppo = async (formData) => {
    const options = {
        url: `${proxyUrl}/lavori/gruppi/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const eliminaGruppo = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/gruppi/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
