import React, {useState} from 'react';
import {aggiungiBollaTrasporto} from "../../../../../fetch/fetchMagazzino";
import {sleep} from "../../../../../constants/sleep";
import Navbar from "../../../../../components/Navbar";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import BackButton from "../../../../../components/BackButton";
import HeaderSection from "../../../../../components/HeaderSection";
import Footer from "../../../../../components/Footer";
import {useQuery} from "react-query";
import {getFornitori} from "../../../../../fetch/fetchFornitori";
import {getLavori} from "../../../../../fetch/fetchLavori";

const AggiungiBollaTrasporto = () => {
    const [alert, setAlert] = useState(0)
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)

        aggiungiBollaTrasporto(formData).then(() => {
            setAlert(1)
            sleep(3000).then(() =>
                setAlert(0)
            )
        }).catch(() => {
            setAlert(-1)
        })
    };

    function generateFormControl(label, type, name, placeholder = '', long = false, required = true) {
        return (
            <div className={long ? "col-span-2" : "col-span-2 lg:col-span-1"}>
                <div className={"flex flex-col lg:flex-col"}>
                    <div className={"text-my-gray"}>
                        {label} {required ? <span className={"text-red-500"}>*</span> : null}
                    </div>
                    <div className="relative">
                        <input
                            type={type}
                            name={name}
                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                            placeholder={placeholder}
                            required={required}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const [fornitori, setFornitori] = useState([])
    const [commesse, setCommesse] = useState([])

    useQuery("fornitori", getFornitori, {
        onSuccess: (data) => {
            setFornitori(data.data)
        }
    })

    useQuery("commesse", getLavori, {
        onSuccess: (data) => {
            setCommesse(data.data)
        }
    })


    return (
        <div className={"flex flex-col h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"La Bolla è stata aggiunta con successo"}/>
                        ) : null
                    }
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi Nuova Bolla Trasporto"}/>
                    <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                        <div
                            className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                            <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                {generateFormControl("Codice DDT", "text", "numero_ddt", "Codice DDT")}
                                {generateFormControl("Data DDT", "date", "data_ddt")}
                                {generateFormControl("DDT", "file", "ddt")}
                                {generateFormControl("Descrizione", "text", "descrizione", "Descrizione", true)}
                                {generateFormControl("Data Consegna", "date", "data_consegna")}
                                {generateFormControl("Luogo Consegna", "text", "luogo_consegna", "Luogo Consegna")}
                                {generateFormControl("Note", "text", "note", "Note", true, false)}

                                {/*Fornitore*/}
                                <div className="col-span-2">
                                    <div className={"flex flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Fornitore <span className={"text-red-500"}>*</span>
                                        </div>
                                        <div className="relative">
                                            <select
                                                name={"fornitore"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                required
                                            >
                                                <option value={""} className={"text-my-gray"}>Seleziona un fornitore</option>
                                                {
                                                    fornitori.map((fornitore) => (
                                                        <option
                                                            value={fornitore.id}>{fornitore.anagrafica.nome}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/*Commessa*/}
                                <div className="col-span-2">
                                    <div className={"flex flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Commessa
                                        </div>
                                        <div className="relative">
                                            <select
                                                name={"commessa"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-my-gray placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            >
                                                <option value={""}>Seleziona una commessa</option>
                                                {
                                                    commesse.map((commessa) => (
                                                        <option
                                                            value={commessa.id}>{commessa.nome}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer/>
        </div>

    );
};

export default AggiungiBollaTrasporto;
