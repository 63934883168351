import React, {useState} from 'react';
import {useQuery} from "react-query";
import {editCandidatura, getCandidature} from "../../../../../fetch/fetchCareers";
import MainLayout from "../../../../../Layouts/MainLayout";
import BackButton from "../../../../../components/BackButton";
import HeaderSection from "../../../../../components/HeaderSection";

const CandidaturePage = () => {
    const [careers, setCareers] = useState([])

    useQuery('candidature', getCandidature, {
        onSuccess: (data) => {
            setCareers(data.data)
        }
    })

    const [filter, setFilter] = useState("")

    const headers = ["Stato", "Nome", "Cognome", "Email", "Telefono", "CV", "Cover Letter", "Messaggio"]

    function TableCell({className, children}) {
        return (
            <td className={`px-6 py-4 ${className}`}>
                <div className="text-sm text-gray-900">{children}</div>
            </td>
        );
    }

    const changeStatus = (e, id, status) => {
        e.preventDefault()
        let t
        if (status === "In attesa") {
            t = "Accettato"
        } else if (status === "Accettato") {
            t = "Rifiutato"
        } else {
            t = "In attesa"
        }

        editCandidatura(id, {stato: t}).then(
            () => {
                setCareers(careers.map(c => {
                    if (c.id === id) {
                        c.stato = t
                    }
                    return c
                }))
            }
        ).catch(
            (error) => {
                console.log(error)
            }
        )

    }

    return (
        <MainLayout>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Candidature"}/>
                <div className={"my-10 flex items-center justify-center"}>
                    <div className="flex flex-col w-11/12">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={"Cerca..."}
                                            className={"w-full py-2 px-4 outline-none border-none"}
                                            onChange={event => setFilter(event.target.value)}
                                        />
                                    </div>
                                    <div className={"max-h-96 overflow-auto"}>
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50 sticky top-0 z-10">
                                            <tr>
                                                {headers.map((header, index) =>
                                                    <th
                                                        key={index}
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        {header}
                                                    </th>
                                                )}
                                            </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                careers.filter(c => (
                                                    c.nome.toLowerCase().includes(filter.toLowerCase()) ||
                                                    c.conome.toLowerCase().includes(filter.toLowerCase()) ||
                                                    c.email.toLowerCase().includes(filter.toLowerCase()) ||
                                                    c.telefono.toLowerCase().includes(filter.toLowerCase()) ||
                                                    c.posizione.toLowerCase().includes(filter.toLowerCase())
                                                )).map((career, i) => (
                                                    <tr key={i}>
                                                        <td
                                                            className={`px-6 py-4`}
                                                            onClick={(e) => changeStatus(e, career.id, career.stato)}
                                                        >
                                                            {
                                                                career.stato === "In attesa" ? (
                                                                    <div
                                                                        className="text-sm px-2 cursor-pointer text-white bg-yellow-400 rounded py-1 text-center font-semibold">{career.stato}</div>
                                                                ) : career.stato === "Accettato" ? (
                                                                    <div
                                                                        className="text-sm px-2 cursor-pointer text-white bg-green-400 rounded py-1 text-center font-semibold">{career.stato}</div>
                                                                ) : (
                                                                    <div
                                                                        className="text-sm px-2 cursor-pointer text-white bg-red-400 rounded py-1 text-center font-semibold">{career.stato}</div>
                                                                )
                                                            }

                                                        </td>
                                                        <TableCell>{career.nome}</TableCell>

                                                        <TableCell>{career.cognome}</TableCell>

                                                        <TableCell>{career.email}</TableCell>

                                                        <TableCell>{career.telefono}</TableCell>

                                                        <TableCell>
                                                            <a href={career.cv} target="_blank" rel="">
                                                                Visualizza CV
                                                            </a>
                                                        </TableCell>

                                                        <TableCell>
                                                            {career.cover_letter === null ? (
                                                                <div className="text-gray-500">Nessuna Cover
                                                                    Letter</div>
                                                            ) : (
                                                                <a href={career.cover_letter} target="_blank" rel="">
                                                                    Visualizza Cover Letter
                                                                </a>
                                                            )}
                                                        </TableCell>

                                                        <TableCell>{career.messaggio}</TableCell>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default CandidaturePage;
