import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import HeaderSection from "./HeaderSection";
import {useDispatch, useSelector} from "react-redux";
import {setFilter} from "../redux/actions";
import {getPostLavori} from "../fetch/lavori_post";

const ProjectGallery = () => {

    const [projects, setProjects] = useState([])
    const filtro = useSelector(state => state.filterReducer)
    const dispatch = useDispatch()

    const filtri = [
        {
            name: "Nessun Filtro",
            value: "RESET"
        },
        {
            name: "Finanza",
            value: "FINANZA"
        },
        {
            name: "Ingegneria",
            value: "INGEGNERIA"
        },
        {
            name: "Elettrotecnica",
            value: "ELETTROTECNICA"
        },
    ]

    useEffect(() => {
        getPostLavori().then(r => setProjects(r.data))
    }, [])

    return (
        <div>
            <section>
                <div className={"mt-12"}>
                    <HeaderSection title={"I nostri Progetti"}/>
                </div>

                <div className={"container mt-4"}>
                    <select
                        onChange={(event => dispatch(setFilter(event.target.value)))}
                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                    >
                        {
                            filtri.map((f, index) => (
                                filtro === f.name ? (
                                    <option
                                        selected
                                        key={index}
                                        value={f.value}
                                    >
                                        {f.name}
                                    </option>
                                ) : (
                                    <option
                                        key={index}
                                        value={f.value}
                                    >
                                        {f.name}
                                    </option>
                                )
                            ))
                        }
                    </select>
                </div>


                <div className="max-w-2xl mx-auto py-4 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
                        {
                            projects.filter(progetto => {
                                if (filtro === "Reset") {
                                    return progetto
                                } else if (
                                    progetto.lavoro.tag.includes(filtro)
                                ) {
                                    return progetto
                                }
                            }).map((project, index) => (
                                <Link to={`/project/${project.id}`} key={index}>
                                    <div
                                        className="w-full aspect-w-1 aspect-h-1 bg-black bg-opacity-20 overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                                        <img
                                            src={project.foto}
                                            alt={""}
                                            className="mix-blend-overlay w-full h-full object-center object-cover group-hover:opacity-75"
                                        />

                                        <div
                                            className={"z-10 text-transparent hover:text-black hover:bg-white transition duration-300  flex flex-col justify-center text-center items-center"}>
                                            <p className={"flex uppercase p-2"}>
                                                {project.lavoro.nome}
                                            </p>
                                            <div className={"flex p-2 flex-col"}>
                                                <p>{project.lavoro.luogo}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ProjectGallery;
