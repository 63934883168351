import React, {useState} from 'react';
import MainLayout from "../Layouts/MainLayout";
import 'react-quill/dist/quill.snow.css';
import Table from "../components/Table/Table";

const TestPage = () => {
    const [value, setValue] = useState('');

    const nomiColonne = [
        "Info",
        "Nome",
        "Cognome",
        "Email",
        "Telefono",
        "Modifica",
        "Elimina"
    ]

    const items = [
        {
            "id": 1,
            "nome": "Mario",
            "cognome": "Rossi",
            "email": "mario@rossi.com",
            "telefono": "1234567890",
        },
        {
            "id": 2,
            "nome": "Luigi",
            "cognome": "Verdi",
            "email": "luigi@verdi.com",
            "telefono": "1234667890",
        }
    ]

    const campi = [
        "info",
        "nome",
        "cognome",
        "email",
        "telefono",
        "modifica",
        "elimina",
    ]

    return (
        <MainLayout>
            <div className={"m-32"}>
                <Table
                    nomiColonne={nomiColonne}
                    items={items}
                    campi={campi}
                />
            </div>

        </MainLayout>
    );
};

export default TestPage;
