import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getDipendenti} from "../../../../../fetch/fetchDipendenti";
import FiltroSocieta from "../../../../../components/FiltroSocieta";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import BackButton from "../../../../../components/BackButton";
import MainLayout from "../../../../../Layouts/MainLayout";

const DipendentiPage = () => {

    const [filter, setFilter] = useState("")
    const {filtroSoc} = useSelector(state => state.societa)

    const [dipendenti, setDipendenti] = useState([])


    useEffect(() => {
        getDipendenti().then(r => {
            setDipendenti(r.data)
        }).catch(
            e => console.log(e)
        )
    }, [])

    return (
        <MainLayout margin={"mt-10"}>
            <div className={"flex px-20 py-2"}>
                <BackButton/>
            </div>
            <HeaderSectionAdd titolo={"Dipendenti"} link={"/add_dipendente"} esporta={"dipendenti"}/>
            <FiltroSocieta/>
            <div className={"my-10"}>
                <div className={"flex w-full justify-center items-center"}>
                    <div className="flex flex-col w-11/12">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={"Cerca..."}
                                            className={"w-full py-2 px-4 outline-none border-none"}
                                            onChange={event => setFilter(event.target.value)}
                                        />
                                    </div>
                                    <div className={"max-h-96 overflow-auto"}>
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50 sticky top-0 z-10">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Name e Cognome
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Ruolo
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Email
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Telefono
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Iban
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Società
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Info
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                dipendenti.filter(dipendente => {
                                                    if (filter === "") {
                                                        return dipendente
                                                    } else if (
                                                        dipendente.anagrafica.nome.toLowerCase().includes(filter.toLowerCase()) ||
                                                        dipendente.anagrafica.cognome.toLowerCase().includes(filter.toLowerCase())
                                                    ) {
                                                        return dipendente
                                                    }
                                                }).filter(dip => (
                                                    dip.societa.nome.toLowerCase().includes(filtroSoc.toLowerCase())
                                                )).map((dipendente, i) => (
                                                    <tr key={i}>
                                                        {/*Nome Dipendente*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="text-sm font-medium text-gray-900">
                                                                    {dipendente.anagrafica.nome} {dipendente.anagrafica.cognome}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Ruolo*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="text-sm text-gray-900">{dipendente.ruolo}</div>
                                                        </td>
                                                        {/*Email*/}
                                                        <td className="px-6 py-4 ">
                                                            <div
                                                                className="text-sm text-gray-900">{dipendente.anagrafica.email_personale}</div>
                                                        </td>
                                                        {/*Telefono*/}
                                                        <td className="px-6 py-4  text-sm text-gray-500">
                                                            {dipendente.anagrafica.cellulare}
                                                        </td>
                                                        {/*Iban*/}
                                                        <td className="px-6 py-4  text-sm text-gray-500">
                                                            {dipendente.anagrafica.iban_1 || "Non presente"}
                                                        </td>
                                                        {/*Società*/}
                                                        <td className="px-6 py-4  text-sm text-gray-500">
                                                            {dipendente.societa.nome}
                                                        </td>
                                                        {/*Info*/}
                                                        <td className="px-6 py-4  text-sm text-gray-500">
                                                            <Link to={`/info_dipendente/${dipendente.id}`}>
                                                                <p className="text-my-green font-bold hover:text-green-700">Info</p>
                                                            </Link>
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default DipendentiPage;
