import React from 'react';
import HeaderSection from "../../../components/HeaderSection";
import {getEventiUtente} from "../../../fetch/fetchEventi";
import BannerEvento from "../../Gestionale/Scadenzario/BannerEvento";
import MainLayout from "../../../Layouts/MainLayout";
import {useQuery} from "react-query";
import {proxyUrl} from "../../../constants/proxyUrl";
import axios from "axios";


export const getTaskUtente = async (path) => {

    const url = path !== "" ? `${proxyUrl}/lavori/task-utente/${path}` : `${proxyUrl}/lavori/task-utente/`;

    const options = {
        url: url,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

const EventiProfiloOggi = () => {

    const [eventi, setEventi] = React.useState([]);


    useQuery({
        queryKey: "eventiUtenteOggi" + localStorage.getItem("username"),
        queryFn: () => getEventiUtente("oggi"),
        onSuccess: (r) => {
            setEventi(r.data);
        }
    })


    return (
        <MainLayout margin={"mt-16"}>
            <div className={"flex flex-col space-y-4"}>
                <div className={"flex flex-col justify-center items-center"}>
                    <HeaderSection title={"I Tuoi Eventi di Oggi"}/>
                    <div className={"flex flex-col space-y-4 my-10 w-3/5"}>
                        {
                            eventi.length !== 0 ? (
                                eventi.map((evento, index) => (
                                    <BannerEvento key={index} evento={evento}/>
                                ))
                            ) : (
                                <div className={"flex flex-col justify-center items-center"}>
                                    <h1 className={"text-2xl font-bold text-gray-700"}>Nessun Evento Oggi</h1>
                                    <h1 className={"text-xl font-bold text-gray-700"}>Ripassa più tardi</h1>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default EventiProfiloOggi;
