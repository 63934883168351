import React, {useEffect, useState} from 'react';
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import HeaderSection from "../../../../components/HeaderSection";
import Navbar from "../../../../components/Navbar";
import Footer from "../../../../components/Footer";
import {creaMateriale, getCategorieMateriali, getSottoCategorieMateriali} from "../../../../fetch/fetchMateriali";
import BackButton from "../../../../components/BackButton";
import {sleep} from "../../../../constants/sleep";

const MaterialeForm = () => {

    const [alert, setAlert] = useState(0)

    const [categorie, setCategorie] = useState([]);
    const [sottocategorie, setSottocategorie] = useState([]);

    const unita_misure = ["cad", "m", "kg", "pz"]

    // const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData()

        formData.append('codice_articolo', e.target.codice_articolo.value)
        formData.append('materiale', e.target.materiale.value)
        formData.append('categoria', e.target.categoria.value)
        formData.append('sottocategoria', e.target.sottocategoria.value)
        formData.append('serie_produttore', e.target.serie_produttore.value)
        formData.append('unita_misura', e.target.unita_misura.value)
        formData.append('notes', e.target.notes.value)

        creaMateriale(formData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    useEffect(() => {
        getCategorieMateriali().then(r => {
            setCategorie(r.data)
        }).catch(e => {
            console.log(e)
        })
        getSottoCategorieMateriali().then(r => {
            setSottocategorie(r.data)
        }).catch(e => {
            console.log(e)
        })
    }, [])


    return (
        <div className={"flex flex-col h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"Il Materiale è stato aggiunto con successo"}/>
                        ) : null
                    }
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi Nuovo Materiale"}/>
                    <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                        <div
                            className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                            <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                {/*Codice Articolo*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Codice Articolo
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name={"codice_articolo"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Codice Articolo"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Bar code*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Bar Code
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name={"bar_code"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Bar Code"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Materiale*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Materiale
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name={"materiale"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Materiale"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Cat*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Categorie
                                        </div>
                                        <select
                                            name={"categoria"}
                                            defaultValue={categorie[0]}
                                            required
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                categorie.map((prod, index) => (
                                                    <option
                                                        key={index}
                                                        value={prod.id}
                                                    >
                                                        {prod.nome}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*Sottocategoria*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Sottocategoria
                                        </div>
                                        <select
                                            name={"sottocategoria"}
                                            defaultValue={sottocategorie[0]}
                                            required
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                sottocategorie.map((s, index) => (
                                                    <option
                                                        key={index}
                                                        value={s.id}
                                                    >
                                                        {s.nome}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*Serie Produttore*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Serie Produttore
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name={"serie_produttore"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Serie Produttore"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Unità di Misura*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Unità di Misura
                                        </div>
                                        <select
                                            name={"unita_misura"}
                                            defaultValue={unita_misure[0]}
                                            required
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                unita_misure.map((u, index) => (
                                                    <option
                                                        key={index}
                                                        value={u}
                                                    >
                                                        {u}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*Notes*/}
                                <div className="col-span-2">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Note
                                        </div>
                                        <div className="relative">
                                            <textarea
                                                name={"notes"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Note"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer/>
        </div>

    );
};

export default MaterialeForm;
