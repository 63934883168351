import React from 'react';
import {Link} from "react-router-dom";

const ServiceCard = ({icon, title, subtitle, description, link}) => {

    return (
        <Link to={link}>
            <div className={"flex flex-col bg-white rounded hover:shadow-md "}>
                <div className="p-6 flex flex-col items-center">
                    <div className={"items-center"}>
                        {icon}
                        {/*<FontAwesomeIcon icon={icon} className={"text-6xl text-primary-blue"}/>*/}
                    </div>
                    <h3 className={"mt-5 mb-2 text-primary-blue text-lg"}>{title}</h3>
                    <p className={"mb-2 text-secondary-blue font-light"}>{subtitle}</p>
                    <p className={"mb-2 text-my-gray font-light"}>{description}</p>
                </div>
            </div>
        </Link>
    );
};

export default ServiceCard;
