import React from 'react';
import HeaderSection from "../../../../components/HeaderSection";
import FormCard from "../../../../components/FormCard";
import BackButton from "../../../../components/BackButton";
import MainLayout from "../../../../Layouts/MainLayout";
import {Box, Boxes, Cake, DollarSign, PackageSearch, Pen, FoldHorizontal, Users} from "lucide-react";

const SezioneAnagrafica = () => {
    return (
        <MainLayout>
            <section className={"bg-white p-20"}>
                <BackButton/>
                <HeaderSection title={"Gestione Dati"}/>
                <div
                    className="container grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 max-w-screen-lg mt-10 gap-4 ">
                    <FormCard icon={<Cake size={48}/>} title={"Anagrafica"} link={"/anagrafica"}/>
                    <FormCard icon={<Users size={48}/>} title={"Dipendenti"} link={"/dipendenti"}/>
                    <FormCard icon={<Pen size={48}/>} title={"Contratti"} link={"/contratti"}/>
                    <FormCard icon={<DollarSign size={48}/>} title={"Clienti"} link={"/clienti"}/>
                    <FormCard icon={<FoldHorizontal size={48}/>} title={"Relazioni Lavorative"} link={"/relazioni"}/>
                    <FormCard icon={<Box size={48}/>} title={"Fornitori"} link={"/fornitori"}/>
                    <FormCard icon={<Boxes size={48}/>} title={"Collaboratori"} link={"/collaboratori"}/>
                    <FormCard icon={<PackageSearch size={48}/>} title={"Produttori"} link={"/produttori"}/>
                </div>
            </section>
        </MainLayout>
    );
};

export default SezioneAnagrafica;
