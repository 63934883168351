import React from 'react';
import {Link} from "react-router-dom";
import video from "../assets/images/Nature Makes You Happy BBC Earth.mp4";

const ImageBanner = ({title, description}) => {

    return (
        <div className="bg-white">
            <div className="relative">
                <div className="mx-auto max-w-7xl">
                    <div className="relative z-10 lg:w-full lg:max-w-2xl">
                        <svg
                            className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            aria-hidden="true"
                        >
                            <polygon points="0,0 90,0 50,100 0,100"/>
                        </svg>

                        <div className="relative px-6 py-10 sm:py-40 lg:px-8 lg:py-56 lg:pr-0 mt-4">
                            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                    {title}
                                </h1>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    {description}
                                </p>
                                <div className="mt-10 flex items-center gap-x-6">
                                    <Link
                                        to="/about"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        About
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">

                    <video
                        autoPlay
                        loop
                        muted
                        className="w-full h-full object-top object-cover"
                    >
                        <source
                            src={video}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    );
};

export default ImageBanner;
