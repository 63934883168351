import React from 'react';
import ProjectForm from "../../../../components/ProjectForm";
import MainLayout from "../../../../Layouts/MainLayout";

const ProjectFormPage = () => {
    return (
        <MainLayout>
            <ProjectForm/>
        </MainLayout>
    );
};

export default ProjectFormPage;
