import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

// CATEGORIE
export const getCategorieRecurring = async () => {
    const options = {
        url: `${proxyUrl}/recurrings/categorie/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const addCategoriaRecurring = async (formData) => {
    const options = {
        url: `${proxyUrl}/recurrings/categorie/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
export const removeCategoriaRecurring = async (id) => {
    const options = {
        url: `${proxyUrl}/recurrings/categorie/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getCategoriaRecurring = async (id) => {
    const options = {
        url: `${proxyUrl}/recurrings/categorie/modifica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const editCategoriaRecurring = async (id, formData) => {
    const options = {
        url: `${proxyUrl}/recurrings/categorie/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


//TIPI
export const getTipiRecurring = async () => {
    const options = {
        url: `${proxyUrl}/recurrings/tipi/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const addTipoRecurring = async (formData) => {
    const options = {
        url: `${proxyUrl}/recurrings/tipi/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
export const removeTipoRecurring = async (id) => {
    const options = {
        url: `${proxyUrl}/recurrings/tipi/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getTipoRecurring = async (id) => {
    const options = {
        url: `${proxyUrl}/recurrings/tipi/modifica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const editTipoRecurring = async (id, formData) => {
    const options = {
        url: `${proxyUrl}/recurrings/tipi/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


//Recurrings
export const getRecurrings = async () => {
    const options = {
        url: `${proxyUrl}/recurrings/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const addRecurring = async (formData) => {
    const options = {
        url: `${proxyUrl}/recurrings/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
export const removeRecurring = async (id) => {
    const options = {
        url: `${proxyUrl}/recurrings/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getRecurring = async (id) => {
    const options = {
        url: `${proxyUrl}/recurrings/tipi/modifica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const getInfoRecurring = async (id) => {
    const options = {
        url: `${proxyUrl}/recurrings/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const editRecurring = async (id, formData) => {
    const options = {
        url: `${proxyUrl}/recurrings/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


// Allegati

export const getListaAllegatiRecurring = async (id) => {
    const options = {
        url: `${proxyUrl}/recurrings/allegati/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const eliminaAllegatoRecurring = async (allegatoID) => {
    const options = {
        url: `${proxyUrl}/recurrings/allegati/elimina/${allegatoID}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const addAllegatoRecurring = async (formData) => {
    const options = {
        url: `${proxyUrl}/recurrings/allegati/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


// MANUTENZIONI
export const getManutenzioni = async () => {
    const options = {
        url: `${proxyUrl}/recurrings/manutenzioni/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const creaManutenzioni = async (data) => {
    const options = {
        url: `${proxyUrl}/recurrings/manutenzioni/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: data
    };

    return axios(options)
}

export const delManutenzione = async (id) => {
    const options = {
        url: `${proxyUrl}/recurrings/manutenzioni/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
