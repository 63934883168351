import React from 'react';
import MainLayout from "../../../../Layouts/MainLayout";
import HeaderSection from "../../../../components/HeaderSection";
import {useQuery} from "react-query";
import axios from "axios";
import {proxyUrl} from "../../../../constants/proxyUrl";
import {Link} from "react-router-dom";

const BlogPage = () => {

    const {
        data: posts,
        isLoading,
        isError,
        isSuccess
    } = useQuery({
        queryKey: ['blog-posts'],
        queryFn: async () => {
            const response = await axios.get(`${proxyUrl}/post-blog/`);
            return response.data
        }
    })

    return (
        <MainLayout>
            <div className={"p-10"}>
                <HeaderSection title={"Blog"}/>

                {isLoading && <div>Loading...</div>}
                {isError && <div>Error fetching data</div>}
                {isSuccess ? (
                    <div className={"grid grid-cols-1 lg:grid-cols-2 gap-6 my-8"}>
                        {posts.map((post, index) => (
                            <Link key={index} className={"bg-white shadow-md rounded-lg"} to={`/blog/${post.id}`}>
                                <img
                                    src={post.cover_image}
                                    alt={post.titolo}
                                    className={"w-full h-96 object-cover rounded-t-lg"}
                                />
                                <div className={"text-2xl font-bold p-2"}>
                                    {post.titolo}
                                </div>
                            </Link>
                        ))}
                    </div>
                ) : null}
            </div>
        </MainLayout>
    );
};

export default BlogPage;
