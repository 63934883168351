import React from 'react';
import {Link} from "react-router-dom";
import {modificaTask} from "../../../../fetch/taskFetch";

const TaskBanner = ({t, setReload, reload}) => {

    const editTask = (e, id, c) => {
        e.preventDefault()
        let newComp
        switch (c) {
            case "Incompleto":
                newComp = "Parziale"
                break
            case "Parziale":
                newComp = "Completato"
                break
            case "Completato":
                newComp = "Incompleto"
                break
            default:
                newComp = "Incompleto"
                break
        }
        modificaTask(id, {completamento: newComp}).then(() => {
            setReload(!reload)
        }).catch(e => {
            console.log(e)
        })
    }

    return (
        <div className={"border-2 rounded-md"}>
            {/*Head*/}
            <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
                <div
                    className="-ml-4 -mt-4 flex flex-col items-center justify-between sm:flex-row">
                    <div className="ml-4 mt-4 w-full">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t.lavoro.nome}</h3>
                        <p className="mt-1 text-sm text-gray-500">
                            {t.descrizione.nome}
                        </p>
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0">
                        <Link
                            to={"/task_info/" + t.id}
                            type="button"
                            className="ml-6 rounded border border-transparent bg-primary-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-transparent hover:text-primary-blue hover:border-primary-blue"
                        >
                            Aggiungi Commento
                        </Link>
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0">
                        <Link
                            to={"/foto_task/" + t.id}
                            type="button"
                            className="ml-6 rounded border border-transparent bg-primary-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-transparent hover:text-primary-blue hover:border-primary-blue"
                        >
                            Aggiungi Foto
                        </Link>
                    </div>
                </div>
            </div>
            {/*Info*/}
            <div
                className={"px-4 py-5 sm:px-6 grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5"}>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Data:
                    </div>
                    {t.data}
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Costo:
                    </div>
                    {t.costo} €
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Cliente:
                    </div>
                    {t.lavoro.cliente.anagrafica.nome} {t.lavoro.cliente.anagrafica.cognome}
                </div>
                <div
                    className={`flex items-center justify-center text-white rounded cursor-pointer py-2 ${t.completamento === "Completato" ? 'bg-my-green' : t.completamento === "Parziale" ? 'bg-amber-500' : "bg-my-red"}`}
                    onClick={(e) => editTask(e, t.id, t.completamento)}
                >
                    {t.completamento}
                </div>
            </div>
        </div>
    );
};

export default TaskBanner;
