import axios from "axios";
import {proxyUrl} from "../constants/proxyUrl";

export const getCollaboratori = async () => {
    const options = {
        url: `${proxyUrl}/collaboratori`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const addCollaboratori = async (formData) => {
    const options = {
        url: `${proxyUrl}/collaboratori/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


export const delCollaboratori = async (id) => {
    const options = {
        url: `${proxyUrl}/collaboratori/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
