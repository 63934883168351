import React, {useEffect, useState} from 'react';
import Navbar from "../../../../components/Navbar";
import Footer from "../../../../components/Footer";
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import HeaderSection from "../../../../components/HeaderSection";
import {getClienti} from "../../../../fetch/fetchClienti";
import {sleep} from "../../../../constants/sleep";
import {addLavoro, getGruppi, getUltimoLavoro} from "../../../../fetch/fetchLavori";
import {getSocieta} from "../../../../fetch/fetchSocieta";
import {aggiungiMaterialiLavoro} from "../../../../fetch/fetchMateriali";
import BackButton from "../../../../components/BackButton";
import {useQuery} from "react-query";
import {getInventari, getSediMagazzino} from "../../../../fetch/fetchMagazzino";

const AddLavoroPage = () => {

    const [alert, setAlert] = useState(0)
    const [clienti, setClienti] = useState([])
    const [gruppi, setGruppi] = useState([])
    const [societa, setSocieta] = useState([])

    const [listaMateriali, setListaMateriali] = useState([])

    const tags = ["Ingegneria", "Finanza", "Elettrotecnica", "Informatica"]

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('nome', e.target.nome.value);
        postData.append('luogo', e.target.luogo.value);
        postData.append('gruppo', e.target.gruppo.value);
        postData.append('cliente', e.target.cliente.value);
        postData.append('societa', e.target.societa.value);
        postData.append('tag', e.target.tag.value);
        postData.append('inizio', e.target.inizio.value);
        postData.append('fine', e.target.fine.value);

        addLavoro(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        }).then(() => {
            if (listaMateriali.length > 0) {
                getUltimoLavoro().then(
                    r => {
                        for (let i = 0; i < listaMateriali.length; i++) {
                            const formData = new FormData()
                            formData.append("commessa", r.data.id)
                            formData.append("materiale", listaMateriali[i].id)
                            formData.append("quantita", listaMateriali[i].quantita)
                            formData.append("percentuale_ricarico", listaMateriali[i].ricarico)

                            aggiungiMaterialiLavoro(formData).then(() => {
                                setAlert(1)
                                setListaMateriali([])
                            }).catch(() => {
                                setAlert(-1)
                            }).finally(async () => {
                                window.scroll(0, 0)
                                await sleep(3000)
                                setAlert(0)
                            })
                        }
                    }
                )


            }
        })
    };


    useEffect(() => {
        getClienti().then(r => {
            setClienti(r.data)
        }).catch(e => {
            console.log(e)
        })

        getSocieta().then(r => {
            setSocieta(r.data)
        }).catch(e => {
            console.log(e)
        })

        getGruppi().then(r => {
            setGruppi(r.data)

        }).catch(e => {
            console.log(e)
        })
    }, [])


    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"Il Lavoro è stato aggiunto con successo"}/>
                        ) : (
                            <div/>
                        )
                    }
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi nuovo Lavoro"}/>
                    <div className={"flex flex-col lg:flex-row justify-evenly"}>
                        <div className={"flex flex-col lg:flex-row flex-1"}>
                            <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                                <div
                                    className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                    <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                        {/*Nome*/}
                                        <div className="col-span-2 lg:col-span-1 ">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Nome
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="text"
                                                        name={"nome"}
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                        placeholder="Nome"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Luogo*/}
                                        <div className="col-span-2 lg:col-span-1 ">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Luogo
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="text"
                                                        name={"luogo"}
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                        placeholder="Luogo"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Gruppo*/}
                                        <div className="col-span-2 lg:col-span-1 ">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Gruppo
                                                </div>
                                                <select
                                                    name={"gruppo"}
                                                    defaultValue={gruppi[0]}
                                                    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                                >
                                                    {
                                                        gruppi.map((g, index) => (
                                                            <option
                                                                key={index}
                                                                value={g.id}
                                                            >
                                                                {g.nome}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/*Cliente*/}
                                        <div className="col-span-2 lg:col-span-1 ">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Cliente
                                                </div>
                                                <select
                                                    name={"cliente"}
                                                    defaultValue={clienti[0]}
                                                    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                                >
                                                    {
                                                        clienti.map((cliente, index) => (
                                                            <option key={index}
                                                                    value={cliente.id}>{cliente.anagrafica.nome + " " + cliente.anagrafica.cognome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/*Società*/}
                                        <div className="col-span-2 lg:col-span-1 ">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Società
                                                </div>
                                                <select
                                                    name={"societa"}
                                                    defaultValue={societa[0]}
                                                    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                                >
                                                    {
                                                        societa.map((soc, index) => (
                                                            <option key={index}
                                                                    value={soc.id}>{soc.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/*Tag*/}
                                        <div className="col-span-2 lg:col-span-1 ">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Tag
                                                </div>
                                                <select
                                                    name={"tag"}
                                                    defaultValue={tags[0]}
                                                    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                                >
                                                    {
                                                        tags.map((soc, index) => (
                                                            <option key={index}
                                                                    value={soc}>{soc}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/*Inizio*/}
                                        <div className="col-span-2 lg:col-span-1">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Data Inizio
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="date"
                                                        name={"inizio"}
                                                        defaultValue={null}
                                                        required
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Fine*/}
                                        <div className="col-span-2 lg:col-span-1">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Data Fine
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="date"
                                                        name={"fine"}
                                                        defaultValue={null}
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/*Bottone*/}
                                        <div className="col-span-2 text-center">
                                            <button
                                                type="submit"
                                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                            >
                                                Aggiungi
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default AddLavoroPage;
