import React, {useEffect, useState} from 'react';
import {getDipendenti} from "../../../../../fetch/fetchDipendenti";
import {addBustaPaga} from "../../../../../fetch/fetchBustaPaga";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import BackButton from "../../../../../components/BackButton";
import HeaderSection from "../../../../../components/HeaderSection";
import {sleep} from "../../../../../constants/sleep";

const BustePagaForm = () => {

    const [alert, setAlert] = useState(0)
    const [dipendenti, setDipendenti] = useState([])

    const clearFields = (event) => {
        event.target.bustaPaga.value = null
        event.target.data.value = null
    }

    useEffect(() => {
        getDipendenti().then(value => {
            setDipendenti(value.data)
        }).catch(e => {
            console.log(e)
        })
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();

        const dataString = e.target.anno.value + "-" + e.target.data.value + "-" + 1

        postData.append('dipendente', e.target.dipendente.value);
        postData.append('data_emissione', dataString);
        postData.append('copia_busta_paga', e.target.bustaPaga.files[0], e.target.bustaPaga.files[0].name);

        addBustaPaga(postData).then(() => {
            setAlert(1)
            clearFields(e)
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    return (
        <div className={"p-10"}>
            {
                alert === -1 ? (
                    <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                ) : alert === 1 ? (
                    <SuccessAlert name={"Pubblicato"} message={"La Busta Paga è stata aggiunta con successo"}/>
                ) : (
                    <div/>
                )
            }
            <div className={"flex px-20 py-2"}>
                <BackButton/>
            </div>
            <HeaderSection title={"Aggiungi Busta Paga"}/>
            <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                <div
                    className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                    <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                        {/*Nome*/}
                        <div className="col-span-2 lg:col-span-2 ">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    Dipendente
                                </div>
                                <select
                                    name={"dipendente"}
                                    defaultValue={dipendenti[0]}
                                    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                >
                                    {
                                        dipendenti.map((dipendente, index) => (
                                            <option key={index}
                                                    value={dipendente.id}>{dipendente.anagrafica.nome + " " + dipendente.anagrafica.cognome}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {/*Busta Paga*/}
                        <div className="col-span-2 lg:col-span-2 ">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    Busta Paga
                                </div>
                                <div className="relative">
                                    <input
                                        type="file"
                                        name={"bustaPaga"}
                                        accept="application/pdf"
                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        placeholder="BustaPaga"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        {/*Mese*/}
                        <div className="col-span-2 lg:col-span-2">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    Mese
                                </div>
                                <div className="relative">
                                    <select
                                        name={"data"}
                                        required
                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                    >
                                        <option value={"01"}>Gennaio</option>
                                        <option value={"02"}>Febbraio</option>
                                        <option value={"03"}>Marzo</option>
                                        <option value={"04"}>Aprile</option>
                                        <option value={"05"}>Maggio</option>
                                        <option value={"06"}>Giugno</option>
                                        <option value={"07"}>Luglio</option>
                                        <option value={"08"}>Agosto</option>
                                        <option value={"09"}>Settembre</option>
                                        <option value={"10"}>Ottobre</option>
                                        <option value={"11"}>Novembre</option>
                                        <option value={"12"}>Dicembre</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/*Anno*/}
                        <div className="col-span-2 lg:col-span-2">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    Anno
                                </div>
                                <div className="relative">
                                    <input
                                        name={"anno"}
                                        type={"number"}
                                        defaultValue={new Date().getFullYear()}
                                        required
                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                        </div>
                        {/*Bottone*/}
                        <div className="col-span-2 text-center">
                            <button
                                type="submit"
                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                            >
                                Aggiungi
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BustePagaForm;
