import React, {useEffect, useState} from 'react';
import {aggiungiMaterialiLavoro, getMateriale} from "../../../../../fetch/fetchMateriali";
import {sleep} from "../../../../../constants/sleep";
import Navbar from "../../../../../components/Navbar";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import HeaderSection from "../../../../../components/HeaderSection";
import Footer from "../../../../../components/Footer";
import {useParams} from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import {useQuery} from "react-query";
import {getInventari, getSediMagazzino} from "../../../../../fetch/fetchMagazzino";

const AggiungiMaterialeCommessa = () => {
    const [alert, setAlert] = useState(0)
    const {id} = useParams()
    const [magazzini, setMagazzini] = useState([])
    const [selectedMagazzino, setSelectedMagazzino] = useState()

    const [selectedMateriale, setSelectedMateriale] = useState()

    const [materiali, setMateriali] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault();

        const mId = materiali.filter(
            mat => parseInt(mat.id) === parseInt(e.target.materiale.value)
        )[0].materiale.id

        const postData = new FormData();
        postData.append('magazzino', selectedMagazzino);
        postData.append('commessa', id);
        postData.append('materiale', mId);
        postData.append('quantita', e.target.quantita.value);
        postData.append('percentuale_ricarico', e.target.ricarico.value);

        aggiungiMaterialiLavoro(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    useEffect(() => {
        getMateriale().then(r => {
            setMateriali(r.data)
        }).catch(e => {
            console.log(e)
        })
    }, [])


    useQuery({
        queryKey: "magazzini",
        queryFn: getSediMagazzino,
        onSuccess: (data) => {
            setMagazzini(data.data)
        }
    })

    useQuery({
        queryKey: "inventari",
        queryFn: getInventari,
        onSuccess: (data) => {
            setMateriali(data.data)
        }
    })

    const getQuantitaMateriale = () => {
        return materiali.filter(
            mat => parseInt(mat.magazzino) === parseInt(selectedMagazzino) &&
                parseInt(mat.id) === parseInt(selectedMateriale)
        )[0]?.quantita || 0
    }

    return (
        <div className={"flex flex-col h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"Il Materiale è stato aggiunto con successo"}/>
                        ) : (
                            <div/>
                        )
                    }
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi Materiale alla Commessa"}/>
                    <form className="grid max-w-xl grid-cols-1 gap-4 m-auto bg-my-white p-6 mt-6 rounded-sm"
                          onSubmit={handleSubmit}>
                        {/*Magazzino*/}
                        <div className="col-span-2 lg:col-span-2 ">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    Magazzino
                                </div>
                                <select
                                    name={"magazzino"}
                                    onChange={e => {
                                        setSelectedMagazzino(e.target.value)
                                    }}
                                    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                >
                                    <option value={0}>Seleziona un magazzino</option>
                                    {
                                        magazzini.map((mag, index) => (
                                            <option
                                                key={index}
                                                value={mag.id}
                                            >
                                                {mag.nome}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {/*Materiale*/}
                        <div className="col-span-2 lg:col-span-2 ">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    Materiale
                                </div>
                                <select
                                    name={"materiale"}
                                    onChange={e => setSelectedMateriale(e.target.value)}
                                    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                >
                                    <option value={0}>Seleziona un materiale</option>
                                    {
                                        materiali.filter(
                                            mat => parseInt(mat.magazzino) === parseInt(selectedMagazzino)
                                        ).map((mat, index) => (
                                            <option
                                                key={index}
                                                value={mat.id}
                                            >
                                                {mat.materiale.materiale}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {/*Quantità*/}
                        <div className="col-span-2 lg:col-span-1">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    Quantità
                                </div>
                                <div className="relative">
                                    <input
                                        type="number"
                                        name={"quantita"}
                                        min={1}
                                        placeholder={"max " + getQuantitaMateriale()}
                                        max={getQuantitaMateriale()}
                                        required
                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                        </div>
                        {/*Disponibilità*/}
                        <div className="col-span-2 lg:col-span-1">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    Disponibilità
                                </div>
                                <div
                                    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                >
                                    {
                                        getQuantitaMateriale()
                                    }
                                </div>
                            </div>
                        </div>

                        {/*Ricarico*/}
                        <div className="col-span-2 lg:col-span-2">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    Percentuale Ricarico
                                </div>
                                <div className="relative">
                                    <input
                                        type="number"
                                        name={"ricarico"}
                                        min={0}
                                        placeholder={"0%"}
                                        required
                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                        </div>
                        {/*Bottone*/}
                        <div className="col-span-2 text-center">
                            <button
                                type={"submit"}
                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                            >
                                Aggiungi Materiale
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer/>
        </div>

    );
};

export default AggiungiMaterialeCommessa;
