import React from 'react';
import HeaderSection from "../../../components/HeaderSection";
import FormCard from "../../../components/FormCard";
import BackButton from "../../../components/BackButton";
import MainLayout from "../../../Layouts/MainLayout";
import {
    Banknote,
    Cake,
    Calculator,
    Car,
    Circle,
    Cog,
    FileWarning,
    GraduationCap,
    Hammer,
    ScrollText,
    Warehouse
} from "lucide-react";

const SezioneGestisci = () => {

    return (
        <MainLayout>
            <section className={"bg-white p-20"}>
                <BackButton/>
                <HeaderSection title={"Gestione Dati"}/>
                <div
                    className="container grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 max-w-screen-lg mt-10 gap-4 ">
                    <FormCard icon={<Cake size={48}/>} title={"Anagrafica"}
                              link={"/sezioni_anagrafica"}/>
                    <FormCard icon={<Calculator size={48}/>} title={"Contabilità"}
                              link={"/sezioni_contabilita"}/>
                    <FormCard icon={<ScrollText size={48}/>} title={"Offerte"}
                              link={"/preventivi"}/>
                    <FormCard icon={<Car size={48}/>} title={"Beni Aziendali"}
                              link={"/beni_aziendali"}/>
                    <FormCard icon={<Calculator size={48}/>} title={"Commesse"}
                              link={"/lavori"}/>
                    <FormCard icon={<Hammer size={48}/>} title={"Materiale"}
                              link={"/materiale"}/>
                    <FormCard icon={<Warehouse size={48}/>} title={"Magazzino"}
                              link={"/logistica"}/>
                    <FormCard icon={<Circle size={48}/>} title={"Recurrings"}
                              link={"/recurrings"}/>
                    <FormCard icon={<Cog size={48}/>} title={"Manutenzioni"}
                              link={"/manutenzioni"}/>
                    <FormCard icon={<Banknote size={48}/>} title={"Tax"} link={"/tax"}/>
                    <FormCard icon={<GraduationCap size={48}/>} title={"Formazione"}
                              link={"/formazione"}/>
                    <FormCard icon={<FileWarning size={48}/>} title={"Protocolli"}
                              link={"/protocolli"}/>
                    <FormCard icon={<Cog size={48}/>} title={"Valori Default"}
                              link={"/sezioni_default"}/>
                </div>
            </section>
        </MainLayout>
    );
};

export default SezioneGestisci;
