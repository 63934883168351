import axios from "axios";
import {proxyUrl} from "../constants/proxyUrl";

export const getFornitori = async () => {
    const options = {
        url: `${proxyUrl}/fornitori`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const addFornitori = async (formData) => {
    const options = {
        url: `${proxyUrl}/fornitori/crea`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


export const delFornitori = async (id) => {
    const options = {
        url: `${proxyUrl}/fornitori/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
