import React, {useEffect, useState} from 'react';
import MainLayout from "../../../../Layouts/MainLayout";
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import BackButton from "../../../../components/BackButton";
import HeaderSection from "../../../../components/HeaderSection";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {proxyUrl} from "../../../../constants/proxyUrl";
import axios from "axios";
import {sleep} from "../../../../constants/sleep";

const EditPostPage = () => {

    const {id} = useParams()

    const [alert, setAlert] = useState(0)

    const {token} = useSelector(state => state.auth)

    const [titolo, setTitolo] = useState("")
    const [descrizione, setDescrizione] = useState("")
    const [testo, setTesto] = useState("")
    const [immagine, setImmagine] = useState(null)

    const getPost = async () => {
        try {
            const response = await fetch(proxyUrl + `/api/posts/edit/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            })
            const actualData = await response.json()
            setTitolo(actualData.titolo)
            setDescrizione(actualData.descrizione)
            setTesto(actualData.testo)
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        getPost()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('immagine', immagine, immagine.nome);
        postData.append('titolo', titolo);
        postData.append('descrizione', descrizione);
        postData.append('testo', testo);

        axios.patch(proxyUrl + `/api/posts/edit/${id}`, postData, {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            }
        }).then(() => {
            setAlert(1)
        }).catch(error => {
            if (error.response) {
                setAlert(-1)
            }
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    }

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Modificato"} message={"Il post è stato Modificato con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Modifica il post"}/>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Nome*/}
                            <div className="col-span-2 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Titolo Post
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"titolo"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Titolo"
                                            defaultValue={titolo}
                                            onChange={event => setTitolo(event.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Email*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Descrizione Post
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"descrizione"}
                                            className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Descrizione"
                                            defaultValue={descrizione}
                                            onChange={event => setDescrizione(event.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Messaggio*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Messaggio del Post
                                    </div>
                                    <label className="text-gray-700" htmlFor="name">
                                    <textarea
                                        className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none"
                                        placeholder="Testo del post"
                                        name="testo"
                                        rows="5"
                                        cols="40"
                                        defaultValue={testo}
                                        onChange={event => setTesto(event.target.value)}
                                        required

                                    />
                                    </label>
                                </div>
                            </div>
                            {/*Immagine*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Immagine del Post
                                    </div>
                                    <label className="text-gray-700" htmlFor="name">
                                        <input
                                            type="file"
                                            id="immagine"
                                            accept="image/png, image/jpeg"
                                            className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-sm focus:outline-none"
                                            required
                                            defaultValue={null}
                                            onChange={event => setImmagine(event.target.files[0])}
                                        />
                                    </label>
                                </div>
                            </div>
                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Modifica
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default EditPostPage;
