import React, {useEffect, useState} from 'react';
import {getInventario} from "../../../../../fetch/fetchMagazzino";
import Navbar from "../../../../../components/Navbar";
import BackButton from "../../../../../components/BackButton";
import Footer from "../../../../../components/Footer";
import {useParams} from "react-router-dom";
import HeaderSection from "../../../../../components/HeaderSection";
import TableCell from "../../../../../components/Table/TableCell";
import {useQuery} from "react-query";

const InventarioMagazzino = () => {

    const {id} = useParams()

    const [filter, setFilter] = useState("")


    const [materiali, setMateriali] = useState([])

    useQuery({
        queryKey: ["inventario", id],
        queryFn: () => getInventario(id),
        onSuccess: r => {
            setMateriali(r.data)
        }
    })
    //
    // const getSupporto = () => {
    //     getInventario(id).then(r => {
    //         setMateriali(r.data)
    //     }).catch(e => {
    //         console.log(e)
    //     })
    // }
    //
    // useEffect(() => {
    //     getSupporto()
    // }, [])

    const filterMaterial = m => {
        if (filter === "") {
            return m;
        }

        return m.materiale.materiale.toLowerCase().includes(filter.toLowerCase())
    }

    const headers = [
        "Materiale",
        "Quantità",
        "Costo Ponderato"
    ];

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"flex-grow mt-10"}>
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Inventario Magazzino"}/>
                    <div className={"my-10 flex justify-center items-center"}>
                        <div className="flex flex-col w-11/12">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <div>
                                            <input
                                                type="text"
                                                placeholder={"Cerca..."}
                                                className={"w-full py-2 px-4 outline-none border-none"}
                                                onChange={event => setFilter(event.target.value)}
                                            />
                                        </div>
                                        <div className={"max-h-96 overflow-auto"}>
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50 sticky top-0 z-10">
                                                <tr>
                                                    {headers.map(header => (
                                                        <th
                                                            key={header}
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            {header}
                                                        </th>
                                                    ))}
                                                </tr>
                                                </thead>

                                                <tbody className="bg-white divide-y divide-gray-200">
                                                {
                                                    materiali
                                                        .filter(filterMaterial)
                                                        .map((carico, i) => (
                                                            <tr key={i}>
                                                                <TableCell content={carico.materiale.materiale}/>
                                                                <TableCell content={carico.quantita}/>
                                                                <TableCell content={`${carico.costo_ponderato} €`}/>
                                                            </tr>
                                                        ))
                                                }
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default InventarioMagazzino;
