import React from 'react';
import Navbar from "../../../../components/Navbar";
import HeaderSection from "../../../../components/HeaderSection";
import FormCard from "../../../../components/FormCard";
import Footer from "../../../../components/Footer";
import BackButton from "../../../../components/BackButton";
import {Circle, Copy, CreditCard, Factory, Layers, List, Percent, SortAsc} from "lucide-react";

const SezioneValoriDefault = () => {
    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <section className={"bg-white p-20"}>
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Gestione Dati"}/>
                    <div
                        className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-screen-lg mt-10 gap-4 ">
                        <FormCard icon={<Factory size={48}/>}
                                  title={"Soggetti Contabili"} link={"/soggetti_contabili"}/>
                        <FormCard icon={<List size={48}/>} title={"Default Task"}
                                  link={"/aggiungi_nome_task"}/>
                        <FormCard icon={<Layers size={48}/>} title={"Gruppi"}
                                  link={"/gruppi"}/>
                        <FormCard icon={<Copy size={48}/>} title={"Categoria Materiale"}
                                  link={"/categoria_materiale"}/>
                        <FormCard icon={<SortAsc size={48}/>}
                                  title={"Sottocategoria Materiale"} link={"/sottocategoria_materiale"}/>
                        <FormCard icon={<Circle size={48}/>}
                                  title={"Categorie Recurring"} link={"/categorie-recurring"}/>
                        <FormCard icon={<Circle size={48}/>} title={"Tipi Recurring"}
                                  link={"/tipi-recurring"}/>
                        <FormCard icon={<CreditCard size={48}/>}
                                  title={"Mezzi Pagamento"} link={"/mezzi-pagamento"}/>
                        <FormCard icon={<Percent size={48}/>}
                                  title={"Percentuale Ricarico"} link={"/percentuale-ricarico"}/>
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    );
};

export default SezioneValoriDefault;
