import React, {useState} from 'react';
import HeaderSection from "../../../components/HeaderSection";
import {Link} from "react-router-dom";
import {getEventiGiorno} from "../../../fetch/fetchEventi";
import BannerEvento from "./BannerEvento";
import HeaderSectionAdd from "../../../components/HeaderSectionAdd";
import {useQuery} from "react-query";
import {getFattureGiorno} from "../../../fetch/fetchFattura";

const EventiEFatture = ({giorno}) => {

    const [eventi, setEventi] = useState([]);
    const [fatture, setFatture] = useState([]);


    useQuery({
        queryKey: ["eventi", giorno],
        queryFn: () => getEventiGiorno(giorno),
        onSuccess: (data) => {
            setEventi(data.data)
        }
    })

    useQuery({
        queryKey: ["fatture", giorno],
        queryFn: () => getFattureGiorno(giorno),
        onSuccess: (data) => {
            setFatture(data.data)
        }
    })

    return (
        <div className={"container"}>
            <HeaderSectionAdd
                titolo={"Eventi del " + giorno}
                link={"/aggiungi_evento/" + giorno}
            />
            <div className={"flex flex-col my-8 space-y-4"}>
                {
                    eventi?.length > 0 ? (
                        eventi.map((evento, index) => (<BannerEvento key={index} evento={evento}/>))
                    ) : (
                        <div>Non ci sono eventi</div>
                    )
                }
            </div>
            <HeaderSection title={"Fatture in scadenza il " + giorno}/>
            <div className={"flex flex-col my-8 space-y-4"}>
                {
                    fatture?.length > 0 ? (fatture.map((fattura, index) => (
                            <div key={index} className={"border-2 rounded-md"}>
                                {/*Head*/}
                                <div className="border-gray-200 px-4 py-5 sm:px-6">
                                    <div
                                        className="-ml-4 -mt-4 flex flex-col items-center justify-between sm:flex-row">
                                        <div className="ml-4 mt-4 w-full">
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">Fattura {fattura.numero_fattura}</h3>
                                        </div>
                                        <div className="ml-4 mt-4 flex-shrink-0">
                                            <Link
                                                to={"/movimenti/" + fattura.id}
                                                type="button"
                                                className="ml-6 rounded border border-transparent bg-primary-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-transparent hover:text-primary-blue hover:border-primary-blue"
                                            >
                                                Info
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))
                    ) : (
                        <div>Non ci sono fatture in scadenza</div>
                    )
                }
            </div>
        </div>
    );
};

export default EventiEFatture;
