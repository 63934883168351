import "./App.css"
import {HashRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import ProjectFormPage from "./pages/Gestionale/CMS/Progetti/ProjectFormPage";
import EditPostPage from "./pages/Gestionale/CMS/Post/EditPostPage";
import EditProjectPage from "./pages/Gestionale/CMS/Progetti/EditProjectPage";
import PostCMSPage from "./pages/Gestionale/CMS/Post/PostCMSPage";
import DipendenteFormPage from "./pages/Gestionale/Gestisci/Anagrafica/Dipendenti/DipendenteFormPage";
import ContrattoFormPage from "./pages/Gestionale/Gestisci/Anagrafica/Contratti/ContrattoFormPage";
import DipendentiPage from "./pages/Gestionale/Gestisci/Anagrafica/Dipendenti/DipendentiPage";
import HomeGestionale from "./pages/Gestionale/HomeGestionale";
import SezioneGestisci from "./pages/Gestionale/Gestisci/SezioneGestisci";
import ContrattiPage from "./pages/Gestionale/Gestisci/Anagrafica/Contratti/ContrattiPage";
import ClienteFormPage from "./pages/Gestionale/Gestisci/Anagrafica/Clienti/ClienteFormPage";
import ClientiPage from "./pages/Gestionale/Gestisci/Anagrafica/Clienti/ClientiPage";
import BustePagaPage from "./pages/Gestionale/Gestisci/Contabilia/BustePaga/BustePagaPage";
import InfoDipendentePage from "./pages/Gestionale/Gestisci/Anagrafica/Dipendenti/InfoDipendentePage";
import AddLavoroPage from "./pages/Gestionale/Gestisci/Lavori/AddLavoroPage";
import LavoriPage from "./pages/Gestionale/Gestisci/Lavori/LavoriPage";
import BustePagaGestionale from "./pages/Gestionale/Gestisci/Contabilia/BustePaga/BustePagaGestionale";
import PreventiviForm from "./pages/Gestionale/Gestisci/Preventivi/PreventiviForm";
import PreventiviPage from "./pages/Gestionale/Gestisci/Preventivi/PreventiviPage";
import PrimaNotaPage from "./pages/Gestionale/Gestisci/Contabilia/PrimaNota/PrimaNotaPage";
import AggiungiPrimaNota from "./pages/Gestionale/Gestisci/Contabilia/PrimaNota/AggiungiPrimaNota";
import AggiungiFattura from "./pages/Gestionale/Gestisci/Contabilia/Fattura/AggiungiFattura";
import FatturaPage from "./pages/Gestionale/Gestisci/Contabilia/Fattura/FatturaPage";
import MaterialeForm from "./pages/Gestionale/Gestisci/Materiale/MaterialeForm";
import MaterialePage from "./pages/Gestionale/Gestisci/Materiale/MaterialePage";
import AggiungiEvento from "./pages/Gestionale/Scadenzario/AggiungiEvento";
import AggiungiNotaSpese from "./pages/Gestionale/Gestisci/Contabilia/NotaSpese/Note/AggiungiNotaSpese";
import NotaSpesePage from "./pages/Gestionale/Gestisci/Contabilia/NotaSpese/Note/NotaSpesePage";
import NotaSpeseInfo from "./pages/Gestionale/Gestisci/Contabilia/NotaSpese/Note/NotaSpeseInfo";
import AggiungiAllegatiNotaSpese
    from "./pages/Gestionale/Gestisci/Contabilia/NotaSpese/Allegati/AggiungiAllegatiNotaSpese";
import AggiungiRelazioni from "./pages/Gestionale/Gestisci/Anagrafica/Relazioni/AggiungiRelazioni";
import RelazioniPage from "./pages/Gestionale/Gestisci/Anagrafica/Relazioni/RelazioniPage";
import AggiungiPartecipazione from "./pages/Gestionale/Gestisci/Lavori/PartecipazioneTask/AggiungiPartecipazione";
import PartecipazionePage from "./pages/Gestionale/Gestisci/Lavori/PartecipazioneTask/PartecipazionePage";
import InfoEvento from "./pages/Gestionale/Scadenzario/InfoEvento";
import CalendarioPage from "./pages/Gestionale/Scadenzario/CalendarioPage";
import SezioneContabilita from "./pages/Gestionale/Gestisci/Contabilia/SezioneContabilita";
import SezioneValoriDefault from "./pages/Gestionale/Gestisci/ValoriDefault/SezioneValoriDefault";
import SezioniCMS from "./pages/Gestionale/CMS/SezioneCMS";
import BeniAziendaliPage from "./pages/Gestionale/Gestisci/BeniAziendali/BeniAziendaliPage";
import AggiungiBeniAziendali from "./pages/Gestionale/Gestisci/BeniAziendali/AggiungiBeniAziendali";
import SoggettiContabiliPage from "./pages/Gestionale/Gestisci/ValoriDefault/SoggettiContabili/SoggettiContabiliPage";
import AggiungiSoggettiContabili
    from "./pages/Gestionale/Gestisci/ValoriDefault/SoggettiContabili/AggiungiSoggettiContabili";
import FornitoriPage from "./pages/Gestionale/Gestisci/Anagrafica/Fornitori/FornitoriPage";
import AggiungiFornitoriPage from "./pages/Gestionale/Gestisci/Anagrafica/Fornitori/AggiungiFornitoriPage";
import CollaboratoriPage from "./pages/Gestionale/Gestisci/Anagrafica/Collaboratori/CollaboratoriPage";
import AggiungiCollaboratori from "./pages/Gestionale/Gestisci/Anagrafica/Collaboratori/AggiungiCollaboratori";
import ProduttoriPage from "./pages/Gestionale/Gestisci/Anagrafica/Produttori/ProduttoriPage";
import ProduttoreForm from "./pages/Gestionale/Gestisci/Anagrafica/Produttori/ProduttoreForm";
import ModificaLavoroPage from "./pages/Gestionale/Gestisci/Lavori/ModificaLavoroPage";
import GruppoPage from "./pages/Gestionale/Gestisci/ValoriDefault/GruppiCommesse/GruppoPage";
import AggiungiGruppo from "./pages/Gestionale/Gestisci/ValoriDefault/GruppiCommesse/AggiungiGruppo";
import AggiungiTask from "./pages/Gestionale/Gestisci/Lavori/Task/AggiungiTask";
import TaskPage from "./pages/Gestionale/Gestisci/Lavori/Task/TaskPage";
import TaskInfo from "./pages/Gestionale/Gestisci/Lavori/Task/TaskInfo";
import AggiungiCommento from "./pages/Gestionale/Gestisci/Lavori/Task/AggiungiCommento";
import MaterialeCommessa from "./pages/Gestionale/Gestisci/Lavori/MaterialeCommessa/MaterialeCommessa";
import AggiungiMaterialeCommessa from "./pages/Gestionale/Gestisci/Lavori/MaterialeCommessa/AggiungiMaterialeCommessa";
import TaskDaApprovare from "./pages/Gestionale/Gestisci/Lavori/Task/TaskDaApprovare";
import FotoTask from "./pages/Gestionale/Gestisci/Lavori/Task/FotoTask";
import AggiungiFotoTask from "./pages/Gestionale/Gestisci/Lavori/Task/AggiungiFotoTask";

import ReactGa from "react-ga"
import AllegatiSpesa from "./pages/Gestionale/Gestisci/Contabilia/NotaSpese/Allegati/AllegatiSpesa";
import AggiungiSpesa from "./pages/Gestionale/Gestisci/Contabilia/NotaSpese/Spese/AggiungiSpesa";
import ModificaNotaSpese from "./pages/Gestionale/Gestisci/Contabilia/NotaSpese/Note/ModificaNotaSpese";
import ModificaSpesa from "./pages/Gestionale/Gestisci/Contabilia/NotaSpese/Spese/ModificaSpesa";
import MovimentiPage from "./pages/Gestionale/Gestisci/Contabilia/Fattura/MovimentiPage";
import AggiungiMovimento from "./pages/Gestionale/Gestisci/Contabilia/Fattura/AggiungiMovimento";
import ModificaMovimento from "./pages/Gestionale/Gestisci/Contabilia/Fattura/ModificaMovimento";
import AggiungiNomeTask from "./pages/Gestionale/Gestisci/Lavori/Task/AggiungiNomeTask";
import CalendarioGiornaliero from "./pages/Gestionale/Scadenzario/calendari/CalendarioGiornaliero";
import CategoriaMateriale from "./pages/Gestionale/Gestisci/Materiale/CategoriaMateriale";
import SottocategoriaMateriale from "./pages/Gestionale/Gestisci/Materiale/SottocategoriaMateriale";
import ProfilePage from "./pages/Profile/ProfilePage";
import SezioneLog from "./pages/Gestionale/Log/SezioneLog";
import LogDipendenti from "./pages/Gestionale/Log/LogDipendenti";
import LogContratti from "./pages/Gestionale/Log/LogContratti";
import LogClienti from "./pages/Gestionale/Log/LogClienti";
import LogRelazioni from "./pages/Gestionale/Log/LogRelazioni";
import LogFornitori from "./pages/Gestionale/Log/LogFornitori";
import LogCollaboratori from "./pages/Gestionale/Log/LogCollaboratori";
import LogProduttori from "./pages/Gestionale/Log/LogProduttori";
import LogNotaSpese from "./pages/Gestionale/Log/LogNotaSpese";
import LogBustePaga from "./pages/Gestionale/Log/LogBustePaga";
import LogPrimaNota from "./pages/Gestionale/Log/LogPrimaNota";
import LogFattura from "./pages/Gestionale/Log/LogFattura";
import LogPreventivi from "./pages/Gestionale/Log/LogPreventivi";
import LogEventi from "./pages/Gestionale/Log/LogEventi";
import LogLavori from "./pages/Gestionale/Log/LogLavori";
import ModificaPreventivo from "./pages/Gestionale/Gestisci/Preventivi/ModificaPreventivo";
import EventiLavoro from "./pages/Gestionale/Gestisci/Lavori/EventiLavoro/EventiLavoro";
import AggiungiEventiLavoro from "./pages/Gestionale/Gestisci/Lavori/EventiLavoro/AggiungiEventiLavoro";
import AggiungiAnagraficaEvento from "./pages/Gestionale/Gestisci/Lavori/EventiLavoro/AggiungiAnagraficaEvento";
import AggiungiPartecipanteEvento from "./pages/Gestionale/Scadenzario/AggiungiPartecipante";
import CategorieRecurring from "./pages/Gestionale/Gestisci/Recurring/Categorie/CategorieRecurring";
import AggiungiCategorieRecurring from "./pages/Gestionale/Gestisci/Recurring/Categorie/AggiungiCategorieRecurring";
import ModificaCategoriaRecurring from "./pages/Gestionale/Gestisci/Recurring/Categorie/ModificaCategoriaRecurring";
import TipiRecurring from "./pages/Gestionale/Gestisci/Recurring/Tipi/TipiRecurring";
import AggiungiTipoRecurring from "./pages/Gestionale/Gestisci/Recurring/Tipi/AggiungiTipoRecurring";
import ModificaTipoRecurring from "./pages/Gestionale/Gestisci/Recurring/Tipi/ModificaTipoRecurring";
import RecurringPage from "./pages/Gestionale/Gestisci/Recurring/RecurringPage";
import AggiungiRecurring from "./pages/Gestionale/Gestisci/Recurring/AggiungiRecurring";
import BustePagaProfilo from "./pages/Profile/BustePagaProfilo";
import EventiProfiloOggi from "./pages/Profile/Eventi/EventiProfiloOggi";
import PagineEventiProfilo from "./pages/Profile/Eventi/PagineEventiProfilo";
import EventiProfiloMese from "./pages/Profile/Eventi/EventiProfiloMese";
import EventiProfiloTutti from "./pages/Profile/Eventi/EventiProfiloTutti";
import EventiProfiloIncompleti from "./pages/Profile/Eventi/EventiProfiloIncompleti";
import ModificaRecurring from "./pages/Gestionale/Gestisci/Recurring/ModificaRecurring";
import InfoRecurring from "./pages/Gestionale/Gestisci/Recurring/InfoRecurring";
import TaxPage from "./pages/Gestionale/Gestisci/Tax/TaxPage";
import AggiungiTax from "./pages/Gestionale/Gestisci/Tax/AggiungiTax";
import InfoTax from "./pages/Gestionale/Gestisci/Tax/InfoTax";
import ModificaTask from "./pages/Gestionale/Gestisci/Lavori/Task/ModificaTask";
import FormazioneProfiloPage from "./pages/Profile/formazione/FormazioneProfiloPage";
import FormazionePage from "./pages/Gestionale/Gestisci/Formazione/FormazionePage";
import AggiungiEventoFormazione from "./pages/Gestionale/Gestisci/Formazione/AggiungiEventoFormazione";
import InfoEventoFormazione from "./pages/Gestionale/Gestisci/Formazione/InfoEventoFormazione";
import AggiungiEventoGiorno from "./pages/Gestionale/Scadenzario/AggiungiEventoGiorno";
import LogSoggettoContabile from "./pages/Gestionale/Log/LogSoggettoContabile";
import MezziPagamentoPage from "./pages/Gestionale/Gestisci/ValoriDefault/MezziPagamento/MezziPagamentoPage";
import AggiungiMezzoPagamento from "./pages/Gestionale/Gestisci/ValoriDefault/MezziPagamento/AggiungiMezzoPagamento";
import MovimentiTax from "./pages/Gestionale/Gestisci/Tax/MovimentiTax";
import {publicPageRoutes} from "./routes/PublicPageRoutes";
import {anagraficaRoutes} from "./routes/AnagraficaRoutes";
import {careersRoutes} from "./routes/CareersRoutes";
import {percentualeRicaricoRoutes} from "./routes/PercentualeRicaricoRoutes";
import {magazzinoRoutes} from "./routes/MagazzinoRoutes";
import {protocolliRoutes} from "./routes/ProtocolliRoutes";
import {manutenzioniRoutes} from "./routes/ManutenzioniRoutes";
import {useEffect} from "react";
import {checkToken} from "./fetch/fetchAuth";
import {useDispatch} from "react-redux";
import {logout} from "./features/authSlice";
import TaskProfiloOggi from "./pages/Profile/Eventi/task/TaskProfiloOggi";
import TaskProfiloMese from "./pages/Profile/Eventi/task/TaskProfiloMese";
import TaskProfiloTutti from "./pages/Profile/Eventi/task/TaskProfiloTutti";
import TaskProfiloIncompleti from "./pages/Profile/Eventi/task/TaskProfiloIncompleti";

ReactGa.initialize("UA-245056135-2")

function App() {

    const PrivateRoute = ({children}) => {
        return localStorage.getItem("token") ? children : <Navigate to="/login"/>;
    }

    // TODO completa il refactoring delle routes in modo da avere un file per ogni sezione
    const temp = [
        {path: "/gestionale", element: <HomeGestionale/>,},
        {path: "/edit_data_page", element: <SezioneGestisci/>,},
        {path: "/dipendenti", element: <DipendentiPage/>,},
        {path: "/contratti", element: <ContrattiPage/>,},
        {path: "/clienti", element: <ClientiPage/>,},
        {path: "/add_post_cms", element: <PostCMSPage/>,},
        {path: "/sezioni_cms", element: <SezioniCMS/>,},
        {path: "/add_dipendente", element: <DipendenteFormPage/>,},
        {path: "/info_dipendente/:id", element: <InfoDipendentePage/>,},
        {path: "/add_contratto", element: <ContrattoFormPage/>},
        {path: "/add_project", element: <ProjectFormPage/>},
        {path: "/lavori", element: <LavoriPage/>},
        {path: "/lavori/eventi/:id", element: <EventiLavoro/>},
        {path: "/lavori/eventi/:id/aggiungi", element: <AggiungiEventiLavoro/>},
        {path: "/lavori/eventi/:id/anagrafiche/crea", element: <AggiungiAnagraficaEvento/>},
        {path: "/prima_nota", element: <PrimaNotaPage/>},
        {path: "/aggiungi_prima_nota", element: <AggiungiPrimaNota/>},
        {path: "/fatture", element: <FatturaPage/>},
        {path: "/aggiungi_fattura", element: <AggiungiFattura/>},
        {path: "/movimenti/:id", element: <MovimentiPage/>},
        {path: "movimenti/aggiungi/:id", element: <AggiungiMovimento/>},
        {path: "movimenti/modifica/:id", element: <ModificaMovimento/>},
        {path: "/materiale", element: <MaterialePage/>},
        {path: "/aggiungi_materiale", element: <MaterialeForm/>},
        {path: "/categoria_materiale", element: <CategoriaMateriale/>},
        {path: "/sottocategoria_materiale", element: <SottocategoriaMateriale/>},
        {path: "/scadenzario", element: <CalendarioPage/>},
        {path: "/scadenzario/:giornoSelezionato", element: <CalendarioGiornaliero/>},
        {path: "/scadenzario/eventi/:id/partecipanti/crea", element: <AggiungiPartecipanteEvento/>},
        {path: "/aggiungi_evento", element: <AggiungiEvento/>},
        {path: "/aggiungi_evento/:giorno", element: <AggiungiEventoGiorno/>},
        {path: "/info_evento/:id", element: <InfoEvento/>},
        {path: "/nota_spese", element: <NotaSpesePage/>},
        {path: "/nota_spese/spesa/aggiungi/:id", element: <AggiungiSpesa/>},
        {path: "/allegati/:id", element: <AllegatiSpesa/>},
        {path: "/nota_spese/:id", element: <NotaSpeseInfo/>},
        {path: "/spesa/modifica/:id", element: <ModificaSpesa/>},
        {path: "/aggiungi_nota_spese", element: <AggiungiNotaSpese/>},
        {path: "/aggiungi_allegati_nota_spese/:id", element: <AggiungiAllegatiNotaSpese/>},
        {path: "/add_lavoro", element: <AddLavoroPage/>},
        {path: "/modifica_lavoro/:id", element: <ModificaLavoroPage/>},
        {path: "/task/:id", element: <TaskPage/>},
        {path: "/task_da_approvare", element: <TaskDaApprovare/>},
        {path: "/aggiungi_nome_task", element: <AggiungiNomeTask/>},
        {path: "/partecipanti_task/:id", element: <PartecipazionePage/>},
        {path: "/partecipanti_task/aggiungi/:id", element: <AggiungiPartecipazione/>},
        {path: "/foto_task/:id", element: <FotoTask/>},
        {path: "/aggiungi_foto_task/:id", element: <AggiungiFotoTask/>},
        {path: "/task_info/:id", element: <TaskInfo/>},
        {path: "/task/modifica/:id", element: <ModificaTask/>},
        {path: "/aggiungi_commento/:id", element: <AggiungiCommento/>},
        {path: "/materiale_commessa/:id", element: <MaterialeCommessa/>},
        {path: "/aggiungi_materiale_commessa/:id", element: <AggiungiMaterialeCommessa/>},
        {path: "/aggiungi_task/:id", element: <AggiungiTask/>},
        {path: "/gruppi", element: <GruppoPage/>},
        {path: "/aggiungi_gruppo", element: <AggiungiGruppo/>},
        {path: "/edit_post/:id", element: <EditPostPage/>},
        {path: "/edit_project/:id", element: <EditProjectPage/>},
        {path: "/add_cliente", element: <ClienteFormPage/>},
        {path: "/add_busta_paga", element: <BustePagaPage/>},
        {path: "/buste_paga", element: <BustePagaGestionale/>},
        {path: "/aggiungi_preventivo", element: <PreventiviForm/>},
        {path: "/preventivi", element: <PreventiviPage/>},
        {path: "/preventivi/:id", element: <ModificaPreventivo/>},
        {path: "/aggiungi_relazioni", element: <AggiungiRelazioni/>},
        {path: "/relazioni", element: <RelazioniPage/>},
        {path: "/partecipazioni", element: <PartecipazionePage/>},
        {path: "/sezioni_contabilita", element: <SezioneContabilita/>},
        {path: "/sezioni_default", element: <SezioneValoriDefault/>},
        {path: "/beni_aziendali", element: <BeniAziendaliPage/>},
        {path: "/aggiungi_beni_aziendali", element: <AggiungiBeniAziendali/>},
        {path: "/soggetti_contabili", element: <SoggettiContabiliPage/>},
        {path: "/aggiungi_soggetti_contabili", element: <AggiungiSoggettiContabili/>},
        {path: "/fornitori", element: <FornitoriPage/>},
        {path: "/aggiungi_fornitori", element: <AggiungiFornitoriPage/>},
        {path: "/collaboratori", element: <CollaboratoriPage/>},
        {path: "/aggiungi_collaboratori", element: <AggiungiCollaboratori/>},
        {path: "/produttori", element: <ProduttoriPage/>},
        {path: "/aggiungi_produttore", element: <ProduttoreForm/>},
        {path: "/profile", element: <ProfilePage/>},
        {path: "/profile/buste-paga", element: <BustePagaProfilo/>},
        {path: "/profile/eventi", element: <PagineEventiProfilo/>},
        {path: "/profile/eventi/oggi", element: <EventiProfiloOggi/>},
        {path: "/profile/eventi/mese", element: <EventiProfiloMese/>},
        {path: "/profile/eventi/tutti", element: <EventiProfiloTutti/>},
        {path: "/profile/eventi/incompleti", element: <EventiProfiloIncompleti/>},
        {path: "/profile/task/oggi", element: <TaskProfiloOggi/>},
        {path: "/profile/task/mese", element: <TaskProfiloMese/>},
        {path: "/profile/task/tutti", element: <TaskProfiloTutti/>},
        {path: "/profile/task/incompleti", element: <TaskProfiloIncompleti/>},
        {path: "/log", element: <SezioneLog/>},
        {path: "/log/dipendenti", element: <LogDipendenti/>},
        {path: "/log/contratti", element: <LogContratti/>},
        {path: "/log/clienti", element: <LogClienti/>},
        {path: "/log/relazioni", element: <LogRelazioni/>},
        {path: "/log/fornitori", element: <LogFornitori/>},
        {path: "/log/collaboratori", element: <LogCollaboratori/>},
        {path: "/log/produttori", element: <LogProduttori/>},
        {path: "/log/nota_spese", element: <LogNotaSpese/>},
        {path: "/log/buste-paga", element: <LogBustePaga/>},
        {path: "/log/prima_nota", element: <LogPrimaNota/>},
        {path: "/log/fatture", element: <LogFattura/>},

        {path: "/log/soggetti_contabili", element: <LogSoggettoContabile/>},
        {path: "/log/preventivi", element: <LogPreventivi/>},
        {path: "/log/eventi", element: <LogEventi/>},
        {path: "/log/lavori", element: <LogLavori/>},
        {path: "/categorie-recurring", element: <CategorieRecurring/>},
        {path: "/categorie-recurring/aggiungi", element: <AggiungiCategorieRecurring/>},
        {path: "/categorie-recurring/modifica/:id", element: <ModificaCategoriaRecurring/>},
        {path: "/tipi-recurring", element: <TipiRecurring/>},
        {path: "/tipi-recurring/aggiungi", element: <AggiungiTipoRecurring/>},
        {path: "/tipi-recurring/aggiungi", element: <AggiungiTipoRecurring/>},
        {path: "/tipi-recurring/modifica/:id", element: <ModificaTipoRecurring/>},
        {path: "/recurrings", element: <RecurringPage/>},
        {path: "/recurrings/aggiungi", element: <AggiungiRecurring/>},
        {path: "/recurrings/:id", element: <ModificaRecurring/>},
        {path: "/recurrings/info/:id", element: <InfoRecurring/>},

        {path: "/tax", element: <TaxPage/>},
        {path: "/tax/aggiungi", element: <AggiungiTax/>},
        {path: "/tax/info/:id", element: <InfoTax/>},
        {path: "/tax/info/:id/movimenti", element: <MovimentiTax/>},
        {path: "/profile/formazione", element: <FormazioneProfiloPage/>},
        {path: "/formazione", element: <FormazionePage/>},
        {path: "/formazione/aggiungi", element: <AggiungiEventoFormazione/>},
        {path: "/formazione/info/:id", element: <InfoEventoFormazione/>},
        {path: "/mezzi-pagamento", element: <MezziPagamentoPage/>},
        {path: "/mezzi-pagamento/aggiungi", element: <AggiungiMezzoPagamento/>},
    ]

    const listaPath = [
        ...temp,
        ...anagraficaRoutes,
        ...careersRoutes,
        ...percentualeRicaricoRoutes,
        ...magazzinoRoutes,
        ...protocolliRoutes,
        ...manutenzioniRoutes,
    ]

    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("token") !== null) {
            checkToken(localStorage.getItem("token")).catch(
                () => {
                    localStorage.removeItem("token")
                    dispatch(logout())
                }
            )
        }
    }, []);

    return (
        <Router>
            <Routes>
                {
                    publicPageRoutes.map((route, index) => {
                        return (
                            <Route key={index} path={route.path} element={route.element}/>
                        )
                    })
                }

                {
                    listaPath.map((item, index) => {
                        return (
                            <Route
                                key={index}
                                path={item.path}
                                element={
                                    <PrivateRoute>
                                        {item.element}
                                    </PrivateRoute>
                                }
                            />
                        )
                    })
                }
            </Routes>
        </Router>
    );
}

export default App;
