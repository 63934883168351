import React from 'react';
import HeaderSection from "../../components/HeaderSection";
import {getCareers} from "../../fetch/fetchCareers";
import {Link} from "react-router-dom";
import MainLayout from "../../Layouts/MainLayout";
import {useQuery} from "react-query";

const Careers = () => {

    const {data: careers = []} = useQuery('careers', () => getCareers().then(
        (data) => {
            return data.data
        }
    ))

    return (
        <MainLayout margin={"mt-16"}>
            <HeaderSection title={"Careers"}/>
            <div className={"flex flex-col space-y-4 items-center justify-center my-6 divide-y"}>
                {
                    careers.map((c, i) => (
                        <div key={i}
                             className={"w-4/5 flex flex-col sm:flex-row p-4 rounded justify-center items-center space-y-4"}>
                            <div className={"flex flex-col flex-1 justify-center items-center sm:items-start"}>
                                <p className={"font-bold"}>
                                    {c?.mansione}
                                </p>
                                <p className={"uppercase italic"}>
                                    {c?.tipo_contratto}
                                </p>
                            </div>
                            <div
                                className={"flex flex-col flex-1 justify-center items-center sm:items-end sm:mr-4"}>
                                <p className={"font-bold"}>
                                    {c?.luogo}
                                </p>
                                <p>
                                    {c?.stato}
                                </p>
                            </div>

                            <Link to={"/careers/" + c?.id}
                                  className={"px-4 py-2 bg-my-green rounded hover:bg-green-600 cursor-pointer text-white font-semibold"}>
                                Più info
                            </Link>

                        </div>
                    ))
                }

            </div>
        </MainLayout>
    );
};

export default Careers;
