import React, {useEffect, useState} from 'react';
import MainLayout from "../../../../../Layouts/MainLayout";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import BackButton from "../../../../../components/BackButton";
import HeaderSection from "../../../../../components/HeaderSection";
import {MyCombobox} from "../../../../../components/combobox/MyCombobox";
import {creaContratto} from "../../../../../fetch/fetchContratti";
import {sleep} from "../../../../../constants/sleep";
import {getAnagrafiche} from "../../../../../fetch/fetchAnagrafica";
import {getSocieta} from "../../../../../fetch/fetchSocieta";

const ContrattoFormPage = () => {
    const [alert, setAlert] = useState(0)
    const [anagrafiche, setAnagrafiche] = useState([])
    const [societa, setSocieta] = useState([])
    const tipo = ["Dipendente", "Cliente", "Collaboratore", "Accordo Quadro", "Fornitura", "Altro"]

    const [selectedAnagrafica, setSelectedAnagrafica] = useState()


    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('anagrafica', selectedAnagrafica.id);
        postData.append('inizio', e.target.inizio.value);
        postData.append('fine', e.target.fine.value);
        postData.append('societa', e.target.societa.value);
        postData.append('tipo_contratto', e.target.tipo_contratto.value);

        creaContratto(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    }

    useEffect(() => {
        getAnagrafiche().then(r => {
            setAnagrafiche(r.data)
            setSelectedAnagrafica(r.data[0])
        }).catch(e => {
            console.log(e)
        })
        getSocieta().then(r => {
            setSocieta(r.data)
        }).catch(e => {
            console.log(e)
        })
    }, [])

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"Il Contratto è stato aggiunto con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi nuovo Contratto"}/>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Anagrafica*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Anagrafica
                                    </div>
                                    <MyCombobox
                                        valueList={anagrafiche}
                                        selectedItem={selectedAnagrafica}
                                        setSelectedItem={setSelectedAnagrafica}
                                    />
                                </div>
                            </div>
                            {/*Società*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Società
                                    </div>
                                    <select
                                        name={"societa"}
                                        defaultValue={societa[0]}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            societa.map((soc, index) => (
                                                <option
                                                    key={index}
                                                    value={soc.id}
                                                >
                                                    {soc.nome}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Inizio*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Inizio Contratto
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="date"
                                            name={"inizio"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Fine*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Fine Contratto
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="date"
                                            name={"fine"}
                                            defaultValue={null}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*Tipo*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Tipo Contratto
                                    </div>
                                    <select
                                        name={"tipo_contratto"}
                                        defaultValue={tipo[0]}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            tipo.map((t, index) => (
                                                <option
                                                    key={index}
                                                    value={t}
                                                >
                                                    {t}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Aggiungi
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default ContrattoFormPage;
