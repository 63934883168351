import React from 'react';
import ServiceCard from "./ServiceCard";
import {Building, HeartHandshake, Leaf} from "lucide-react";

const HomeService = () => {
    return (
        <section className={"mt-20 bg-white"}>

            {/*Cards Team*/}
            <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-screen-lg mt-16 space-x-5">
                <ServiceCard icon={<HeartHandshake/>} title={"Finanza"} subtitle={"Affrontare le sfide finanziarie con determinazione e intelligenza"} description={"La linfa vitale delle imprese, la forza che le spinge a crescere, innovare e prosperare. Gestione finanziaria sana e oculata, un piani industriali, consulenza per l'ottenimento di finanziamenti."}
                             link={"/finanza"}/>
                <ServiceCard icon={<Building/>} title={"Ingegneria"} subtitle={"L'ingegneria come catalizzatore del cambiamento e dell'innovazione"} description={"la spina dorsale della società moderna, la forza che sostiene e connette tutte le infrastrutture essenziali che ci circondano, dai ponti alle autostrade, dalle ferrovie agli edifici."}
                             link={"/ingegneria"}/>
                <ServiceCard icon={<Leaf/>} title={"Green energy"}
                             subtitle={"Rinnovare il nostro approccio all'energia con soluzioni green"} description={"Il potere della natura per alimentare il nostro futuro in modo sostenibile e rispettoso dell'ambiente. Il cammino verso un futuro sostenibile, illuminando il percorso verso fonti energetiche pulite."} link={"/elettrotecnica"}/>
            </div>
        </section>
    );
};

export default HomeService;
