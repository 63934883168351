export const login = () => {
    return {
        type: "LOGIN"
    }
}
export const logout = () => {
    return {
        type: "LOGOUT"
    }
}

export const setFilter = (nome) => {
    return {
        type: nome
    }
}


export const setFilterSocieta = (nome) => {
    return {
        type: nome
    }
}

