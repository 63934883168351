import React, {useEffect, useState} from 'react';
import HeaderSectionAdd from "../../../../components/HeaderSectionAdd";
import {delBeniAziendali, getBeniAziendali} from "../../../../fetch/fetchBeniAziendali";
import FiltroSocieta from "../../../../components/FiltroSocieta";
import {useSelector} from "react-redux";
import BackButton from "../../../../components/BackButton";
import MainLayout from "../../../../Layouts/MainLayout";

const BeniAziendaliPage = () => {

    const [filter, setFilter] = useState("")
    const [beniAziendali, setBeniAziendali] = useState([])
    const {filtroSoc} = useSelector(state => state.societa)

    const [showModal, setShowModal] = useState(false)
    const [eliminazione, setEliminazione] = useState(-1)

    const myDel = async (e, id) => {
        e.preventDefault()
        delBeniAziendali(id).then(() =>
            getClientiSupport()
        ).catch(e => {
            console.log(e)
        })
    }

    const getClientiSupport = () => {
        getBeniAziendali().then(r =>
            setBeniAziendali(r.data)
        ).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        getClientiSupport()
    }, [])

    return (
        <MainLayout>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Eliminazione
                                    </h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        Sicuro di voler eliminare?
                                    </p>
                                </div>
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-my-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Annulla
                                    </button>
                                    <button
                                        className="bg-my-red text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(e) => {
                                            myDel(e, eliminazione)
                                            setShowModal(false)
                                        }}
                                    >
                                        Elimina
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSectionAdd titolo={"Beni Aziendali"} link={"/aggiungi_beni_aziendali"}
                                  esporta={"beni_aziendali"}/>
                <FiltroSocieta/>
                <div className={"my-10 flex justify-center items-center"}>
                    <div className="flex flex-col w-11/12">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={"Cerca..."}
                                            className={"w-full py-2 px-4 outline-none border-none"}
                                            onChange={event => setFilter(event.target.value)}
                                        />
                                    </div>
                                    <div className={"max-h-96 overflow-x-auto"}>
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50 sticky top-0 z-10">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Tipo
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Produttore
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Modello
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Descrizione
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Società
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Seriale Interno
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Seriale Produttore
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                In uso da
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Elimina
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            beniAziendali.filter(bene => (
                                                bene.tipo.toLowerCase().includes(filter.toLowerCase())
                                            )).filter(bene => (
                                                bene.societa.nome.toLowerCase().includes(filtroSoc.toLowerCase())
                                            )).map((bene, i) => (
                                                <tr key={i}>
                                                    {/*Tipo*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{bene.tipo}
                                                        </div>
                                                    </td>
                                                    {/*Produttore*/}
                                                    <td className="px-6 py-4 ">
                                                        <div className="text-sm text-gray-900">
                                                            {bene.produttore.anagrafica.nome} {bene.produttore.anagrafica.cognome}
                                                        </div>
                                                    </td>
                                                    {/*Modello*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{bene.modello}
                                                        </div>
                                                    </td>
                                                    {/*Descrizione*/}
                                                    <td className="px-6 py-4 w-80 flex overflow-auto">
                                                        <div
                                                            className="text-sm text-gray-900">{bene.descrizione}
                                                        </div>
                                                    </td>
                                                    {/*Societa*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{bene.societa.nome}
                                                        </div>
                                                    </td>
                                                    {/*Seriale Interno*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{bene.seriale_interno}
                                                        </div>
                                                    </td>
                                                    {/*Seriale Produttore*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{bene.seriale_produttore}
                                                        </div>
                                                    </td>
                                                    {/*Seriale Produttore*/}
                                                    <td className="px-6 py-4 ">
                                                        <div className="text-sm text-gray-900">
                                                            {bene.in_uso_da ? bene.in_uso_da.nome + " " + bene.in_uso_da.cognome : "Uso Comune"}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4  text-sm text-gray-500">
                                                        <button onClick={() => {
                                                            setShowModal(true)
                                                            setEliminazione(bene.id)
                                                        }}>
                                                            <p className="text-my-red font-bold hover:text-red-700">Elimina</p>
                                                        </button>
                                                    </td>

                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default BeniAziendaliPage;
