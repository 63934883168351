import React from 'react';
import Footer from "../../../../components/Footer";
import HeaderSection from "../../../../components/HeaderSection";
import BackButton from "../../../../components/BackButton";
import Navbar from "../../../../components/Navbar";
import {addEventiFormazione} from "../../../../fetch/fetchFormazione";

const AggiungiEventoFormazione = () => {

    const handleSubmit = (event) => {
        event.preventDefault()
        const data = new FormData(event.target)

        addEventiFormazione(data).then(() => {
            event.target.reset()
        }).catch(
            e => {
                console.log(e)
            }
        )
    }

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Evento Formazione"}/>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Nome*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Nome
                                    </div>
                                    <input
                                        type="text"
                                        name="nome"
                                        placeholder={"Nome"}
                                        required
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                            {/*Descrizione*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Descrizione
                                    </div>
                                    <textarea
                                        name={"descrizione"}
                                        placeholder={"Inserisci la descrizione"}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Aggiungi
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer/>
        </div>
    );
};

export default AggiungiEventoFormazione;
