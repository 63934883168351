import React from 'react';
import BackButton from "../../../../components/BackButton";
import HeaderSection from "../../../../components/HeaderSection";
import FormCard from "../../../../components/FormCard";
import MainLayout from "../../../../Layouts/MainLayout";
import {File, Truck, Warehouse} from "lucide-react";

const SezioniLogistica = () => {
    return (
        <MainLayout>
            <section className={"bg-white p-20"}>
                <BackButton/>
                <HeaderSection title={"Logistica"}/>
                <div
                    className="container grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 max-w-screen-lg mt-10 gap-4 ">
                    {/*<FormCard icon={solid("file")} title={"Bolle Trasporto"} link={"/bolle-trasporto"}/>*/}
                    {/*<FormCard icon={solid("truck")} title={"Carichi"} link={"/carichi-magazzino"}/>*/}
                    {/*<FormCard icon={solid("warehouse")} title={"Magazzini"} link={"/magazzini"}/>*/}
                    <FormCard icon={<File size={48}/>} title={"Bolle Trasporto"} link={"/bolle-trasporto"}/>
                    <FormCard icon={<Truck size={48}/>} title={"Carichi"} link={"/carichi-magazzino"}/>
                    <FormCard icon={<Warehouse size={48}/>} title={"Magazzini"} link={"/magazzini"}/>
                </div>
            </section>
        </MainLayout>
    );
};

export default SezioniLogistica;
