import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {navLink} from "../constants/PageLinks"
import logoPiccolo from "../assets/Logo/FWD engineering bluearrow.png"
import {useDispatch, useSelector} from "react-redux";
import {login, logout} from "../features/authSlice";
import {LogIn, LogOut, Menu, X} from "lucide-react";


const Navbar = () => {

    const {username, is_superuser} = useSelector(state => state.auth)

    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)

    const clearToken = () => {
        localStorage.removeItem("token")
        dispatch(logout())
    }

    useEffect(() => {
        if (localStorage.getItem("token")) {
            dispatch(login({
                token: localStorage.getItem("token"),
                username: localStorage.getItem("username"),
                is_superuser: localStorage.getItem("is_superuser")
            }))
        }
    }, [])

    const displayNavLink = () => (
        <>
            {
                navLink.map((link, index) => (
                    <li className={"cursor-pointer text-primary-blue hover:underline"} key={index}>
                        <Link to={link.path}>
                            <p>
                                {link.nome}
                            </p>
                        </Link>
                    </li>
                ))
            }
        </>
    )

    return (
        <div className={"py-10"}>

            <header className={"bg-white lg:px-8 shadow fixed z-50 inset-x-0 top-0"}>
                {/*Menù standard per la visione su schermi grandi*/}
                <nav className="flex items-center p-6 justify-between">
                    {/*NavLink*/}
                    <ul className="hidden lg:flex items-center gap-12 text-primary-blue uppercase">
                        {/*Logo*/}
                        <div className="py-1">
                            <Link to={"/"}>
                                {/*<h1 className={"text-3xl text-primary-blue font-bold"}>Forward</h1>*/}
                                <div className={"hidden lg:flex"}>
                                    <img src={logoPiccolo} alt="" className={"w-20"}/>
                                </div>

                            </Link>
                        </div>
                        {/*Links*/}
                        {
                            displayNavLink()
                        }
                    </ul>
                    <ul className="hidden lg:flex items-center gap-12 text-primary-blue uppercase">
                        {
                            username ? (
                                <div className={"flex gap-12"}>
                                    {
                                        is_superuser !== "false" ? (
                                            <li className={"cursor-pointer text-primary-blue hover:underline"}>
                                                <Link to={"/gestionale"}>
                                                    <p>
                                                        Gestionale
                                                    </p>
                                                </Link>
                                            </li>
                                        ) : null
                                    }
                                    <li className={"cursor-pointer text-primary-blue hover:underline"}>
                                        <Link to={"/profile"}>
                                            <p>
                                                {username}
                                            </p>
                                        </Link>
                                    </li>
                                </div>
                            ) : null
                        }
                        {
                            username ? (
                                <li className={"cursor-pointer text-primary-blue hover:underline"}
                                    onClick={clearToken}
                                >
                                    <Link to={"/"}>
                                        <LogOut className={"text-2xl text-primary-blue"}/>
                                    </Link>
                                </li>
                            ) : (
                                <li className={"cursor-pointer text-primary-blue hover:underline"}>
                                    <Link to={"/login"}>
                                        <LogIn className={"text-2xl text-primary-blue"}/>
                                    </Link>
                                </li>
                            )
                        }
                    </ul>
                    {/*Logo schermi piccoli*/}

                    <div className={"flex lg:hidden flex-1 justify-start"}>
                        <img src={logoPiccolo} alt="" className={"w-16"}/>
                    </div>

                    {/*Burger che compare solo per schermi piccoli*/}
                    <div className={"flex lg:hidden flex-1 justify-end"}>
                        <button onClick={() => setIsOpen(!isOpen)}>
                            {
                                !isOpen ?
                                    <Menu className={"text-2xl text-primary-blue"}/>
                                    :
                                    <X className={"text-3xl text-primary-blue"}/>
                            }
                        </button>
                    </div>
                </nav>

                {/*Menù a scomparsa*/}
                <div>
                    {/* Quando viene cliccato il burger si apre il menù verticale */}
                    {
                        isOpen &&
                        <ul className="flex lg:hidden flex-col flex-1 justify-end items-center gap-10 text-primary-blue pb-4 uppercase animate-fadeIn">
                            {
                                displayNavLink()
                            }
                            {
                                username ? (
                                    <>
                                        {
                                            is_superuser !== "false" ? (
                                                <li className={"cursor-pointer text-primary-blue hover:underline"}>
                                                    <Link to={"/gestionale"}>
                                                        <p>
                                                            Gestionale
                                                        </p>
                                                    </Link>
                                                </li>
                                            ) : null
                                        }

                                        <li className={"cursor-pointer text-primary-blue hover:underline"}>
                                            <Link to={"/profile"}>
                                                <p>
                                                    {username}
                                                </p>
                                            </Link>
                                        </li>
                                    </>
                                ) : null
                            }
                            {
                                username ? (
                                    <li className={"cursor-pointer text-primary-blue hover:underline"}
                                        onClick={clearToken}>
                                        <Link to={"/"}>
                                            <p>
                                                Logout
                                            </p>
                                        </Link>
                                    </li>
                                ) : (
                                    <li className={"cursor-pointer text-primary-blue hover:underline"}>
                                        <Link to={"/login"}>
                                            <p>
                                                Login
                                            </p>
                                        </Link>
                                    </li>
                                )
                            }
                        </ul>
                    }
                </div>
            </header>
        </div>

    );
};

export default Navbar;
