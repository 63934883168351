import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import HeaderSection from "../../../components/HeaderSection";
import {
    editEvento,
    eliminaAllegatoEvento,
    eliminaEvento,
    eliminaPartecipanteEvento,
    getEvento,
    getPartecipantiEvento,
    listaAllegatiEvento
} from "../../../fetch/fetchEventi";
import BackButton from "../../../components/BackButton";
import DangerAlert from "../../../components/DangerAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import MainLayout from "../../../Layouts/MainLayout";
import {Trash} from "lucide-react";

const InfoEvento = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const [alert, setAlert] = useState(0)

    const [evento, setEvento] = useState({
        id: id,
        nome: "",
        data: "",
        data_fine: "",
        ora_inizio: "",
        ora_fine: "",
        descrizione: "",
        luogo: "",
        anagrafica: {
            nome: "",
            cognome: "",
        }
    })
    const [statoEvento, setStatoEvento] = useState("")

    const modificaStatoEvento = (e) => {
        e.preventDefault()
        console.log(statoEvento)
        switch (statoEvento) {
            case "Incompleto":
                setStatoEvento("Parziale")
                break
            case "Parziale":
                setStatoEvento("Completato")
                break
            case "Completato":
                setStatoEvento("Incompleto")
                break
            default:
                setStatoEvento("Incompleto")
        }
    }

    const [soc, setSoc] = useState()
    const [x, setX] = useState()

    const getSupporto = () => {
        getEvento(id).then(
            r => {
                setEvento({
                    id: r.data.id,
                    nome: r.data.nome,
                    data: r.data.data,
                    data_fine: r.data.data_fine,
                    ora_inizio: r.data.ora_inizio,
                    ora_fine: r.data.ora_fine,
                    descrizione: r.data.descrizione,
                    luogo: r.data.luogo,
                    anagrafica: {
                        nome: r.data.anagrafica.nome,
                        cognome: r.data.anagrafica.cognome,
                    }
                })
                setStatoEvento(r.data.is_completato)
                setSoc(r.data.societa.nome)
                setX(r.data.tipo_evento.nome)
            }
        )
        getPartecipantiEvento(id).then(
            r => {
                setPartecipanti(r.data)
            }
        ).catch(
            e => console.log(e)
        )
        listaAllegatiEvento(id).then(
            r => {
                setAllegati(r.data)
            }
        ).catch(
            e => console.log(e)
        )

    }

    const [partecipanti, setPartecipanti] = useState([])
    const [allegati, setAllegati] = useState([])

    useEffect(() => {
        getSupporto()
        window.scrollTo(0, 0)
    }, [])

    const onChange = (e) => setEvento({...evento, [e.target.name]: e.target.value})

    const modifica = (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append("nome", evento.nome)
        formData.append("data", evento.data)
        formData.append("ora_inizio", evento.ora_inizio)
        formData.append("descrizione", evento.descrizione)
        formData.append("is_completato", statoEvento)

        if (evento.data_fine) {
            formData.append("data_fine", evento.data_fine)
        }

        if (evento.ora_fine) {
            formData.append("ora_fine", evento.ora_fine)
        }

        console.log(formData)

        editEvento(evento.id, formData).then(
            () => {
                setAlert(1)
                getSupporto()
            }
        ).catch(
            () => setAlert(-1)
        ).finally(
            () => {
                setTimeout(() => {
                    setAlert(0)
                }, 3000)
            }
        )
    }

    const elimina = (e) => {
        e.preventDefault()
        eliminaEvento(evento.id).then(
            () => navigate(-1)
        )
    }

    const eliminaPartecipante = (idPartecipante) => {
        eliminaPartecipanteEvento(idPartecipante).then(
            () => getSupporto()
        ).catch(
            () => console.log("Errore")
        )
    }
    const eliminaAllegato = (idAllegato) => {
        eliminaAllegatoEvento(id, idAllegato).then(
            () => getSupporto()
        ).catch(
            () => console.log("Errore")
        )
    }

    return (
        <MainLayout>
            <div className={"mt-10 flex flex-col items-center"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <div className={"w-4/5"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"Evento aggiunto con successo"}/>
                        ) : (
                            <div/>
                        )
                    }
                </div>
                <HeaderSection title={evento.nome}/>
            </div>
            <section className={"bg-white p-10 flex items-center justify-center"}>
                <div className={"w-full md:w-2/3 p-4 border-2 rounded flex flex-col space-y-4"}>
                    <div className={"flex flex-col sm:flex-row gap-4"}>
                        <div className={"flex flex-1 flex-col"}>
                            <p className={"text-xs"}>Nome</p>
                            <input
                                type="text"
                                value={evento.nome}
                                name={"nome"}
                                onChange={onChange}
                                className={"bg-my-white rounded px-2 py-1 outline-none w-full"}
                            />
                        </div>

                        <div className={"flex flex-1 flex-col"}>
                            <p className={"text-xs"}>Completamento</p>
                            {
                                statoEvento === "Completato" ? (
                                    <div
                                        className={"bg-my-green rounded px-2 py-1 cursor-pointer hover:bg-green-600"}
                                        onClick={modificaStatoEvento}
                                    >
                                        Completato
                                    </div>
                                ) : statoEvento === "Parziale" ? (
                                    <div
                                        className={"bg-yellow-400 rounded px-2 py-1 cursor-pointer hover:bg-yellow-600"}
                                        onClick={modificaStatoEvento}
                                    >
                                        Parziale
                                    </div>
                                ) : (
                                    <div
                                        className={"bg-my-red rounded px-2 py-1 cursor-pointer hover:bg-red-600"}
                                        onClick={modificaStatoEvento}
                                    >
                                        Incompleto
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div className={"flex flex-col"}>
                        <p className={"text-xs"}>Descrizione</p>
                        <textarea
                            value={evento.descrizione}
                            name={"descrizione"}
                            onChange={onChange}
                            className={"bg-my-white rounded px-2 py-1 outline-none"}
                        />
                    </div>

                    <div className={"flex flex-col sm:flex-row  gap-4"}>
                        <div className={"flex flex-1 flex-col"}>
                            <p className={"text-xs"}>Data Inizio</p>
                            <input
                                type="date"
                                value={evento.data}
                                name={"data"}
                                onChange={onChange}
                                className={"bg-my-white rounded px-2 py-1 outline-none"}
                            />
                        </div>
                        <div className={"flex flex-1 flex-col"}>
                            <p className={"text-xs"}>Data Fine</p>
                            <input
                                type="date"
                                value={evento.data_fine}
                                name={"data_fine"}
                                onChange={onChange}
                                className={"bg-my-white rounded px-2 py-1 outline-none"}
                            />
                        </div>
                    </div>

                    <div className={"flex flex-col sm:flex-row  gap-4"}>
                        <div className={"flex flex-1 flex-col"}>
                            <p className={"text-xs"}>Ora Inizio</p>
                            <input
                                type="time"
                                value={evento.ora_inizio}
                                name={"ora_inizio"}
                                onChange={onChange}
                                className={"bg-my-white rounded px-2 py-1 outline-none"}
                            />
                        </div>
                        <div className={"flex flex-1 flex-col"}>
                            <p className={"text-xs"}>Ora Fine</p>
                            <input
                                type="time"
                                value={evento.ora_fine}
                                name={"ora_fine"}
                                onChange={onChange}
                                className={"bg-my-white rounded px-2 py-1 outline-none"}
                            />
                        </div>
                    </div>

                    <div className={"flex flex-col sm:flex-row  space-x-2 items-center"}>
                        <p className={"font-bold"}>Società:</p>
                        <p>{soc}</p>
                    </div>
                    <div className={"flex flex-col sm:flex-row  space-x-2 items-center"}>
                        <p className={"font-bold"}>Tipologia:</p>
                        <p>{x}</p>
                    </div>
                    <div className={"flex flex-col sm:flex-row  space-x-2 items-center"}>
                        <p className={"font-bold"}>Luogo:</p>
                        <p>{evento.luogo}</p>
                    </div>
                    <div className={"flex flex-col sm:flex-row  space-x-2 items-center"}>
                        <p className={"font-bold"}>Anagrafica Principale:</p>
                        <p>{evento.anagrafica.nome} {evento.anagrafica.cognome}</p>
                    </div>
                    <div className={"flex flex-col sm:flex-row  justify-evenly items-center gap-4"}>
                        <div
                            onClick={modifica}
                            className={"bg-my-green px-2 py-1 rounded w-11/12  sm:w-1/5 text-center hover:bg-green-600 cursor-pointer text-white font-semibold"}
                        >
                            Salva
                        </div>
                        <div
                            onClick={elimina}
                            className={"bg-my-red px-2 py-1 rounded w-11/12  sm:w-1/5 text-center hover:bg-red-600 cursor-pointer text-white font-semibold"}
                        >
                            Elimina
                        </div>
                    </div>

                </div>
            </section>
            <div className={"flex flex-col w-full items-center justify-center mb-10"}>
                <HeaderSection title={"Partecipanti Aggiuntivi"}/>
                <div className={"w-4/5 sm:w-3/5 bg-my-white my-3 p-3"}>
                    {
                        partecipanti.length > 0 ? (
                            partecipanti.map((partecipante, index) => (
                                <div key={index} className={"flex justify-between items-center"}>
                                    <div className={"text-xl font-semibold"}>
                                        {partecipante.anagrafica.nome} {partecipante.anagrafica.cognome}
                                    </div>
                                    <div>
                                        <Trash
                                            onClick={() => eliminaPartecipante(partecipante.id)}
                                            className={"h-5 hover:text-my-red cursor-pointer"}
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className={"flex flex-col items-center justify-center"}>
                                <p className={"text-xl font-semibold"}>Nessun partecipante aggiuntivo</p>
                            </div>
                        )

                    }
                </div>
            </div>
            <div className={"flex flex-col w-full items-center justify-center mb-10"}>
                <HeaderSection title={"Allegati Evento"}/>
                <div className={"w-4/5 sm:w-3/5 bg-my-white my-3 p-3"}>
                    {
                        allegati.length > 0 ? (
                            allegati.map((allegato, index) => (
                                <div key={index} className={"flex justify-between items-center"}>
                                    <a href={allegato.file} target={"_blank"}
                                       className={"text-xl font-semibold cursor-pointer hover:underline"}>
                                        {allegato.nome_file}
                                    </a>
                                    <div>
                                        <Trash
                                            onClick={() => eliminaAllegato(allegato.id)}
                                            className={"h-5 hover:text-my-red cursor-pointer"}
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className={"flex flex-col items-center justify-center"}>
                                <p className={"text-xl font-semibold"}>Nessun allegato</p>
                            </div>
                        )

                    }
                </div>
            </div>
        </MainLayout>
    );
};

export default InfoEvento;
