import axios from "axios";
import {proxyUrl} from "../constants/proxyUrl";

export const getCareers = async () => {
    const options = {
        url: `${proxyUrl}/careers/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    };

    return axios(options)
}

export const getCareersById = async (id) => {
    const options = {
        url: `${proxyUrl}/careers/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    };

    return axios(options)
}

export const addCareers = async (data) => {
    const options = {
        url: `${proxyUrl}/careers/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        data: data
    };

    return axios(options)
}

export const editCareer = async (id, data) => {
    const options = {
        url: `${proxyUrl}/careers/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        data: data
    };

    return axios(options)
}

export const deleteCareers = async (id) => {
    const options = {
        url: `${proxyUrl}/careers/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    return axios(options)
}

//CANDIDATURE
export const getCandidature = async () => {
    const options = {
        url: `${proxyUrl}/careers/candidature/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    return axios(options)
}

export const addCandidatura = async (data) => {
    const options = {
        url: `${proxyUrl}/careers/candidature/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        },
        data: data
    };

    return axios(options)
}

export const editCandidatura = async (id, data) => {
    const options = {
        url: `${proxyUrl}/careers/candidature/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        data: data
    };

    return axios(options)
}
