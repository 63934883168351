import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import Navbar from "../../../../components/Navbar";
import BackButton from "../../../../components/BackButton";
import HeaderSection from "../../../../components/HeaderSection";
import Footer from "../../../../components/Footer";
import {TrashIcon} from "@heroicons/react/solid";
import {
    getInfoEventoFormazione,
    listaAllegatiEventoFormazione,
    removeAllegatoEventoFormazione
} from "../../../../fetch/fetchFormazione";
import AllegatoEventoFormazioneModal from "./AllegatoEventoFormazioneModal";

const InfoEventoFormazione = () => {

    const {id} = useParams();

    const [eventoFormazione, setEventoFormazione] = React.useState({});
    const [allegatiRecurring, setAllegatiRecurring] = React.useState([]);

    const getAllegati = () => {
        listaAllegatiEventoFormazione(id).then((response) => {
            setAllegatiRecurring(response.data);
        }).catch(
            (error) => {
                console.log(error);
            }
        )
    }
    const rimuoviAllegato = (id) => {
        removeAllegatoEventoFormazione(id).then(() => {
            getAllegati();
        }).catch((error) => {
            console.log(error);
        })
    }

    const [open, setOpen] = useState(false)

    React.useEffect(() => {
        getInfoEventoFormazione(id).then((response) => {
            setEventoFormazione(response.data);
        }).catch((error) => {
            console.log(error);
        })

        getAllegati();

    }, [open]);

    return (
        <div className={"flex flex-col min-h-screen"}>
            <AllegatoEventoFormazioneModal setOpen={setOpen} open={open} id={id}/>

            <Navbar/>
            <div className={"flex-grow mt-10 px-4"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Info Evento Formazione"}/>
                <div className={"bg-gray-100 container my-4 rounded"}>
                    <div className={"flex flex-col p-4"}>
                        <div className={"flex flex-col"}>
                            <div className={"flex flex-row"}>
                                <div className={"font-bold text-gray-600 "}>Nome:</div>
                                <div className={"ml-2 "}>{eventoFormazione.nome}</div>
                            </div>
                            <div className={"flex flex-row"}>
                                <div className={"font-bold text-gray-600 "}>Descrizione:</div>
                                <div
                                    className={"ml-2 "}>{eventoFormazione?.descrizione ? eventoFormazione.descrizione : "Nessuna descrizione"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"flex container items-center justify-evenly"}>
                    <div className={"text-3xl text-center text-black uppercase font-bold"}>
                        Allegati Evento Formazione
                    </div>
                    <div
                        onClick={() => setOpen(true)}
                        className={"flex bg-my-green px-4 py-2 rounded text-white font-semibold hover:bg-green-600 cursor-pointer"}>
                        Aggiungi
                    </div>
                </div>

                {
                    allegatiRecurring.length === 0 ? (
                        <div className={"flex justify-center items-center"}>
                            <div className={"text-gray-500 text-xl font-bold"}>Non ci sono allegati</div>
                        </div>
                    ) : (
                        <div className={"flex flex-col"}>
                            {
                                allegatiRecurring.map((allegato, index) => (
                                    <div className={"bg-gray-100 container my-4 rounded"} key={index}>
                                        <div className={"flex flex-col p-4"}>
                                            <div className={"flex flex-row justify-between items-center"}>
                                                <div className={"flex"}>
                                                    <div className={"font-bold text-gray-600"}>Allegato {index + 1}:
                                                    </div>
                                                    <a href={allegato.allegato} target={"_blank"}
                                                       className={"ml-2 "}>Visualizza</a>
                                                </div>
                                                <TrashIcon className={"h-6 text-red-500 cursor-pointer"}
                                                           onClick={() => rimuoviAllegato(allegato.id)}/>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </div>
            <Footer/>
        </div>
    );
};

export default InfoEventoFormazione;
