import React from 'react';

const HeaderSection = ({title}) => {
    return (
        <div className="sm:w-3/4 lg:w-5/6 mx-auto px-2 flex flex-col items-center">
            <h1 className={"text-3xl text-center text-black uppercase font-bold "}>{title}</h1>
            <div className={"w-20 h-1 bg-primary-blue mt-2"}/>
        </div>
    );
};

export default HeaderSection;
