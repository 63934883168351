import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

// TIPI EVENTO
export const getTipoEventi = async () => {
    const options = {
        url: `${proxyUrl}/eventi/tipi_eventi/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

// EVENTI
export const getEventi = async () => {
    const options = {
        url: `${proxyUrl}/eventi/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

// EVENTI UTENTE
export const getEventiUtente = async (path) => {

    const url = path !== "" ? `${proxyUrl}/eventi/personali/${path}/` : `${proxyUrl}/eventi/personali/`;

    const options = {
        url: url,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const getEventiByName = async (filtro) => {
    const options = {
        url: `${proxyUrl}/eventi/nome/${filtro}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const getEventiGiorno = async (giorno) => {
    const options = {
        url: `${proxyUrl}/eventi/${giorno}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getFullEventiGiorno = async (giorno) => {
    const options = {
        url: `${proxyUrl}/eventi/full/${giorno}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getEvento = async (id) => {
    const options = {
        url: `${proxyUrl}/eventi/info/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const creaEvento = async (formData) => {
    const options = {
        url: `${proxyUrl}/eventi/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
export const editEvento = async (id, formData) => {
    const options = {
        url: `${proxyUrl}/eventi/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
export const eliminaEvento = async (id) => {
    const options = {
        url: `${proxyUrl}/eventi/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


// PARTECIPANTI EVENTO
export const aggiungiAnagraficaEvento = async (formData) => {
    const options = {
        url: `${proxyUrl}/eventi/partecipanti/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
export const getPartecipantiEvento = async (idEvento) => {
    const options = {
        url: `${proxyUrl}/eventi/partecipanti/${idEvento}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const eliminaPartecipanteEvento = async (idPartecipante) => {
    const options = {
        url: `${proxyUrl}/eventi/partecipanti/modifica/${idPartecipante}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

// ALLEGATI EVENTO
export const listaAllegatiEvento = async (id) => {
    const options = {
        url: `${proxyUrl}/eventi/${id}/allegati`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const aggiungiAllegatoEvento = async (id, formData) => {
    const options = {
        url: `${proxyUrl}/eventi/${id}/allegati/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
export const eliminaAllegatoEvento = async (id, idAllegato) => {
    const options = {
        url: `${proxyUrl}/eventi/${id}/allegati/${idAllegato}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
