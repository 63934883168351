import React, {useState} from 'react';
import TableCell from "./TableCell";
import EliminaItemModal from "./EliminaItemModal";

const Table = ({nomiColonne, items, campi, }) => {

    const [filter, setFilter] = useState("")

    const [showModal, setShowModal] = useState(true);


    return (
        <div className={"my-10"}>

            <EliminaItemModal
                open={showModal}
                setOpen={setShowModal}
                title={"Elimina Bolla di Trasporto"}
            />

            <div className={"flex w-full justify-center items-center"}>
                <div className="flex flex-col w-11/12">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <div>
                                    <input
                                        type="text"
                                        placeholder={"Cerca..."}
                                        className={"w-full py-2 px-4 outline-none border-none"}
                                        onChange={event => setFilter(event.target.value)}
                                    />
                                </div>
                                <div className={"max-h-96 overflow-auto relative"}>
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50 sticky top-0 z-10">
                                        <tr>
                                            {nomiColonne.map(header => (
                                                <th
                                                    key={header}
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    {header}
                                                </th>
                                            ))}
                                        </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            items.map((item, i) => (
                                                <tr key={i}>
                                                    {
                                                        campi.map((campo, index) => (
                                                            campo === "elimina" ? (
                                                                <td className="px-6 py-4 text-sm text-gray-500"
                                                                    onClick={() => {
                                                                        setShowModal(true);
                                                                        // setEliminazione(bolla.id);
                                                                    }}
                                                                >
                                                                    <p className="text-my-red font-bold hover:text-red-700 cursor-pointer">Elimina</p>
                                                                </td>
                                                            ) : campo === "info" ? (
                                                                <td className="px-6 py-4 text-sm text-gray-500"
                                                                >
                                                                    <p className="text-green-600 font-bold hover:text-green-700 cursor-pointer">Info</p>
                                                                </td>
                                                            ) : campo === "modifica" ? (
                                                                <td className="px-6 py-4 text-sm text-gray-500"
                                                                >
                                                                    <p className="text-yellow-600 font-bold hover:text-yellow-700 cursor-pointer">Modifica</p>
                                                                </td>
                                                            ) : (
                                                                <TableCell key={index} content={item[campo]}/>
                                                            )
                                                        ))
                                                    }
                                                </tr>

                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Table;
