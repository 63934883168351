import React, {useEffect} from 'react';
import ProjectGallery from "../../../../components/ProjectGallery";
import MainLayout from "../../../../Layouts/MainLayout";

const ProjectsPage = () => {

    useEffect(() => {
            window.scrollTo(0, 0);
        }, []
    )

    return (
        <MainLayout>
            <ProjectGallery/>
        </MainLayout>
    );
};

export default ProjectsPage;
