import React, {useEffect, useState} from 'react';
import Navbar from "../../../../../components/Navbar";
import Footer from "../../../../../components/Footer";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import BackButton from "../../../../../components/BackButton";
import {getCategorieRecurring, removeCategoriaRecurring} from "../../../../../fetch/fetchRecurring";
import {Link} from "react-router-dom";

const CategorieRecurring = () => {

    const [filter, setFilter] = useState("")
    const [categorieRecurring, setCategorieRecurring] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [eliminazione, setEliminazione] = useState(-1)

    const support = () => {
        getCategorieRecurring().then(r => {
            setCategorieRecurring(r.data)
        }).catch(e => {
            console.log(e)
        })
    }

    const handleRemove = (e, id) => {
        e.preventDefault()

        removeCategoriaRecurring(id).then(() => {
            support()
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        support()
    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Eliminazione
                                    </h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        Sicuro di voler eliminare?
                                    </p>
                                </div>
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-my-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Annulla
                                    </button>
                                    <button
                                        className="bg-my-red text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(e) => {
                                            handleRemove(e, eliminazione)
                                            setShowModal(false)
                                        }}
                                    >
                                        Elimina
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <Navbar/>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSectionAdd titolo={"Categorie Recurring"} link={"aggiungi"}/>
                <div className={"my-10 flex justify-center items-center"}>
                    <div className="flex flex-col w-11/12">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={"Cerca..."}
                                            className={"w-full py-2 px-4 outline-none border-none"}
                                            onChange={event => setFilter(event.target.value)}
                                        />
                                    </div>
                                    <div className={"max-h-96 overflow-auto"}>
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Nome
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Modifica
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Elimina
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                categorieRecurring.filter(recurring => (
                                                    recurring.nome.toLowerCase().includes(filter.toLowerCase())
                                                )).map((recurring, i) => (
                                                    <tr key={i}>
                                                        {/*Nome*/}
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div
                                                                className="text-sm text-gray-900">{recurring.nome}
                                                            </div>
                                                        </td>
                                                        {/*Nome*/}
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <Link
                                                                to={`/categorie-recurring/modifica/${recurring.id}`}
                                                                className="text-sm text-green-500 font-bold cursor-pointer">Modifica
                                                            </Link>
                                                        </td>
                                                        {/*Nome*/}
                                                        {/*Elimina*/}
                                                        <td
                                                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                                            onClick={() => {
                                                                setShowModal(true)
                                                                setEliminazione(recurring.id)
                                                            }}
                                                        >
                                                            <p className="text-my-red font-bold hover:text-red-700 cursor-pointer">Elimina</p>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CategorieRecurring;
