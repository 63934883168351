import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {eliminaBustePaga, getBustePaga} from "../../../../../fetch/fetchBustaPaga";
import BackButton from "../../../../../components/BackButton";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import FiltroSocieta from "../../../../../components/FiltroSocieta";
import MainLayout from "../../../../../Layouts/MainLayout";
import BustaPagaModal from "./BustaPagaModal";

const BustePagaGestionale = () => {
    const [filter, setFilter] = useState("")


    const {filtroSoc} = useSelector(state => state.societa)
    const [bustePaga, setBustePaga] = useState([])

    const eliminaBusta = (e, id) => {
        e.preventDefault()
        eliminaBustePaga(id).then(() => {
            getSupporto()
        }).catch(e => {
            console.log(e)
        })
    }

    const getSupporto = () => {
        getBustePaga().then(r => {
            setBustePaga(r.data)
        }).catch(e => {
            console.log(e)
        })
    }

    const [open, setOpen] = useState(false)

    useEffect(() => {
        getSupporto()
    }, [open])

    const [currentBusta, setCurrentBusta] = useState(0)

    return (
        <MainLayout>
            <BustaPagaModal
                open={open}
                setOpen={setOpen}
                bustaPaga={currentBusta}
            />
            <div className={"flex px-20 py-2 mt-10"}>
                <BackButton/>
            </div>
            <HeaderSectionAdd titolo={"Buste Paga"} link={"/add_busta_paga"}/>
            <FiltroSocieta/>
            <div className={"my-10 flex justify-center items-center"}>
                <div className="flex flex-col w-11/12">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <div>
                                    <input
                                        type="text"
                                        placeholder={"Cerca..."}
                                        className={"w-full py-2 px-4 outline-none border-none"}
                                        onChange={event => setFilter(event.target.value)}
                                    />
                                </div>
                                <div className={"max-h-96 overflow-auto"}>
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50 sticky top-0 z-10">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Dipendente
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Società
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Data Emissione
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Busta Paga
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Pagata
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Data Pagamento
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Conto Corrente
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Elimina
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            bustePaga.filter(busta => {
                                                if (filter === "") {
                                                    return busta
                                                } else if (
                                                    busta.dipendente.anagrafica.nome.toLowerCase().includes(filter.toLowerCase()) ||
                                                    busta.dipendente.anagrafica.cognome.toLowerCase().includes(filter.toLowerCase())
                                                ) {
                                                    return busta
                                                }
                                                return busta
                                            }).filter(busta => {
                                                if (filtroSoc === "") {
                                                    return busta
                                                }
                                                if (filtroSoc === busta.dipendente.societa.nome) {
                                                    return busta
                                                }
                                                return busta
                                            }).map((busta, i) => (
                                                <tr key={i}>
                                                    {/*Dipendente*/}
                                                    <td className="px-6 py-4 ">
                                                        <div className="flex items-center">
                                                            <div
                                                                className="text-sm font-medium text-gray-900">{busta.dipendente.anagrafica.nome} {busta.dipendente.anagrafica.cognome}</div>
                                                        </div>
                                                    </td>
                                                    {/*Dipendente*/}
                                                    <td className="px-6 py-4 ">
                                                        <div className="flex items-center">
                                                            <div
                                                                className="text-sm font-medium text-gray-900">{busta.dipendente.societa.nome}</div>
                                                        </div>
                                                    </td>
                                                    {/*Data Emissione*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{busta.data_emissione}</div>
                                                    </td>
                                                    {/*Busta Paga*/}
                                                    <td className="px-6 py-4  text-sm text-gray-500">
                                                        <a
                                                            href={busta.copia_busta_paga}
                                                            download
                                                            target="_blank"
                                                            className="text-my-green font-bold hover:text-green-700 cursor-pointer"
                                                        >
                                                            Visualizza
                                                        </a>
                                                    </td>
                                                    {/*Data Emissione*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{busta.data_pagamento ?
                                                            <div>
                                                                <p className={"text-my-green font-bold"}>SI</p>
                                                            </div> :
                                                            <div
                                                                className={"bg-my-red rounded cursor-pointer text-white text-center font-semibold"}
                                                                onClick={() => {
                                                                    setCurrentBusta(busta.id)
                                                                    setOpen(true)
                                                                }}
                                                            >
                                                                Paga
                                                            </div>
                                                        }</div>
                                                    </td>
                                                    {/*Data Pagamento*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{busta.data_pagamento ? busta.data_pagamento : "Non Pagata"}</div>
                                                    </td>
                                                    {/*Conto corrente*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{busta.conto_corrente ? busta.conto_corrente : "Non Presente"}</div>
                                                    </td>

                                                    {/*Elimina*/}
                                                    <td
                                                        className="px-6 py-4  text-sm text-gray-500"
                                                        onClick={(e) => eliminaBusta(e, busta.id)}
                                                    >
                                                        <p className="text-my-red font-bold hover:text-red-700 cursor-pointer">Elimina</p>
                                                    </td>

                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default BustePagaGestionale;
