import React, {useEffect, useState} from 'react';
import Navbar from "../../../../../components/Navbar";
import Footer from "../../../../../components/Footer";
import {eliminaPartecipazione, getPartecipazione} from "../../../../../fetch/fetchPartecipazione";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import BackButton from "../../../../../components/BackButton";
import {useParams} from "react-router-dom";

const PartecipazionePage = () => {

    const {id} = useParams();

    const [partecipazioni, setPartecipazioni] = useState([])

    useEffect(() => {
        supportFunc()
    }, [])

    const supportFunc = () => {
        getPartecipazione(id).then(
            r => {
                setPartecipazioni(r.data)
            }
        ).catch(
            e => console.log(e)
        )
    }

    const handleEliminaPartecipazione = (e, id) => {
        eliminaPartecipazione(id).then(
            () => {
                supportFunc()
            }
        ).catch(
            e => console.log(e)
        )
    }

    return (
        <div className={"flex flex-col h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSectionAdd titolo={"Partecipazioni Task"} link={"/partecipanti_task/aggiungi/" + id}/>
                    <div className={"flex flex-col lg:flex-row"}>
                        <div className={"flex flex-1 my-10"}>
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50 sticky top-0 z-10">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-2xl font-semibold uppercase tracking-wider"
                                    >
                                        Dipendenti
                                    </th>
                                </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    partecipazioni.map((partecipazione, i) => (
                                        <tr key={i}>
                                            {/*Dipendente*/}
                                            <td className="px-6 py-4 flex justify-between">
                                                <div className="flex items-center">
                                                    <div className="">
                                                        <div
                                                            className="text-sm font-medium text-gray-900">{partecipazione.dipendente.anagrafica.nome} {partecipazione.dipendente.anagrafica.cognome}</div>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={(e) => handleEliminaPartecipazione(e, partecipazione.id)}
                                                    className={"text-my-red hover:text-red-600 font-semibold cursor-pointer"}
                                                >
                                                    Elimina
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default PartecipazionePage;
