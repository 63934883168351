import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {proxyUrl} from "../../../../constants/proxyUrl";
import MainLayout from "../../../../Layouts/MainLayout";
import {useMutation, useQuery} from "react-query";
import axios from "axios";
import HeaderSection from "../../../../components/HeaderSection";
import MyMdEditorPreview from "../../../../components/markdown/MyMdEditorPreview";
import {useSelector} from "react-redux";

const PostPage = () => {

    const {id} = useParams()

    const {is_superuser} = useSelector(state => state.auth)

    const navigation = useNavigate()

    const eliminaPostMutation = useMutation({
        mutationFn: async () => {
            const response = await axios.delete(`${proxyUrl}/post-blog/${id}`, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            );
            return response.data
        },
        onSuccess: () => {
            navigation('/blog')
        }
    })

    const {
        data: post,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['blog-posts', id],
        queryFn: async () => {
            const response = await axios.get(`${proxyUrl}/post-blog/${id}`);
            return response.data
        }
    })

    if (isLoading) return <div>Loading...</div>
    if (isError) return <div>Error fetching data</div>

    return (
        <MainLayout>
            <div className={"p-10"}>
                {is_superuser ? (
                    <div className={"flex justify-end"}>
                        <button
                            onClick={() => eliminaPostMutation.mutate()}
                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-red-600 text-white hover:bg-my-white hover:text-black"}
                        >
                            Elimina
                        </button>
                    </div>
                ) : null}

                <HeaderSection title={post.titolo}/>
                <MyMdEditorPreview text={post.contenuto}/>
            </div>

        </MainLayout>
    );
};

export default PostPage;
