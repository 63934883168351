import React from 'react';
import {useParams} from "react-router-dom";
import Navbar from "../../../../components/Navbar";
import Footer from "../../../../components/Footer";
import EventiEFatture from "../EventiEFatture";

const CalendarioGiornaliero = () => {
    const {giornoSelezionato} = useParams();


    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow p-10"}>
                <EventiEFatture giorno={giornoSelezionato}/>
            </div>
            <Footer/>
        </div>
    );
};

export default CalendarioGiornaliero;
