import {configureStore} from '@reduxjs/toolkit'
import authReducer from './features/authSlice'
import filterSocietaReducer from './features/filterSocietaSlice'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        societa: filterSocietaReducer,
    },
})
