import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getAnagrafiche} from "../../../fetch/fetchAnagrafica";
import DangerAlert from "../../../components/DangerAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import BackButton from "../../../components/BackButton";
import HeaderSection from "../../../components/HeaderSection";
import {MyCombobox} from "../../../components/combobox/MyCombobox";
import {aggiungiAnagraficaEvento} from "../../../fetch/fetchEventi";
import {sleep} from "../../../constants/sleep";
import MainLayout from "../../../Layouts/MainLayout";


const AggiungiPartecipanteEvento = () => {

    const {id} = useParams();

    const [alert, setAlert] = useState(0)
    const [anagrafica, setPartecipante] = useState([])
    const [selectedPartecipante, setSelectedPartecipante] = useState()

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('anagrafica', selectedPartecipante.id);
        postData.append('evento', id);

        aggiungiAnagraficaEvento(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    }

    useEffect(() => {
        getAnagrafiche().then(r => {
            setPartecipante(r.data)
            setSelectedPartecipante(r.data[0])
        }).catch(e => {
            console.log(e)
        })
    }, [])

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"}
                                      message={"Partecipante Evento è stata aggiunta con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Partecipante Evento"}/>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Partecipante*/}
                            <div className="col-span-2 lg:col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Partecipante
                                    </div>
                                    <MyCombobox
                                        valueList={anagrafica}
                                        selectedItem={selectedPartecipante}
                                        setSelectedItem={setSelectedPartecipante}
                                    />
                                </div>
                            </div>

                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Aggiungi
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default AggiungiPartecipanteEvento;
