import React, {useEffect, useState} from 'react';
import HeaderSection from "../../components/HeaderSection";
import {eliminaBustePaga, getBustePagaPersonali} from "../../fetch/fetchBustaPaga";
import MainLayout from "../../Layouts/MainLayout";

const BustePagaProfilo = () => {

    const [bustePaga, setBustePaga] = useState([])

    const eliminaBusta = (e, id) => {
        e.preventDefault()
        eliminaBustePaga(id).then(() => {
            getSupporto()
        }).catch(e => {
            console.log(e)
        })
    }

    const getSupporto = () => {
        getBustePagaPersonali().then(r => {
            setBustePaga(r.data)
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        getSupporto()
    }, [])

    return (
        <MainLayout margin={"mt-16"}>
            <div className={"flex flex-col space-y-4"}>
                <div className={"flex flex-col justify-center items-center"}>
                    <HeaderSection title={"Le Tue Buste Paga"}/>
                    <div className="flex flex-col w-11/12 sm:w-4/5 mt-4">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Dipendente
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Società
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Data Emissione
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Busta Paga
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Elimina
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            bustePaga.map((busta, i) => (
                                                <tr key={i}>
                                                    {/*Dipendente*/}
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <div
                                                                className="text-sm font-medium text-gray-900">{busta.dipendente.anagrafica.nome} {busta.dipendente.anagrafica.cognome}</div>
                                                        </div>
                                                    </td>
                                                    {/*Dipendente*/}
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <div
                                                                className="text-sm font-medium text-gray-900">{busta.dipendente.societa.nome}</div>
                                                        </div>
                                                    </td>
                                                    {/*Data Emissione*/}
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div
                                                            className="text-sm text-gray-900">{busta.data_emissione}</div>
                                                    </td>
                                                    {/*Busta Paga*/}
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <a
                                                            href={busta.copia_busta_paga}
                                                            download
                                                            target="_blank"
                                                            className="text-my-green font-bold hover:text-green-700 cursor-pointer"
                                                        >
                                                            Visualizza
                                                        </a>
                                                    </td>
                                                    {/*Elimina*/}
                                                    <td
                                                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                                        onClick={(e) => eliminaBusta(e, busta.id)}
                                                    >
                                                        <p className="text-my-red font-bold hover:text-red-700 cursor-pointer">Elimina</p>
                                                    </td>

                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default BustePagaProfilo;
