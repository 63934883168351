import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {eliminaPostLavoro, infoProgetto} from "../../../../fetch/lavori_post";
import MainLayout from "../../../../Layouts/MainLayout";


const SingleProjectPage = () => {

    const navigate = useNavigate()

    const {token} = useSelector(state => state.auth)
    const {id} = useParams()

    const [post, setPost] = useState({
        lavoro: {},
        descrizione: "",
        array_tipi: [],
        array_info: [],
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        infoProgetto(id).then(r => {
            setPost(r.data)
        }).catch(e => {
            console.log(e)
        })
    }, [])

    const deleteProject = async () => {
        eliminaPostLavoro(id).then(() => {
            navigate("/projects")
        }).catch(e => {
            console.log(e)
        })
    }

    return (
        <MainLayout>
            <div className={"flex justify-between items-center "}>
                <div>
                    <h1 className={"text-5xl text-start text-primary-blue font-bold"}>{post.lavoro.nome}</h1>
                    <h1 className={"text-lg text-start text-secondary-blue font-bold mb-4"}>{post.lavoro.luogo}</h1>
                </div>

                {
                    token && (
                        <div className={"flex space-x-2"}>
                            <button
                                onClick={deleteProject}
                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-my-red text-white hover:bg-my-white hover:text-black"}
                            >
                                Elimina
                            </button>
                        </div>
                    )
                }


            </div>
            <img src={post.foto} alt=""
                 className={"w-full h-full object-center object-cover group-hover:opacity-75 rounded-sm"}/>
            <section>
                <div className="w-full">
                    <div className={"xl:flex xl:justify-between py-2"}>
                        <p className="text-my-gray font-bold">
                                <span className={"text-black"}>
                                   Inizio Lavori:
                                </span> {post.lavoro.inizio}
                        </p>
                        <p className="text-my-gray font-bold">
                                <span className={"text-black"}>
                                   Fine Lavori:
                                </span> {post.lavoro.fine ? post.lavoro.fine : "In Corso"}
                        </p>
                        <p className="text-my-gray font-bold">
                            {post.lavoro.tag}
                        </p>
                    </div>
                    <p className="text-my-gray">
                        {post.descrizione}
                    </p>
                </div>
                <div>
                    {
                        post.array_info.map((info, i) => {
                            if (post.array_tipi[i] === "Testo") {
                                return (
                                    <div key={i} className={"py-2"}>
                                        <h1 className={""}>{info}</h1>
                                    </div>
                                )
                            } else if (post.array_tipi[i] === "Sottotitolo") {
                                return (
                                    <div key={i} className={"py-2"}>
                                        <h1 className={"font-bold text-xl"}>{info}</h1>
                                    </div>
                                )
                            } else if (post.array_tipi[i] === "Quotes") {
                                return (
                                    <div key={i} className={"py-2"}>
                                        <h1 className={"italic"}>"{info}"</h1>
                                    </div>
                                )
                            } else if (post.array_tipi[i] === info) {
                                return (
                                    <div key={i} className={"py-2"}>
                                        <hr/>
                                    </div>

                                )
                            }
                        })
                    }
                </div>
            </section>
        </MainLayout>
    );
};

export default SingleProjectPage;
