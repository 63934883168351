import React, {useEffect, useState} from 'react';
import {getSocieta} from "../../fetch/fetchSocieta";

const SocietaInput = ({value, setSelectedValue}) => {

    const [societa, setSocieta] = useState([])

    useEffect(() => {
        getSocieta().then(
            r => {
                setSocieta(r.data)
                setSelectedValue({...value, societa: r.data[0].id})
            }
        ).catch(
            e => console.log(e)
        )
    }, []);


    return (
        <div className="col-span-2">
            <div className={"flex flex-col lg:flex-col"}>
                <div className={"text-my-gray"}>
                    Società
                </div>
                <select
                    defaultValue={societa[0]}
                    onChange={(e) => setSelectedValue({...value, societa: parseInt((e.target.value))})}
                    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                    required
                >
                    {
                        societa.map((soc, index) => (
                            <option
                                key={index}
                                value={soc.id}
                            >
                                {soc.nome}
                            </option>
                        ))
                    }
                </select>
            </div>
        </div>
    );
};

export default SocietaInput;
