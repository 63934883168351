import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    username: localStorage.getItem("username") ? localStorage.getItem("username") : "",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
    is_superuser: localStorage.getItem("is_superuser") ? localStorage.getItem("is_superuser") : false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.username = action.payload.username
            state.token = action.payload.token
            state.is_superuser = action.payload.is_superuser
            localStorage.setItem('token', action.payload.token)
            localStorage.setItem('username', action.payload.username)
            localStorage.setItem('is_superuser', action.payload.is_superuser)
        },
        logout: (state) => {
            state.username = ""
            state.token = ""
            state.is_superuser = false
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            localStorage.removeItem('is_superuser')
        }
    },
})

// Action creators are generated for each case reducer function
export const {login, logout} = authSlice.actions

export default authSlice.reducer
