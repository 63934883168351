import React, {useState} from 'react';
import {MdEditor} from "md-editor-rt";
import 'md-editor-rt/lib/style.css';


const MyMdEditor = ({value, setValue}) => {

    const [id] = useState('preview-only');

    return (
        <MdEditor
            editorId={id}
            modelValue={value}
            language={"en-US"}
            onChange={(data) => setValue(data)}
            showCodeRowNumber={true}
            previewTheme={"default"}
            style={{
                // background: "transparent",
                // color: "white"
            }}
            sanitize={
                (html) => {
                    return html;
                }
            }
            toolbarsExclude={
                [
                    'link',
                    'github',
                    'htmlPreview',
                    'pageFullscreen',
                    'fullscreen',
                    'catalog',
                    'save',
                    'mermaid',
                    'task',
                ]
            }
        />
    );
};

export default MyMdEditor;