import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

// SEDI

export const getSediMagazzino = async () => {
    const options = {
        url: `${proxyUrl}/magazzino/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


// InventarioMagazzino
export const getInventario = async (id) => {
    const options = {
        url: `${proxyUrl}/magazzino/inventario/?magazzino=${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getInventari = async () => {
    const options = {
        url: `${proxyUrl}/magazzino/inventario/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getInfoMaterialeInventario = async (mag, mat) => {
    const options = {
        url: `${proxyUrl}/magazzino/inventario/?magazzino=${mag}&materiale=${mat}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

// Bolle di trasporto
export const getBolleTrasporto = async () => {
    const options = {
        url: `${proxyUrl}/magazzino/bolle-trasporto/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const eliminaBollaTrasporto = async (id) => {
    const options = {
        url: `${proxyUrl}/magazzino/bolle-trasporto/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const aggiungiBollaTrasporto = async (data) => {
    const options = {
        url: `${proxyUrl}/magazzino/bolle-trasporto/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: data
    };

    return axios(options)
}




// Carichi
export const getCarichiMagazzino = async () => {
    const options = {
        url: `${proxyUrl}/magazzino/carichi`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const eliminaCaricoMagazzino = async (id) => {
    const options = {
        url: `${proxyUrl}/magazzino/carichi/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const aggiungiCaricoMagazzino = async (data) => {
    const options = {
        url: `${proxyUrl}/magazzino/carichi/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: data
    };

    return axios(options)
}

