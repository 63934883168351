import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const getTipoTax = async () => {
    const options = {
        url: `${proxyUrl}/tax/tipi/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const addMovimentoTax = async (formData) => {
    const options = {
        url: `${proxyUrl}/tax/movimenti/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const listaMovimentiTax = async (id) => {
    const options = {
        url: `${proxyUrl}/tax/movimenti/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const eliminaMovimentoTax = async (id) => {
    const options = {
        url: `${proxyUrl}/tax/movimenti/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const addTax = async (formData) => {
    const options = {
        url: `${proxyUrl}/tax/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const getTax = async () => {
    const options = {
        url: `${proxyUrl}/tax/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const removeTax = async (id) => {
    const options = {
        url: `${proxyUrl}/tax/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getInfoTax = async (id) => {
    const options = {
        url: `${proxyUrl}/tax/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const addAllegatoTax = async (formData) => {
    const options = {
        url: `${proxyUrl}/tax/allegati/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const getListaAllegatiTax = async (id) => {
    const options = {
        url: `${proxyUrl}/tax/allegati/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const eliminaAllegatoTax = async (allegatoID) => {
    const options = {
        url: `${proxyUrl}/tax/allegati/elimina/${allegatoID}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
