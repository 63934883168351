import React, {useEffect, useState} from 'react';
import {sleep} from "../../../../../constants/sleep";
import Navbar from "../../../../../components/Navbar";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import Footer from "../../../../../components/Footer";
import {useParams} from "react-router-dom";
import {addPartecipazioneTask, addTask, getNomiTasks} from "../../../../../fetch/taskFetch";
import {getDipendenti} from "../../../../../fetch/fetchDipendenti";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import {ComboboxTask} from "../../../../../components/combobox/ComboboxTask";

const AggiungiTask = () => {

    const {id} = useParams()

    const comp = ["Completato", "Incompleto", "Parziale"]
    const [alert, setAlert] = useState(0)
    const [nomiTasks, setNomiTasks] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('lavoro', id);
        postData.append('descrizione', selectedTask.id);
        postData.append('data', e.target.data.value);
        postData.append('completamento', e.target.completamento.value);
        postData.append('costo', e.target.costo.value);


        addTask(postData).then(
            r => {
                if (selezionati.length > 0) {
                    selezionati.forEach((dipendente) => {
                        addPartecipazioneTask(
                            {task: r.data.task_id, dipendente: dipendente.id}
                        ).then((r) => console.log(r)).catch((e) => console.log(e))
                    })
                    setAlert(1)
                } else {
                    setAlert(1)
                    e.target.reset()
                }
            }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    const [selezionati, setSelezionati] = useState([])
    const [dipendenti, setDipendenti] = useState([])


    const addDipendente = (e) => {
        e.preventDefault()

        const dipendente = dipendenti.find(dipendente => dipendente.id === parseInt(e.target.dipendente.value))
        setSelezionati(old => [...old, dipendente])
    }

    const rimuoviDipendente = (e, id) => {
        e.preventDefault()
        setSelezionati(selezionati.filter(dip => dip.id !== id))
    }

    useEffect(() => {
        getNomiTasks().then((r) => {
            setNomiTasks(r.data)
        }).catch(
            (e) => {
                console.log(e)
            }
        )
        getDipendenti().then(
            r => setDipendenti(r.data)
        ).catch(
            e => console.log(e)
        )
    }, []);

    const [selectedTask, setSelectedTask] = useState("")


    return (
        <div className={"flex flex-col h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"La Task è stato aggiunta con successo"}/>
                        ) : (
                            <div/>
                        )
                    }
                    <HeaderSectionAdd titolo={"Aggiungi Nuova Task"} link={"/aggiungi_nome_task"}
                                      label={"Task Predefinite"}/>
                    <div className={"flex flex-col lg:flex-row justify-evenly"}>
                        <div className={"flex flex-col lg:flex-row flex-1"}>
                            <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                                <div
                                    className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                    <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                        {/*Descrizione*/}
                                        <div className="col-span-2">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Task
                                                </div>
                                                {/*    Select input with nomi tasks*/}

                                                <ComboboxTask
                                                    setSelectedItem={setSelectedTask}
                                                    selectedItem={selectedTask}
                                                    valueList={nomiTasks}
                                                />

                                            </div>
                                        </div>
                                        {/*Descrizione*/}
                                        <div className="col-span-2">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Costo
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        name={"costo"}
                                                        type={"number"}
                                                        step={"0.01"}
                                                        required
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                        placeholder="Costo task"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Data*/}
                                        <div className="col-span-2">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Data
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="date"
                                                        name={"data"}
                                                        required
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Completamento*/}
                                        <div className="col-span-2 lg:col-span-2">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Completamento
                                                </div>
                                                <select
                                                    name={"completamento"}
                                                    defaultValue={comp[0]}
                                                    required
                                                    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                                >
                                                    {
                                                        comp.map((c, index) => (
                                                            <option
                                                                key={index}
                                                                value={c}
                                                            >
                                                                {c}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/*Bottone*/}
                                        <div className="col-span-2 text-center">
                                            <button
                                                type="submit"
                                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                            >
                                                Aggiungi
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className={"flex-1"}>
                            <div className={"flex flex-col lg:flex-row"}>
                                <div className="flex container space-x-3 pb-10">
                                    <div
                                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                        <form className="grid max-w-xl grid-cols-1 gap-4 m-auto"
                                              onSubmit={addDipendente}>
                                            {/*Allegato*/}
                                            <div className="col-span-2 lg:col-span-2 ">
                                                <div className={"flex flex-col lg:flex-col"}>
                                                    <div className={"text-my-gray"}>
                                                        Dipendente
                                                    </div>
                                                    <select
                                                        name={"dipendente"}
                                                        defaultValue={dipendenti[0]}
                                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                                    >
                                                        {
                                                            dipendenti.map((dip, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={dip.id}>
                                                                    {dip.anagrafica.nome} {dip.anagrafica.cognome}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            {/*Bottone*/}
                                            <div className="col-span-2 text-center">
                                                <button
                                                    type={"submit"}
                                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                                >
                                                    Aggiungi Dipendente
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <p className={"font-semibold italic"}>Lista Dipendenti:</p>

                                {
                                    selezionati.length === 0 ? (
                                        <p>Non ci sono materiali</p>
                                    ) : (

                                        selezionati.map((dip, i) => (
                                            <div key={i} className={"flex justify-between"}>
                                                <p>
                                                    {dip.anagrafica.nome} {dip.anagrafica.cognome}
                                                </p>
                                                <p
                                                    onClick={e => rimuoviDipendente(e, dip.id)}
                                                    className={"font-semibold text-my-red hover:text-red-600 cursor-pointer"}>
                                                    X
                                                </p>
                                            </div>
                                        ))
                                    )
                                }

                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <Footer/>
        </div>

    );
};

export default AggiungiTask;
