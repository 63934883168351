import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const checkToken = async (token) => {
    const options = {
        url: `${proxyUrl}/auth/verify-token/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        },
        data: {
            token: token
        }
    };

    return axios(options)
}
