import React, {useEffect, useState} from 'react';
import HeaderSection from "../../components/HeaderSection";
import FormCard from "../../components/FormCard";
import {getTaskNonApprovate} from "../../fetch/taskFetch";
import {Link} from "react-router-dom";
import EventiEFatture from "./Scadenzario/EventiEFatture";
import MainLayout from "../../Layouts/MainLayout";
import {BarChart2, CalendarDays, Check, Cog, PlusIcon} from "lucide-react";


const HomeGestionale = () => {

    const [task, setTask] = useState([])

    useEffect(() => {
        getTaskNonApprovate().then(
            r => setTask(r.data)
        ).catch(e => {
            console.log(e)
        })
    }, [])


    const date = new Date();

    const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    return (
        <MainLayout>
            <section className={"bg-white p-20"}>
                <HeaderSection title={"Gestione Dati"}/>
                <div
                    className="container grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 max-w-screen-lg mt-16 gap-4 ">
                    <FormCard icon={<PlusIcon size={48}/>} title={"CMS"}
                              link={"/sezioni_cms"}/>
                    <FormCard icon={<Cog size={48}/>} title={"Gestisci"}
                              link={"/edit_data_page"}/>
                    <FormCard icon={<CalendarDays size={48}/>} title={"Calendario"}
                              link={"/scadenzario"}/>
                    <FormCard icon={<BarChart2 size={48}/>} title={"Log"} link={"/log"}/>

                    {
                        task.length !== 0 && (
                            <Link to={"/task_da_approvare"}>
                                <div className={"flex flex-col bg-gray-100 rounded-sm border-4 border-yellow-600"}>
                                    <div className="p-6 flex flex-col items-center">
                                        <div className={"items-center"}>
                                            <Check className={"text-6xl text-yellow-500"}/>
                                            {/*<FontAwesomeIcon icon={solid("list-check")}*/}
                                            {/*                 className={"text-6xl text-yellow-500"}/>*/}
                                        </div>
                                        <h3 className={"mt-5 text-yellow-500 text-lg text-center"}>Task Da Approvare</h3>
                                    </div>
                                </div>
                            </Link>
                        )
                    }
                </div>
            </section>
            <div className={"flex justify-center items-center"}>
                <EventiEFatture giorno={today}/>
            </div>
        </MainLayout>
    );
};

export default HomeGestionale;
