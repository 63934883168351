import React, {useEffect} from 'react';
import FeatureRight from "../../components/FeatureRight";
import Manifest from "../../components/Manifest";
import MainLayout from "../../Layouts/MainLayout";

const AboutPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <MainLayout>
            <div className={"flex justify-center"}>
                <div
                    className="visible bg-clip-text text-transparent lg:text-huge text-7xl font-bold bg-colori py-4">
                    Forward.
                </div>
            </div>
            <FeatureRight/>

            <Manifest/>
        </MainLayout>
    );
};

export default AboutPage;
