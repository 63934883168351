import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const getDipendenti = () => {
    const options = {
        url: `${proxyUrl}/dipendenti`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    };

    return axios(options)
}

export const creaDipendente = async (formData) => {
    const options = {
        url: `${proxyUrl}/dipendenti/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const modificaDipendente = async (id, formData) => {
    const options = {
        url: `${proxyUrl}/dipendenti/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const getDipendente = async (id) => {
    const options = {
        url: `${proxyUrl}/dipendenti/modifica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const eliminaDipendente = async (username) => {
    const options = {
        url: `${proxyUrl}/auth/elimina/${username}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
