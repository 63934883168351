import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const creaPartecipazione = async (formData) => {
    const options = {
        url: `${proxyUrl}/lavori/partecipazioni/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const getPartecipazione = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/partecipazioni/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const eliminaPartecipazione = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/partecipazioni/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
