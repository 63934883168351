import React, {useState} from 'react';
import MainLayout from "../../../../Layouts/MainLayout";
import {useParams} from "react-router-dom";
import {sleep} from "../../../../constants/sleep";
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import BackButton from "../../../../components/BackButton";
import HeaderSection from "../../../../components/HeaderSection";
import {addMovimentoTax, getInfoTax} from "../../../../fetch/fetchTax";
import {useQuery} from "react-query";

const MovimentiTax = () => {

    const {id} = useParams()

    const {data: isF24} = useQuery({
        queryKey: ["isF24", id],
        queryFn: () => getInfoTax(id).then(r => r.data.tipo.nome === "F24")
    })

    const [alert, setAlert] = useState(0)

    const [formData, setFormData] = useState({
        fattura: id,
        descrizione: "",
        importo: "",
        data: "",
        metodo_pagamento: "",
        ente: "Erario"
    })

    const enti = [
        "Erario",
        "INPS",
        "Regioni",
        "IMU",
        "INAIL",
        "Altri enti previdenziali e assicurativi"
    ]


    const [allegato, setAllegato] = useState(null)

    const {descrizione, importo, data, metodo_pagamento, ente} = formData

    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const metodi_pagamento = [
        "Bonifico",
        "Contanti",
        "Carta di Credito",
        "Assegno",
        "RIBA",
        "SSD",
        "Baratto",
        "Altro"
    ]

    const handleSubmit = (e) => {
        e.preventDefault();

        const x = new FormData()
        x.append("tax", id)
        x.append("descrizione", descrizione)
        x.append("importo", importo)
        x.append("data", data)
        x.append("metodo_pagamento", metodo_pagamento)
        x.append("ente", ente)

        if (allegato) {
            x.append("allegato", allegato, allegato.name)
        }

        addMovimentoTax(x).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"La Prima Nota è stato aggiunta con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Movimento Tax"}/>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Data Emissione*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Data Emissione
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="date"
                                            name={"data"}
                                            required={true}
                                            onChange={e => onChange(e)}
                                            value={data}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Importo*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Importo
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="number"
                                            required={true}
                                            step={"0.01"}
                                            name={"importo"}
                                            onChange={e => onChange(e)}
                                            value={importo}
                                            placeholder={"Importo"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Descrizione*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Descrizione
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"descrizione"}
                                            required={true}
                                            onChange={e => onChange(e)}
                                            value={descrizione}
                                            placeholder={"Descrizione"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Allegato*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Allegato
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="file"
                                            name={"allegato"}
                                            required={false}
                                            onChange={e => setAllegato(e.target.files[0])}
                                            placeholder={"Allegato"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Ente*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Metodo Pagamento
                                    </div>
                                    <select
                                        name={"metodo_pagamento"}
                                        defaultValue={metodi_pagamento[0]}
                                        onChange={e => onChange(e)}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            metodi_pagamento.map((metodo, index) => (
                                                <option
                                                    key={index}
                                                    value={metodo}
                                                >
                                                    {metodo}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {
                                isF24 ? (
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Ente
                                            </div>
                                            <select
                                                name={"ente"}
                                                defaultValue={enti[0]}
                                                onChange={e => onChange(e)}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            >
                                                {
                                                    enti.map((metodo, index) => (
                                                        <option
                                                            key={index}
                                                            value={metodo}
                                                        >
                                                            {metodo}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                ) : null
                            }

                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Aggiungi
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default MovimentiTax;
