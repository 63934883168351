import React, {useEffect, useState} from 'react';
import MainLayout from "../../../../Layouts/MainLayout";
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import BackButton from "../../../../components/BackButton";
import HeaderSection from "../../../../components/HeaderSection";
import {useParams} from "react-router-dom";
import axios from "axios";
import {proxyUrl} from "../../../../constants/proxyUrl";
import {sleep} from "../../../../constants/sleep";
import {getClienti} from "../../../../fetch/fetchClienti";
import {getProgetto} from "../../../../fetch/fetchProgetti";

const EditProjectPage = () => {

    const {id} = useParams()
    const token = localStorage.getItem("token")
    const tags = ["Ingegneria", "Finanza", "Elettrotecnica"]

    const [alert, setAlert] = useState(0)
    const [clienti, setClienti] = useState([])
    const [nome, setNome] = useState("")
    const [luogo, setLuogo] = useState("")
    const [inizio, setInizio] = useState(null)
    const [fine, setFine] = useState(null)
    const [cliente, setCliente] = useState(0)
    const [descrizione, setDescrizione] = useState("")
    const [immagine, setImmagine] = useState(null)
    const [fattura, setFattura] = useState(null)
    const [tag, setTag] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        e.preventDefault();
        const projectData = new FormData();

        projectData.append('nome', nome)
        projectData.append('luogo', luogo)
        projectData.append('testo', descrizione)
        projectData.append('inizio_lavori', inizio)
        projectData.append('fine_lavori', fine)
        projectData.append('fine_lavori', fine)
        projectData.append('tag', e.target.tag.value)

        if (e.target.immagine.files[0] != null) {
            projectData.append('immagine', immagine, immagine.name)
        }
        if (e.target.fattura.files[0] != null) {
            projectData.append('fattura', fattura, fattura.name)
        }
        projectData.append('cliente', cliente)

        axios.patch(proxyUrl + `/api/lavori/progetti/${id}`, projectData, {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            }
        }).then(() => {
            setAlert(1)
        }).catch(error => {
            if (error.response) {
                setAlert(-1)
            }
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    }

    useEffect(() => {
        getClienti(token).then(value => setClienti(value))
        getProgetto(id, token).then(value => {
            setNome(value.nome)
            setLuogo(value.luogo)
            setInizio(value.inizio_lavori)
            setFine(value.fine_lavori)
            setCliente(value.cliente)
            setDescrizione(value.testo)
            setTag(value.tag)
        })
    }, [])

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Inserito"} message={"Il progetto è stato Modificato con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Modifica il Progetto"}/>

                <form className="flex space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Sezione
                                    </div>
                                    <select
                                        name={"tag"}
                                        defaultValue={tag}

                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            tags.map((x, index) => (
                                                x === tag ? (
                                                    <option key={index} value={x} selected>{x}</option>
                                                ) : (
                                                    <option key={index} value={x}>{x}</option>
                                                )
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Nome*/}
                            <div className="col-span-2 lg:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Nome Progetto
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"nome"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Nome Progetto"
                                            defaultValue={nome}
                                            onChange={event => setNome(event.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*Luogo*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Luogo dei Lavori
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"luogo"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Luogo"
                                            defaultValue={luogo}
                                            onChange={event => setLuogo(event.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*Inizio*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Data Inizio Lavori
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="date"
                                            name={"inizio"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            defaultValue={inizio}
                                            onChange={event => setInizio(event.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*Fine*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Data Fine Lavori
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="date"
                                            name={"fine"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            defaultValue={fine}
                                            onChange={event => setFine(event.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*Cliente*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Cliente
                                    </div>
                                    <select
                                        name={"cliente"}
                                        value={cliente}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            clienti.map((cliente, index) => (
                                                <option key={index} value={cliente.id}>{cliente.nome}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            {/*Fattura*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Fattura
                                    </div>
                                    <label className="text-gray-700" htmlFor="name">
                                        <input
                                            type={"file"}
                                            className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-sm focus:outline-none"
                                            name="fattura"
                                            defaultValue={null}
                                            onChange={event => setFattura(event.target.files[0])}
                                        />
                                    </label>
                                </div>
                            </div>

                            {/*Testo*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Descrizione Progetto
                                    </div>
                                    <label className="text-gray-700" htmlFor="name">
                                 <textarea
                                     className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none"
                                     placeholder="Descrizione"
                                     name="testo"
                                     rows="5"
                                     cols="40"
                                     defaultValue={descrizione}
                                     onChange={event => setDescrizione(event.target.value)}
                                     required
                                 />
                                    </label>
                                </div>
                            </div>

                            {/*Immagine*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Foto Progetto
                                    </div>
                                    <label className="text-gray-700" htmlFor="name">
                                        <input
                                            type={"file"}
                                            className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-sm focus:outline-none"
                                            name="immagine"
                                            defaultValue={null}
                                            onChange={event => setImmagine(event.target.files[0])}
                                        />
                                    </label>
                                </div>
                            </div>

                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Modifica
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default EditProjectPage;
