import axios from "axios";
import {proxyUrl} from "../constants/proxyUrl";

export const getTaskLavoro = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/task/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const eliminaNomiTasks = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/nomi-task/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const getNomiTasks = async () => {
    const options = {
        url: `${proxyUrl}/lavori/nomi-task/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const getTaskNonApprovate = async () => {
    const options = {
        url: `${proxyUrl}/lavori/task_da_approvare`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const addNomeTask = async (formData) => {
    const options = {
        url: `${proxyUrl}/lavori/nomi-task/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
export const addTask = async (formData) => {
    const options = {
        url: `${proxyUrl}/lavori/task/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const eliminaTask = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/task/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const infoTask = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/task/modifica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
    };

    return axios(options)
}

export const modificaTask = async (id, c) => {
    const options = {
        url: `${proxyUrl}/lavori/task/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: c
    };

    return axios(options)
}

export const listaFotoPerTask = async (id) => {
    const options = {
        url: `${proxyUrl}/lavori/task/foto/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const aggiungiFotoTask = async (formData) => {
    const options = {
        url: `${proxyUrl}/lavori/task/foto/crea`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const addPartecipazioneTask = async (formData) => {
    const options = {
        url: `${proxyUrl}/lavori/partecipazioni/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
