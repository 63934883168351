import React, {useState} from 'react';
import MainLayout from "../../../../../Layouts/MainLayout";
import BackButton from "../../../../../components/BackButton";
import HeaderSection from "../../../../../components/HeaderSection";
import {MyCombobox} from "../../../../../components/combobox/MyCombobox";
import {useQuery} from "react-query";
import {getAnagrafiche} from "../../../../../fetch/fetchAnagrafica";
import {getSocieta} from "../../../../../fetch/fetchSocieta";
import {creaMezzoPagamento} from "../../../../../fetch/fetchMezzi";

const AggiungiMezzoPagamento = () => {

    const [istituto, setIstituto] = useState();
    const [referente, setReferente] = useState();
    const [titolare, setTitolare] = useState();
    const [selectedTipo, setSelectedTipo] = useState("Carta");

    const {data: anagrafica = []} = useQuery("anagrafica", () => getAnagrafiche().then(
        r => {
            setIstituto(r.data[0])
            setReferente(r.data[0])
            setTitolare(r.data[0])
            return r.data
        }
    ))

    const {data: societa = []} = useQuery("societa", () => getSocieta().then(
        r => r.data
    ))


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        data.append("istituto_credito", istituto.id)
        data.append("referente", referente.id)
        data.append("titolare_carta", titolare.id)

        creaMezzoPagamento(data).then(r => {
                console.log(r)
                event.target.reset()
            }
        ).catch(
            e => console.log(e)
        )

    }

    return (
        <MainLayout>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Mezzo Pagamento"}/>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">


                            {/*Iban*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Iban
                                    </div>
                                    <input
                                        type="text"
                                        name="iban"
                                        required
                                        placeholder="Iban"
                                        maxLength={27}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>

                            {/*Società*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Intestatario
                                    </div>
                                    <select
                                        name={"intestatario"}
                                        defaultValue={societa[0]}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            societa.map((soc, index) => (
                                                <option
                                                    key={index}
                                                    value={soc.id}
                                                >
                                                    {soc.nome}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Filiale*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Filiale
                                    </div>
                                    <input
                                        type="text"
                                        name="filiale"
                                        required
                                        placeholder="Filiale"
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                            {/*Data Apertura*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Data Apertura
                                    </div>
                                    <input
                                        type="date"
                                        name="data_apertura"
                                        required
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                            {/* Istituto credito */}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Istituto credito
                                    </div>
                                    <MyCombobox
                                        valueList={anagrafica}
                                        selectedItem={istituto}
                                        setSelectedItem={setIstituto}
                                    />
                                </div>
                            </div>
                            {/* Referente */}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Referente
                                    </div>
                                    <MyCombobox
                                        valueList={anagrafica}
                                        selectedItem={referente}
                                        setSelectedItem={setReferente}
                                    />
                                </div>
                            </div>
                            {/* Titolare carta */}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Titolare carta
                                    </div>
                                    <MyCombobox
                                        valueList={anagrafica}
                                        selectedItem={titolare}
                                        setSelectedItem={setTitolare}
                                    />
                                </div>
                            </div>
                            {/*Tipo*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Tipo
                                    </div>
                                    <select
                                        name="tipo"
                                        required
                                        onChange={(e) => setSelectedTipo(e.target.value)}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none">
                                        <option value="Carta">Carta</option>
                                        <option value="Conto Corrente">Conto Corrente</option>
                                    </select>
                                </div>
                            </div>
                            {
                                selectedTipo === "Carta" && (
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Numero carta
                                            </div>
                                            <input
                                                type="text"
                                                name="numero_carta"
                                                required
                                                placeholder="Numero carta"
                                                maxLength={16}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                )
                            }

                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Aggiungi
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </MainLayout>
    );
};

export default AggiungiMezzoPagamento;
