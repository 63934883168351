import SezioniLogistica from "../pages/Gestionale/Gestisci/Magazzino/SezioniLogistica";
import CarichiMagazzino from "../pages/Gestionale/Gestisci/Magazzino/Carichi/CarichiMagazzino";
import AggiungiCaricoMagazzino from "../pages/Gestionale/Gestisci/Magazzino/Carichi/AggiungiCaricoMagazzino";
import InventarioMagazzino from "../pages/Gestionale/Gestisci/Magazzino/Magazzino/InventarioMagazzino";
import LogMagazzino from "../pages/Gestionale/Log/LogMagazzino";
import BolleTrasportoPage from "../pages/Gestionale/Gestisci/Magazzino/BolleTrasporto/BolleTrasportoPage";
import AggiungiBollaTrasporto from "../pages/Gestionale/Gestisci/Magazzino/BolleTrasporto/AggiungiBollaTrasporto";
import GrigliaMagazzini from "../pages/Gestionale/Gestisci/Magazzino/Magazzino/GrigliaMagazzini";

export const magazzinoRoutes = [
    {path: "/logistica", element: <SezioniLogistica/>},
    {path: "/magazzini", element: <GrigliaMagazzini/>},
    {path: "", element: <GrigliaMagazzini/>},
    {path: "/carichi-magazzino", element: <CarichiMagazzino/>},
    {path: "/carichi-magazzino/aggiungi", element: <AggiungiCaricoMagazzino/>},


    {path: "/bolle-trasporto", element: <BolleTrasportoPage/>},
    {path: "/bolle-trasporto/aggiungi", element: <AggiungiBollaTrasporto/>},



    {path: "/magazzini/:id", element: <InventarioMagazzino/>},
    {path: "/log/magazzino", element: <LogMagazzino/>},
]
