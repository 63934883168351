import React from 'react';

const ManifestCard = ({title, description, mt}) => {
    return (
        <div className={"flex flex-col"}>
            <div className={`p-6 flex flex-col items-center ${mt}`}>
                <h3 className={"mt-5 mb-2 text-primary-blue text-3xl"}>{title}</h3>
                <p className={"mb-2 text-my-gray font-light"}>{description}</p>
            </div>
        </div>
    );
};

export default ManifestCard;
