import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getAllegatiNotaSpese} from "../../../../../../fetch/fetchNotaSpese";
import HeaderSectionAdd from "../../../../../../components/HeaderSectionAdd";
import BackButton from "../../../../../../components/BackButton";
import MainLayout from "../../../../../../Layouts/MainLayout";

const NotaSpeseInfo = () => {

    const {id} = useParams()

    const [allegati, setAllegati] = useState([])


    useEffect(() => {
        getAllegatiNotaSpese(id).then(
            r => {
                setAllegati(r.data)
            }
        ).catch(
            e => console.log(e)
        )
    }, [])


    return (
        <MainLayout>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSectionAdd titolo={"Allegati Spesa"} link={`/aggiungi_allegati_nota_spese/${id}`}/>
                {
                    allegati.length > 0 ? (
                        <div className={"flex flex-col items-center justify-center gap-8 my-8"}>
                            {
                                allegati.map((allegato, i) => (
                                    <div className={"py-4 bg-my-white w-4/5 rounded"} key={i}>
                                        <div className="flex flex-col container">
                                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div
                                                    className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                    <div className={"flex justify-between items-center"}>
                                                        <div className={"font-bold text-3xl"}>
                                                            Allegato n° {i + 1}
                                                        </div>
                                                    </div>
                                                    <div className={"my-4"}>
                                                        <p className={"font-semibold"}>Allegato: </p>
                                                        <a target={"_blank"} href={allegato.documento}
                                                           className={"italic"}>
                                                            {allegato.titolo || "Nessun titolo"}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ) : (
                        <div className={"flex flex-col items-center justify-center gap-8 my-8"}>
                            <div className={"py-4 bg-my-white w-4/5 rounded"}>
                                <div className="flex flex-col container">
                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className={"flex justify-between items-center"}>
                                                <div className={"font-bold text-3xl"}>
                                                    Nessun allegato
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            </div>
        </MainLayout>
    );
};

export default NotaSpeseInfo;
