import React, {useEffect, useState} from 'react';
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import HeaderSection from "../../../../../components/HeaderSection";
import {sleep} from "../../../../../constants/sleep";
import {getSocieta} from "../../../../../fetch/fetchSocieta";
import {creaFattura} from "../../../../../fetch/fetchFattura";
import {getAnagrafiche} from "../../../../../fetch/fetchAnagrafica";
import BackButton from "../../../../../components/BackButton";
import {MyCombobox} from "../../../../../components/combobox/MyCombobox";
import MainLayout from "../../../../../Layouts/MainLayout";

const AggiungiFattura = () => {

    const [alert, setAlert] = useState(0)

    const [societa, setSocieta] = useState([])
    const [anagrafiche, setAnagrafiche] = useState([])
    const [selectedAnagrafica, setSelectedAnagrafica] = useState()

    const tipiFattura = [
        "Attiva",
        "Passiva",
    ]

    const paesi = [
        "IT",
        "EU",
        "EXEU",
    ]

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();

        let isAutofattura = selectedType === 'Autofattura';
        let isInfragruppo = selectedType === 'Infragruppo';
        let societa2Value = isAutofattura ? e.target.societa.value : s2;

        if (selectedAnagrafica.id === 0) {
            postData.append('is_autofattura', isAutofattura);
            postData.append('is_infragruppo', isInfragruppo);
            postData.append('societa_2', societa2Value);
        } else {
            postData.append('is_autofattura', isAutofattura);
            postData.append('is_infragruppo', isInfragruppo);
            postData.append('societa_2', societa2Value);
            postData.append('anagrafica', selectedAnagrafica.id);
        }

        postData.append('numero_fattura', e.target.numero_fattura.value);
        postData.append('is_attiva', e.target.is_attiva.value);
        postData.append('paese', e.target.paese.value);
        postData.append('data_emissione', e.target.data_emissione.value);
        postData.append('data_scadenza', e.target.data_scadenza.value);
        postData.append('societa', e.target.societa.value);
        postData.append('documento', e.target.documento.files[0], e.target.documento.files[0].name);
        postData.append('is_incassata', "Non Saldata");
        postData.append('importo_netto', e.target.importo_netto.value);
        postData.append('cassa_previdenziale', e.target.cassa_previdenziale.value);
        postData.append('iva', e.target.iva.value);
        postData.append('ritenuta_acconto', e.target.ritenuta_acconto.value);


        creaFattura(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };
    const [s2, setS2] = useState(0)

    useEffect(() => {
        getSocieta().then(
            r => {
                setSocieta(r.data)
                setS2(r.data[0].id)
            }

        ).catch(
            e => console.log(e)
        )
        getAnagrafiche().then(
            r => {
                setAnagrafiche(r.data)
                setSelectedAnagrafica(r.data[0])
            }
        ).catch(
            e => console.log(e)
        )
    }, [])

    const types = [
        "Autofattura",
        "Infragruppo",
    ]


    const [selectedType, setSelectedType] = useState("")

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"La Prima Nota è stato aggiunta con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>

                <HeaderSection title={"Aggiungi Fattura"}/>

                <div
                    className={"w-full flex max-w-2xl p-2 m-auto mt-10 bg-my-white rounded-sm shadow-sm  items-center"}
                >
                    <select
                        onChange={(e) => {
                            setSelectedType(e.target.value)
                            setSelectedAnagrafica({
                                id: 0,
                                nome: "",
                                cognome: "",
                            })
                        }}
                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                    >
                        <option value={""}>Seleziona il tipo di fattura</option>
                        {
                            types.map((type, index) => (
                                <option key={index} value={type}>{type}</option>
                            ))
                        }
                    </select>
                </div>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Numero Fattura*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Numero Fattura
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            min={0}
                                            name={"numero_fattura"}
                                            placeholder={"Numero Fattura"}
                                            required={true}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Anagrafica*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Anagrafica
                                    </div>
                                    <MyCombobox
                                        valueList={anagrafiche}
                                        selectedItem={selectedAnagrafica}
                                        setSelectedItem={setSelectedAnagrafica}
                                    />
                                </div>
                            </div>
                            {/*Attiva*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Tipo Fattura
                                    </div>
                                    <select
                                        name={"is_attiva"}
                                        defaultValue={tipiFattura[0]}
                                        required={true}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            tipiFattura.map((t, index) => (
                                                <option
                                                    key={index}
                                                    value={t}
                                                >
                                                    {t}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Paese*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Paese Fattura
                                    </div>
                                    <select
                                        name={"paese"}
                                        defaultValue={paesi[0]}
                                        required={true}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            paesi.map((t, index) => (
                                                <option
                                                    key={index}
                                                    value={t}
                                                >
                                                    {t}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Data Emissione*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Data Emissione
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="date"
                                            name={"data_emissione"}
                                            required={true}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Data Scadenza*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Data Scadenza
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="date"
                                            name={"data_scadenza"}
                                            required={true}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Societa*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Soggetto Contabile
                                    </div>
                                    <select
                                        name={"societa"}
                                        defaultValue={societa[0]}
                                        required={true}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            societa.map((soc, index) => (
                                                <option
                                                    key={index}
                                                    value={soc.id}
                                                >
                                                    {soc.nome}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            {/*Societa 2*/}
                            {
                                selectedType === "Infragruppo" ? (
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Soggetto Contabile 2
                                            </div>
                                            <select
                                                name={"societa_2"}
                                                defaultValue={societa[0]}
                                                onChange={(e) => setS2(e.target.value)}
                                                required={true}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            >
                                                {
                                                    societa.map((soc, index) => (
                                                        <option
                                                            key={index}
                                                            value={soc.id}
                                                        >
                                                            {soc.nome}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                ) : null
                            }

                            {/*Documento*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Fattura
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="file"
                                            name={"documento"}
                                            required={true}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Netto*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Importo Netto
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="number"
                                            step={0.01}
                                            min={0}
                                            name={"importo_netto"}
                                            placeholder={"Netto"}
                                            required={true}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Cassa Previdenziale*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Cassa Previdenziale (Opt)
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="number"
                                            step={0.01}
                                            min={0}
                                            defaultValue={0}
                                            name={"cassa_previdenziale"}
                                            placeholder={"Cassa Previdenziale"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Iva*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Iva (Opt)
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="number"
                                            step={0.01}
                                            min={0}
                                            defaultValue={0}
                                            name={"iva"}
                                            placeholder={"Iva"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Ritenuta Acconto*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Ritenuta Acconto (Opt)
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="number"
                                            step={0.01}
                                            min={0}
                                            defaultValue={0}
                                            name={"ritenuta_acconto"}
                                            placeholder={"Ritenuta Acconto"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Aggiungi
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default AggiungiFattura;
