import React from 'react';
import HeaderSection from "../../../../components/HeaderSection";
import FormCard from "../../../../components/FormCard";

import BackButton from "../../../../components/BackButton";
import MainLayout from "../../../../Layouts/MainLayout";
import {Banknote, Cake, File, Pen} from "lucide-react";

const SezioneContabilita = () => {
    return (
        <MainLayout>
            <section className={"bg-white p-20"}>
                <BackButton/>
                <HeaderSection title={"Gestione Dati"}/>
                <div
                    className="container grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 max-w-screen-lg mt-10 gap-4 ">

                    <FormCard icon={<Cake size={48}/>} title={"Nota Spese"}
                              link={"/nota_spese"}/>
                    <FormCard icon={<Banknote size={48}/>} title={"Buste Paga"}
                              link={"/buste_paga"}/>
                    <FormCard icon={<Pen size={48}/>} title={"Prima Nota"}
                              link={"/prima_nota"}/>
                    <FormCard icon={<File size={48}/>} title={"Fatture"}
                              link={"/fatture"}/>

                </div>
            </section>
        </MainLayout>
    );
};

export default SezioneContabilita;
