import AnagraficaForm from "../pages/Gestionale/Gestisci/Anagrafica/Anagrafica/AnagraficaForm";
import AnagraficaPage from "../pages/Gestionale/Gestisci/Anagrafica/Anagrafica/AnagraficaPage";
import InfoAnagrafica from "../pages/Gestionale/Gestisci/Anagrafica/Anagrafica/InfoAnagrafica";
import ModificaAnagrafica from "../pages/Gestionale/Gestisci/Anagrafica/Anagrafica/ModificaAnagrafica";
import LogAnagrafica from "../pages/Gestionale/Log/LogAnagrafica";
import SezioneAnagrafica from "../pages/Gestionale/Gestisci/Anagrafica/SezioneAnagrafica";

export const anagraficaRoutes = [
    {path: "/sezioni_anagrafica", element: <SezioneAnagrafica/>},
    {path: "/aggiungi_anagrafica", element: <AnagraficaForm/>},
    {path: "/anagrafica", element: <AnagraficaPage/>},
    {path: "/anagrafica/:id", element: <InfoAnagrafica/>},
    {path: "/anagrafica/modifica/:id", element: <ModificaAnagrafica/>},
    {path: "/log/anagrafica", element: <LogAnagrafica/>},
]
