import React from 'react';
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import {getEventiFormazioneUtente} from "../../../fetch/fetchFormazione";
import FormazioneCard from "./FormazioneCard";


const FormazioneProfiloPage = () => {

    const [eventiFormazione, setEventiFormazione] = React.useState([]);

    React.useEffect(() => {
        getEventiFormazioneUtente().then((response) => {
            setEventiFormazione(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow mt-10"}>
                <div className={"flex items-center justify-center mb-4"}>
                    <div className={"flex sm:w-4/5 w-11/12 items-center justify-evenly"}>
                        <div className={"text-lg sm:text-3xl text-center text-black uppercase font-bold "}>
                            Procedure aziendali
                        </div>
                    </div>
                </div>
                <div className={"container"}>
                    <div role="list" className="divide-y-2 divide-gray-100">
                        {
                            eventiFormazione.map((event, key) => (
                                <FormazioneCard event={event} key={key}/>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default FormazioneProfiloPage;
