import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {eliminaMaterialeCommessa, getMaterialeLavoro} from "../../../../../fetch/fetchMateriali";
import Navbar from "../../../../../components/Navbar";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import Footer from "../../../../../components/Footer";
import BackButton from "../../../../../components/BackButton";
import {getInfoMaterialeInventario} from "../../../../../fetch/fetchMagazzino";
import {roundToTwoDecimals} from "../../../../../utils/number-formatter";

const MaterialeCommessa = () => {
    const [filter, setFilter] = useState("")
    const {id} = useParams()

    const [materiali, setMateriali] = useState([])

    const delMateriale = (e, id) => {
        e.preventDefault()
        eliminaMaterialeCommessa(id).then(() => {
            setMateriali(prevState => prevState.filter((item) => item.id !== id))
        }).catch(e => {
            console.log(e)
        })
    }

    const getSupporto = () => {
        getMaterialeLavoro(id).then(r => {
            const data = r.data
            for (let i = 0; i < data.length; i++) {
                getInfoMaterialeInventario(data[i].magazzino.id, data[i].materiale.id).then(r => {
                    setMateriali(prevState => [...prevState, {
                        id: data[i].id,
                        commessa: data[i].commessa,
                        magazzino: data[i].magazzino,
                        materiale: data[i].materiale,
                        quantita: data[i].quantita,
                        costo_ponderato: r.data[0].costo_ponderato,
                        costo_totale: roundToTwoDecimals(r.data[0].costo_ponderato * data[i].quantita),
                        percentuale_ricarico: data[i].percentuale_ricarico,
                        tot: (
                            roundToTwoDecimals((r.data[0].costo_ponderato * data[i].quantita) +
                            (r.data[0].costo_ponderato * data[i].quantita * data[i].percentuale_ricarico / 100))
                        ),
                    }])
                }).catch(e => {
                    console.log(e)
                })
            }
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        getSupporto()
    }, [])


    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"flex-grow mt-10"}>
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSectionAdd titolo={"Materiali Commessa"} link={`/aggiungi_materiale_commessa/${id}`}/>
                    <div className={"flex container items-center justify-between"}>
                        <div className={"mt-5 bg-green-200 p-2 rounded"}>
                            Costo Totale
                            Materiali: {roundToTwoDecimals(materiali.reduce((a, b) => parseFloat(a) + parseFloat( b.tot), 0))} €
                        </div>
                        <div className={"mt-5 bg-amber-200 p-2 rounded"}>
                            Costo Materiali: {roundToTwoDecimals(materiali.reduce((a, b) => parseFloat(a) + parseFloat( b.costo_totale), 0))} €
                        </div>
                        <div className={"mt-5 bg-sky-200 p-2 rounded"}>
                            Costo Ricarico
                            Materiali: {roundToTwoDecimals(materiali.reduce((a, b) => parseFloat(a) + parseFloat( b.tot - b.costo_totale), 0))} €
                        </div>
                    </div>

                    <div className={"my-10 flex justify-center items-center"}>
                        <div className="flex flex-col w-11/12">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <div>
                                            <input
                                                type="text"
                                                placeholder={"Cerca..."}
                                                className={"w-full py-2 px-4 outline-none border-none"}
                                                onChange={event => setFilter(event.target.value)}
                                            />
                                        </div>
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50 sticky top-0 z-10">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Commessa
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Magazzino
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Materiale
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Quantità
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Costo al Pezzo
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Costo Totale
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Percentuale Ricarico
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Costo Totale + Ricarico
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Elimina
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                materiali.filter(m => {
                                                    if (filter === "") {
                                                        return m
                                                    } else if (
                                                        m.materiale.materiale.toLowerCase().includes(filter.toLowerCase())
                                                    ) {
                                                        return m
                                                    }
                                                }).map((m, i) => (
                                                    <tr key={i}>
                                                        {/*Commessa*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{m.commessa.nome}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Materiale*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{m.magazzino.nome}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Materiale*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{m.materiale.materiale}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Quantità*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{m.quantita}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Costo al pezzo*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{m.costo_ponderato} €
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Totale*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{m.costo_totale} €
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Costo Ricaricato*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{m.percentuale_ricarico} %
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Totale + Ricarico*/}
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{m.tot} €
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Elimina*/}
                                                        <td
                                                            className="px-6 py-4  text-sm text-gray-500"
                                                            onClick={(e) => delMateriale(e, m.id)}
                                                        >
                                                            <p className="text-my-red font-bold hover:text-red-700 cursor-pointer">Elimina</p>
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default MaterialeCommessa;
