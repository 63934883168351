import React, {useEffect, useState} from 'react';
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import HeaderSection from "../../../../../components/HeaderSection";
import {sleep} from "../../../../../constants/sleep";
import {getSocieta} from "../../../../../fetch/fetchSocieta";
import {creaPrimaNota} from "../../../../../fetch/fetchPrimaNota";
import {getAnagrafiche} from "../../../../../fetch/fetchAnagrafica";
import BackButton from "../../../../../components/BackButton";
import MainLayout from "../../../../../Layouts/MainLayout";
import {useQuery} from "react-query";
import {getMezziPagamento} from "../../../../../fetch/fetchMezzi";
import {getFatture} from "../../../../../fetch/fetchFattura";
import {getTax} from "../../../../../fetch/fetchTax";

const AggiungiPrimaNota = () => {

    const [alert, setAlert] = useState(0)

    const [selectedAnagrafica, setSelectedAnagrafica] = useState()

    const {data: soc = []} = useQuery({
        queryKey: 'societa',
        queryFn: () => getSocieta().then(
            r => r.data
        )
    })

    const {data: mezziPagamento = []} = useQuery({
        queryKey: 'mezziPagamento',
        queryFn: () => getMezziPagamento().then(
            r => r.data
        )
    })

    const {data: fatture = []} = useQuery({
        queryKey: 'fatture',
        queryFn: () => getFatture().then(
            r => r.data
        )
    })

    const {data: tax = []} = useQuery({
        queryKey: 'tax',
        queryFn: () => getTax().then(
            r => r.data
        )
    })

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('societa', e.target.societa.value);
        formData.append('tipo_movimento', e.target.tipo_movimento.value);
        formData.append('mezzo_pagamento_partenza', e.target.mezzo_pagamento_partenza.value);
        formData.append('mezzo_pagamento_arrivo', e.target.mezzo_pagamento_arrivo.value);
        formData.append('data', e.target.data.value);
        formData.append('importo', e.target.importo.value);
        if (e.target.positivo.value === "true") {
            formData.append('positivo', true);
        } else {
            formData.append('positivo', false);
        }
        formData.append('descrizione', e.target.descrizione.value);
        if (e.target.fattura.value !== "") {
            formData.append('fattura', e.target.fattura.value);
        }
        if (e.target.tax.value !== "") {
            formData.append('tax', e.target.tax.value);
        }

        creaPrimaNota(formData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };


    return (
        <MainLayout>
            <div className={"p-10"}>
                <div>

                </div>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"La Prima Nota è stato aggiunta con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Prima Nota"}/>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Societa*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Società
                                    </div>
                                    <select
                                        name={"societa"}
                                        defaultValue={soc[0]}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            soc.map((soc, index) => (
                                                <option
                                                    key={index}
                                                    value={soc.id}
                                                >
                                                    {soc.nome}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Tipo*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Tipo Movimento
                                    </div>
                                    <select
                                        name={"tipo_movimento"}
                                        defaultValue={"Giroconto"}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        <option value="Giroconto">Giroconto</option>
                                        <option value="Prelievo">Prelievo</option>
                                        <option value="Versamento">Versamento</option>
                                        <option value="Altro">Altro</option>
                                    </select>
                                </div>
                            </div>
                            {/*Metodo Pagamento Partenza*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Mezzo Pagamento
                                    </div>
                                    <select
                                        name={"mezzo_pagamento_partenza"}
                                        defaultValue={mezziPagamento[0]?.id}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            mezziPagamento?.map((mp, index) => (
                                                <option
                                                    key={index}
                                                    value={mp.id}
                                                >
                                                    {mp.intestatario} - {mp.tipo}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Metodo Pagamento Arrivo*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Mezzo Pagamento Arrivo
                                    </div>
                                    <select
                                        name={"mezzo_pagamento_arrivo"}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        <option value={""}>Seleziona</option>
                                        {
                                            mezziPagamento?.map((mp, index) => (
                                                <option
                                                    key={index}
                                                    value={mp.id}
                                                >
                                                    {mp.intestatario} - {mp.tipo}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Data*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Data
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="date"
                                            name={"data"}
                                            required={true}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Importo*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Importo
                                    </div>
                                    <div className="relative rounded-md shadow-sm">
                                        <div className="absolute inset-y-0 left-0 flex items-center">
                                            <label htmlFor="country" className="sr-only">
                                                Positivo
                                            </label>
                                            <select
                                                name="positivo"
                                                className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                            >
                                                <option value={"true"}>POS</option>
                                                <option value={"false"}>NEG</option>
                                            </select>
                                        </div>
                                        <input
                                            type="number"
                                            name={"importo"}
                                            min={0}
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="0"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*Descrizione*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Descrizione
                                    </div>
                                    <div className="relative">
                                            <textarea
                                                maxLength={255}
                                                name={"descrizione"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                    </div>
                                </div>
                            </div>

                            {/*Fattura*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Fattura
                                    </div>
                                    <select
                                        name={"fattura"}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        <option value={""}>Seleziona Fattura</option>
                                        {
                                            fatture?.map((soc, index) => (
                                                <option
                                                    key={index}
                                                    value={soc.id}
                                                >
                                                    {soc.numero_fattura}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            {/*Tax*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Tax
                                    </div>
                                    <select
                                        name={"tax"}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        <option value={""}>Seleziona Tax</option>
                                        {
                                            tax?.map((soc, index) => (
                                                <option
                                                    key={index}
                                                    value={soc.id}
                                                >
                                                    {soc.nota}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Aggiungi
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default AggiungiPrimaNota;
