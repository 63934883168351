import React, {Fragment, useEffect, useState} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {Link} from "react-router-dom";
import {getSocieta} from "../../../../fetch/fetchSocieta";
import CellaGiorno from "./CellaGiorno";
import {ChevronDown, ChevronLeft, ChevronRight} from "lucide-react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const CalendarioMensile = () => {

    const [currentDate, setCurrentDate] = useState(new Date())
    const year = currentDate.getFullYear()

    const tipiEvento = [
        {
            nome: "Appuntamento",
            colore: "bg-blue-200"
        },
        {
            nome: "Commessa",
            colore: "bg-green-200"
        },
        {
            nome: "Stipendio",
            colore: "bg-red-200"
        },
        {
            nome: "Fattura",
            colore: "bg-yellow-200"
        },
        {
            nome: "Tassa",
            colore: "bg-purple-200"
        },
        {
            nome: "FollowUp",
            colore: "bg-pink-200"
        },
        {
            nome: "Sopralluogo",
            colore: "bg-secondary-blue"
        },
        {
            nome: "Malattia",
            colore: "bg-red-100"
        },
        {
            nome: "Altro",
            colore: "bg-gray-200"
        },
        {
            nome: "Recurring",
            colore: "bg-pink-700"
        },

        {
            nome: "Evento networking",
            colore: "bg-pink-300"
        },
        {
            nome: "Nessun Filtro",
            colore: "bg-white"
        }
    ]

    const [filtro, setFiltro] = useState({
        nome: "Nessun Filtro",
        valore: ""
    })

    const [filtroSoc, setFiltroSoc] = useState({
        nome: "Nessun Filtro Società",
        valore: ""
    })

    const [societa, setSocieta] = useState([])

    function nextMonth() {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))
    }

    function prevMonth() {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))
    }

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const createMonthList = () => {
        const x = currentDate
        const currentMonth = x.getMonth();
        const currentYear = x.getFullYear();
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
        const firstDayOfWeek = firstDayOfMonth.getDay();
        const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
        const daysBeforeMonday = (firstDayOfWeek + 6) % 7;

        // Calculate the previous month and year
        let prevMonth = currentMonth - 1;
        let prevYear = currentYear;
        if (prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }

        // Calculate the number of days in the previous month
        const daysInPrevMonth = new Date(prevYear, prevMonth + 1, 0).getDate();

        // Calculate the days to display in the list
        const days = [];

        // Add days from the previous month
        for (let i = daysInPrevMonth - daysBeforeMonday + 1; i <= daysInPrevMonth; i++) {
            const prevDate = new Date(prevYear, prevMonth, i);
            days.push({
                date: prevDate.getFullYear() + "-" + (prevDate.getMonth() + 1) + "-" + prevDate.getDate(),
                isCurrentMonth: false,
                isToday: false
            });
        }

        // Add days from the current month
        for (let i = 1; i <= daysInMonth; i++) {
            const currentDate = new Date(currentYear, currentMonth, i);

            days.push({
                date: currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate(),
                isCurrentMonth: true,
                isToday: currentDate.toDateString() === new Date().toDateString()
            });
        }

        // Add days from the next month to complete the last week
        const remainingDays = 7 - ((daysBeforeMonday + daysInMonth) % 7);
        for (let i = 1; i <= remainingDays; i++) {
            const nextDate = new Date(currentYear, currentMonth + 1, i);
            days.push({
                date: nextDate.getFullYear() + "-" + (nextDate.getMonth() + 1) + "-" + nextDate.getDate(),
                isCurrentMonth: false,
                isToday: false
            });
        }

        return days;
    }

    const giorniDellaSettimana = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom']

    const [days, setDays] = useState([])

    useEffect(() => {
        setDays(createMonthList())
        getSocieta().then(
            r => {
                setSocieta(r.data)
            }
        ).catch(
            e => console.log(e)
        )
    }, [currentDate]);

    return (
        <div className="flex flex-col items-center sm:items-start sm:flex-row justify-center sm:space-y-20">
            {/*Header*/}
            <div className="sm:fixed mb-4 z-30 w-11/12 flex-1">
                <div className={"flex items-center justify-between w-full py-4 px-2 rounded bg-my-white"}>
                    <div className="text-lg font-semibold text-gray-900">
                        <div>
                            {capitalize(currentDate.toLocaleString('default', {month: 'long'}))} {year}
                        </div>
                    </div>
                    <div className="flex items-center gap-6">
                        <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                            <button
                                type="button"
                                onClick={prevMonth}
                                className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            >
                                <span className="sr-only">Previous month</span>
                                <ChevronLeft className="h-5 w-5" aria-hidden="true"/>
                            </button>
                            <button
                                type="button"
                                onClick={() => setCurrentDate(new Date())}
                                className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
                            >
                                Today
                            </button>
                            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden"/>
                            <button
                                type="button"
                                onClick={nextMonth}
                                className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            >
                                <span className="sr-only">Next month</span>
                                <ChevronRight className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>
                        {/*FILTRO*/}
                        <div className={"hidden md:flex md:items-center"}>
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button
                                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 ">
                                        {filtro.nome}
                                        <ChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {
                                                tipiEvento.map((tipo, index) => (
                                                    <div key={index}>
                                                        <Menu.Item>
                                                            {({active}) => (
                                                                <div
                                                                    onClick={() => setFiltro({
                                                                        nome: tipo.nome,
                                                                        valore: tipo.nome === "Nessun Filtro" ? "" : tipo.nome
                                                                    })}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    {tipo.nome}
                                                                </div>
                                                            )}
                                                        </Menu.Item>
                                                    </div>

                                                ))
                                            }
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                        {/*Filtro Società*/}
                        <div className={"hidden md:flex md:items-center"}>
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button
                                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 ">
                                        {filtroSoc.nome}
                                        <ChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {
                                                societa.map((societa, index) => (
                                                    <div key={index}>
                                                        <Menu.Item>
                                                            {({active}) => (
                                                                <div
                                                                    onClick={() => setFiltroSoc({
                                                                        nome: societa.nome,
                                                                        valore: societa.nome === "Nessun Filtro Società" ? "" : societa.nome
                                                                    })}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    {societa.nome}
                                                                </div>
                                                            )}
                                                        </Menu.Item>
                                                    </div>

                                                ))
                                            }
                                            <Menu.Item>
                                                {({active}) => (
                                                    <div
                                                        onClick={() => setFiltroSoc({
                                                            nome: "Nessun Filtro Società",
                                                            valore: ""
                                                        })}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Nessun Filtro Società
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                        <div className="hidden md:flex md:items-center">
                            <Link
                                to={"/aggiungi_evento"}
                                type="button"
                                className="rounded border border-transparent bg-primary-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-transparent hover:text-primary-blue hover:border-primary-blue"
                            >
                                Aggiungi Evento
                            </Link>
                        </div>
                        <Menu as="div" className="relative md:hidden">
                            <Menu.Button
                                className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Open menu</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                    />
                                </svg>

                            </Menu.Button>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({active}) => (
                                                <Link
                                                    to="/aggiungi_evento"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    Aggiungi Evento
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    </div>
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({active}) => (
                                                <div
                                                    onClick={() => setCurrentDate(new Date())}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    Oggi
                                                </div>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>

            </div>

            {/*Calendario*/}
            <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-col w-11/12">
                {/*Giorni Della Settimana*/}
                <div
                    className="sm:mt-0 grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                    {
                        giorniDellaSettimana.map((giorno, index) => (
                            <div className="bg-white py-2" key={index}>
                                {giorno.charAt(0)}<span
                                className="sr-only sm:not-sr-only">{giorno.substring(1, 3)}</span>
                            </div>
                        ))
                    }
                </div>
                {/*Lista Giorni*/}
                <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                    {/*Giorni FULL SCREEN*/}
                    <div className="hidden min-h-screen w-full lg:grid lg:grid-cols-7 lg:grid-flow-row lg:gap-px">
                        {
                            days.map((day, i) => (
                                <CellaGiorno
                                    day={day}
                                    key={i}
                                    filtro={filtro}
                                    filtroSoc={filtroSoc}
                                    phoneMode={false}
                                />
                            ))
                        }
                    </div>
                    {/*Giorni MOBILE*/}
                    <div className="isolate grid w-full grid-cols-7 grid-rows-5 gap-px lg:hidden">
                        {
                            days.map((day, i) => (
                                <CellaGiorno
                                    day={day}
                                    key={i}
                                    filtro={filtro}
                                    filtroSoc={filtroSoc}
                                    phoneMode={true}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
