import React, {useEffect, useState} from 'react';
import {TabellaLog} from "./TabellaLog";
import LayoutLog from "./LayoutLog";
import axios from "axios";
import {proxyUrl} from "../../../constants/proxyUrl";

const LogPrimaNota = () => {

    const [listaLogState, setListaLogState] = useState([]);

    useEffect(() => {

        const options = {
            url: `${proxyUrl}/prima_nota/log/`,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
            }
        };

        axios(options).then(
            (response) => {
                setListaLogState(response.data);
            }
        ).catch(
            (error) => {
                console.log(error);
            }
        )
    }, []);

    return (
        <LayoutLog
            titolo={"Log Prima Nota"}
        >
            <TabellaLog
                nomeItem={"prima_nota"}
                listaLog={listaLogState}
            />
        </LayoutLog>
    );
};

export default LogPrimaNota;
