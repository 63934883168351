import React, {useState} from 'react';
import ChangePasswordModal from "./ChangePasswordModal";
import BannerGrid from "./BannerGrid";
import MainLayout from "../../Layouts/MainLayout";
import {PencilIcon} from "lucide-react";

const ProfilePage = () => {

    const [open, setOpen] = useState(false)

    const gridSection = [{
        title: 'Buste Paga',
        description: 'In questa sezioni puoi visualizzare le tue buste paga',
        href: 'buste-paga',
        icon: (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                    stroke="currentColor" className="w-10 h-10">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"/>
        </svg>),
    }, {
        title: 'Eventi e Task',
        description: 'In questa sezioni puoi visualizzare gli eventi e le Task a cui parteciperai',
        href: 'eventi',
        icon: (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                    stroke="currentColor" className="w-10 h-10">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
            </svg>

        ),
    }, {
        title: 'Formazione',
        description: 'In questa sezioni puoi visualizzare le procedure di aziendali',
        href: 'formazione',
        icon: (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                    stroke="currentColor" className="w-10 h-10">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"/>
        </svg>),
    },]

    return (<MainLayout margin={"mt-16"}>
        <ChangePasswordModal open={open} setOpen={setOpen}/>
        <div className={"flex items-center justify-center mb-4"}>
            <div className={"flex sm:w-4/5 w-11/12 items-center justify-evenly"}>
                <div className={"text-lg sm:text-3xl text-center text-black uppercase font-bold "}>
                    La tua area personale
                </div>
                <div
                    className={"items-center justify-between bg-primary-blue text-xs sm:text-lg p-2 flex rounded text-white font-semibold cursor-pointer"}
                    onClick={() => setOpen(true)}
                >
                    <PencilIcon className={"h-4 w-4 mr-2"}/>
                    Password
                </div>
            </div>
        </div>
        <BannerGrid gridSection={gridSection}/>
    </MainLayout>);
};

export default ProfilePage;
