import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const getEventiFormazioneUtente = async () => {
    const options = {
        url: `${proxyUrl}/formazione/user`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const editEventiFormazioneUtente = async (id, data) => {
    const options = {
        url: `${proxyUrl}/formazione/user/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: data
    };

    return axios(options)
}

export const getInfoEventoFormazione = async (id) => {
    const options = {
        url: `${proxyUrl}/formazione/modifica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const getEventiFormazione = async () => {
    const options = {
        url: `${proxyUrl}/formazione/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const addEventiFormazione = async (data) => {
    const options = {
        url: `${proxyUrl}/formazione/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: data
    };

    return axios(options)
}
export const removeEventiFormazione = async (id) => {
    const options = {
        url: `${proxyUrl}/formazione/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const listaAllegatiEventoFormazione = async (id) => {
    const options = {
        url: `${proxyUrl}/formazione/allegati/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
    };

    return axios(options)
}
export const addAllegatoEventoFormazione = async (data) => {
    const options = {
        url: `${proxyUrl}/formazione/allegati/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: data
    };

    return axios(options)
}
export const removeAllegatoEventoFormazione = async (id) => {
    const options = {
        url: `${proxyUrl}/formazione/allegati/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
