import React, {useEffect, useState} from 'react';
import {sleep} from "../../../../../constants/sleep";
import {getDipendenti} from "../../../../../fetch/fetchDipendenti";
import Navbar from "../../../../../components/Navbar";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import HeaderSection from "../../../../../components/HeaderSection";
import Footer from "../../../../../components/Footer";
import {creaPartecipazione} from "../../../../../fetch/fetchPartecipazione";
import BackButton from "../../../../../components/BackButton";
import {useParams} from "react-router-dom";

const AggiungiPartecipazione = () => {
    const {id} = useParams();
    const [alert, setAlert] = useState(0)

    const [dipendenti, setDipendenti] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('dipendente', e.target.dipendente.value);
        postData.append('task', id);

        creaPartecipazione(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    useEffect(() => {
        getDipendenti().then(
            r => setDipendenti(r.data)
        ).catch(
            e => console.log(e)
        )
    }, [])
    return (
        <div className={"flex flex-col h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"}
                                          message={"La Partecipazione è stata aggiunta con successo"}/>
                        ) : (
                            <div/>
                        )
                    }

                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi Partecipazione"}/>
                    <div className={"flex flex-col lg:flex-row"}>
                        <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                            <div
                                className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                <div className="grid max-w-xl grid-cols-1 gap-4 m-auto">
                                    {/*Dipendente*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Dipendente
                                            </div>
                                            <select
                                                name={"dipendente"}
                                                defaultValue={dipendenti[0]}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            >
                                                {
                                                    dipendenti.map((dip, index) => (
                                                        <option
                                                            key={index}
                                                            value={dip.id}
                                                        >
                                                            {dip.anagrafica.nome} {dip.anagrafica.cognome}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/*Bottone*/}
                                    <div className="col-span-2 text-center">
                                        <button
                                            type="submit"
                                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                        >
                                            Aggiungi
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default AggiungiPartecipazione;
