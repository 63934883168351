import React from 'react';
import Navbar from "../../../components/Navbar";
import BackButton from "../../../components/BackButton";
import HeaderSection from "../../../components/HeaderSection";
import FormCard from "../../../components/FormCard";
import Footer from "../../../components/Footer";
import {Cake} from "lucide-react";

const SezioneLog = () => {
    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <section className={"bg-white p-20"}>
                    <BackButton/>
                    <HeaderSection title={"Gestione Log"}/>
                    <div
                        className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 mt-10 gap-4 ">
                        {/*<FormCard icon={solid("cake-candles")} title={"Log Anagrafica"} link={"/log/anagrafica"}/>*/}
                        {/*<FormCard icon={solid("users")} title={"Log Dipendenti"} link={"/log/dipendenti"}/>*/}
                        {/*<FormCard icon={solid("pen-fancy")} title={"Log Contratti"} link={"/log/contratti"}/>*/}
                        {/*<FormCard icon={solid("hand-holding-dollar")} title={"Log Clienti"} link={"/log/clienti"}/>*/}
                        {/*<FormCard icon={solid("people-arrows")} title={"Log Relazioni Lavorative"}*/}
                        {/*          link={"/log/relazioni"}/>*/}
                        {/*<FormCard icon={solid("box")} title={"Log Fornitori"} link={"/log/fornitori"}/>*/}
                        {/*<FormCard icon={solid("box")} title={"Log Lavori"} link={"/log/lavori"}/>*/}
                        {/*<FormCard icon={solid("handshake-angle")} title={"Log Collaboratori"}*/}
                        {/*          link={"/log/collaboratori"}/>*/}
                        {/*<FormCard icon={solid("street-view")} title={"Log Produttori"} link={"/log/produttori"}/>*/}
                        {/*<FormCard icon={solid("plane")} title={"Log Nota Spese"} link={"/log/nota_spese"}/>*/}
                        {/*<FormCard icon={solid("money-bill")} title={"Log Buste Paga"} link={"/log/buste-paga"}/>*/}
                        {/*<FormCard icon={solid("pen-clip")} title={"Log Prima Nota"} link={"/log/prima_nota"}/>*/}
                        {/*<FormCard icon={solid("file-invoice")} title={"Log Fatture"} link={"/log/fatture"}/>*/}
                        {/*<FormCard icon={solid("warehouse")} title={"Log InventarioMagazzino"} link={"/log/magazzino"}/>*/}
                        {/*<FormCard icon={solid("industry")} title={"Log Soggetti Contabili"}*/}
                        {/*          link={"/log/soggetti_contabili"}/>*/}
                        {/*<FormCard icon={solid("note-sticky")} title={"Log Offerte"} link={"/log/preventivi"}/>*/}
                        {/*<FormCard icon={solid("calendar")} title={"Log Eventi"} link={"/log/eventi"}/>*/}
                        <FormCard icon={<Cake size={48}/>} title={"Log Anagrafica"} link={"/log/anagrafica"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Dipendenti"} link={"/log/dipendenti"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Contratti"} link={"/log/contratti"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Clienti"} link={"/log/clienti"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Relazioni Lavorative"}
                                  link={"/log/relazioni"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Fornitori"} link={"/log/fornitori"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Lavori"} link={"/log/lavori"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Collaboratori"}
                                  link={"/log/collaboratori"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Produttori"} link={"/log/produttori"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Nota Spese"} link={"/log/nota_spese"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Buste Paga"} link={"/log/buste-paga"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Prima Nota"} link={"/log/prima_nota"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Fatture"} link={"/log/fatture"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log InventarioMagazzino"} link={"/log/magazzino"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Soggetti Contabili"}
                                  link={"/log/soggetti_contabili"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Offerte"} link={"/log/preventivi"}/>
                        <FormCard icon={<Cake size={48}/>} title={"Log Eventi"} link={"/log/eventi"}/>
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    );
};

export default SezioneLog;
