import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const getMateriale = async () => {
    const options = {
        url: `${proxyUrl}/materiale/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const getCategorieMateriali = async () => {
    const options = {
        url: `${proxyUrl}/materiale/categorie`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const addCategorieMateriali = async (formData) => {
    const options = {
        url: `${proxyUrl}/materiale/categorie/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData

    };

    return axios(options)
}

export const removeCategorieMateriali = async (id) => {
    const options = {
        url: `${proxyUrl}/materiale/categorie/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getSottoCategorieMateriali = async () => {
    const options = {
        url: `${proxyUrl}/materiale/sottocategorie`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
export const addSottocategorieMateriali = async (formData) => {
    const options = {
        url: `${proxyUrl}/materiale/sottocategorie/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData

    };

    return axios(options)
}

export const removeSottocategorieMateriali = async (id) => {
    const options = {
        url: `${proxyUrl}/materiale/sottocategorie/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const creaMateriale = async (formData) => {
    const options = {
        url: `${proxyUrl}/materiale/crea`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


export const eliminaMateriale = async (id) => {
    const options = {
        url: `${proxyUrl}/materiale/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const aggiungiMaterialiLavoro = async (formData) => {
    const options = {
        url: `${proxyUrl}/materiale/commessa/crea`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


export const getMaterialeLavoro = async (id) => {
    const options = {
        url: `${proxyUrl}/materiale/commessa/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const eliminaMaterialeCommessa = async (id) => {
    const options = {
        url: `${proxyUrl}/materiale/commessa/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
