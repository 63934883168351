import React from 'react';

const GenericInput = ({value, setValue, name, label, type, isLong}) => {
    return (
        <div className={isLong ? "col-span-2" : "col-span-2 sm:col-span-1"}>
            <div className={"flex flex-col lg:flex-col"}>
                <div className={"text-my-gray"}>
                    {label}
                </div>
                <div className="relative">
                    <input
                        type={type}
                        name={name}
                        onChange={(e) => setValue({...value, [e.target.name]: e.target.value})}
                        className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                        placeholder={label}
                        required
                    />
                </div>
            </div>
        </div>
    );
};

export default GenericInput;
