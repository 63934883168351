import React, {useEffect, useState} from 'react';
import {eliminaContratto, getContratti} from "../../../../../fetch/fetchContratti";
import {useSelector} from "react-redux";
import FiltroSocieta from "../../../../../components/FiltroSocieta";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import BackButton from "../../../../../components/BackButton";
import MainLayout from "../../../../../Layouts/MainLayout";
import ContrattoModal from "./ContrattoModal";

const ContrattiPage = () => {

    const [filter, setFilter] = useState("")
    const [contratti, setContratti] = useState([])

    const [showModal, setShowModal] = useState(false)

    const [eliminazione, setEliminazione] = useState(-1)

    const {filtroSoc} = useSelector(state => state.societa)

    const getContrattiSupport = () => {
        getContratti().then(r => {
            setContratti(r.data)
        })
    }
    const delContratto = (e, id) => {
        e.preventDefault()
        eliminaContratto(id).then(
            () => getContrattiSupport()
        ).catch(
            e => console.log(e)
        )
    }
    const [open, setOpen] = useState(false)

    useEffect(() => {
        getContrattiSupport()
    }, [open])


    const [selectedContratto, setSelectedContratto] = useState(null)

    return (
        <MainLayout margin={"mt-10"}>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Eliminazione
                                    </h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        Sicuro di voler eliminare?
                                    </p>
                                </div>
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-my-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Annulla
                                    </button>
                                    <button
                                        className="bg-my-red text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(e) => {
                                            delContratto(e, eliminazione)
                                            setShowModal(false)
                                        }}
                                    >
                                        Elimina
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <div className={"flex px-20 py-2"}>
                <BackButton/>
            </div>

            <ContrattoModal setOpen={setOpen} open={open} id={selectedContratto}/>

            <HeaderSectionAdd titolo={"Contratti"} link={"/add_contratto"} esporta={"contratti"}/>
            <FiltroSocieta/>
            <div className={"my-10 flex justify-center items-center"}>
                <div className="flex flex-col w-11/12">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <div>
                                    <input
                                        type="text"
                                        placeholder={"Cerca..."}
                                        className={"w-full py-2 px-4 outline-none border-none"}
                                        onChange={event => setFilter(event.target.value)}
                                    />
                                </div>
                                <div className={"max-h-96 overflow-auto"}>
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50 sticky top-0 z-10">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Codice
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Anagrafica
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Inizio
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Fine
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Società
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Tipo Contratto
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Documento
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Elimina
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            contratti.filter(contratto => {
                                                if (filter === "") {
                                                    return contratto
                                                } else if (
                                                    contratto.anagrafica.nome.toLowerCase().includes(filter.toLowerCase()) ||
                                                    contratto.anagrafica.cognome.toLowerCase().includes(filter.toLowerCase())
                                                ) {
                                                    return contratto
                                                }
                                            }).filter(contratto => {
                                                if (filtroSoc === "") {
                                                    return contratto
                                                }
                                                if (filtroSoc === contratto.societa.nome) {
                                                    return contratto
                                                }
                                            }).map((contratto, i) => (
                                                <tr key={i}>
                                                    {/*Codice Contratto*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{contratto.codice_contratto}</div>
                                                    </td>
                                                    {/*Anagrafica*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{contratto.anagrafica.nome} {contratto.anagrafica.cognome}</div>
                                                    </td>
                                                    {/*Inizio*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{contratto.inizio}</div>
                                                    </td>
                                                    {/*Fine*/}
                                                    <td className="px-6 py-4 ">
                                                        <div className="text-sm text-gray-900">
                                                            {
                                                                contratto.fine === null ? (
                                                                    "Indeterminato"
                                                                ) : (
                                                                    contratto.fine
                                                                )
                                                            }
                                                        </div>
                                                    </td>
                                                    {/*Societa*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{contratto.societa.nome}
                                                        </div>
                                                    </td>

                                                    {/*Tipo*/}
                                                    <td className="px-6 py-4 ">
                                                        <div
                                                            className="text-sm text-gray-900">{contratto.tipo_contratto}
                                                        </div>
                                                    </td>
                                                    {/*Contratto*/}
                                                    <td className="px-6 py-4">
                                                        {
                                                            contratto.copia_contratto === null ? (
                                                                <div
                                                                    onClick={() => {
                                                                        setOpen(true)
                                                                        setSelectedContratto(contratto.id)
                                                                    }}
                                                                    className={"text-sm text-green-600 font-semibold hover:underline cursor-pointer"}>
                                                                    Aggiungi
                                                                </div>
                                                            ) : (
                                                                <a
                                                                    href={contratto.copia_contratto}
                                                                    target={"_blank"}
                                                                    className="text-sm text-gray-900 font-semibold hover:underline cursor-pointer"
                                                                >
                                                                    Visualizza Contratto
                                                                </a>
                                                            )
                                                        }

                                                    </td>
                                                    <td className="px-6 py-4  text-sm text-gray-500">
                                                        <button
                                                            onClick={() => {
                                                                setShowModal(true)
                                                                setEliminazione(contratto.id)
                                                            }}>
                                                            <p className="text-my-red font-bold hover:text-red-700">Elimina</p>
                                                        </button>
                                                    </td>

                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default ContrattiPage;
