import React from 'react';
import {Link} from "react-router-dom";
import {esportaExcelHTTP} from "../fetch/esportaExcel";

const FileDownload = require('js-file-download');

const HeaderSectionAdd = ({titolo, link, esporta, label="Aggiungi"}) => {

    const esportaExcel = (e) => {
        e.preventDefault()
        esportaExcelHTTP(esporta).then(
            r => FileDownload(
                r.data,
                r.headers['content-disposition']
                    .split(';')[1]
                    .split('=')[1]
                    .replace('"', '')
                    .replace('"', ''))
        )
    }

    return (
        <div
            className="sm:w-full lg:w-5/6 mx-auto px-2 flex flex flex-col md:flex-row space-y-4 md:space-y-0 items-center justify-evenly">
            <div className={"flex flex-col items-center"}>
                <h1 className={"text-3xl text-center text-black uppercase font-bold "}>{titolo}</h1>
                <div className={"w-20 h-1 bg-primary-blue mt-2"}/>
            </div>
            <Link to={link}
                  className={"flex bg-my-green px-4 py-2 rounded text-white font-semibold hover:bg-green-600 cursor-pointer"}>
                {label}
            </Link>
            {
                esporta ? (
                    <button
                        className={"flex bg-blue-500 px-4 py-2 rounded text-white font-semibold hover:bg-blue-700 cursor-pointer"}
                        onClick={esportaExcel}
                    >
                        Esporta
                    </button>
                ) : null
            }
        </div>
    );
};

export default HeaderSectionAdd;
