import axios from "axios";
import {proxyUrl} from "../constants/proxyUrl";

export const addProduttore = async (formData) => {
    const options = {
        url: `${proxyUrl}/produttori/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const getProduttori = async () => {
    const options = {
        url: `${proxyUrl}/produttori`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const eliminaProduttore = async (id) => {
    const options = {
        url: `${proxyUrl}/produttori/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const modificaProduttore = async (id, data) => {
    const options = {
        url: `${proxyUrl}/produttori/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: data
    };

    return axios(options)
}
