import React, {useEffect, useState} from 'react';
import FiltroSocieta from "../../../../../components/FiltroSocieta";
import {useSelector} from "react-redux";
import {eliminaPrimaNota, getPrimeNote} from "../../../../../fetch/fetchPrimaNota";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import BackButton from "../../../../../components/BackButton";
import MainLayout from "../../../../../Layouts/MainLayout";
import TableCell from "../../../../../components/Table/TableCell";
import {Trash} from "lucide-react";

const PrimaNotaPage = () => {

    const [filter, setFilter] = useState("")

    const {filtroSoc} = useSelector(state => state.societa)

    const [showModal, setShowModal] = useState(false)
    const [eliminazione, setEliminazione] = useState(-1)


    const [primaNota, setPrimaNota] = useState([])

    const delPrimaNota = (e, id) => {
        e.preventDefault()
        eliminaPrimaNota(id).then(() => {
            getSupporto()
        }).catch(e => {
            console.log(e)
        })
    }

    const getSupporto = () => {
        getPrimeNote().then(r => {
            console.log(r.data)
            setPrimaNota(r.data)
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        getSupporto()
    }, [])

    const titles = [
        "Società",
        "Mezzo Pagamento 1",
        "Mezzo Pagamento 2",
        "Tipo Movimento",
        "Data",
        "Importo",
        "Descrizione",
        "Fattura",
        "Tax",
        "Elimina"
    ];

    const [range, setRange] = useState({
        start: "",
        end: ""
    })


    return (
        <MainLayout>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Eliminazione
                                    </h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        Sicuro di voler eliminare?
                                    </p>
                                </div>
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-my-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Annulla
                                    </button>
                                    <button
                                        className="bg-my-red text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(e) => {
                                            delPrimaNota(e, eliminazione)
                                            setShowModal(false)
                                        }}
                                    >
                                        Elimina
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSectionAdd titolo={"Prima Nota"} link={"/aggiungi_prima_nota"} esporta={"prima_nota"}/>
                <FiltroSocieta/>
                {/*Info*/}
                <div className={"flex container items-center justify-between mt-4"}>
                    <div className={"flex items-center gap-4"}>
                        <div>Da</div>
                        <input
                            type="date"
                            id={"start"}
                            onChange={event => setRange({...range, start: event.target.value})}
                            className="rounded-lg flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                        />
                        <Trash size={20} className={"cursor-pointer flex text-red-600"} onClick={() => {
                            setRange({...range, start: ""})
                            document.getElementById("start").value = ""
                        }}/>
                    </div>
                    <div className={"flex items-center gap-4"}>
                        <div>A</div>
                        <input
                            type="date"
                            id={"end"}
                            onChange={event => setRange({...range, end: event.target.value})}
                            className="rounded-lg flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                        />
                        <Trash size={20} className={"cursor-pointer h-6 w-6 flex text-red-600"} onClick={() => {
                            setRange({...range, end: ""})
                            document.getElementById("end").value = ""
                        }}/>
                    </div>
                    <div className={"bg-green-200 p-2 rounded"}>
                        Totale Entrate: {primaNota.filter(p => p.positivo).reduce((a, b) => parseFloat(a) + parseFloat(b.importo), 0)} €
                    </div>
                    <div className={"bg-red-200 p-2 rounded"}>
                        Totale Uscite: {primaNota.filter(p => !p.positivo).reduce((a, b) => parseFloat(a) + parseFloat(b.importo), 0)} €
                    </div>
                </div>
                <div className={"my-10 flex justify-center items-center"}>
                    <div className="flex flex-col w-11/12">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={"Cerca..."}
                                            className={"w-full py-2 px-4 outline-none border-none"}
                                            onChange={event => setFilter(event.target.value)}
                                        />
                                    </div>
                                    <div className={"max-h-96 overflow-y-auto"}>
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50 sticky top-0 z-10">
                                            <tr>
                                                {titles.map((title) => (
                                                    <th
                                                        key={title}
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        {title}
                                                    </th>
                                                ))}
                                            </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                primaNota.filter(
                                                    (val) =>
                                                        val.societa.toLowerCase().includes(filtroSoc.toLowerCase())
                                                ).filter(
                                                    (val) =>
                                                        range.start === "" || range.end === "" ? val :
                                                            val.data >= range.start && val.data <= range.end
                                                ).map((nota, i) => (
                                                    <tr key={i}>
                                                        <TableCell
                                                            content={nota.societa}
                                                        />
                                                        <TableCell
                                                            content={nota.mezzo_pagamento_partenza}
                                                        />
                                                        <TableCell
                                                            content={nota.mezzo_pagamento_arrivo ? nota.mezzo_pagamento_arrivo : "Non presente"}
                                                        />
                                                        <TableCell
                                                            content={nota.tipo_movimento}
                                                        />
                                                        <TableCell
                                                            content={nota.data}
                                                        />
                                                        <TableCell
                                                            content={nota.importo + "€"}
                                                        />
                                                        <TableCell
                                                            content={nota.descrizione ? nota.descrizione : "Non presente"}
                                                        />
                                                        <TableCell
                                                            content={nota.fattura ? nota.fattura.numero_fattura : "Non presente"}
                                                        />
                                                        <TableCell
                                                            content={nota.tax ? nota.tax.nota : "Non presente"}
                                                        />
                                                        {/*Elimina*/}
                                                        <td
                                                            className="px-6 py-4  text-sm text-gray-500"
                                                            onClick={() => {
                                                                setShowModal(true)
                                                                setEliminazione(nota.id)
                                                            }}
                                                        >
                                                            <p className="text-my-red font-bold hover:text-red-700 cursor-pointer">Elimina</p>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default PrimaNotaPage;
