import React from 'react';
import HeaderSection from "../../../../components/HeaderSection";
import FormCard from "../../../../components/FormCard";
import MainLayout from "../../../../Layouts/MainLayout";
import {File, HelpCircle} from "lucide-react";


const CareersGrid = () => {
    return (
        <MainLayout>
            <section className={"bg-white p-20"}>
                <HeaderSection title={"Gestione Dati"}/>
                <div
                    className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 max-w-screen-lg mt-10 gap-4 ">

                    <FormCard icon={<File size={48}/>} title={"Careers"} link={"list"}/>
                    <FormCard icon={<HelpCircle size={48}/>} title={"Candidature"}
                              link={"candidature"}/>
                </div>
            </section>
        </MainLayout>
    );
};

export default CareersGrid;
