import React from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const MainLayout = ({children, margin="mt-0"}) => {
    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow " + margin}>
                {children}
            </div>
            <Footer/>
        </div>
    );
};

export default MainLayout;
