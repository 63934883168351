import React, {useEffect, useState} from 'react';
import Navbar from "../../../../../components/Navbar";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import Footer from "../../../../../components/Footer";
import {useParams} from "react-router-dom";
import {infoTask, modificaTask} from "../../../../../fetch/taskFetch";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";

const ModificaTask = () => {

    const {id} = useParams()

    const [alert, setAlert] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('costo', currentTask.costo);
        postData.append('data', currentTask.data);

        modificaTask(id, postData).then(
            () => {
                setAlert(1)
            }
        ).catch(
            () => {
                setAlert(-1)
            }
        )

    };

    const [currentTask, setCurrentTask] = useState({})


    useEffect(() => {
        infoTask(id).then(
            (res) => {
                setCurrentTask(res.data)
            }
        ).catch(
            (err) => {
                console.log(err)
            }
        )
    }, []);

    // const [selectedTask, setSelectedTask] = useState("")


    return (
        <div className={"flex flex-col h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"La Task è stato aggiunta con successo"}/>
                        ) : (
                            <div/>
                        )
                    }
                    <HeaderSectionAdd titolo={"Aggiungi Nuova Task"} link={"/aggiungi_nome_task"}
                                      label={"Task Predefinite"}/>
                    <div className={"flex flex-col lg:flex-row justify-evenly"}>
                        <div className={"flex flex-col lg:flex-row flex-1"}>
                            <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                                <div
                                    className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                    <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                        {/*Costo*/}
                                        <div className="col-span-2">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Costo
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        name={"costo"}
                                                        type={"number"}
                                                        step={"0.01"}
                                                        defaultValue={currentTask.costo}
                                                        onChange={(e) => {
                                                            setCurrentTask({...currentTask, costo: e.target.value})
                                                        }}
                                                        required
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                        placeholder="Costo task"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Data*/}
                                        <div className="col-span-2">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Data
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="date"
                                                        name={"data"}
                                                        defaultValue={currentTask.data}
                                                        onChange={(e) => {
                                                            setCurrentTask({...currentTask, data: e.target.value})
                                                        }}
                                                        required
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Bottone*/}
                                        <div className="col-span-2 text-center">
                                            <button
                                                type="submit"
                                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                            >
                                                Modifica
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </div>
            <Footer/>
        </div>

    );
};

export default ModificaTask;
