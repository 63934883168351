import React, {useState} from 'react';
import MainLayout from "../../../../../Layouts/MainLayout";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import BackButton from "../../../../../components/BackButton";
import HeaderSection from "../../../../../components/HeaderSection";
import {addAnagrafica} from "../../../../../fetch/fetchAnagrafica";
import {sleep} from "../../../../../constants/sleep";

const INITIAL_FORM_DATA = {
    nome: "",
    cognome: "",
    data_nascita: "",
    email_personale: "",
    email_aziendale: "",
    cellulare: "",
    fisso: "",
    telefono_aziendale: "",
    iban_1: "",
    iban_2: "",
    indirizzo_residenza: "",
    comune_residenza: "",
    provincia_residenza: "",
    cap_residenza: "",
    indirizzo_fatturazione: "",
    comune_fatturazione: "",
    provincia_fatturazione: "",
    cap_fatturazione: "",
    documento: "",
    tipo_documento: "",
    codice_fiscale: "",
    partita_iva: "",
    sdi: "",
    pec: "",
    paese: "",
    paese_id: "",
    vies: "",
    is_giuridica: false,
    is_condominio: false,
    is_ente_pubblico: false,
}

const AnagraficaForm = () => {
    const [alert, setAlert] = useState(0)
    const [tipoAnagrafica, setTipoAnagrafica] = useState("Persona Fisica")
    const [usaResidenza, setUsaResidenza] = useState(false)
    const [formData, setFormData] = useState(INITIAL_FORM_DATA)

    const tipi_anagrafica = [
        "Persona Fisica",
        "Persona Giuridica",
        "Condominio",
        "Ente Pubblico",
    ]

    const tipi_documento = [
        "Carta di Identità",
        "Passaporto",
        "Patente di Guida",
    ]

    const onChange = (e) => setFormData({...formData, [e.target.name]: e.target.value});

    const provinceItaliane = [
        "AG", "AL", "AN", "AO", "AP", "AQ", "AR", "AT", "AV", "BA",
        "BG", "BI", "BL", "BN", "BO", "BR", "BS", "BT", "BZ", "CA",
        "CB", "CE", "CH", "CI", "CL", "CN", "CO", "CR", "CS", "CT",
        "CZ", "EN", "FC", "FE", "FG", "FI", "FM", "FR", "GE", "GO",
        "GR", "IM", "IS", "KR", "LC", "LE", "LI", "LO", "LT", "LU",
        "MB", "MC", "ME", "MI", "MN", "MO", "MS", "MT", "NA", "NO",
        "NU", "OG", "OR", "OT", "PA", "PC", "PD", "PE", "PG", "PI",
        "PN", "PO", "PR", "PT", "PU", "PV", "PZ", "RA", "RC", "RE",
        "RG", "RI", "RM", "RN", "RO", "SA", "SI", "SO", "SP", "SR",
        "SS", "SV", "TA", "TE", "TN", "TO", "TP", "TR", "TS", "TV",
        "UD", "VA", "VB", "VC", "VE", "VI", "VR", "VS", "VT", "VV"
    ];

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = new FormData(e.target)

        if (data.get("documento") === "") {
            data.append("tipo_documento", "")
        }

        if (tipoAnagrafica === "Persona Giuridica") {
            data.set("is_giuridica", true)
        } else if (tipoAnagrafica === "Condominio") {
            data.set("is_condominio", true)
        } else if (tipoAnagrafica === "Ente Pubblico") {
            data.set("is_ente_pubblico", true)
        }

        if (usaResidenza) {
            data.set("indirizzo_fatturazione", data.get("indirizzo_residenza"))
            data.set("comune_fatturazione", data.get("comune_residenza"))
            data.set("provincia_fatturazione", data.get("provincia_residenza"))
            data.set("cap_fatturazione", data.get("cap_residenza"))
        }

        data.append("paese", "")
        data.append("paese_id", "")

        addAnagrafica(data).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    }

    const renderInputSection = (name, label, type = 'text', placeholder, isRequired = false, maxLength) => (
        <div className="col-span-1">
            <div className="flex flex-col lg:flex-col">
                <div className={isRequired ? "text-black" : "text-my-gray"}>
                    {label} {isRequired && <span className="text-red-500">*</span>}
                </div>
                <div className="relative">
                    <input
                        type={type}
                        name={name}
                        onChange={onChange}
                        maxLength={maxLength}
                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                        placeholder={placeholder}
                        required={isRequired}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert
                            name={"Errore"}
                            message={"I dati inseriti non sono validi"}
                        />
                    ) : alert === 1 ? (
                        <SuccessAlert
                            name={"Pubblicato"}
                            message={"I Dati Anagrafici sono stati aggiunti con successo"}
                        />
                    ) : null
                }

                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Dati Anagrafici"}/>
                <form className="flex flex-col space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div className="w-full p-5 mt-10 bg-my-white rounded-sm shadow-sm">
                        {/*Tipo Anagrafica*/}
                        <div className="col-span-3">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    Tipo Anagrafica
                                </div>
                                <div className="relative">
                                    <select
                                        name={"tipo_anagrafica"}
                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        onChange={(e) => setTipoAnagrafica(e.target.value)}
                                    >
                                        {
                                            tipi_anagrafica.map((tipo, index) => (
                                                <option key={index} value={tipo}>{tipo}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/*Dati Anagrafici*/}
                        <div className={"font-semibold text-3xl mt-4"}>
                            Dati Anagrafici
                        </div>
                        <div className={"grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4"}>
                            {renderInputSection("nome", "Nome", "text", "Nome", true)}
                            {renderInputSection("cognome", "Cognome", "text", "Cognome", tipoAnagrafica === "Persona Fisica")}
                            {renderInputSection("data_nascita", "Data di Nascita", "date", "", false)}
                            {renderInputSection("email_personale", "Email Personale", "email", "Email Personale", formData.cellulare === "")}
                            {renderInputSection("email_aziendale", "Email Aziendale ", "email", "Email Aziendale")}
                            {renderInputSection("cellulare", "Cellulare ", "text", "Cellulare", formData.email_personale === "", 10)}
                            {renderInputSection("fisso", "Fisso ", "text", "Fisso", false, 10)}
                            {renderInputSection("telefono_aziendale", "Telefono Aziendale ", "text", "Telefono Aziendale", false, 10)}
                            {renderInputSection("codice_fiscale", "Codice Fiscale ", "text", "Codice Fiscale", false, 16)}
                            {renderInputSection("documento", "Numero Documento ", "text", "Numero Documento")}
                            {
                                formData.documento !== "" ? (
                                    <div className="col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-black"}>
                                                Tipo Documento
                                            </div>
                                            <div className="relative">
                                                <select
                                                    name="tipo_documento"
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    onChange={onChange}
                                                >
                                                    {
                                                        tipi_documento.map((tipo, index) => (
                                                            <option key={index} value={tipo}>{tipo}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>

                        {/*Dati Residenza*/}
                        <div className={"font-semibold text-3xl mt-4"}>
                            Dati Bancari
                        </div>
                        <div className={"grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4"}>
                            {renderInputSection("iban_1", "Iban ", "text", "Iban", false)}
                            {renderInputSection("iban_2", "Iban Secondario ", "text", "Iban Secondario", false)}
                            {renderInputSection("partita_iva", "Partita Iva ", "text", "Partita Iva", false)}
                            {renderInputSection("pec", "Pec ", "email", "Pec", false)}
                            {renderInputSection("sdi", "SDI ", "text", "SDI", false)}
                            {renderInputSection("vies", "VIES ", "text", "VIES", false)}
                        </div>

                        {/*Dati Residenza*/}
                        <div className={"flex flex-col justify-between font-semibold text-3xl mt-4 mb-2"}>
                            <div className={"text"}>
                                Indirizzi
                            </div>
                            <div className={"flex items-center space-x-4"}>
                                <input type="checkbox" onChange={() => setUsaResidenza(!usaResidenza)}/>
                                <div className={"text-base"}>
                                    Utilizza Residenza come Fatturazione
                                </div>
                            </div>
                        </div>
                        <div className={"grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4"}>
                            {renderInputSection("indirizzo_residenza", "Indirizzo Residenza ", "text", "Indirizzo Residenza", false)}
                            {renderInputSection("comune_residenza", "Comune Residenza ", "text", "Comune Residenza", false)}
                            {renderInputSection("cap_residenza", "Cap Residenza ", "text", "Cap Residenza", false)}
                            <div className="col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Provincia Residenza
                                    </div>
                                    <div className="relative">
                                        <select
                                            name="provincia_residenza"
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            onChange={onChange}
                                        >
                                            <option value="">Seleziona una provincia</option>
                                            {
                                                provinceItaliane.map((tipo, index) => (
                                                    <option key={index} value={tipo}>{tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {
                                !usaResidenza ? (
                                    <>
                                        {renderInputSection("indirizzo_fatturazione", "Indirizzo Fatturazione ", "text", "Indirizzo Fatturazione", false)}
                                        {renderInputSection("comune_fatturazione", "Comune Fatturazione ", "text", "Comune Fatturazione", false)}
                                        {renderInputSection("cap_fatturazione", "Cap Fatturazione ", "text", "Cap Fatturazione", false)}
                                        <div className="col-span-1">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Provincia Fatturazione
                                                </div>
                                                <div className="relative">
                                                    <select
                                                        name="provincia_fatturazione"
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                        onChange={onChange}
                                                    >
                                                        <option value="">Seleziona una provincia</option>
                                                        {
                                                            provinceItaliane.map((tipo, index) => (
                                                                <option key={index} value={tipo}>{tipo}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null
                            }
                        </div>


                        {/*Bottone*/}
                        <div className="col-span-2 text-center mt-6">
                            <button
                                type="submit"
                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                            >
                                Registra
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    )
};

export default AnagraficaForm;
