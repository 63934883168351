import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const getNotaSpese = async () => {
    const options = {
        url: `${proxyUrl}/nota_spese/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getSpeseNotaSpese = async (id) => {
    const options = {
        url: `${proxyUrl}/nota_spese/spese/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getNotaSpeseInfo = async (id) => {
    const options = {
        url: `${proxyUrl}/nota_spese/modifica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const getCategorie = async () => {
    const options = {
        url: `${proxyUrl}/nota_spese/categorie`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getSingolaNotaSpese = async (id) => {
    const options = {
        url: `${proxyUrl}/nota_spese/modifica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const rimborsaSpesa = async (formData) => {
    const options = {
        url: `${proxyUrl}/nota_spese/rimborso/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const eliminaRimborsoNotaSpese = async (id) => {
    const options = {
        url: `${proxyUrl}/nota_spese/rimborso/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getResoconto = async (id) => {
    const options = {
        url: `${proxyUrl}/nota_spese/resoconto/${id}/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const modificaNotaSpese = async (id, formData) => {
    const options = {
        url: `${proxyUrl}/nota_spese/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const getAllegatiNotaSpese = async (id) => {
    const options = {
        url: `${proxyUrl}/nota_spese/allegati/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const creaNotaSpese = async (formData) => {
    const options = {
        url: `${proxyUrl}/nota_spese/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const creaSpesaNotaSpese = async (formData) => {
    const options = {
        url: `${proxyUrl}/nota_spese/spese/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


export const aggiungiAllegatoNotaSpese = async (formData) => {
    const options = {
        url: `${proxyUrl}/nota_spese/allegati/crea`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


export const eliminaSpesaNotaSpese = async (id) => {
    const options = {
        url: `${proxyUrl}/nota_spese/spese/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const eliminaNotaSpese = async (id) => {
    const options = {
        url: `${proxyUrl}/nota_spese/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}


export const getSingolaSpesa = async (id) => {
    const options = {
        url: `${proxyUrl}/nota_spese/spese/modifica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const modificaSpesa = async (id, formData) => {
    const options = {
        url: `${proxyUrl}/nota_spese/spese/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
