import React, {useEffect, useState} from 'react';
import {sleep} from "../../../../../../constants/sleep";
import DangerAlert from "../../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../../components/SuccessAlert";
import HeaderSection from "../../../../../../components/HeaderSection";
import {aggiungiAllegatoNotaSpese, creaSpesaNotaSpese, getCategorie} from "../../../../../../fetch/fetchNotaSpese";
import {useParams} from "react-router-dom";
import BackButton from "../../../../../../components/BackButton";
import MainLayout from "../../../../../../Layouts/MainLayout";

const AggiungiSpesa = () => {

    const {id} = useParams();

    const [alert, setAlert] = useState(0)

    const [categoria, setCategoria] = useState([])
    const [listaAllegati, setListaAllegati] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('nota_spese', id);
        postData.append('categoria', e.target.categoria.value);
        // postData.append('anagrafica', selectedAnagrafica.id);
        // postData.append('societa', e.target.societa.value);
        postData.append('data', e.target.data.value);
        postData.append('costo_sostenuto', e.target.costo_sostenuto.value);
        // postData.append('costo_rimborsabile', e.target.costo_rimborsabile.value);
        postData.append('descrizione', e.target.descrizione.value);

        creaSpesaNotaSpese(postData).then(
            r => {
                e.target.reset()
                const last_id = r.data.id
                if (listaAllegati.length > 0) {
                    for (let i = 0; i < listaAllegati.length; i++) {
                        const formData = new FormData()
                        formData.append('titolo', listaAllegati[i].titolo)
                        formData.append("documento", listaAllegati[i].file, listaAllegati[i].nome)
                        formData.append("spesa_nota_spese", last_id)

                        aggiungiAllegatoNotaSpese(formData).then(() => {
                            setAlert(1)
                            setListaAllegati([])
                        }).catch(() => {
                            setAlert(-1)
                        }).finally(async () => {
                            window.scroll(0, 0)
                            await sleep(3000)
                            setAlert(0)
                        })
                    }
                }
            }
        ).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    const addAllegato = (e) => {
        e.preventDefault();

        const file = e.target.allegato.files[0];

        // Break down filename into base name and extension
        const basename = file.name.split('.').slice(0, -1).join('.');
        const extension = file.name.split('.').pop();

        // Assemble filename with timestamp before the extension
        const timestampedFilename = `${basename}-${new Date().getTime()}.${extension}`;

        setListaAllegati(old => [
            ...old,
            {
                file: file,
                nome: timestampedFilename,
                titolo: e.target.titolo.value,
            },
        ]);
    };

    const rimuoviAllegato = (e, nome) => {
        e.preventDefault()
        setListaAllegati(listaAllegati.filter(allegato => allegato.nome !== nome))
    }


    useEffect(() => {
        getCategorie().then(
            r => setCategoria(r.data)
        ).catch(
            e => console.log(e)
        )
    }, [])

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"La Nota Spese è stato aggiunta con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Spesa"}/>
                <div className={"flex flex-col lg:flex-row justify-evenly"}>
                    <div className={"flex flex-col lg:flex-row"}>
                        <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                            <div
                                className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                    {/*Categoria*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Categoria Nota Spese
                                            </div>
                                            <select
                                                name={"categoria"}
                                                defaultValue={categoria[0]}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            >
                                                {
                                                    categoria.map((tipo, index) => (
                                                        <option
                                                            key={index}
                                                            value={tipo.id}
                                                        >
                                                            {tipo.nome}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/*Data*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Data
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="date"
                                                    name={"data"}
                                                    required={true}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Costo Sostenuto*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Costo Sostenuto
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="number"
                                                    step={"0.01"}
                                                    name={"costo_sostenuto"}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    placeholder="Costo Sostenuto"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Descrizione*/}
                                    <div className="col-span-2">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Descrizione
                                            </div>
                                            <div className="relative">
                                            <textarea
                                                required={true}
                                                name={"descrizione"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                            </div>
                                        </div>
                                    </div>

                                    {/*Bottone*/}
                                    <div className="col-span-2 text-center">
                                        <button
                                            type="submit"
                                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                        >
                                            {
                                                listaAllegati.length === 0 ? "Aggiungi Senza Allegati" : "Aggiungi Con Allegati"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div>
                        <div className={"flex flex-col lg:flex-row"}>
                            <div className="flex container space-x-3 pb-10">
                                <div
                                    className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                    <form className="grid max-w-xl grid-cols-1 gap-4 m-auto" onSubmit={addAllegato}>
                                        {/*Titolo*/}
                                        <div className="col-span-2">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Titolo
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="text"
                                                        name={"titolo"}
                                                        maxLength={100}
                                                        placeholder="Titolo"
                                                        required={true}
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Allegato*/}
                                        <div className="col-span-2 lg:col-span-1">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Aggiungi Allegato
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="file"
                                                        name={"allegato"}
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Bottone*/}
                                        <div className="col-span-2 text-center">
                                            <button
                                                type={"submit"}
                                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                            >
                                                Aggiungi
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div>
                            <p className={"font-semibold italic"}>Lista Allegati:</p>

                            {
                                listaAllegati.length === 0 ? (
                                    <p>Non ci sono allegati</p>
                                ) : (

                                    listaAllegati.map((all, i) => (
                                        <div key={i} className={"flex justify-between"}>
                                            <p>
                                                {all.nome}
                                            </p>
                                            <p
                                                onClick={e => rimuoviAllegato(e, all.nome)}
                                                className={"font-semibold text-my-red hover:text-red-600 cursor-pointer"}>
                                                X
                                            </p>
                                        </div>
                                    ))

                                )
                            }

                        </div>
                    </div>

                </div>

            </div>
        </MainLayout>
    );
};

export default AggiungiSpesa;
