import React from 'react';
import {useNavigate} from 'react-router-dom';

const BackButton = () => {

    const router = useNavigate()

    return (

        <div className={"hidden sm:flex items-center text-white"}>
            <button onClick={() => router(-1)} className={"flex items-center bg-primary-blue px-2 py-1 rounded"}>
                <svg className={"w-6 h-6 mr-2"} fill={"none"} stroke={"currentColor"} viewBox={"0 0 24 24"}
                     xmlns={"http://www.w3.org/2000/svg"}>
                    <path strokeLinecap={"round"} strokeLinejoin={"round"} strokeWidth={2}
                          d={"M10 19l-7-7m0 0l7-7m-7 7h18"}>
                    </path>
                </svg>
                <span className={"font-semibold"}>Indietro</span>
            </button>
        </div>
    );
};

export default BackButton;
