import React, {useState} from 'react';
import {aggiungiAllegatoNotaSpese} from "../../../../../../fetch/fetchNotaSpese";
import {sleep} from "../../../../../../constants/sleep";
import DangerAlert from "../../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../../components/SuccessAlert";
import HeaderSection from "../../../../../../components/HeaderSection";
import {useParams} from "react-router-dom";
import BackButton from "../../../../../../components/BackButton";
import MainLayout from "../../../../../../Layouts/MainLayout";

const AggiungiAllegatiNotaSpese = () => {

    const {id} = useParams()

    const [alert, setAlert] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('spesa_nota_spese', id);
        postData.append('titolo', e.target.titolo.value);
        postData.append('documento', e.target.documento.files[0], e.target.documento.files[0].name);

        aggiungiAllegatoNotaSpese(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"La Nota Spese è stato aggiunta con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Allegato Nota Spese"}/>
                <div className={"flex flex-col lg:flex-row"}>
                    <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                        <div
                            className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                            <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                {/*Titolo*/}
                                <div className="col-span-2">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Titolo
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name="titolo"
                                                maxLength={100}
                                                placeholder="Titolo"
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Documento*/}
                                <div className="col-span-2">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Documento
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="file"
                                                name={"documento"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>


            </div>
        </MainLayout>
    );
};

export default AggiungiAllegatiNotaSpese;
