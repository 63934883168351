import React from 'react';
import {useParams} from "react-router-dom";
import HeaderSection from "../../../../../components/HeaderSection";
import BackButton from "../../../../../components/BackButton";
import MainLayout from "../../../../../Layouts/MainLayout";
import {useQuery} from "react-query";
import {getInfoAnagrafica} from "../../../../../fetch/fetchAnagrafica";
import {fromListToDict} from "../../../../../utils/string-formatter";

const InfoAnagrafica = () => {

    const {id} = useParams()

    const {
        data: anagrafica = {
            nome: "",
            cognome: "",
            data_nascita: "",
            email_personale: "",
            email_aziendale: "",
            cellulare: "",
            fisso: "",
            telefono_aziendale: "",
            iban_1: "",
            iban_2: "",
            indirizzo_residenza: "",
            comune_residenza: "",
            provincia_residenza: "",
            cap_residenza: "",
            indirizzo_fatturazione: "",
            comune_fatturazione: "",
            provincia_fatturazione: "",
            cap_fatturazione: "",
            documento: "",
            tipo_documento: "",
            codice_fiscale: "",
            partita_iva: "",
            sdi: "",
            pec: "",
            paese: "",
            paese_id: "",
            vies: "",
            is_giuridica: false,
            is_condominio: false,
            is_ente_pubblico: false,
        }
    } = useQuery("anagrafica" + id, () => getInfoAnagrafica(id).then(
        r => r.data
    ))

    const listaCampi = [
        "custom_id",
        "nome",
        "cognome",
        "data_nascita",
        "email_personale",
        "email_aziendale",
        "cellulare",
        "fisso",
        "telefono_aziendale",
        "iban_1",
        "iban_2",
        "indirizzo_residenza",
        "comune_residenza",
        "provincia_residenza",
        "cap_residenza",
        "indirizzo_fatturazione",
        "comune_fatturazione",
        "provincia_fatturazione",
        "cap_fatturazione",
        "documento",
        "tipo_documento",
        "codice_fiscale",
        "partita_iva",
        "sdi",
        "pec",
        "paese",
        "paese_id",
        "vies",
        "is_giuridica",
        "is_condominio",
        "is_ente_pubblico"
    ]

    let listaCampiObjects = fromListToDict(listaCampi)

    const DataContainer = ({ label, data }) => (
        <div className="bg-white rounded p-2">
            {
                typeof data === "boolean" ? (
                    <div>
                        <span className="font-semibold">{label}:</span> {data ? "Si" : "No"}
                    </div>
                ) : (
                    <div>
                        <span className="font-semibold">{label}:</span> {data ? data : <span className={"text-gray-500"}>Non Presente</span>}
                    </div>
                )
            }
        </div>
    );


    return (
        <MainLayout>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <div className={"flex flex-col w-full items-center justify-center"}>
                    <HeaderSection title={"Anagrafica"}/>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 w-11/12 bg-my-white p-4 rounded my-8">
                        {
                            listaCampiObjects.map((campo, index) => (
                                <DataContainer key={index} label={campo.label} data={anagrafica[campo.value]} />
                            ))
                        }
                    </div>
                </div>


            </div>
        </MainLayout>
    );
};

export default InfoAnagrafica;
