import React, {useEffect, useState} from 'react';
import Navbar from "../../../../components/Navbar";
import Footer from "../../../../components/Footer";
import {eliminaPreventivo, getPreventivi} from "../../../../fetch/fetchPreventivo";
import FiltroSocieta from "../../../../components/FiltroSocieta";
import {useSelector} from "react-redux";
import HeaderSectionAdd from "../../../../components/HeaderSectionAdd";
import BackButton from "../../../../components/BackButton";
import {Link} from "react-router-dom";

const PreventiviPage = () => {
    const [filter, setFilter] = useState("")

    const [preventivi, setPreventivi] = useState([])


    const {filtroSoc} = useSelector(state => state.societa)

    const [showModal, setShowModal] = useState(false)
    const [eliminazione, setEliminazione] = useState(-1)

    const delPreventivo = (e, id) => {
        e.preventDefault()
        eliminaPreventivo(id).then(() => {
            getSupporto()
        }).catch(e => {
            console.log(e)
        })
    }

    const getSupporto = () => {
        getPreventivi().then(r => {
            setPreventivi(r.data)
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        getSupporto()
    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Eliminazione
                                    </h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        Sicuro di voler eliminare?
                                    </p>
                                </div>
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-my-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Annulla
                                    </button>
                                    <button
                                        className="bg-my-red text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(e) => {
                                            delPreventivo(e, eliminazione)
                                            setShowModal(false)
                                        }}
                                    >
                                        Elimina
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <Navbar/>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSectionAdd titolo={"Offerte"} link={"/aggiungi_preventivo"} esporta={"preventivi"}/>
                <FiltroSocieta/>
                <div className={"my-10 flex justify-center items-center"}>
                    <div className="flex flex-col w-11/12">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={"Cerca..."}
                                            className={"w-full py-2 px-4 outline-none border-none"}
                                            onChange={event => setFilter(event.target.value)}
                                        />
                                    </div>
                                    <div className={"max-h-96 overflow-auto"}>
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50 sticky top-0 z-10">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Descrizione
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Società
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Cliente
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Stato Offerta
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Preventivo
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Seriale
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Modifica
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Elimina
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                preventivi.filter(preventivo => {
                                                    if (filter === "") {
                                                        return preventivo
                                                    } else if (
                                                        preventivo.descrizione.toLowerCase().includes(filter.toLowerCase()) ||
                                                        preventivo.societa.nome.toLowerCase().includes(filter.toLowerCase()) ||
                                                        preventivo.cliente.anagrafica.nome.toLowerCase().includes(filter.toLowerCase()) ||
                                                        preventivo.cliente.anagrafica.cognome.toLowerCase().includes(filter.toLowerCase()) ||
                                                        preventivo.is_accettato.toString().toLowerCase().includes(filter.toLowerCase()) ||
                                                        preventivo.seriale_interno.toString().toLowerCase().includes(filter.toLowerCase())

                                                    ) {
                                                        return preventivo
                                                    }
                                                }).filter(preventivo => {
                                                    if (filtroSoc === "") {
                                                        return preventivo
                                                    }
                                                    if (filtroSoc === preventivo.societa.nome) {
                                                        return preventivo
                                                    }
                                                }).map((preventivo, i) => (
                                                    <tr key={i}>
                                                        {/*Nome*/}
                                                        <td className="px-6 py-4 flex w-80 overflow-auto">
                                                            <div
                                                                className="text-sm text-gray-900">{preventivo.descrizione}</div>
                                                        </td>
                                                        {/*Società*/}
                                                        <td className="px-6 py-4 ">
                                                            <div
                                                                className="text-sm text-gray-900">{preventivo.societa.nome}</div>
                                                        </td>
                                                        {/*Cliente*/}
                                                        <td className="px-6 py-4 ">
                                                            <div
                                                                className="text-sm text-gray-900">{preventivo.cliente.anagrafica.nome} {preventivo.cliente.anagrafica.cognome}</div>
                                                        </td>
                                                        {/*Accettato*/}
                                                        <td className="px-6 py-4  text-center">
                                                            {
                                                                preventivo.is_accettato === "Accettato" ? (
                                                                    <div
                                                                        className={"bg-my-green text-white px-1 rounded"}
                                                                    >
                                                                        Accettato
                                                                    </div>
                                                                ) : preventivo.is_accettato === "In Compilazione" ? (
                                                                    <div
                                                                        className={"bg-primary-blue text-white px-1 rounded"}
                                                                    >
                                                                        In Compilazione
                                                                    </div>
                                                                ) : preventivo.is_accettato === "In Attesa Cliente" ? (
                                                                    <div
                                                                        className={"bg-yellow-300 text-white px-1 rounded"}
                                                                    >
                                                                        In Attesa Cliente
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={"bg-my-red text-white px-1 rounded"}
                                                                    >
                                                                        Rifiutato
                                                                    </div>
                                                                )
                                                            }
                                                        </td>
                                                        {/*Preventivo*/}
                                                        <td className="px-6 py-4  text-sm text-gray-500">
                                                            {
                                                                preventivo.is_accettato === "In Compilazione" ? (
                                                                    <div
                                                                        className={"text-gray-300 font-bold"}>
                                                                        Documento Assente
                                                                    </div>
                                                                ) : (
                                                                    <a href={preventivo.copia_preventivo}
                                                                       target={"_blank"}
                                                                       className={"text-my-green font-bold hover:text-green-700 cursor-pointer"}>
                                                                        Visualizza
                                                                    </a>
                                                                )
                                                            }

                                                        </td>

                                                        {/*Seriale*/}
                                                        <td className="px-6 py-4 ">
                                                            <div
                                                                className="text-sm text-gray-900">{preventivo.seriale_interno}</div>
                                                        </td>
                                                        {/*Modifica*/}
                                                        <td
                                                            className="px-6 py-4  text-sm text-gray-500"
                                                        >
                                                            <Link to={"" + preventivo.id}
                                                                  className="text-my-green font-bold hover:text-green-700 cursor-pointer">Modifica</Link>
                                                        </td>
                                                        {/*Info*/}
                                                        <td
                                                            className="px-6 py-4  text-sm text-gray-500"
                                                            onClick={() => {
                                                                setShowModal(true)
                                                                setEliminazione(preventivo.id)
                                                            }}
                                                        >
                                                            <p className="text-my-red font-bold hover:text-red-700 cursor-pointer">Elimina</p>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default PreventiviPage;
