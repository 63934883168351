import React from 'react';
import BackButton from "../../../components/BackButton";
import HeaderSection from "../../../components/HeaderSection";
import MainLayout from "../../../Layouts/MainLayout";

const LayoutLog = ({children, titolo}) => {
    return (
        <MainLayout margin={"mt-10"}>
            <div className={"flex px-20 py-2"}>
                <BackButton/>
            </div>
            <HeaderSection title={titolo}/>
            <div className={"my-10"}>
                {children}
            </div>
        </MainLayout>
    );
};

export default LayoutLog;
