import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Navbar from "../../../../../components/Navbar";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import Footer from "../../../../../components/Footer";
import {listaFotoPerTask} from "../../../../../fetch/taskFetch";
import {formatDateAndTime} from "../../../../../utils/string-formatter";

const FotoTask = () => {
    const {id} = useParams()

    const [foto, setFoto] = useState([])


    useEffect(() => {
        listaFotoPerTask(id).then(
            r => setFoto(r.data)
        ).catch(
            e => console.log(e)
        )

    }, [])


    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"flex-grow mt-10"}>
                    <HeaderSectionAdd titolo={"Foto Task"} link={`/aggiungi_foto_task/${id}`}/>
                    <div className={"my-10"}>
                        <div className="flex flex-col container">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className={"text-3xl font-semibold mb-4"}>Foto Relative alla Task</div>
                                    <div className={"space-y-4 flex flex-col"}>

                                        {
                                            foto.length === 0 ? "Non ci sono foto per questa task" : (
                                                foto.map((c, i) => (
                                                    <div key={i}
                                                         className={"flex flex-col border p-2 rounded"}>
                                                        <div className={"flex items-center justify-between"}>
                                                            <div className={"text-xl font-semibold"}>
                                                                {c.owner.username}
                                                            </div>
                                                            <div>
                                                                {formatDateAndTime(c.data)}
                                                            </div>
                                                        </div>

                                                        <a href={c.foto} target={"_blank"} rel={"noreferrer"} className={"bg-primary-blue text-center text-white py-2 rounded cursor-pointer mt-4"}>
                                                            Visualizza Foto
                                                        </a>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default FotoTask;
