import React, {useRef, useState} from 'react';
import {Mail, Map, Phone} from "lucide-react";
import emailjs from '@emailjs/browser';


const ContactBanner = () => {

    const sedi = [
        {
            id: 1,
            sede: "Milano",
            phone: "+39 02 87165 781",
            address: "via romana 3",
            email: "info@forwardeng.it",
            coord: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d75169.29094572995!2d9.121195368975972!3d45.464761168145024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c1493f1275e7%3A0x3cffcd13c6740e8d!2sMilan%2C%20Metropolitan%20City%20of%20Milan!5e0!3m2!1sen!2sit!4v1679429909360!5m2!1sen!2sit"
        },
        {
            id: 2,
            sede: "San Felice Circeo",
            phone: "+39 0773 879362",
            address: "via romana 3",
            email: "info@forwardeng.it",
            coord: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47997.87948050303!2d13.066151370480526!3d41.24644474484289!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13251eb33db4b199%3A0x964f4f6685e5db4c!2s04017%20San%20Felice%20Circeo%2C%20Province%20of%20Latina!5e0!3m2!1sen!2sit!4v1679429959321!5m2!1sen!2sit"

        },
        {
            id: 3,
            sede: "Bari",
            phone: "+39 080 8406235",
            address: "via romana 3",
            email: "info@forwardeng.it",
            coord: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96193.9561608223!2d16.812040071557895!3d41.111355992111285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1347e8f8f3078d6f%3A0x87254066a3908e1!2sBari%2C%20Metropolitan%20City%20of%20Bari!5e0!3m2!1sen!2sit!4v1679429991013!5m2!1sen!2sit"
        },
    ]

    const [sede, setSede] = useState(sedi[0])

    const displaySedi = () => (
        sedi.map((sede, i) => (
            <h3
                key={i}
                className={"text-xl text-my-white hover:underline cursor-pointer"}
                onClick={() => setSede(sedi[i])}
            >
                {sede.sede}
            </h3>
        ))
    )

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_frw2z5g',
            'template_h3sp3l9',
            form.current, 'cQJs0LwISZ4HwLuP2'
        ).then((result) => {
                e.target.reset();
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            }
        );
    };


    return (
        <div className={"mb-10"}>
            <div className={"flex justify-center"}>
                <div className={"w-full h-128"}>
                    <iframe
                        title={"Mappa"}
                        className={"h-full w-full rounded-sm filter grayscale"}
                        src={sede.coord}
                    />
                </div>
            </div>
            <div className={"container"}>
                <div className={"bg-my-white h-full w-full flex flex-col mt-6 lg:flex-row over"}>
                    <div className={"flex flex-col bg-primary-blue rounded-tl-md rounded-bl-md text-white z-20"}>
                        <div className={"p-10"}>
                            <div className={"mb-2"}>
                                <h1 className={"text-2xl font-bold space-x-4 flex items-center"}>
                                    <Map/>
                                    <div>
                                        Sedi
                                    </div>
                                </h1>
                                {
                                    displaySedi()
                                }
                            </div>

                            <div className={"mt-10"}>
                                <h1 className={"text-2xl font-bold space-x-4 flex items-center"}>
                                    <Mail/>
                                    <div>
                                        Mail
                                    </div>
                                </h1>
                                <h3 className={"text-xl text-my-white"}>
                                    {sede.email}
                                </h3>
                            </div>

                            <div className={"mt-10"}>
                                <h1 className={"text-2xl font-bold space-x-4 flex items-center"}>
                                    <Phone/>
                                    <div>
                                        Telefono
                                    </div>
                                </h1>
                                <h3 className={"text-xl text-my-white"}>
                                    {sede.phone}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className={"flex flex-1 flex-col bg-my-white p-4 rounded-tr-md rounded-br-md relative"}>
                        <h1 className={"text-center text-4xl text-primary-blue font-bold"}>Contattaci</h1>
                        <form className="grid max-w-xl grid-cols-2 gap-4 m-auto" ref={form} onSubmit={sendEmail}>
                            {/*Nome*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className=" relative ">
                                    <input
                                        type="text"
                                        name={"nome"}
                                        className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        placeholder="Nome e Cognome"
                                    />
                                </div>
                            </div>
                            {/*Email*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className=" relative ">
                                    <input
                                        type="text"
                                        name={"email"}
                                        className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        placeholder="Email"/>
                                </div>
                            </div>
                            {/*Oggetto*/}
                            <div className="col-span-2">
                                <div className="relative">
                                    <input
                                        type="text"
                                        name={"oggetto"}
                                        className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        placeholder="Oggetto"
                                    />
                                </div>
                            </div>
                            {/*Messaggio*/}
                            <div className="col-span-2">
                                <textarea
                                    className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none"
                                    placeholder="Scrivi il tuo messaggio"
                                    name="messaggio"
                                    rows="5"
                                    cols="40"
                                />
                            </div>
                            {/*Bottone*/}
                            <div>
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Invia Mail
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactBanner;
