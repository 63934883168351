import React from 'react';

const SuccessAlert = ({name, message}) => {
    return (
        <div className={"flex items-center justify-center mb-6"}>
            <div className={"container px-0 "}>
                <div className={"bg-my-green text-white font-bold rounded-t px-4 py-2"}>
                    {name}
                </div>
                <div className="border border-t-0 border-my-green rounded-b bg-green-100 px-4 py-3 text-green-700">
                    <p>{message}</p>
                </div>
            </div>
        </div>

    );
};

export default SuccessAlert;
