import React from 'react';
import Navbar from "../../../../../components/Navbar";
import Footer from "../../../../../components/Footer";
import HeaderSection from "../../../../../components/HeaderSection";
import BackButton from "../../../../../components/BackButton";
import {addCategoriaRecurring} from "../../../../../fetch/fetchRecurring";

const AggiungiCategorieRecurring = () => {

    const handleSubmit = (event) => {
        event.preventDefault()
        const data = new FormData(event.target)
        addCategoriaRecurring(data).then(() => {
            event.target.reset()
        }).catch(
            e => {
                console.log(e)
            }
        )
    }

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Categorie Recurring"}/>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Anagrafica*/}
                            <div className="col-span-2 lg:col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Nome Categoria
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"nome"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Nome"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Aggiungi
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer/>
        </div>
    );
};

export default AggiungiCategorieRecurring;
