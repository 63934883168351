import React, {useState} from 'react';
import MainLayout from "../../../../../Layouts/MainLayout";
import BackButton from "../../../../../components/BackButton";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import {useQuery} from "react-query";
import {deleteMezzoPagamento, getMezziPagamento} from "../../../../../fetch/fetchMezzi";

const MezziPagamentoPage = () => {

    const [filter, setFilter] = useState("")

    const filterMezzi = (mezzo) => {
        return filter === "" || mezzo?.iban?.toLowerCase().includes(filter.toLowerCase());
    }

    const TABLE_CELLS = [
        {propName: 'intestatario'},
        {propName: 'istituto_credito'},
        {propName: 'referente'},
        {propName: 'titolare_carta'},
        {propName: 'iban'},
        {propName: 'data_apertura'},
        {propName: 'filiale'},
        {propName: 'tipo'},
        {propName: 'numero_carta'}
    ];

    const [mezzi, setMezzi] = useState()
    useQuery(
        "mezzi",
        () => getMezziPagamento().then(
            r => r.data
        ),
        {
            onSuccess: (data) => {
                setMezzi(data)
            }
        }
    )


    const deleteMezzo = (e, id) => {
        deleteMezzoPagamento(id).then(
            () => {
                setMezzi(mezzi.filter(m => m.id !== id))
            }
        ).catch(
            e => {
                console.log(e)
            }
        )
    }

    return (
        <MainLayout>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSectionAdd titolo={"Mezzi Pagamento"} link={"aggiungi"}
                                  esporta={"società"}/>
                <div className={"my-10 flex justify-center items-center"}>
                    <div className="flex flex-col w-11/12">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={"Cerca..."}
                                            className={"w-full py-2 px-4 outline-none border-none"}
                                            onChange={event => setFilter(event.target.value)}
                                        />
                                    </div>
                                    <div className={"max-h-96 overflow-auto"}>
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50 sticky top-0 z-10">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Intestatario
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Istituto Credito
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Referente
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Titolare Carta
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Iban
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Data Apertura
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Filiale
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Tipo
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Numero Carta
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Elimina
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                mezzi?.filter(filterMezzi).map((mezzo, i) => (
                                                    <tr key={i}>
                                                        {
                                                            TABLE_CELLS.map(({propName}, i) => (
                                                                <td className="px-6 py-4 text-sm text-gray-900" key={i}>
                                                                    <div>{mezzo?.[propName] ?? <span
                                                                        className={"text-gray-400"}>Non presente</span>}</div>
                                                                </td>
                                                            ))
                                                        }
                                                        <td className="px-6 py-4 text-sm text-gray-900">
                                                            <div
                                                                className={"text-my-red font-bold hover:text-red-700 cursor-pointer"}
                                                                onClick={e => deleteMezzo(e, mezzo.id)}
                                                            >
                                                                Elimina
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default MezziPagamentoPage;
