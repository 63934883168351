import React, {useEffect, useState} from 'react';
import {useQuery} from "react-query";
import {sleep} from "../../../../constants/sleep";
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import BackButton from "../../../../components/BackButton";
import HeaderSection from "../../../../components/HeaderSection";
import MainLayout from "../../../../Layouts/MainLayout";
import {getSocieta} from "../../../../fetch/fetchSocieta";
import {getLavori} from "../../../../fetch/fetchLavori";
import {getAnagrafiche} from "../../../../fetch/fetchAnagrafica";
import {MyCombobox} from "../../../../components/combobox/MyCombobox";
import {getDipendenti} from "../../../../fetch/fetchDipendenti";
import {creaProtocollo} from "../../../../fetch/fetchProtocolli";
import CommesseCombobox from "../../../../components/combobox/CommesseCombobox";
import {ComboboxCliente} from "../../../../components/combobox/ComboboxCliente";

const AggiungiProtocollo = () => {
    const [alert, setAlert] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        formData.append("anagrafica", selectedAnagrafica.id)

        if (selectedCommessa) {
            formData.append("commessa", selectedCommessa.id)
        }
        if (selectedDipendente) {
            formData.append("dipendente", selectedDipendente.id)
        }


        creaProtocollo(formData).then(() => {
            setAlert(1)
            sleep(3000).then(() =>
                setAlert(0)
            )
        }).catch(() => {
            setAlert(-1)
        })

    };


    const {data: societa = []} = useQuery(['societa'], () => getSocieta().then(
        (res) => res.data
    ))

    const {data: commesse = []} = useQuery(['commesse'], () => getLavori().then(
        (res) => res.data
    ))

    const {data: dipendenti = []} = useQuery(['dipendenti'], () => getDipendenti().then(
        (res) => res.data
    ))


    const [anagrafica, setAnagrafica] = useState([])
    const [selectedAnagrafica, setSelectedAnagrafica] = useState()
    const [selectedCommessa, setSelectedCommessa] = useState("")
    const [selectedDipendente, setSelectedDipendente] = useState()

    useEffect(() => {
        getAnagrafiche().then(r => {
            setAnagrafica(r.data)
            setSelectedAnagrafica(r.data[0])
        }).catch(e => {
            console.log(e)
        })
    }, [])


    return (
        <MainLayout>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"Il Protocollo è stato aggiunto con successo"}/>
                        ) : null
                    }
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi Nuovo Protocollo"}/>
                    <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                        <div
                            className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                            <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                {/*File*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                File
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="file"
                                                    name={"file"}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Flusso*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Flusso
                                        </div>
                                        <select
                                            name={"flusso"}
                                            defaultValue={"IN"}
                                            required
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            <option value={"IN"}>IN</option>
                                            <option value={"OUT"}>OUT</option>
                                        </select>
                                    </div>
                                </div>
                                {/*Descrizione*/}
                                <div className="col-span-2">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Descrizione
                                        </div>
                                        <div className="relative">
                                            <textarea
                                                name={"descrizione"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Descrizione"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Società*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Società
                                        </div>
                                        <select
                                            name={"societa"}
                                            defaultValue={societa[0]?.id}
                                            required
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                societa.map((prod, index) => (
                                                    <option
                                                        key={index}
                                                        value={prod.id}
                                                    >
                                                        {prod.nome}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*Anagrafica*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Anagrafica
                                        </div>
                                        <MyCombobox
                                            valueList={anagrafica}
                                            selectedItem={selectedAnagrafica}
                                            setSelectedItem={setSelectedAnagrafica}
                                        />
                                    </div>
                                </div>
                                {/*Commessa*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Commessa
                                        </div>
                                        <CommesseCombobox
                                            valueList={commesse}
                                            selectedItem={selectedCommessa}
                                            setSelectedItem={setSelectedCommessa}
                                        />
                                    </div>
                                </div>
                                {/*Dipendente*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Dipendente
                                        </div>
                                        <ComboboxCliente
                                            valueList={dipendenti}
                                            selectedItem={selectedDipendente}
                                            setSelectedItem={setSelectedDipendente}
                                        />
                                        {/*<select*/}
                                        {/*    name={"dipendente"}*/}
                                        {/*    className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"*/}
                                        {/*>*/}
                                        {/*    <option value={""}>Nessuno</option>*/}
                                        {/*    {*/}
                                        {/*        dipendenti.map((bolla, index) => (*/}
                                        {/*            <option*/}
                                        {/*                key={index}*/}
                                        {/*                value={bolla.id}*/}
                                        {/*            >*/}
                                        {/*                {bolla.anagrafica.nome + " " + bolla.anagrafica.cognome}*/}
                                        {/*            </option>*/}
                                        {/*        ))*/}
                                        {/*    }*/}
                                        {/*</select>*/}
                                    </div>
                                </div>
                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </MainLayout>
    )
}

export default AggiungiProtocollo;
