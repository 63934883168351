const formatLabel = (string) => {
    // Replacing underscores with a space and converting it to Title Case
    return string.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
}

export const fromListToDict = (list) => {
    return list.map(item => ({
        label: formatLabel(item),
        value: item
    }));
}


export function formatDateAndTime(timestamp) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed in JS
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}
