import React, {useState} from 'react';
import MainLayout from "../../Layouts/MainLayout";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import {addCandidatura, getCareersById} from "../../fetch/fetchCareers";
import DOMPurify from "dompurify";
import DangerAlert from "../../components/DangerAlert";
import SuccessAlert from "../../components/SuccessAlert";
import HeaderSection from "../../components/HeaderSection";
import {sleep} from "../../constants/sleep";

const CareersDetail = () => {

    const {id} = useParams()

    const [alert, setAlert] = useState(0)

    const [inputValue, setInputValue] = React.useState("");
    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);
        formData.append('posizione', data?.id)

        addCandidatura(formData).then(
            () => {
                setAlert(1)
                setInputValue("")
                e.target.reset()
            }
        ).catch(
            () => setAlert(-1)
        ).finally(async () => {
            window.scroll(0, 0)
            await sleep(5000)
            setAlert(0)
        })

    }
    const handleInputChange = event => {
        const {value} = event.target;
        const regex = new RegExp(/^[0-9\b]+$/); // Put your regex pattern here

        if (regex.test(value) || value === "") {
            setInputValue(value);
        }
    };


    const {data} = useQuery({
        queryKey: 'careers/' + id,
        queryFn: () => getCareersById(id).then(
            (res) => res.data
        )
    })

    return (
        <MainLayout>
            <div className={"container"}>
                <div className={"p-4 md:px-10 md:pt-12"}>
                    <div className={"text-xl md:text-3xl font-bold"}>
                        {data?.mansione}
                    </div>
                    <div>
                        {data?.luogo} - {data?.stato}
                    </div>
                </div>
                <div className={"p-4 md:px-10"}>
                    <div className={"text-lg md:text-2xl font-bold text-primary-blue"}>
                        Responsabilità
                    </div>
                    <div className="prose max-w-none">
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data?.responsabilita)}}/>
                    </div>
                    <div className={"text-lg md:text-2xl font-bold text-primary-blue"}>
                        Requisiti
                    </div>
                    <div className="prose max-w-none">
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data?.requisiti)}}/>
                    </div>
                    <div className={"flex items-center space-x-4"}>
                        <div className={"text-lg font-bold text-primary-blue"}>
                            Salario
                        </div>
                        <div>
                            {data?.salario}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className={""}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"C'è stato un errore, riprova più tardi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Inviato"}
                                          message={"Abbiamo ricevuto la tua richiesta, ti contatteremo al più presto"}/>
                        ) : (
                            <div/>
                        )
                    }
                </div>

                <div className={"w-full flex flex-col items-center justify-center"}>
                    <HeaderSection title={"Contattaci per " + data?.mansione}/>
                </div>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 rounded-lg shadow-sm border">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Nome*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Nome <span className={"text-red-500"}>*</span>
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"nome"}
                                            placeholder={"Nome"}
                                            required={true}
                                            className="rounded-lg  flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Cognome*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Cognome <span className={"text-red-500"}>*</span>
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"cognome"}
                                            placeholder={"Cognome"}
                                            required={true}
                                            className="rounded-lg  flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Email*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Email <span className={"text-red-500"}>*</span>
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"email"}
                                            placeholder={"Email"}
                                            required={true}
                                            className="rounded-lg  flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Telefono*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Telefono <span className={"text-red-500"}>*</span>
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"telefono"}
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            maxLength={10}
                                            placeholder={"Telefono"}
                                            required={true}
                                            className="rounded-lg  flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*Messaggio*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Messaggio <span className={"text-red-500"}>*</span>
                                    </div>
                                    <div className="relative">
                                    <textarea
                                        name={"messaggio"}
                                        placeholder={"Messaggio"}
                                        required={true}
                                        className="rounded-lg  flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                    />
                                    </div>
                                </div>
                            </div>

                            {/*Curriculum*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Curriculum <span className={"text-red-500"}>*</span>
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="file"
                                            name={"cv"}
                                            placeholder={"Curriculum"}
                                            required={true}
                                            className="rounded-lg  flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Cover Letter*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Cover Letter (opzionale)
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="file"
                                            name={"cover_letter"}
                                            placeholder={"Cover Letter"}
                                            className="rounded-lg  flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Invia
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </MainLayout>
    );
};

export default CareersDetail;
