import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import Navbar from "../../../../components/Navbar";
import BackButton from "../../../../components/BackButton";
import HeaderSection from "../../../../components/HeaderSection";
import Footer from "../../../../components/Footer";
import {TrashIcon} from "@heroicons/react/solid";
import AllegatoTaxModal from "./AllegatoTaxModal";
import {
    eliminaAllegatoTax,
    eliminaMovimentoTax,
    getInfoTax,
    getListaAllegatiTax,
    listaMovimentiTax
} from "../../../../fetch/fetchTax";
import {useQuery} from "react-query";

const InfoTax = () => {

    const {id} = useParams();

    const [tax, setTax] = React.useState(null);
    const [allegatiRecurring, setAllegatiRecurring] = React.useState([]);
    const [movimenti, setMovimenti] = React.useState([]);

    useQuery({
        queryKey: ["listaMovimentiTax", id],
        queryFn: () => listaMovimentiTax(id).then(
            r => {
                setMovimenti(r.data);
            }
        )
    })

    const handleDelete = (id) => {
        eliminaMovimentoTax(id).then(
            () => {
                setMovimenti(movimenti.filter((movimento) => movimento.id !== id));
            }
        ).catch(
            (error) => {
                console.log(error);
            }
        )
    }


    const getAllegati = () => {
        getListaAllegatiTax(id).then((response) => {
            setAllegatiRecurring(response.data);
        }).catch(
            (error) => {
                console.log(error);
            }
        )
    }
    const rimuoviAllegato = (id) => {
        eliminaAllegatoTax(id).then(() => {
            getAllegati();
        }).catch((error) => {
            console.log(error);
        })
    }

    const [open, setOpen] = useState(false)

    React.useEffect(() => {
        getInfoTax(id).then((response) => {
            setTax(response.data);
        }).catch((error) => {
            console.log(error);
        })

        getAllegati();

    }, [open]);

    return (
        <div className={"flex flex-col min-h-screen"}>

            <AllegatoTaxModal setOpen={setOpen} open={open} id={id}/>

            <Navbar/>
            <div className={"flex-grow mt-10 px-4"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Info Tax"}/>
                <div className={"w-full flex items-center justify-center"}>
                    <div className={"grid grid-cols-1 sm:grid-cols-3 gap-4 w-11/12 bg-my-white p-4 rounded my-8"}>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Data Emissione:</span> {tax?.data_ricezione}
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Data Scadenza:</span> {tax?.data_scadenza}
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Società:</span> {tax?.societa.nome}
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span
                                className={"font-semibold"}>Importo:</span> {tax?.importo}€
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Saldato:</span> {tax?.importo_saldato}€
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Rimanente:</span> {
                            tax?.importo - tax?.importo_saldato} €
                        </div>
                        <div>
                            {
                                tax?.incasso === "Saldata" ? (
                                    <div className={"bg-my-green p-2  rounded w-full"}>
                                        {tax?.incasso}
                                    </div>
                                ) : tax?.incasso === "Parziale" ? (
                                    <div
                                        className={"bg-yellow-400 p-2 text-black rounded w-full"}>
                                        {tax?.incasso}
                                    </div>
                                ) : (
                                    <div className={"bg-my-red p-2 rounded w-full"}>
                                        {tax?.incasso}
                                    </div>
                                )
                            }
                        </div>

                    </div>
                </div>

                {/*Allegati*/}
                <div className={"flex container items-center justify-evenly"}>
                    <div className={"text-3xl text-center text-black uppercase font-bold"}>
                        Allegati Tax
                    </div>
                    <div
                        onClick={() => setOpen(true)}
                        className={"flex bg-my-green px-4 py-2 rounded text-white font-semibold hover:bg-green-600 cursor-pointer"}>
                        Aggiungi
                    </div>
                </div>

                {
                    allegatiRecurring.length === 0 ? (
                        <div className={"flex justify-center items-center"}>
                            <div className={"text-gray-500 text-xl font-bold"}>Non ci sono allegati</div>
                        </div>
                    ) : (
                        <div className={"flex flex-col"}>
                            {
                                allegatiRecurring.map((allegato, index) => (
                                    <div className={"bg-gray-100 container my-4 rounded"} key={index}>
                                        <div className={"flex flex-col p-4"}>
                                            <div className={"flex flex-row justify-between items-center"}>
                                                <div className={"flex"}>
                                                    <div className={"font-bold text-gray-600"}>Allegato {index + 1}:
                                                    </div>
                                                    <a href={allegato.allegato} target={"_blank"}
                                                       className={"ml-2 "}>Visualizza</a>
                                                </div>
                                                <TrashIcon className={"h-6 text-red-500 cursor-pointer"}
                                                           onClick={() => rimuoviAllegato(allegato.id)}/>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }


                {/*Movimenti*/}
                <div className={"flex container items-center justify-evenly"}>
                    <div className={"text-3xl text-center text-black uppercase font-bold"}>
                        Movimenti Tax
                    </div>
                    <Link
                        to={`movimenti`}
                        className={"flex bg-my-green px-4 py-2 rounded text-white font-semibold hover:bg-green-600 cursor-pointer"}>
                        Aggiungi
                    </Link>
                </div>
                <div className={"mb-10"}>
                    {
                        movimenti?.length === 0 ? (
                            <div className={"flex justify-center items-center"}>
                                <div className={"text-gray-500 text-xl font-bold"}>Non ci sono movimenti</div>
                            </div>
                        ) : (
                            <div className={"flex flex-col w-full items-center justify-center gap-8 my-8"}>
                                {
                                    movimenti.map((movimento, i) => (
                                        <div className={"p-4 bg-my-white rounded w-full"} key={i}>
                                            <div className="flex flex-col">
                                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                    <div
                                                        className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                        <div
                                                            className={"flex flex-col sm:flex-row justify-between items-center"}>
                                                            <div className={"font-bold text-3xl"}>
                                                                Movimento n° {i + 1}
                                                            </div>
                                                            <div
                                                                className={"grid grid-cols-2 sm:grid-cols-4 gap-4 text-center"}>
                                                                <div
                                                                    onClick={() => handleDelete(movimento.id)}
                                                                    className={"bg-my-red font-semibold text-white border-my-red border-2 px-4 p-2 rounded cursor-pointer hover:bg-my-white hover:text-my-red "}>
                                                                    Elimina
                                                                </div>
                                                                <Link to={`/movimenti/modifica/${movimento.id}`}
                                                                      className={"bg-yellow-500 font-semibold text-white border-yellow-500 border-2 px-4 p-2 rounded cursor-pointer hover:bg-my-white hover:text-yellow-500 "}>
                                                                    Modifica
                                                                </Link>
                                                            </div>

                                                        </div>
                                                        <div className={"my-4"}>
                                                            <p className={"font-semibold"}>Descrizione: </p>
                                                            <p className={"italic"}>
                                                                {movimento.descrizione}
                                                            </p>
                                                        </div>
                                                        <div className={"flex flex-col sm:flex-row justify-evenly"}>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Importo: </p>
                                                                <p className={"italic"}>
                                                                    {movimento.importo} €
                                                                </p>
                                                            </div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Data: </p>
                                                                <p className={"italic"}>
                                                                    {movimento.data}
                                                                </p>
                                                            </div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Metodo
                                                                    Pagamento: </p>
                                                                <p className={"italic"}>
                                                                    {movimento.metodo_pagamento}
                                                                </p>
                                                            </div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Allegato</p>
                                                                <a className={"italic"} href={movimento.allegato}
                                                                   target={"_blank"} rel={""}>
                                                                    {movimento.allegato ? "Apri" : "Nessun Allegato"}
                                                                </a>
                                                            </div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Ente</p>
                                                                <p className={"italic"}>
                                                                    {movimento.ente ? movimento.ente : "Nessun Ente"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default InfoTax;
