import NotFoundPage from "../pages/NotFoundPage";
import HomePage from "../pages/Home/HomePage";
import LoginPage from "../pages/Home/LoginPage";
import AboutPage from "../pages/About/AboutPage";
import ProjectsPage from "../pages/Gestionale/CMS/Progetti/ProjectsPage";
import Careers from "../pages/Careers/Careers";
import BlogPage from "../pages/Gestionale/CMS/Post/BlogPage";
import PostPage from "../pages/Gestionale/CMS/Post/PostPage";
import SingleProjectPage from "../pages/Gestionale/CMS/Progetti/SingleProjectPage";
import FinanzaPage from "../pages/Servizi/FinanzaPage";
import IngegneriaPage from "../pages/Servizi/IngegneriaPage";
import ElettrotecnicaPage from "../pages/Servizi/ElettrotecnicaPage";
import LetteraPage from "../pages/LetteraPage";
import TestPage from "../pages/TestPage";
import CareersDetail from "../pages/Careers/CareersDetail";

export const publicPageRoutes = [
    {path: "*", element: <NotFoundPage/>},
    {path: "/", element: <HomePage/>},
    {path: "/login", element: <LoginPage/>},
    {path: "/about", element: <AboutPage/>},
    {path: "/projects", element: <ProjectsPage/>},
    {path: "/careers", element: <Careers/>},
    {path: "/careers/:id", element: <CareersDetail/>},
    {path: "/blog", element: <BlogPage/>},
    {path: "/blog/:id", element: <PostPage/>},
    {path: "/project/:id", element: <SingleProjectPage/>},
    {path: "/finanza", element: <FinanzaPage/>},
    {path: "/ingegneria", element: <IngegneriaPage/>},
    {path: "/elettrotecnica", element: <ElettrotecnicaPage/>},
    {path: "/lettera", element: <LetteraPage/>},
    {path: "/test", element: <TestPage/>},
];
