import axios from "axios";
import {proxyUrl} from "../constants/proxyUrl";

export const addAnagrafica = async (formData) => {
    const options = {
        url: `${proxyUrl}/anagrafica/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const getAnagrafiche = async () => {
    const options = {
        url: `${proxyUrl}/anagrafica`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getAnagrafica = async (id) => {
    const options = {
        url: `${proxyUrl}/anagrafica/modifica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getInfoAnagrafica = async (id) => {
    const options = {
        url: `${proxyUrl}/anagrafica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const eliminaAnagrafica = async (id) => {
    const options = {
        url: `${proxyUrl}/anagrafica/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const modificaAnagrafica = async (id, anagrafica) => {
    const options = {
        url: `${proxyUrl}/anagrafica/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: anagrafica
    };

    return axios(options)
}
