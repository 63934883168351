import React from 'react';
import BackButton from "../../../../../components/BackButton";
import HeaderSection from "../../../../../components/HeaderSection";
import FormCard from "../../../../../components/FormCard";
import MainLayout from "../../../../../Layouts/MainLayout";
import {useQuery} from "react-query";
import {getSediMagazzino} from "../../../../../fetch/fetchMagazzino";
import {Warehouse} from "lucide-react";

const GrigliaMagazzini = () => {

    const [magazzini, setMagazzini] = React.useState([]);

    useQuery({
        queryKey: "magazzini",
        queryFn: getSediMagazzino,
        onSuccess: (data) => {
            setMagazzini(data.data)
        }
    })

    return (
        <MainLayout>
            <section className={"bg-white p-20"}>
                <BackButton/>
                <HeaderSection title={"Magazzini"}/>
                <div
                    className="container grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 max-w-screen-lg mt-10 gap-4 ">
                    {
                        magazzini.map((magazzino) => {
                            return (
                                <FormCard
                                    key={magazzino.id}
                                    icon={<Warehouse size={48}/>}
                                    title={magazzino.nome}
                                    link={`/magazzini/${magazzino.id}`}
                                />
                            )
                        })
                    }
                </div>
            </section>
        </MainLayout>
    )

};

export default GrigliaMagazzini;
