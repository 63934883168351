import React from 'react';

const TableCell = ({className = '', content}) => {
    return (
        <td className={`px-6 py-4 ${className}`}>
            <div className="flex items-center">
                <div className="">
                    <div className="text-sm font-medium text-gray-900">{content}</div>
                </div>
            </div>
        </td>
    );
};

export default TableCell;
