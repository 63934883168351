import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {getPartecipantiEvento} from "../../../fetch/fetchEventi";

const BannerEvento = ({evento}) => {

    const [partecipanti, setPartecipanti] = useState([]);

    const supporto = () => {
        getPartecipantiEvento(evento.id).then(
            r => {
                setPartecipanti(r.data)
            }
        ).catch(
            e => console.log(e)
        )
    }

    useEffect(() => {
        supporto()
    }, []);


    return (
        <div className={"border-2 rounded-md"}>
            {/*Head*/}
            <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
                <div
                    className="-ml-4 -mt-4 flex flex-col items-center justify-between sm:flex-row">
                    <div className="ml-4 mt-4 w-full">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{evento.nome}</h3>
                        <p className="mt-1 text-sm text-gray-500">
                            {evento.descrizione}
                        </p>
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0">
                        <Link
                            to={"/info_evento/" + evento.id}
                            type="button"
                            className="ml-6 rounded border border-transparent bg-primary-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-transparent hover:text-primary-blue hover:border-primary-blue"
                        >
                            Modifica
                        </Link>
                    </div>
                </div>
            </div>
            {/*Info*/}
            <div
                className={"px-4 py-5 sm:px-6 grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5"}>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Data:
                    </div>
                    {evento.data}
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Anagrafica:
                    </div>
                    {evento.anagrafica.nome} {evento.anagrafica.cognome}
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Società:
                    </div>
                    {evento.societa.nome}
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Tipo Evento:
                    </div>
                    {evento.tipo_evento.nome}
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Ora Inizio:
                    </div>
                    {evento.ora_inizio}
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Ora Fine:
                    </div>
                    {evento.ora_fine ? evento.ora_fine : "Non disponibile"}
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Data Fine:
                    </div>
                    {evento.data_fine ? evento.data_fine : "Non disponibile"}
                </div>
                <div className={"flex items-center justify-center"}>
                    <div
                        className={"font-semibold mr-1"}> Stato:
                    </div>
                    {
                        evento.is_completato === "Completato" ? (
                            <div className={"bg-green-500 text-white rounded px-1"}>
                                Completato
                            </div>
                        ) : evento.is_completato === "Incompleto" ? (
                            <div className={"bg-red-500 text-white rounded px-1"}>
                                Incompleto
                            </div>
                        ) : (
                            <div className={"bg-yellow-500 text-white rounded px-1"}>
                                Parziale
                            </div>
                        )
                    }
                </div>
            </div>
            {/*Partecipanti*/}
            <div className={"px-4 py-5 sm:px-6 grid grid-cols-1 border-t md:grid-cols-3 gap-5"}>
                {
                    partecipanti.length > 0 ? (
                        <div className={"col-span-3 flex flex-col"}>
                            <div className={"grid grid-cols-1 lg:grid-cols-3 gap-5"}>
                                {
                                    partecipanti.map((anagrafica, index) => (
                                        <div className={"col-span-1"} key={index}>
                                            <div className={"flex items-center justify-start"}>
                                                <div
                                                    className={"font-semibold mr-1"}> Anagrafica:
                                                </div>
                                                {anagrafica.anagrafica.nome} {anagrafica.anagrafica.cognome}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <Link
                                to={"/scadenzario/eventi/" + evento.id + "/partecipanti/crea"}
                                className="mt-4 items-center justify-center flex rounded bg-my-green text-white py-2 px-4 text-sm font-semibold cursor-pointer"
                            >
                                Aggiungi
                            </Link>
                        </div>

                    ) : (
                        <div className={"col-span-3 flex justify-between items-center"}>
                            <div>
                                Non ci sono anagrafiche coinvolte
                            </div>
                            <Link
                                to={"/lavori/eventi/" + evento.id + "/anagrafiche/crea"}
                                className="ml-6 items-center justify-center flex rounded bg-my-green text-white py-2 px-4 text-sm font-semibold cursor-pointer"
                            >
                                Aggiungi
                            </Link>
                        </div>
                    )
                }
            </div>

        </div>
    );
};

export default BannerEvento;
