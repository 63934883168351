import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const creaRelazione = async (formData) => {
    const options = {
        url: `${proxyUrl}/dipendenti/relazione/crea`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const getRelazione = async (id) => {
    const options = {
        url: `${proxyUrl}/dipendenti/relazione/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
