import React from 'react';
import Navbar from "../../../../../components/Navbar";
import HeaderSection from "../../../../../components/HeaderSection";
import Footer from "../../../../../components/Footer";
import {addEventoLavoro} from "../../../../../fetch/fetchEventiLavoro";
import {useParams} from "react-router-dom";

const AggiungiEventiLavoro = () => {

    const {id} = useParams();

    const handleSubmit = (e) => {

        e.preventDefault()

        const formData = new FormData();
        formData.append('commessa', id);
        if (e.target.luogo.value !== "") {
            formData.append('luogo', e.target.luogo.value);
        }
        if (e.target.foto.files[0] !== undefined) {
            formData.append('foto', e.target.foto.files[0], e.target.foto.files[0].name);
        }
        formData.append('commento', e.target.commento.value);

        addEventoLavoro(formData).then(
            () => {
                e.target.reset()
            }
        ).catch(
            e => console.log(e)
        )
    }

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow mt-20"}>
                <HeaderSection title={"Aggiungi Eventi Lavoro"}/>

                <div className={"flex flex-col lg:flex-row justify-evenly"}>
                    <div className={"flex flex-col lg:flex-row w-full container items-center justify-center"}>
                        <form className="flex space-x-3 pb-10 w-4/5" onSubmit={handleSubmit}>
                            <div
                                className="w-full px-5 py-10 mt-10 bg-my-white rounded-sm shadow-sm">
                                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                    {/*Luogo*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Luogo (Facoltativo)
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    name={"luogo"}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    placeholder="Luogo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Foto*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Foto (Facoltativo)
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="file"
                                                    name={"foto"}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Commento*/}
                                    <div className="col-span-2">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Commento
                                            </div>
                                            <div className="relative">
                                                <textarea
                                                    name={"commento"}
                                                    placeholder={"Commento"}
                                                    required
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*Bottone*/}
                                    <div className="col-span-2 text-center">
                                        <button
                                            type="submit"
                                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                        >
                                            Aggiungi
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default AggiungiEventiLavoro;
