import React, {useEffect, useState} from 'react';
import {eliminaAnagrafica, getInfoAnagrafica, modificaAnagrafica} from "../../../../../fetch/fetchAnagrafica";
import {sleep} from "../../../../../constants/sleep";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import {useNavigate, useParams} from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import MainLayout from "../../../../../Layouts/MainLayout";

const ModificaAnagrafica = () => {
    const [alert, setAlert] = useState(0)

    const {id} = useParams();
    const navigator = useNavigate();

    const tipi_documento = [
        "Carta di Identità",
        "Passaporto",
        "Patente di Guida",
    ]

    const [formData, setFormData] = useState({
        nome: "",
        cognome: "",
        email_personale: "",
        email_aziendale: "",
        cellulare: "",
        fisso: "",
        telefono_aziendale: "",
        iban_1: "",
        iban_2: "",
        indirizzo_residenza: "",
        comune_residenza: "",
        provincia_residenza: "",
        cap_residenza: "",
        indirizzo_fatturazione: "",
        comune_fatturazione: "",
        provincia_fatturazione: "",
        cap_fatturazione: "",
        documento: "",
        tipo_documento: "N/A",
        codice_fiscale: "",
        partita_iva: "",
        sdi: "",
        pec: "",
        paese: "",
        paese_id: "",
        vies: "",
    })

    const {
        nome,
        cognome,
        email_personale,
        email_aziendale,
        cellulare,
        fisso,
        telefono_aziendale,
        iban_1,
        iban_2,
        indirizzo_residenza,
        comune_residenza,
        provincia_residenza,
        cap_residenza,
        indirizzo_fatturazione,
        comune_fatturazione,
        provincia_fatturazione,
        cap_fatturazione,
        documento,
        codice_fiscale,
        partita_iva,
        sdi,
        pec,
        paese,
        paese_id,
        vies,
    } = formData

    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        modificaAnagrafica(id, formData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    const handleDelete = (e) => {
        e.preventDefault()

        eliminaAnagrafica(id).then(() => {
            navigator('/anagrafica')
        }).catch(() => {
            setAlert(-1)
        })
    }

    useEffect(() => {
        getInfoAnagrafica(id).then(
            r => {
                setFormData(r.data)
            }
        ).catch(
            e => console.log(e)
        )
    }, []);


    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"}
                                      message={"I Dati Anagrafici sono stati aggiornati con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <div className={"flex flex-col sm:flex-row items-center justify-evenly"}>
                    <div className={"text-2xl uppercase font-bold"}>
                        Modifica Anagrafica
                    </div>
                    <div
                        onClick={handleDelete}
                        className={"bg-my-red px-4 py-2 rounded text-white cursor-pointer hover:bg-white border-2 hover:border-my-red hover:text-black"}
                    >
                        Elimina
                    </div>
                </div>
                <form className="flex space-x-3 pb-10 items-center justify-center" onSubmit={handleSubmit}>
                    <div className="w-full p-5 mt-10 bg-my-white rounded-sm shadow-sm ">
                        <label className="text-xl font-medium text-gray-700 dark:text-gray-200">
                            Dati Anagrafici
                        </label>
                        <div className="grid grid-cols-3 gap-4 m-auto mb-4">
                            {/*nome*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Nome
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"nome"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Nome"
                                            value={nome || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Cognome*/}
                            <div className="col-span-3 sm:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Cognome
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"cognome"}
                                            className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Cognome"
                                            value={cognome || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Email Personale*/}
                            <div className="col-span-3 sm:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Email Personale
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="email"
                                            name={"email_personale"}
                                            className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Email Personale"
                                            value={email_personale !== "N/A" ? email_personale : ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Email Aziendale*/}
                            <div className="col-span-3 sm:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Email Aziendale
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="email"
                                            name={"email_aziendale"}
                                            className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Email Aziendale"
                                            value={email_aziendale !== "N/A" ? email_aziendale : ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Cellulare*/}
                            <div className="col-span-3 sm:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Cellulare
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"cellulare"}
                                            className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Cellulare"
                                            maxLength={10}
                                            value={cellulare || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Fisso*/}
                            <div className="col-span-3 sm:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Telefono Fisso
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"fisso"}
                                            className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Telefono Fisso"
                                            maxLength={10}
                                            value={fisso || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*telefono Aziendale*/}
                            <div className="col-span-3 sm:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Telefono Aziendale
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"telefono_aziendale"}
                                            className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Telefono Aziendale"
                                            maxLength={10}
                                            value={telefono_aziendale || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*codice fiscale*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Codice Fiscale
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"codice_fiscale"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Codice Fiscale"
                                            value={codice_fiscale || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Iban1*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Iban 1
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"iban_1"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Iban 1"
                                            value={iban_1 || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Iban2*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Iban 2
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"iban_2"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Iban 2"
                                            value={iban_2 || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <label className="text-xl font-medium text-gray-700 dark:text-gray-200">
                            Indirizzi
                        </label>
                        <div className="grid grid-cols-3 gap-4 m-auto mb-4">
                            {/*indirizzo residenza*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Indirizzo Residenza
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"indirizzo_residenza"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Indirizzo Residenza"
                                            value={indirizzo_residenza || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*comune residenza*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Comune Residenza
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"comune_residenza"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Comune Residenza"
                                            value={comune_residenza || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*provincia residenza*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Provincia Residenza
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"provincia_residenza"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Provincia Residenza"
                                            value={provincia_residenza || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*cap residenza*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Cap Residenza
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"cap_residenza"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Cap Residenza"
                                            value={cap_residenza || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*indirizzo fatturazione*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Indirizzo Fatturazione
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"indirizzo_fatturazione"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Indirizzo Fatturazione"
                                            value={indirizzo_fatturazione || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*comune fatturazione*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Comune Fatturazione
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"comune_fatturazione"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Comune Fatturazione"
                                            value={comune_fatturazione || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*provincia fatturazione*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Provincia Fatturazione
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"provincia_fatturazione"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Provincia Fatturazione"
                                            value={provincia_fatturazione || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*cap fatturazione*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Cap Fatturazione
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"cap_fatturazione"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Cap Fatturazione"
                                            value={cap_fatturazione || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <label className="text-xl font-medium text-gray-700 dark:text-gray-200">
                            Altro
                        </label>
                        <div className="grid grid-cols-3 gap-4 m-auto mb-4">
                            {/*numero documento*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Numero Documento
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"documento"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Numero Documento"
                                            value={documento}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*codice fiscale*/}
                            <div className="col-span-3 sm:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Tipo Documento
                                    </div>
                                    <div className="relative">
                                        <select
                                            name="tipo_documento"
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            onChange={onChange}
                                        >
                                            <option value={"N/A"}>Nessun Documento</option>
                                            {
                                                tipi_documento.map((tipo, index) => (
                                                    <option key={index} value={tipo}>{tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/*piva*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Partita IVA
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"partita_iva"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Partita IVA"
                                            value={partita_iva || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*sdi*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        SDI
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"sdi"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="SDI"
                                            value={sdi || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*pec*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        PEC
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="email"
                                            name={"pec"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="PEC"
                                            value={pec !== "N/A" ? pec : ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*paese*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Paese
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"paese"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="Paese"
                                            value={paese || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*paese id*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        ID PAESE
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"paese_id"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="ID PAESE"
                                            maxLength={2}
                                            value={paese_id || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*vies*/}
                            <div className="col-span-3 sm:col-span-1 ">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        VIES
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"vies"}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            placeholder="VIES"
                                            value={vies || ""}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Bottone*/}
                        <div className="col-span-3 text-center">
                            <button
                                type="submit"
                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                            >
                                Modifica
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default ModificaAnagrafica;
