import React, {useEffect, useState} from 'react';
import {sleep} from "../../../../constants/sleep";
import {getSocieta} from "../../../../fetch/fetchSocieta";
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import HeaderSection from "../../../../components/HeaderSection";
import {addBeniAziendali, addDocBeniAziendali, getUltimoBeneAziendale} from "../../../../fetch/fetchBeniAziendali";
import {getProduttori} from "../../../../fetch/fetchProduttori";
import BackButton from "../../../../components/BackButton";
import {getDipendenti} from "../../../../fetch/fetchDipendenti";
import {getCollaboratori} from "../../../../fetch/fetchCollaboratori";
import MainLayout from "../../../../Layouts/MainLayout";

const AggiungiBeniAziendali = () => {
    const [alert, setAlert] = useState(0)
    const [societa, setSocieta] = useState([])
    const [produttori, setProduttori] = useState([])
    const [utenti, setUtenti] = useState([])
    const [isUsoComune, setIsUsoComune] = useState(false)

    const [listaAllegati, setListaAllegati] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('modello', e.target.modello.value);
        postData.append('produttore', e.target.produttore.value);
        postData.append('tipo', e.target.tipo.value);
        postData.append('descrizione', e.target.descrizione.value);
        postData.append('seriale_produttore', e.target.seriale_produttore.value);
        postData.append('societa', e.target.societa.value);
        if (!isUsoComune) {
            postData.append('in_uso_da', e.target.utente.value);
        }

        addBeniAziendali(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        }).then(() => {
            if (listaAllegati.length > 0) {
                getUltimoBeneAziendale().then(
                    r => {
                        for (let i = 0; i < listaAllegati.length; i++) {
                            const formData = new FormData()
                            formData.append("documento", listaAllegati[i].file, listaAllegati[i].nome)
                            formData.append("bene", r.data.id)

                            addDocBeniAziendali(formData).then(() => {
                                setAlert(1)
                                setListaAllegati([])
                            }).catch(() => {
                                setAlert(-1)
                            }).finally(async () => {
                                window.scroll(0, 0)
                                await sleep(3000)
                                setAlert(0)
                            })
                        }
                    }
                )


            }
        })
    }

    const addAllegato = (e) => {
        e.preventDefault()
        setListaAllegati(
            old => [...old,
                {
                    file: e.target.allegato.files[0],
                    nome: e.target.allegato.files[0].name
                }
            ])
    }

    const rimuoviAllegato = (e, nome) => {
        e.preventDefault()
        setListaAllegati(listaAllegati.filter(allegato => allegato.nome !== nome))
    }

    const tipi = [
        "Pc",
        "Stampante",
        "Veicolo",
        "Telefono",
        "Utensile",
        "Macchinario",
        "Networking",
        "Elettronica",
        "Mobilia"
    ]

    useEffect(() => {
        getSocieta().then(r => {
            setSocieta(r.data)
        }).catch(e => {
            console.log(e)
        })
        getProduttori().then(r => {
            setProduttori(r.data)
        }).catch(e => {
            console.log(e)
        })
        getDipendenti().then(r => {
            getCollaboratori().then(res => {
                setUtenti([...r.data, ...res.data])
            }).catch(
                e => console.log(e)
            )
        }).catch(
            e => console.log(e)
        )
    }, [])

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"}
                                      message={"Il Bene Aziendale è stato aggiunto con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Bene Aziendale"}/>
                <div className={"flex flex-col lg:flex-row justify-evenly"}>
                    <div className={"flex flex-col lg:flex-row"}>
                        <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                            <div
                                className="w-full max-w-2xl px-5 py-5 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                    {/*Tipo*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Tipo
                                            </div>
                                            <select
                                                name={"tipo"}
                                                defaultValue={tipi[0]}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            >
                                                {
                                                    tipi.map((t, index) => (
                                                        <option
                                                            key={index}
                                                            value={t}
                                                        >
                                                            {t}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/*Produttore*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Produttore
                                            </div>
                                            <select
                                                name={"produttore"}
                                                defaultValue={produttori[0]}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            >
                                                {
                                                    produttori.map((prod, index) => (
                                                        <option
                                                            key={index}
                                                            value={prod.id}
                                                        >
                                                            {prod.anagrafica.nome} {prod.anagrafica.cognome}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/*Nome*/}
                                    <div className="col-span-2 lg:col-span-1 ">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Modello
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    name={"modello"}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    placeholder="Modello"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Societa*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Società
                                            </div>
                                            <select
                                                name={"societa"}
                                                defaultValue={societa[0]}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            >
                                                {
                                                    societa.map((soc, index) => (
                                                        <option
                                                            key={index}
                                                            value={soc.id}
                                                        >
                                                            {soc.nome}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/*Descrizione*/}
                                    <div className="col-span-2 lg:col-span-2 ">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Descrizione
                                            </div>
                                            <div className="relative">
                                            <textarea
                                                name={"descrizione"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Descrizione"
                                                required
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Seriale*/}
                                    <div className="col-span-2 lg:col-span-2 ">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Seriale Produttore
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    name={"seriale_produttore"}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    placeholder="Seriale Produttore"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Utente*/}
                                    {
                                        !isUsoComune ? (
                                            <div className="col-span-2 lg:col-span-2">
                                                <div className={"flex flex-col lg:flex-col"}>
                                                    <div className={"text-my-gray"}>
                                                        In uso a
                                                    </div>
                                                    <select
                                                        name={"utente"}
                                                        defaultValue={utenti[0]}
                                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                                    >
                                                        {
                                                            utenti.map((utente, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={utente.anagrafica.id}
                                                                >
                                                                    {utente.anagrafica.nome} {utente.anagrafica.cognome}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                    <div className="flex gap-4">
                                        <input type="checkbox" className={""}
                                               onChange={() => setIsUsoComune(!isUsoComune)}/>
                                        <div>
                                            Uso Comune
                                        </div>
                                    </div>
                                    {/*Bottone*/}
                                    <div className="col-span-2 text-center">
                                        <button
                                            type="submit"
                                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                        >
                                            {
                                                listaAllegati.length === 0 ? "Aggiungi Senza Allegati" : "Aggiungi Con Allegati"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div>
                        <div className={"flex flex-col lg:flex-row"}>
                            <div className="flex container space-x-3 pb-10">
                                <div
                                    className="w-full max-w-2xl px-5 py-5 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                    <form className="grid max-w-xl grid-cols-1 gap-4 m-auto" onSubmit={addAllegato}>
                                        {/*Allegato*/}
                                        <div className="col-span-1 lg:col-span-1">
                                            <div className={"flex flex-col lg:flex-col"}>
                                                <div className={"text-my-gray"}>
                                                    Aggiungi Allegato
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="file"
                                                        name={"allegato"}
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*Bottone*/}
                                        <div className="col-span-2 text-center">
                                            <button
                                                type={"submit"}
                                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                            >
                                                Aggiungi
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div>
                            <p className={"font-semibold italic"}>Lista Allegati:</p>

                            {
                                listaAllegati.length === 0 ? (
                                    <p>Non ci sono allegati</p>
                                ) : (

                                    listaAllegati.map((all, i) => (
                                        <div key={i} className={"flex justify-between"}>
                                            <p>
                                                {all.nome}
                                            </p>
                                            <p
                                                onClick={e => rimuoviAllegato(e, all.nome)}
                                                className={"font-semibold text-my-red hover:text-red-600 cursor-pointer"}>
                                                X
                                            </p>
                                        </div>
                                    ))

                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default AggiungiBeniAziendali;
