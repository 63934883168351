import React from 'react';
import video from "../assets/images/Nature Makes You Happy BBC Earth.mp4";


const FeatureRight = () => {
    return (
        <div className="relative">
            <div className="container flex flex-col lg:flex-row items-center justify-center">

                <div className="flex flex-1 flex-col items-center lg:items-center">
                    <h1 className={"text-3xl text-primary-blue"}>Su di noi</h1>
                    <h1 className={"text-lg text-primary-blue"}>Aqua fortis saxum scindit </h1>
                    <p className={"text-my-gray my-4 text-center lg:text-center lg:w-7/12"}>
                    Visione a lungo termine, ricerca di un futuro sostenibile e responsabile, attraverso l'adozione di soluzioni innovative e sostenibili che possano migliorare la qualità della vita delle persone e delle comunità in cui operano. Consolidare il nostro successo nel lungo periodo, attraverso l'adozione di strategie di crescita sostenibile e l'investimento in risorse umane e tecnologiche. Promozione dei nostri valori, missione e impegno a fornire soluzioni di alta qualità sostenibili per il bene comune
                    </p>
                </div>
                
            </div>
        </div>
    );
};

export default FeatureRight;
