import axios from "axios";
import {proxyUrl} from "../constants/proxyUrl";

export const getBeniAziendali = async () => {
    const options = {
        url: `${proxyUrl}/beni_aziendali`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getUltimoBeneAziendale = async () => {
    const options = {
        url: `${proxyUrl}/beni_aziendali/ultimo_bene`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const addBeniAziendali = async (formData) => {
    const options = {
        url: `${proxyUrl}/beni_aziendali/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const addDocBeniAziendali = async (formData) => {
    const options = {
        url: `${proxyUrl}/beni_aziendali/documenti/crea`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}


export const delBeniAziendali = async (id) => {
    const options = {
        url: `${proxyUrl}/beni_aziendali/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}
