import React from 'react';
import {Link} from "react-router-dom";

const BannerGrid = ({gridSection}) => {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className={"justify-center items-center flex mb-4"}>
            <div className={"sm:w-4/5 w-11/12"}>
                <div
                    className="divide-y divide-gray-200 flex flex-col overflow-hidden bg-gray-200 rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                    {
                        gridSection.map((action, actionIdx) => (
                            <Link
                                key={actionIdx}
                                className={classNames(
                                    actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                                    actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                                    actionIdx === gridSection.length - 2 ? 'sm:rounded-bl-lg' : '',
                                    actionIdx === gridSection.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                                    'group relative bg-white p-6 cursor-pointer'
                                )}
                                to={"/profile/" + action.href}
                            >
                                <div>
                                    <div
                                        className={classNames(
                                            action.iconBackground,
                                            action.iconForeground,
                                            'inline-flex rounded-lg p-3 ring-4 ring-white'
                                        )}
                                    >
                                        {action.icon}
                                    </div>
                                </div>
                                <div className="mt-8">
                                    <div className="text-xl font-semibold leading-6 text-gray-900">
                                        <div className="focus:outline-none">
                                            {/* Extend touch target to entire panel */}
                                            <span className="absolute inset-0" aria-hidden="true"/>
                                            {action.title}
                                        </div>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-500">
                                        {action.description}
                                    </div>
                                </div>
                                <div
                                    className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                                    aria-hidden="true"
                                >
                                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"/>
                                    </svg>
                                </div>
                            </Link>
                        ))
                    }
                </div>

            </div>

        </div>
    );
};

export default BannerGrid;
