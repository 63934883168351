import React, {useEffect, useState} from 'react';
import {sleep} from "../../../../../constants/sleep";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import HeaderSection from "../../../../../components/HeaderSection";
import {creaRelazione} from "../../../../../fetch/fetchRelazioni";
import {getAnagrafiche} from "../../../../../fetch/fetchAnagrafica";
import BackButton from "../../../../../components/BackButton";
import {MyCombobox} from "../../../../../components/combobox/MyCombobox";
import MainLayout from "../../../../../Layouts/MainLayout";

const AggiungiRelazioni = () => {
    const [alert, setAlert] = useState(0)

    const [anagrafiche, setAnagrafiche] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('anagrafica1', selectedAnagrafica1.id);
        postData.append('anagrafica2', selectedAnagrafica2.id);
        postData.append('descrizione', e.target.descrizione.value);

        creaRelazione(postData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    const [selectedAnagrafica1, setSelectedAnagrafica1] = useState()
    const [selectedAnagrafica2, setSelectedAnagrafica2] = useState()

    useEffect(() => {
        getAnagrafiche().then(
            r => {
                setAnagrafiche(r.data)
                setSelectedAnagrafica1(r.data[0])
                setSelectedAnagrafica2(r.data[0])
            }
        ).catch(
            e => console.log(e)
        )
    }, [])
    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"La Relazione è stata aggiunta con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi Relazione"}/>
                <div className={"flex flex-col lg:flex-row"}>
                    <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                        <div
                            className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                            <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                {/*Dipendente*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Anagrafica 1
                                        </div>
                                        <MyCombobox
                                            selectedItem={selectedAnagrafica1}
                                            setSelectedItem={setSelectedAnagrafica1}
                                            valueList={anagrafiche}
                                        />
                                    </div>
                                </div>
                                {/*Dipendente*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Anagrafica 2
                                        </div>
                                        <MyCombobox
                                            selectedItem={selectedAnagrafica2}
                                            setSelectedItem={setSelectedAnagrafica2}
                                            valueList={anagrafiche}
                                        />
                                    </div>
                                </div>
                                {/*Descrizione*/}
                                <div className="col-span-2 lg:col-span-2 ">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Descrizione Relazione
                                        </div>
                                        <div className="relative">
                                                <textarea
                                                    name={"descrizione"}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    placeholder="Descrizione"
                                                    required
                                                />
                                        </div>
                                    </div>
                                </div>

                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </MainLayout>
    );
};

export default AggiungiRelazioni;
