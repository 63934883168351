import CareersGrid from "../pages/Gestionale/CMS/GestioneCareers/CareersGrid";
import CareersList from "../pages/Gestionale/CMS/GestioneCareers/Careers/CareersList";
import AggiungiCareer from "../pages/Gestionale/CMS/GestioneCareers/Careers/AggiungiCareer";
import CandidaturePage from "../pages/Gestionale/CMS/GestioneCareers/Candidature/CandidaturePage";
import ModificaCareer from "../pages/Gestionale/CMS/GestioneCareers/Careers/ModificaCareer";

export const careersRoutes = [
    {path: "/gestione-careers", element: <CareersGrid/>},
    {path: "/gestione-careers/candidature", element: <CandidaturePage/>},
    {path: "/gestione-careers/list", element: <CareersList/>},
    {path: "/gestione-careers/list/:id", element: <ModificaCareer/>},
    {path: "/gestione-careers/list/aggiungi", element: <AggiungiCareer/>},
]
