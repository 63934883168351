import React, {useEffect, useState} from 'react';
import Navbar from "../../../../../components/Navbar";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import Footer from "../../../../../components/Footer";
import {getSocieta} from "../../../../../fetch/fetchSocieta";
import BackButton from "../../../../../components/BackButton";

const SoggettiContabiliPage = () => {

    const [filter, setFilter] = useState("")
    const [societa, setSocieta] = useState([])

    const getClientiSupport = () => {
        getSocieta().then(r =>
            setSocieta(r.data)
        ).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        getClientiSupport()
    }, [])

    return (
        <div className={"flex flex-col h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"flex-grow mt-10"}>
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSectionAdd titolo={"Soggetti Contabili"} link={"/aggiungi_soggetti_contabili"}
                                      esporta={"società"}/>
                    <div className={"my-10 flex justify-center items-center"}>
                        <div className="flex flex-col w-11/12">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <div>
                                            <input
                                                type="text"
                                                placeholder={"Cerca..."}
                                                className={"w-full py-2 px-4 outline-none border-none"}
                                                onChange={event => setFilter(event.target.value)}
                                            />
                                        </div>
                                        <div className={"max-h-96 overflow-auto"}>
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50 sticky top-0 z-10">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Nome
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        P.IVA
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Codice Fiscale
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Acronimo
                                                    </th>
                                                </tr>
                                                </thead>

                                                <tbody className="bg-white divide-y divide-gray-200">
                                                {
                                                    societa.filter(soc => {
                                                        if (filter === "") {
                                                            return soc
                                                        } else if (
                                                            soc.nome.toLowerCase().includes(filter.toLowerCase())
                                                        ) {
                                                            return soc
                                                        }
                                                    }).map((soc, i) => (
                                                        <tr key={i}>
                                                            {/*Nome*/}
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div
                                                                    className="text-sm text-gray-900">{soc.nome}
                                                                </div>
                                                            </td>

                                                            {/*Iban*/}
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div
                                                                    className="text-sm text-gray-900">{soc.partita_iva ? soc.partita_iva : <span className={"text-gray-400"}>Non presente</span>}
                                                                </div>
                                                            </td>
                                                            {/*CF*/}
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div
                                                                    className="text-sm text-gray-900">{soc.codice_fiscale ? soc.codice_fiscale : <span className={"text-gray-400"}>Non presente</span>}
                                                                </div>
                                                            </td>

                                                            {/*ACRONIMO*/}
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div
                                                                    className="text-sm text-gray-900">{soc.acronimo}
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default SoggettiContabiliPage;
