import React, {useEffect, useState} from 'react';
import Navbar from "../../../../components/Navbar";
import DangerAlert from "../../../../components/DangerAlert";
import SuccessAlert from "../../../../components/SuccessAlert";
import Footer from "../../../../components/Footer";
import {editLavoro, getInfoLavoro} from "../../../../fetch/fetchLavori";
import {sleep} from "../../../../constants/sleep";
import {Link, useParams} from "react-router-dom";
import {esportaExcelHTTP} from "../../../../fetch/esportaExcel";
import FileDownload from "js-file-download";
import BackButton from "../../../../components/BackButton";
import HeaderSection from "../../../../components/HeaderSection";
import {useQuery} from "react-query";

const ModificaLavoroPage = () => {

    const esportaExcel = (e, link) => {
        e.preventDefault()
        esportaExcelHTTP(link).then(
            r => FileDownload(
                r.data,
                r.headers['content-disposition']
                    .split(';')[1]
                    .split('=')[1]
                    .replace('"', '')
                    .replace('"', ''))
        )
    }

    const {id} = useParams()

    const [alert, setAlert] = useState(0)

    const [nome, setNome] = useState("")
    const [luogo, setLuogo] = useState("")
    const [inizio, setInizio] = useState("")
    const [fine, setFine] = useState("")
    const [isPublic, setIsPublic] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('nome', nome);
        postData.append('luogo', luogo);
        postData.append('inizio', inizio);
        postData.append('fine', fine);

        editLavoro(postData, id).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    useEffect(() => {

        getInfoLavoro(id).then(
            r => {
                const data = r.data
                setNome(data.nome)
                setLuogo(data.luogo)
                setInizio(data.inizio)
                setFine(data.fine)
                setIsPublic(data.is_public)
            }
        ).catch(e => {
            console.log(e)
        })
    }, [])

    const {
        data: commessa = {
            nome: "",
            luogo: "",
            inizio: "",
            fine: "",
            codice: "",
            tag: "",
            gruppo: {
                nome: ""
            },
            cliente: {
                anagrafica: {
                    nome: "",
                    cognome: ""
                }
            },
            societa: {
                nome: ""
            }

        }
    } = useQuery("commessa" + id, () => getInfoLavoro(id).then(
        r => r.data
    ))

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"p-10"}>
                    {
                        alert === -1 ? (
                            <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                        ) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"} message={"Il Lavoro è stato modificato con successo"}/>
                        ) : (
                            <div/>
                        )
                    }
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <div
                        className="sm:w-full lg:w-5/6 mx-auto px-2 flex flex-col md:flex-row space-y-4 md:space-y-0 items-center justify-evenly">
                        <div className={"flex flex-col items-center"}>
                            <h1 className={"text-3xl text-center text-black uppercase font-bold "}>Info Commessa</h1>
                            <div className={"w-20 h-1 bg-primary-blue mt-2"}/>
                        </div>
                        <Link to={`/task/${id}`}
                              className={"flex bg-my-green px-4 py-2 rounded text-white font-semibold hover:bg-green-600 cursor-pointer"}>
                            Visualizza Task
                        </Link>
                        <Link to={`/materiale_commessa/${id}`}
                              className={"flex bg-yellow-500 px-4 py-2 rounded text-white font-semibold hover:bg-yellow-600 cursor-pointer"}>
                            Visualizza Materiali
                        </Link>
                        <button
                            className={"flex bg-blue-500 px-4 py-2 rounded text-white font-semibold hover:bg-blue-700 cursor-pointer"}
                            onClick={(e) => esportaExcel(e, "lavori/task")}
                        >
                            Esporta Tasks
                        </button>
                    </div>
                    <div className={"flex items-center justify-center"}>
                        <div className={"grid grid-cols-1 sm:grid-cols-3 gap-4 w-11/12 bg-my-white p-4 rounded my-8"}>
                            <div className={"bg-white rounded p-2"}>
                                <span className={"font-semibold"}>Codice:</span> {commessa?.codice}
                            </div>
                            <div className={"bg-white rounded p-2"}>
                                <span className={"font-semibold"}>Nome:</span> {commessa?.nome}
                            </div>
                            <div className={"bg-white rounded p-2"}>
                                <span className={"font-semibold"}>Luogo:</span> {commessa?.luogo}
                            </div>
                            <div className={"bg-white rounded p-2"}>
                                <span className={"font-semibold"}>Gruppo:</span> {commessa?.gruppo.nome}
                            </div>
                            <div className={"bg-white rounded p-2"}>
                            <span
                                className={"font-semibold"}>Cliente:</span> {commessa?.cliente.anagrafica.nome} {commessa?.cliente.anagrafica.cognome}
                            </div>
                            <div className={"bg-white rounded p-2"}>
                                <span className={"font-semibold"}>Società:</span> {commessa?.societa.nome}
                            </div>
                            <div className={"bg-white rounded p-2"}>
                                <span className={"font-semibold"}>Tag:</span> {commessa?.tag}
                            </div>
                            <div className={"bg-white rounded p-2"}>
                                <span className={"font-semibold"}>Inizio:</span> {commessa?.inizio}
                            </div>
                            <div className={"bg-white rounded p-2"}>
                            <span
                                className={"font-semibold"}>Fine:</span> {commessa?.fine ? commessa?.fine : "In Corso"}
                            </div>

                        </div>
                    </div>

                    <HeaderSection title={"Modifica Commessa"}/>
                    <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                        <div
                            className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                            <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                {/*Nome*/}
                                <div className="col-span-2 lg:col-span-2 ">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Nome
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name={"nome"}
                                                value={nome}
                                                onChange={e => setNome(e.target.value)}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Nome"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Luogo*/}
                                <div className="col-span-2 lg:col-span-2 ">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Luogo
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name={"luogo"}
                                                value={luogo}
                                                onChange={e => setLuogo(e.target.value)}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Luogo"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Inizio*/}
                                <div className="col-span-2 lg:col-span-2">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Data Inizio
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="date"
                                                name={"inizio"}
                                                defaultValue={inizio}
                                                onChange={e => setInizio(e.target.value)}
                                                required
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Fine*/}
                                <div className="col-span-2 lg:col-span-2">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Data Fine
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="date"
                                                name={"fine"}
                                                defaultValue={fine}
                                                onChange={e => setFine(e.target.value)}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Modifica
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>



            </div>
            <Footer/>
        </div>
    );
};

export default ModificaLavoroPage;
