import React, {useEffect, useState} from 'react';
import HeaderSection from "./HeaderSection";
import DangerAlert from "./DangerAlert";
import SuccessAlert from "./SuccessAlert";
import {sleep} from "../constants/sleep";
import {getLavori} from "../fetch/fetchLavori";
import {addPostLavoro} from "../fetch/lavori_post";

const ProjectForm = () => {
    const type = [
        {
            id: 1,
            nome: "Testo"
        },
        {
            id: 2,
            nome: "Sottotitolo"
        },
        {
            id: 3,
            nome: "Quotes"
        },
        {
            id: 4,
            nome: "Hr"
        }
    ]
    const [fase, setFase] = useState(1)

    const [lavoro, setLavoro] = useState({})
    const [lavori, setLavori] = useState([])
    const [foto, setFoto] = useState("")
    const [descrizione, setDescrizione] = useState("")

    const [currentType, setCurrentType] = useState("Testo")
    const [arrayTipi, setArrayTipi] = useState([])
    const [arrayInfo, setArrayInfo] = useState([])

    const [alert, setAlert] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault();
        const projectData = new FormData();
        projectData.append("lavoro", lavoro)
        projectData.append("descrizione", descrizione)
        projectData.append("foto", foto, foto.name)

        arrayTipi.forEach(t => projectData.append("array_tipi", t))
        arrayInfo.forEach(t => projectData.append("array_info", t))

        addPostLavoro(projectData).then(() => {
            setAlert(1)
            setFase(1)
        }).catch(error => {
            if (error.response) {
                setAlert(-1)
            }
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    const displayTextInput = (name) => (
        <div className="col-span-2">
            <div className={"flex flex-col lg:flex-col"}>
                <div className={"text-my-gray"}>
                    {name}
                </div>
                <label className="text-gray-700" htmlFor="name">
                    <textarea
                        className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none"
                        placeholder={name}
                        name="info"
                        rows="5"
                        cols="40"
                        required
                    />
                </label>
            </div>
        </div>
    )

    const fase1 = (e) => {
        e.preventDefault()
        setDescrizione(e.target.descrizione.value)
        setLavoro(e.target.lavoro.value)
        setFoto(e.target.foto.files[0])
        setFase(2)
    }

    const fase2 = (e) => {
        e.preventDefault()
        if (currentType === "Hr") {
            setArrayTipi(t => [...t, e.target.tipo.value])
            setArrayInfo(t => [...t, "Hr"])
        } else {
            setArrayTipi(t => [...t, e.target.tipo.value])
            setArrayInfo(t => [...t, e.target.info.value])
        }
    }

    const changeCurrentType = (e) => {
        e.preventDefault()
        setCurrentType(e.target.value)
    }


    useEffect(() => {
        getLavori().then(r => setLavori(r.data))
    }, [])


    return (
        <div className={"p-10"}>
            {
                alert === -1 ? (
                    <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                ) : alert === 1 ? (
                    <SuccessAlert name={"Inserito"} message={"Il progetto è stato inserito con successo"}/>
                ) : (
                    <div/>
                )
            }

            {
                fase === 1 ? (
                    <div>
                        <HeaderSection title={"Campi Obbligatori"}/>
                        <form className="flex container space-x-3 pb-10" onSubmit={fase1}>
                            <div
                                className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                    {/*Lavoro*/}
                                    <div className={"flex flex-col lg:flex-col col-span-2"}>
                                        <div className={"text-my-gray"}>
                                            Lavoro
                                        </div>
                                        <select
                                            name={"lavoro"}
                                            defaultValue={lavori[0]}
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                lavori.filter(lav => lav.is_public === true).map((lavoro, index) => (
                                                    <option
                                                        key={index}
                                                        value={lavoro.id}
                                                    >
                                                        {lavoro.nome}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    {/*Foto*/}
                                    <div className={"flex flex-col lg:flex-col col-span-2"}>
                                        <div className={"text-my-gray"}>
                                            Lavoro
                                        </div>
                                        <input
                                            className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none"
                                            type="file"
                                            name="foto"
                                            required
                                        />
                                    </div>

                                    {/*Descrizione*/}
                                    <div className="col-span-2">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Descrizione Lavoro
                                            </div>
                                            <label className="text-gray-700" htmlFor="name">
                                                <textarea
                                                    className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none"
                                                    placeholder={"Descrizione Lavoro"}
                                                    name="descrizione"
                                                    rows="5"
                                                    cols="40"
                                                    required
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    {/*Bottone*/}
                                    <div className="col-span-2 text-center">
                                        <button
                                            type="submit"
                                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                        >
                                            Avanti
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div>
                        <HeaderSection title={"CMS"}/>
                        <form className="flex container space-x-3 pb-4" onSubmit={fase2}>
                            <div
                                className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                <div className="grid max-w-xl grid-cols-1 gap-4 m-auto">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Tipo
                                        </div>
                                        <select
                                            onChange={changeCurrentType}
                                            name={"tipo"}
                                            defaultValue={"Testo"}
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {
                                                type.map((tipo, index) => (
                                                    <option key={index} value={tipo.nome}>{tipo.nome}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                    {
                                        currentType === "Testo" ? (
                                            displayTextInput("Testo Normale")
                                        ) : currentType === "Sottotitolo" ? (
                                            displayTextInput("Sottotitolo")
                                        ) : currentType === "Quotes" ? (
                                            displayTextInput("Quotes")
                                        ) : currentType === "Hr" ? (
                                            <div/>
                                        ) : (
                                            <h1>Errore</h1>
                                        )
                                    }

                                    {/*Bottone*/}
                                    <div className="col-span-2 text-center">
                                        <button
                                            type="submit"
                                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-my-green text-white hover:bg-my-white hover:text-black"}
                                        >
                                            Aggiungi
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className={"flex flex-col"}>
                            <div className={"container py-4"}>
                                <HeaderSection title={"Struttura del Post"}/>
                                <div className={""}>
                                    {
                                        arrayInfo.map((a, i) => (
                                            <div key={i} className={"flex justify-center space-x-40"}>
                                                <h1>{arrayTipi[i]}</h1>
                                                <button onClick={() => {
                                                    arrayInfo.splice(i, 1)
                                                    arrayTipi.splice(i, 1)
                                                    setArrayInfo([...arrayInfo])
                                                    setArrayTipi([...arrayTipi])
                                                }}>
                                                    <h1 className={"font-bold text-my-red"}>Rimuovi</h1>
                                                </button>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="col-span-2 text-center">
                            <button
                                onClick={handleSubmit}
                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-my-red text-white hover:bg-my-white hover:text-black"}
                            >
                                Post
                            </button>
                        </div>


                    </div>
                )
            }


        </div>
    );
};

export default ProjectForm;
