import React from 'react';

const DangerAlert = ({name, message}) => {
    return (
        <div className={"flex item-center justify-center mb-6"}>
            <div className={"container px-0"}>
                <div className={"bg-my-red text-white font-bold rounded-t px-4 py-2"}>
                    {name}
                </div>
                <div className="border border-t-0 border-my-red rounded-b bg-red-100 px-4 py-3 text-red-700">
                    <p>{message}</p>
                </div>
            </div>
        </div>

    );
};

export default DangerAlert;
