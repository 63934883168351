import React, {useState} from 'react';
import {sleep} from "../../../../../constants/sleep";
import MainLayout from "../../../../../Layouts/MainLayout";
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import BackButton from "../../../../../components/BackButton";
import HeaderSection from "../../../../../components/HeaderSection";
import {addCareers} from "../../../../../fetch/fetchCareers";
import ReactQuill from "react-quill";

const AggiungiCareer = () => {
    const [alert, setAlert] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target)

        if (responsabilita !== '' || requisiti !== '') {
            data.append('responsabilita', responsabilita)
            data.append('requisiti', requisiti)

            addCareers(data).then(() => {
                setAlert(1)
                e.target.reset()
            }).catch(() => {
                setAlert(-1)
            }).finally(async () => {
                window.scroll(0, 0)
                await sleep(3000)
                setAlert(0)
            })
        } else {
            setAlert(-1)
        }
    };

    const [responsabilita, setResponsabilita] = useState('');
    const [requisiti, setRequisiti] = useState('');


    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"La Prima Nota è stato aggiunta con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>

                <HeaderSection title={"Aggiungi Career"}/>

                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Mansione*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Mansione
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"mansione"}
                                            placeholder={"Mansione"}
                                            required={true}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Società*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Tipo Contratto
                                    </div>
                                    <select
                                        name={"tipo_contratto"}
                                        defaultValue={"Full-Time"}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        <option value={"Full-Time"}>Full-Time</option>
                                        <option value={"Part-Time"}>Part-Time</option>
                                    </select>
                                </div>
                            </div>
                            {/*Stato*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Stato
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"stato"}
                                            placeholder={"Stato"}
                                            required={true}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Luogo*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Luogo
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"luogo"}
                                            placeholder={"Luogo"}
                                            required={true}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Range Salario*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Range Salario
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name={"salario"}
                                            placeholder={"25.000 - 40.000"}
                                            required={true}
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Responsabilità*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Responsabilità
                                    </div>
                                    <ReactQuill theme="snow" className={"bg-white"} value={responsabilita}
                                                onChange={setResponsabilita}/>
                                </div>
                            </div>
                            {/*Responsabilità*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Requisiti
                                    </div>
                                    <ReactQuill theme="snow" className={"bg-white"} value={requisiti}
                                                onChange={setRequisiti}/>
                                </div>
                            </div>
                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Aggiungi
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default AggiungiCareer;
