import React from 'react';
import {Link} from "react-router-dom";

const FormCard = ({icon, title, link}) => {
    return (
        <Link to={link}>
            <div className={"flex flex-col bg-gray-100 rounded-sm"}>
                <div className="p-6 flex flex-col items-center">
                    <div className={"items-center"}>
                        <div className={"text-primary-blue"}>
                            {icon}
                        </div>
                        {/*<FontAwesomeIcon icon={icon} className={"text-6xl text-primary-blue"}/>*/}
                    </div>
                    <h3 className={"mt-5 text-primary-blue text-lg text-center"}>{title}</h3>
                </div>
            </div>
        </Link>
    );
};

export default FormCard;
