import React from 'react';
import BustePagaForm from "./BustePagaForm";
import MainLayout from "../../../../../Layouts/MainLayout";

const BustePagaPage = () => {
    return (
        <MainLayout>
            <BustePagaForm/>
        </MainLayout>
    );
};

export default BustePagaPage;
