import {proxyUrl} from "../constants/proxyUrl";
import axios from "axios";

export const getPreventivi = async () => {
    const options = {
        url: `${proxyUrl}/preventivi/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const getPreventivo = async (id) => {
    const options = {
        url: `${proxyUrl}/preventivi/modifica/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const creaPreventivo = async (formData) => {
    const options = {
        url: `${proxyUrl}/preventivi/crea/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}

export const eliminaPreventivo = async (id) => {
    const options = {
        url: `${proxyUrl}/preventivi/modifica/${id}`,
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    return axios(options)
}

export const modificaPreventivo = async (id, data) => {
    const options = {
        url: `${proxyUrl}/preventivi/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: data
    };

    return axios(options)
}

export const accettaPreventivo = async (id, formData) => {
    const options = {
        url: `${proxyUrl}/preventivi/modifica/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        data: formData
    };

    return axios(options)
}
