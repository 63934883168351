import React, {useEffect, useState} from 'react';
import Footer from "../../../../components/Footer";
import HeaderSection from "../../../../components/HeaderSection";
import BackButton from "../../../../components/BackButton";
import Navbar from "../../../../components/Navbar";
import {
    editRecurring,
    getCategorieRecurring,
    getInfoRecurring,
    getTipiRecurring
} from "../../../../fetch/fetchRecurring";
import {MyCombobox} from "../../../../components/combobox/MyCombobox";
import {getAnagrafiche} from "../../../../fetch/fetchAnagrafica";
import {getSocieta} from "../../../../fetch/fetchSocieta";
import {useParams} from "react-router-dom";

const ModificaRecurring = () => {

    const {id} = useParams()

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log(currentRecurring)

        const data = new FormData()
        data.append("anagrafica", currentRecurring.anagrafica.id)
        data.append("categoria", currentRecurring.categoria.id)
        data.append("tipo", currentRecurring.tipo.id)

        if (currentRecurring.data_fine) {
            data.append("data_fine", currentRecurring.data_fine)
        }

        if (currentRecurring.intervallo) {
            data.append("intervallo", currentRecurring.intervallo)
        }

        data.append("data_inizio", currentRecurring.data_inizio)

        data.append("frequenza", currentRecurring.frequenza)

        data.append("note", currentRecurring.note)
        data.append("societa", currentRecurring.societa.id)

        console.log(data)

        editRecurring(id, data).then(
            r => {
                console.log(r)
            }
        ).catch(
            e => console.log(e)
        )
    }


    const [anagrafica, setAnagrafica] = useState([])
    const [selectedAnagrafica, setSelectedAnagrafica] = useState()

    const [societa, setSocieta] = useState([])


    const [categorie, setCategorie] = useState([])

    const [tipi, setTipi] = useState([])

    const frequenze = [
        "Giornaliero",
        "Settimanale",
        "Mensile",
        "Bimestrale",
        "Trimestrale",
        "Quadrimestrale",
        "Semestrale",
        "Annuale",
        "Più"
    ]

    const [currentRecurring, setCurrentRecurring] = useState({
        id: "",
        anagrafica: {},
        categoria: {},
        tipo: {},
        data_inizio: "",
        data_fine: "",
        frequenza: "",
        intervallo: "",
        note: "",
        societa: {}
    })

    useEffect(() => {
        getAnagrafiche().then(r => {
            setAnagrafica(r.data)
        }).catch(e => {
            console.log(e)
        })

        getCategorieRecurring().then(r => {
            setCategorie(r.data)
        }).catch(
            e => {
                console.log(e)
            }
        )

        getTipiRecurring().then(r => {
            setTipi(r.data)
        }).catch(
            e => {
                console.log(e)
            }
        )

        getSocieta().then(
            r => setSocieta(r.data)
        ).catch(
            e => console.log(e)
        )

        getInfoRecurring(id).then(
            r => {
                setCurrentRecurring(r.data)
            }
        ).catch(
            e => console.log(e)
        )

    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Modifica Recurring"}/>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Anagrafica*/}
                            <div className="col-span-2 lg:col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Anagrafica
                                    </div>
                                    <MyCombobox
                                        valueList={anagrafica}
                                        selectedItem={currentRecurring.anagrafica}
                                        setSelectedItem={setSelectedAnagrafica}
                                    />
                                </div>
                            </div>
                            {/*Società*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Società
                                    </div>
                                    <select
                                        name={"societa"}
                                        defaultValue={currentRecurring.societa.id}
                                        onChange={e => setCurrentRecurring({
                                            ...currentRecurring,
                                            societa: e.target.value
                                        })}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            societa.map((societa, index) => (

                                                <option
                                                    key={index}
                                                    value={societa.id}
                                                    selected={currentRecurring.societa.id === societa.id}
                                                >
                                                    {societa.nome}
                                                </option>
                                            ))
                                        }

                                    </select>
                                </div>
                            </div>
                            {/*Tipo*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Tipi
                                    </div>
                                    <select
                                        name={"tipo"}
                                        defaultValue={currentRecurring.tipo.id}
                                        onChange={e => setCurrentRecurring({...currentRecurring, tipo: e.target.value})}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            tipi.map((tipo, index) => (
                                                <option
                                                    key={index}
                                                    value={tipo.id}
                                                    selected={currentRecurring.tipo.id === tipo.id}
                                                >
                                                    {tipo.nome}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Categoria*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Categorie
                                    </div>
                                    <select
                                        name={"categoria"}
                                        defaultValue={currentRecurring.categoria.id}
                                        onChange={e => setCurrentRecurring({
                                            ...currentRecurring,
                                            categoria: e.target.value
                                        })}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            categorie.map((cat, index) => (
                                                <option
                                                    key={index}
                                                    value={cat.id}
                                                    selected={currentRecurring.categoria.id === cat.id}
                                                >
                                                    {cat.nome}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Frequenza*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Frequenza
                                    </div>
                                    <select
                                        name={"frequenza"}
                                        defaultValue={currentRecurring.frequenza}
                                        onChange={e => setCurrentRecurring({
                                            ...currentRecurring,
                                            frequenza: e.target.value
                                        })}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    >
                                        {
                                            frequenze.map((f, index) => (
                                                <option
                                                    key={index}
                                                    value={f}
                                                    selected={currentRecurring.frequenza === f}
                                                >
                                                    {f}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {/*Data Inizio*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Data Inizio
                                    </div>
                                    <input
                                        type="date"
                                        name="data_inizio"
                                        required
                                        defaultValue={currentRecurring.data_inizio}
                                        onChange={e => setCurrentRecurring({
                                            ...currentRecurring,
                                            data_inizio: e.target.value
                                        })}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>
                            {/*Data Fine*/}
                            <div className="col-span-2 lg:col-span-1">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Data Fine (Facoltativa)
                                    </div>
                                    <input
                                        type="date"
                                        name="data_fine"
                                        defaultValue={currentRecurring.data_fine}
                                        onChange={e => setCurrentRecurring({
                                            ...currentRecurring,
                                            data_fine: e.target.value
                                        })}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>

                            {/*Intervallo*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Numero di Anni (Inserire solo se la frequenza è Più)
                                    </div>
                                    <input
                                        name={"intervallo"}
                                        type={"number"}
                                        defaultValue={currentRecurring.intervallo}
                                        onChange={e => setCurrentRecurring({
                                            ...currentRecurring,
                                            intervallo: e.target.value
                                        })}
                                        placeholder={"Inserisci il numero di anni di ricorrenza"}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>

                            {/*Note*/}
                            <div className="col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Note
                                    </div>
                                    <textarea
                                        name={"note"}
                                        placeholder={"Inserisci le note"}
                                        defaultValue={currentRecurring.note}
                                        onChange={(e) => setCurrentRecurring({
                                            ...currentRecurring,
                                            note: e.target.value
                                        })}
                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                    />
                                </div>
                            </div>

                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Modifica
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer/>
        </div>
    );
};

export default ModificaRecurring;
