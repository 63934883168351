import React, {useEffect, useState} from 'react';
import Navbar from "../../../../../components/Navbar";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import Footer from "../../../../../components/Footer";
import {eliminaTask, getTaskLavoro, modificaTask} from "../../../../../fetch/taskFetch";
import {Link, useParams} from "react-router-dom";

const TaskPage = () => {

    const {id} = useParams()
    const [filter, setFilter] = useState("")
    const [showModal, setShowModal] = useState(false)

    const [eliminazione, setEliminazione] = useState(-1)

    const [tasks, setTasks] = useState([])

    const delTask = (e, id) => {
        e.preventDefault()
        eliminaTask(id).then(() => {
            getSupporto()
            setEliminazione(-1)
        }).catch(e => {
            console.log(e)
        })
    }

    const editTask = (e, id, c) => {
        e.preventDefault()
        let newComp = ""
        switch (c) {
            case "Incompleto":
                newComp = "Parziale"
                break
            case "Parziale":
                newComp = "Completato"
                break
            case "Completato":
                newComp = "Incompleto"
                break
        }
        modificaTask(id, {completamento: newComp}).then(() => {
            getSupporto()
        }).catch(e => {
            console.log(e)
        })
    }

    const getSupporto = () => {
        getTaskLavoro(id).then(r => {
            setTasks(r.data)
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        getSupporto()
    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Eliminazione
                                    </h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                        Sicuro di voler eliminare?
                                    </p>
                                </div>
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-my-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Annulla
                                    </button>
                                    <button
                                        className="bg-my-red text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(e) => {
                                            delTask(e, eliminazione)
                                            setShowModal(false)
                                        }}
                                    >
                                        Elimina
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            <Navbar/>
            <div className={"flex-grow"}>
                <div className={"flex-grow mt-10"}>
                    <HeaderSectionAdd titolo={"Task"} link={`/aggiungi_task/${id}`}/>
                    <div className={"my-10"}>
                        <div className="flex flex-col container">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <div>
                                            <input
                                                type="text"
                                                placeholder={"Cerca..."}
                                                className={"w-full py-2 px-4 outline-none"}
                                                onChange={event => setFilter(event.target.value)}
                                            />
                                        </div>
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Modifica
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Lavoro
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Descrizione
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Data
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Costo
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Completamento
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Commenti
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Foto
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Partecipanti
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Elimina
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                tasks.filter(task => {
                                                    if (filter === "") {
                                                        return task
                                                    } else if (
                                                        task.descrizione.nome.toLowerCase().includes(filter.toLowerCase())
                                                    ) {
                                                        return task
                                                    }
                                                }).map((task, i) => (
                                                    <tr key={i}>

                                                        {/*Commenti*/}
                                                        <td
                                                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                                        >
                                                            <Link to={`/task/modifica/${task.id}`}
                                                                  className="text-my-green font-bold hover:text-green-700 cursor-pointer">Modifica</Link>
                                                        </td>

                                                        {/*Lavoro*/}
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{task.lavoro.nome}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Descrizione*/}
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{task.descrizione.nome}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Data*/}
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{task.data}</div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Costo*/}
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{task.costo} €
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/*Completamento*/}
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="flex items-center">

                                                                {
                                                                    task.completamento === "Completato" ? (
                                                                        <div
                                                                            className="text-sm font-medium cursor-pointer hover:underline bg-my-green px-2 py-1 rounded text-white hover:bg-green-600"
                                                                            onClick={e => editTask(e, task.id, task.completamento)}
                                                                        >
                                                                            {task.completamento}
                                                                        </div>
                                                                    ) : task.completamento === "Parziale" ? (
                                                                        <div
                                                                            className="text-sm font-medium cursor-pointer hover:underline bg-yellow-500 px-2 py-1 rounded text-white hover:bg-yellow-700"
                                                                            onClick={e => editTask(e, task.id, task.completamento)}
                                                                        >
                                                                            {task.completamento}
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            className="text-sm font-medium cursor-pointer hover:underline bg-my-red px-2 py-1 rounded text-white hover:bg-red-600"
                                                                            onClick={e => editTask(e, task.id, task.completamento)}
                                                                        >
                                                                            {task.completamento}
                                                                        </div>
                                                                    )
                                                                }


                                                            </div>
                                                        </td>
                                                        {/*Commenti*/}
                                                        <td
                                                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                                        >
                                                            <Link to={`/task_info/${task.id}`}
                                                                  className="text-my-green font-bold hover:text-green-700 cursor-pointer">Commenti</Link>
                                                        </td>
                                                        {/*Foto*/}
                                                        <td
                                                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                                        >
                                                            <Link to={`/foto_task/${task.id}`}
                                                                  className="text-primary-blue font-bold hover:text-blue-800 cursor-pointer">Foto</Link>
                                                        </td>
                                                        {/*Partecipanti*/}
                                                        <td
                                                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                                        >
                                                            <Link to={`/partecipanti_task/${task.id}`}
                                                                  className="text-yellow-600 font-bold hover:text-yellow-700 cursor-pointer">Partecipanti</Link>
                                                        </td>
                                                        {/*Elimina*/}
                                                        <td
                                                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                                            onClick={() => {
                                                                setShowModal(true)
                                                                setEliminazione(task.id)
                                                            }}
                                                        >
                                                            <p className="text-my-red font-bold hover:text-red-700 cursor-pointer">Elimina</p>
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default TaskPage;
