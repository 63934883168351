import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import HeaderSection from "../../../../../components/HeaderSection";
import {eliminaDipendente, getDipendente} from "../../../../../fetch/fetchDipendenti";
import BackButton from "../../../../../components/BackButton";
import MainLayout from "../../../../../Layouts/MainLayout";

const InfoDipendentePage = () => {

    const {id} = useParams()

    const [nome, setNome] = useState("")
    const [username, setUsername] = useState("")
    const [cognome, setCognome] = useState("")
    const [ruolo, setRuolo] = useState("")
    const [iban, setIban] = useState("")
    const [telefono, setTelefono] = useState("")
    const [email, setEmail] = useState("")
    const [codiceFiscale, setCodiceFiscale] = useState("")

    const navigate = useNavigate()

    const deleteDipendente = (e) => {
        e.preventDefault()
        eliminaDipendente(username).then(() => {
            navigate("/dipendenti")
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        getDipendente(id).then(r => {
            const actualData = r.data
            setNome(actualData.anagrafica.nome)
            setCognome(actualData.anagrafica.cognome)
            setCodiceFiscale(actualData.anagrafica.codice_fiscale)
            setRuolo(actualData.ruolo)
            setEmail(actualData.anagrafica.email_personale)
            setIban(actualData.anagrafica.iban_1)
            setTelefono(actualData.anagrafica.cellulare)
            setUsername(actualData.user.username)
        }).catch(e => {
            console.log(e)
        })
    }, [])

    return (
        <MainLayout margin={"mt-10"}>
            <div className={"flex px-20 py-2"}>
                <BackButton/>
            </div>
            <HeaderSection title={nome + " " + cognome}/>
            <div className={"flex items-center justify-evenly py-2"}>
                <div className={"flex space-x-2 justify-center"}>
                    <button
                        onClick={deleteDipendente}
                        className={"shadow-md py-2 px-4 rounded-sm transition duration-300 bg-my-red text-white hover:bg-my-white hover:text-black"}
                    >
                        Elimina
                    </button>
                </div>
            </div>
            <div className={"flex flex-col md:flex-row container py-4 justify-between bg-my-white rounded"}>
                <div className={"grid grid-cols-1 sm:grid-cols-3 w-full gap-10"}>
                    <div className={"flex space-x-3 bg-white flex-1 px-2 py-1 rounded"}>
                        <div className={"font-bold"}>
                            Nome:
                        </div>
                        <div>
                            {nome}
                        </div>
                    </div>
                    <div className={"flex space-x-3 bg-white flex-1 px-2 py-1 rounded"}>
                        <div className={"font-bold"}>
                            Cognome:
                        </div>
                        <div>
                            {cognome}
                        </div>
                    </div>
                    <div className={"flex space-x-3 bg-white flex-1 px-2 py-1 rounded"}>
                        <div className={"font-bold"}>
                            Username:
                        </div>
                        <div>
                            {username}
                        </div>
                    </div>
                    <div className={"flex space-x-3 bg-white flex-1 px-2 py-1 rounded"}>
                        <div className={"font-bold"}>
                            Codice Fiscale:
                        </div>
                        <div>
                            {codiceFiscale}
                        </div>
                    </div>
                    <div className={"flex space-x-3 bg-white flex-1 px-2 py-1 rounded"}>
                        <div className={"font-bold"}>
                            Ruolo:
                        </div>
                        <div>
                            {ruolo}
                        </div>
                    </div>
                    <div className={"flex space-x-3 bg-white flex-1 px-2 py-1 rounded"}>
                        <div className={"font-bold"}>
                            Email:
                        </div>
                        <div>
                            {email}
                        </div>
                    </div>
                    <div className={"flex space-x-3 bg-white flex-1 px-2 py-1 rounded"}>
                        <div className={"font-bold"}>
                            Telefono:
                        </div>
                        <div>
                            {telefono}
                        </div>
                    </div>
                    <div className={"flex space-x-3 bg-white flex-1 px-2 py-1 rounded"}>
                        <div className={"font-bold"}>
                            Iban:
                        </div>
                        <div>
                            {iban || "Non presente"}
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default InfoDipendentePage;
