import {useState} from "react";

export const TabellaLog = ({listaLog, nomeItem}) => {

    function upperCaseFirstLetter(nomeItem) {
        return nomeItem.charAt(0).toUpperCase() + nomeItem.slice(1);
    }

    const [filter, setFile] = useState("");


    return (
        <div className={"flex flex-col"}>
            <div className={"px-4 sm:px-6 lg:px-8"}>
                <input
                    type="text"
                    placeholder={"Cerca..."}
                    className={"bg-my-white w-full px-3 py-2 rounded outline-none border-none"}
                    onChange={(e) => setFile(e.target.value)}
                />
            </div>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="mt-8 flow-root">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50 sticky top-0 z-10">
                                    <tr>
                                        <th scope="col"
                                            className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 ">
                                            {upperCaseFirstLetter(nomeItem)}
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Operazione
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Descrizione
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Utente
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Data
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {
                                        listaLog.filter(
                                            (log) => (
                                                log[nomeItem].toLowerCase().includes(filter.toLowerCase()) ||
                                                log.operazione.toLowerCase().includes(filter.toLowerCase()) ||
                                                log.descrizione.toLowerCase().includes(filter.toLowerCase()) ||
                                                log.utente.username.toLowerCase().includes(filter.toLowerCase()) ||
                                                log.data.toLowerCase().includes(filter.toLowerCase())
                                            )
                                        ).map((log, index) => (
                                            <tr key={index}>
                                                <td className=" px-3 py-4 text-sm text-gray-500">{log[nomeItem]}</td>
                                                <td
                                                    className=" px-3 py-4 text-sm text-gray-500"
                                                >
                                                    {
                                                        log.operazione === "Creazione" ? (
                                                            <div
                                                                className={"bg-my-green text-white px-2 py-1 font-semibold rounded"}>
                                                                {log.operazione}
                                                            </div>
                                                        ) : log.operazione === "Eliminazione" ? (
                                                            <div
                                                                className={"bg-my-red text-white px-2 py-1 font-semibold rounded"}>
                                                                {log.operazione}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={"bg-secondary-blue text-white px-2 py-1 font-semibold rounded"}>
                                                                {log.operazione}
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                                <td className=" px-3 py-4 text-sm text-gray-500">{log.descrizione}</td>
                                                <td className=" px-3 py-4 text-sm text-gray-500">{log.utente.username}</td>
                                                <td className=" px-3 py-4 text-sm text-gray-500">{log.data}</td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
