import React, {useEffect, useState} from 'react';
import {getSocieta} from "../../../../../../fetch/fetchSocieta";
import DangerAlert from "../../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../../components/SuccessAlert";
import HeaderSection from "../../../../../../components/HeaderSection";
import {getCategorie, getSingolaSpesa, modificaSpesa} from "../../../../../../fetch/fetchNotaSpese";
import {getAnagrafiche} from "../../../../../../fetch/fetchAnagrafica";
import {useParams} from "react-router-dom";
import BackButton from "../../../../../../components/BackButton";
import MainLayout from "../../../../../../Layouts/MainLayout";

const ModificaSpesa = () => {

    const {id} = useParams();

    const [alert, setAlert] = useState(0)

    const [categorie, setCategorie] = useState([])
    const [soc, setSoc] = useState([])
    const [anagrafiche, setAnagrafiche] = useState([])

    const [formData, setFormData] = useState({
        categoria: '',
        anagrafica: '',
        societa: '',
        data: '',
        costo_sostenuto: '',
        costo_rimborsabile: '',
        descrizione: '',
    })

    const {categoria, anagrafica, societa, data, costo_sostenuto, costo_rimborsabile, descrizione} = formData

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value})


    const handleSubmit = (e) => {
        e.preventDefault();
        modificaSpesa(id, formData).then(
            () => {
                setAlert(1)
                setTimeout(() => {
                    setAlert(0)
                }, 3000)
            }
        ).catch(
            () => {
                setAlert(2)
                setTimeout(() => {
                    setAlert(0)
                }, 3000)
            }
        )
    };

    useEffect(() => {
        getCategorie().then(
            r => setCategorie(r.data)
        ).catch(
            e => console.log(e)
        )

        getSocieta().then(
            r => setSoc(r.data)
        ).catch(
            e => console.log(e)
        )

        getAnagrafiche().then(
            r => setAnagrafiche(r.data)
        ).catch(
            e => console.log(e)
        )

        getSingolaSpesa(id).then(
            r => setFormData(r.data)
        ).catch(
            e => console.log(e)
        )
    }, [])

    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"}
                                      message={"La Nota Spese è stato modificata con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Modifica Spesa"}/>
                <div className={"flex flex-col lg:flex-row justify-evenly"}>
                    <div className={"flex flex-col lg:flex-row"}>
                        <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                            <div
                                className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                    {/*Categoria*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Categoria Nota Spese
                                            </div>
                                            <select
                                                name={"categoria"}
                                                defaultValue={categoria}
                                                onChange={e => onChange(e)}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            >
                                                {
                                                    categorie.map((tipo, index) => (
                                                        //Show selected option
                                                        <option key={index} value={tipo.id}
                                                                selected={tipo.id === categoria}>
                                                            {tipo.nome}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/*Dipendente*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Anagrafica
                                            </div>
                                            <select
                                                name={"anagrafica"}
                                                defaultValue={anagrafica}
                                                onChange={e => onChange(e)}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            >
                                                {
                                                    anagrafiche.map((dip, index) => (
                                                        //Show selected option
                                                        <option key={index} value={dip.id}
                                                                selected={dip.id === anagrafica}>
                                                            {dip.nome} {dip.cognome}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/*Data*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Data
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="date"
                                                    name={"data"}
                                                    value={data}
                                                    onChange={e => onChange(e)}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Costo Sostenuto*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Costo Sostenuto
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="number"
                                                    step={"0.01"}
                                                    name={"costo_sostenuto"}
                                                    value={costo_sostenuto}
                                                    onChange={e => onChange(e)}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    placeholder="Costo Sostenuto"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Costo Rimborsabile*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Costo Rimborsabile
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="number"
                                                    step={"0.01"}
                                                    value={costo_rimborsabile}
                                                    onChange={e => onChange(e)}
                                                    name={"costo_rimborsabile"}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                    placeholder="Costo Rimborsabile"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Società*/}
                                    <div className="col-span-2 lg:col-span-1">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Società
                                            </div>
                                            <select
                                                name={"societa"}
                                                defaultValue={societa}
                                                onChange={e => onChange(e)}
                                                className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                            >
                                                {
                                                    soc.map((soc, index) => (
                                                        //Show selected option
                                                        <option key={index} value={soc.id}
                                                                selected={soc.id === societa}>
                                                            {soc.nome}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/*Descrizione*/}
                                    <div className="col-span-2">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Descrizione
                                            </div>
                                            <div className="relative">
                                            <textarea
                                                name={"descrizione"}
                                                value={descrizione}
                                                onChange={e => onChange(e)}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                            </div>
                                        </div>
                                    </div>

                                    {/*Bottone*/}
                                    <div className="col-span-2 text-center">
                                        <button
                                            type="submit"
                                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                        >
                                            Modifica
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </MainLayout>
    );
};

export default ModificaSpesa;
