import React, {useEffect, useState} from 'react';
import HeaderSection from "../../../components/HeaderSection";
import {sleep} from "../../../constants/sleep";
import {aggiungiAllegatoEvento, aggiungiAnagraficaEvento, creaEvento, getTipoEventi} from "../../../fetch/fetchEventi";
import {getSocieta} from "../../../fetch/fetchSocieta";
import BackButton from "../../../components/BackButton";
import {getAnagrafiche} from "../../../fetch/fetchAnagrafica";
import {getDipendenti} from "../../../fetch/fetchDipendenti";
import {MyCombobox} from "../../../components/combobox/MyCombobox";
import DangerAlert from "../../../components/DangerAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import MainLayout from "../../../Layouts/MainLayout";

const AggiungiEvento = () => {

    const [alert, setAlert] = useState(0)

    const [selectedAnagrafica, setSelectedAnagrafica] = useState()

    const [tipiEventi, setTipiEventi] = useState([])
    const [societa, setSocieta] = useState([])
    const [anagrafiche, setAnagrafiche] = useState([])
    const statoEvento = ["Incompleto", "Parziale", "Completato"]

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append('tipo_evento', e.target.tipo_evento.value);
        postData.append('anagrafica', selectedAnagrafica.id);
        postData.append('societa', e.target.societa.value);
        postData.append('data', e.target.data.value);
        postData.append('data_fine', e.target.data_fine.value);
        postData.append('ora_inizio', e.target.ora_inizio.value);
        postData.append('ora_fine', e.target.ora_fine.value);
        postData.append('nome', e.target.nome.value);
        postData.append('descrizione', e.target.descrizione.value);
        postData.append('is_completato', e.target.is_completato.value);

        if (e.target.luogo.value === "") {
            postData.append('luogo', "Nessun luogo specificato");
        }

        creaEvento(postData).then((r) => {
            selezionati.forEach(dipendente => {
                const postData = new FormData();
                postData.append('evento', r.data.id);
                postData.append('anagrafica', dipendente.anagrafica.id);
                aggiungiAnagraficaEvento(postData).then(r => console.log(r.data)).catch(e => console.log(e))
            })
            listaAllegati.forEach(allegato => {
                const postData = new FormData();
                postData.append('evento', r.data.id);
                postData.append('nome_file', allegato.nome_file);
                postData.append('file', allegato.file);
                aggiungiAllegatoEvento(r.data.id, postData).then(r => console.log(r.data)).catch(e => console.log(e))
            })
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    useEffect(() => {
        getTipoEventi().then(r => setTipiEventi(r.data)).catch(e => console.log(e))

        getSocieta().then(r => setSocieta(r.data)).catch(e => console.log(e))

        getAnagrafiche().then(r => {
            setAnagrafiche(r.data)
            setSelectedAnagrafica(r.data[0])
        }).catch(e => console.log(e))

        getDipendenti().then(r => setDipendenti(r.data)).catch(e => console.log(e))

    }, [])


    const [selezionati, setSelezionati] = useState([])
    const [dipendenti, setDipendenti] = useState([])

    const addDipendente = (e) => {
        e.preventDefault()

        const dipendente = dipendenti.find(dipendente => dipendente.id === parseInt(e.target.dipendente.value))
        setSelezionati(old => [...old, dipendente])
    }

    const rimuoviDipendente = (e, id) => {
        e.preventDefault()
        setSelezionati(selezionati.filter(dip => dip.id !== id))
    }

    const [listaAllegati, setListaAllegati] = useState([])

    const aggiungiAllegato = (e) => {
        e.preventDefault()
        const allegato = {
            evento: 0, nome_file: e.target.nome_file.value, file: e.target.file.files[0]
        }
        setListaAllegati(old => [...old, allegato])
    }

    const rimuoviAllegato = (e, index) => {
        e.preventDefault()
        setListaAllegati(listaAllegati.filter((allegato, i) => i !== index))
    }


    return (
        <MainLayout>
            <div className={"flex flex-col lg:flex-row justify-evenly"}>
                <div className={"flex w-full items-center flex-col mt-20"}>
                    <div className={"w-full"}>
                        {alert === -1 ? (<DangerAlert name={"Errore"}
                                                      message={"I dati inseriti non sono validi"}/>) : alert === 1 ? (
                            <SuccessAlert name={"Pubblicato"}
                                          message={"La Relazione è stata aggiunta con successo"}/>) : (<div/>)}
                    </div>
                    <div className={"flex px-20 py-2"}>
                        <BackButton/>
                    </div>
                    <HeaderSection title={"Aggiungi Nuovo Evento"}/>

                    <div className={"flex w-full flex-col lg:flex-row justify-evenly mt-10"}>
                        <form className="flex flex-1 space-x-3 pb-10 w-full items-center justify-center"
                              onSubmit={handleSubmit}>
                            <div className="grid grid-cols-2 gap-4 w-11/12 bg-my-white p-4">
                                {/*Tipo*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Tipo Evento
                                        </div>
                                        <select
                                            name={"tipo_evento"}
                                            defaultValue={tipiEventi[0]}
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {tipiEventi.map((tipo, index) => (<option
                                                key={index}
                                                value={tipo.id}
                                            >
                                                {tipo.nome}
                                            </option>))}
                                        </select>
                                    </div>
                                </div>
                                {/*Anagrafica*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Anagrafica
                                        </div>
                                        <MyCombobox
                                            valueList={anagrafiche}
                                            selectedItem={selectedAnagrafica}
                                            setSelectedItem={setSelectedAnagrafica}
                                        />
                                    </div>
                                </div>
                                {/*Società*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Società
                                        </div>
                                        <select
                                            name={"societa"}
                                            defaultValue={societa[0]}
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {societa.map((soc, index) => (<option
                                                key={index}
                                                value={soc.id}
                                            >
                                                {soc.nome}
                                            </option>))}
                                        </select>
                                    </div>
                                </div>
                                {/*Nome*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Nome
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name={"nome"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Nome"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Data*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Data
                                        </div>
                                        <div className="relative">
                                            <input
                                                required
                                                type="date"
                                                name={"data"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Data Fine*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Data Fine
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="date"
                                                name={"data_fine"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Ora Inizio*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Ora Inizio
                                        </div>
                                        <div className="relative">
                                            <input
                                                required
                                                type="time"
                                                name={"ora_inizio"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Ora Fine*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Ora Fine
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="time"
                                                name={"ora_fine"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Stato*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Stato Evento
                                        </div>
                                        <select
                                            name={"is_completato"}
                                            defaultValue={statoEvento[0]}
                                            className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                        >
                                            {statoEvento.map((stato, index) => (<option
                                                key={index}
                                                value={stato}
                                            >
                                                {stato}
                                            </option>))}
                                        </select>
                                    </div>
                                </div>
                                {/*Luogo*/}
                                <div className="col-span-2 lg:col-span-1">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Luogo
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                name={"luogo"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                placeholder="Luogo"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Descrizione*/}
                                <div className="col-span-2">
                                    <div className={"flex flex-col lg:flex-col"}>
                                        <div className={"text-my-gray"}>
                                            Descrizione
                                        </div>
                                        <div className="relative">
                                            <textarea
                                                name={"descrizione"}
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*Bottone*/}
                                <div className="col-span-2 text-center">
                                    <button
                                        type="submit"
                                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                    >
                                        Aggiungi
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className={"flex-1 w-full flex flex-col items-center justify-center mb-10"}>
                            <div className={"flex w-11/12 flex-col lg:flex-row"}>
                                <div className="flex w-full pb-10">
                                    <div
                                        className="w-full px-5 py-10 bg-my-white rounded-sm shadow-sm ">
                                        <form className="grid max-w-xl grid-cols-1 gap-4 m-auto"
                                              onSubmit={addDipendente}>
                                            {/*Allegato*/}
                                            <div className="col-span-2 lg:col-span-2 ">
                                                <div className={"flex flex-col lg:flex-col"}>
                                                    <div className={"text-my-gray"}>
                                                        Dipendente
                                                    </div>
                                                    <select
                                                        name={"dipendente"}
                                                        defaultValue={dipendenti[0]}
                                                        className="rounded-lg border-transparent flex-1 border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none"
                                                    >
                                                        {dipendenti.map((dip, index) => (<option
                                                            key={index}
                                                            value={dip.id}>
                                                            {dip.anagrafica.nome} {dip.anagrafica.cognome}
                                                        </option>))}
                                                    </select>
                                                </div>
                                            </div>
                                            {/*Bottone*/}
                                            <div className="col-span-2 text-center">
                                                <button
                                                    type={"submit"}
                                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                                >
                                                    Aggiungi Dipendente
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <p className={"font-semibold italic"}>Lista Dipendenti:</p>

                                {selezionati.length === 0 ? (<p>Non ci sono materiali</p>) : (

                                    selezionati.map((dip, i) => (<div key={i} className={"flex justify-between"}>
                                        <p>
                                            {dip.anagrafica.nome} {dip.anagrafica.cognome}
                                        </p>
                                        <p
                                            onClick={e => rimuoviDipendente(e, dip.id)}
                                            className={"font-semibold text-my-red hover:text-red-600 cursor-pointer"}>
                                            X
                                        </p>
                                    </div>)))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"w-full flex flex-col items-center justify-center"}>
                <form onSubmit={aggiungiAllegato} className={"bg-my-white w-11/12 p-4 rounded mb-10"}>
                    <div className={"text-xl font-bold"}>
                        Aggiungi Allegati
                    </div>
                    <div className={"flex flex-col md:flex-row gap-2 mt-2"}>
                        {/*Nome File*/}
                        <div className="flex-1">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    Nome File
                                </div>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name={"nome_file"}
                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        placeholder="Nome"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        {/*Nome File*/}
                        <div className="flex-1">
                            <div className={"flex flex-col lg:flex-col"}>
                                <div className={"text-my-gray"}>
                                    File
                                </div>
                                <div className="relative">
                                    <input
                                        type="file"
                                        name={"file"}
                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                        placeholder="Nome"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-2">
                        <button
                            type="submit"
                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                        >
                            Aggiungi Allegato
                        </button>
                    </div>
                </form>
                <div className={"w-full flex items-center justify-center"}>
                    {listaAllegati.length === 0 ? (<p>Non ci sono allegati</p>) : (
                        <div className={"w-11/12 p-4 rounded mb-10"}>
                            <div className={"text-xl font-bold w-full"}>
                                Lista Allegati
                            </div>
                            <div className={"flex flex-col gap-2 mt-2 w-full"}>
                                {listaAllegati.map((allegato, i) => (<div key={i} className={"flex justify-between"}>
                                    <p>
                                        {allegato.nome_file}
                                    </p>
                                    <p
                                        onClick={e => rimuoviAllegato(e, i)}
                                        className={"font-semibold text-my-red hover:text-red-600 cursor-pointer"}>
                                        X
                                    </p>
                                </div>))}
                            </div>
                        </div>)}
                </div>
            </div>
        </MainLayout>
    )
};

export default AggiungiEvento;
