import React, {useEffect, useState} from 'react';
import {getAnagrafiche} from "../../../../../fetch/fetchAnagrafica";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import {Link} from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import MainLayout from "../../../../../Layouts/MainLayout";

const AnagraficaPage = () => {
    const [filter, setFilter] = useState("")

    const [anagrafiche, setAnagrafiche] = useState([])

    const nomiColonne = [
        "custom_id",
        "nome",
        "cognome",
        "email_personale",
        "cellulare",
    ]

    const getSupporto = () => {
        getAnagrafiche().then(r => {
            setAnagrafiche(r.data)
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        getSupporto()
    }, [])
    return (
        <MainLayout margin={"mt-10"}>
            <div className={"flex px-20 py-2"}>
                <BackButton/>
            </div>
            <HeaderSectionAdd titolo={"Anagrafica"} link={"/aggiungi_anagrafica"} esporta={"anagrafica"}/>
            <div className={"my-10"}>
                <div className={"flex w-full justify-center items-center"}>
                    <div className="flex flex-col w-11/12">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <div>
                                        <input
                                            type="text"
                                            placeholder={"Cerca..."}
                                            className={"w-full py-2 px-4 outline-none border-none"}
                                            onChange={event => setFilter(event.target.value)}
                                        />
                                    </div>
                                    <div className={"max-h-96 overflow-auto relative"}>
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50 sticky top-0 z-10">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Info
                                                </th>
                                                {
                                                    nomiColonne.map((colonna, index) => {
                                                        return (
                                                            <th
                                                                key={index}
                                                                scope="col"
                                                                className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                            >
                                                                {colonna}
                                                            </th>
                                                        )
                                                    })
                                                }
                                                <th
                                                    scope="col"
                                                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Tipo
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Modifica
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                anagrafiche.filter(anagrafica => {
                                                    const lowerCaseFilter = filter.toLowerCase();
                                                    return ['custom_id', 'nome', 'email_personale', 'cognome', 'cellulare'].some(property =>
                                                        anagrafica[property]?.toLowerCase().includes(lowerCaseFilter),
                                                    );
                                                }).sort(
                                                    (a, b) => {
                                                        if (a.custom_id > b.custom_id) {
                                                            return 1;
                                                        }
                                                        if (a.custom_id < b.custom_id) {
                                                            return -1;
                                                        }
                                                        return 0;
                                                    }
                                                ).map((anagrafica, i) => (
                                                    <tr key={i}>
                                                        <td className="px-6 py-4  text-sm text-gray-500"
                                                        >
                                                            <Link to={`${anagrafica.id}`}
                                                                  className="text-my-green font-bold hover:text-green-700 cursor-pointer">Info</Link>
                                                        </td>
                                                        {
                                                            nomiColonne.map((colonna, index) => {
                                                                return (
                                                                    <td
                                                                        key={index}
                                                                        className="px-6 py-4  text-sm text-gray-500"
                                                                    >
                                                                        {
                                                                            anagrafica[colonna] === null || anagrafica[colonna] === "" || anagrafica[colonna] === "na@gmail.com" ? "Nessun dato" : anagrafica[colonna]
                                                                        }
                                                                    </td>
                                                                )
                                                            })
                                                        }

                                                        <td
                                                            className="px-6 py-4  text-sm text-gray-500"
                                                        >
                                                            {
                                                                anagrafica.is_giuridica ? "Persona Giuridica" : anagrafica.is_condominio ? "Condominio" : "Persona Fisica"
                                                            }
                                                        </td>

                                                        <td
                                                            className="px-6 py-4  text-sm text-gray-500"
                                                        >
                                                            <Link to={`modifica/${anagrafica.id}`}
                                                                  className="text-yellow-600 font-bold hover:text-yellow-700 cursor-pointer">Modifica</Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </MainLayout>
    );
};

export default AnagraficaPage;
