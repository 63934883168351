import React from 'react';
import image_2 from "../assets/images/foto_lunga.jpg"
import lettera from "../assets/pdf/Lettera.pdf"
import MainLayout from "../Layouts/MainLayout";

const LetteraPage = () => {

    return (
        <MainLayout>
            <div className=" flex flex-col w-full">
                <div className='flex'>
                    <img
                        src={image_2}
                        alt=""
                        className={"w-full"}/>
                </div>
                <div className="flex flex-col px-10  lg:mt-0">
                    <div className="text-3xl font-semibold text-center m-2">
                        Lettera agli investitori
                    </div>
                    <div className={"container"}>
                        Gentili Investitori,
                        <br/>
                        Siamo lieti di comunicare i progressi e gli sviluppi della nostra società di ingegneria
                        durante l'ultimo anno. Grazie al vostro sostegno e al nostro impegno costante per
                        l'eccellenza, siamo riusciti a raggiungere importanti traguardi che ci hanno permesso di
                        consolidare la nostra posizione nel mercato.
                        <br/>
                        <br/>
                        Uno dei principali obiettivi dell'anno è stata l'espansione delle nostre attività a livello
                        globale, attraverso la creazione di nuove partnership strategiche e l'apertura di nuove
                        filiali in diversi Paesi. Questo ci ha permesso di accrescere la nostra presenza
                        internazionale e di offrire soluzioni ingegneristiche avanzate e sostenibili ai nostri
                        clienti in tutto il mondo.
                        <br/>
                        <br/>
                        Inoltre, abbiamo continuato a investire nelle nostre competenze chiave, acquisendo e
                        sviluppando nuove tecnologie, strumenti e metodologie avanzate per soddisfare le crescenti
                        esigenze del mercato. In particolare, abbiamo dedicato notevoli risorse allo sviluppo di
                        soluzioni innovative per l'industria 4.0, in grado di garantire elevati standard di
                        efficienza e sostenibilità.
                        <br/>
                        <br/>
                        Infine, ci siamo impegnati a garantire la massima qualità dei nostri servizi e a migliorare
                        costantemente la nostra attenzione al cliente, con l'obiettivo di soddisfare al meglio le
                        esigenze dei nostri partner commerciali.
                        <br/>
                        <br/>
                        Riteniamo che questi progressi siano testimonianza della forza e della competitività della
                        nostra società di ingegneria, e siamo entusiasti di poter continuare a crescere e a
                        sviluppare la nostra attività grazie al vostro supporto.
                        <br/>
                        <br/>
                        Vi ringraziamo per la vostra fiducia e il vostro sostegno, e ci impegniamo a continuare a
                        lavorare con dedizione e passione per raggiungere ulteriori traguardi nel futuro.
                        <br/>
                        <br/>
                        Distinti saluti.
                    </div>
                    <div className="flex flex-col my-4 justify-center items-center">
                        <a href={lettera} target={"_blank"} rel={""}>
                            Scarica PDF
                        </a>
                    </div>
                </div>

            </div>
        </MainLayout>
    );
};

export default LetteraPage;
