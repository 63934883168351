import React, {useEffect, useState} from 'react';
import Navbar from "../../../../../components/Navbar";
import Footer from "../../../../../components/Footer";
import {deleteEventoLavoro, getEventiLavoro} from "../../../../../fetch/fetchEventiLavoro";
import {useParams} from "react-router-dom";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import BannerEventoCommessa from "./BannerEventoCommessa";

const EventiLavoro = () => {

    const {id} = useParams();
    const [eventi, setEventi] = useState([]);

    const supporto = () => {
        getEventiLavoro(id).then((response) => {
            setEventi(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        supporto()
    }, []);

    const handleDelete = (id) => {
        deleteEventoLavoro(id).then(() => {
            supporto()
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"flex-grow mt-20 container"}>
                <HeaderSectionAdd titolo={"Eventi Commessa"} link={`/lavori/eventi/${id}/aggiungi`}/>
                <div className={"flex flex-col my-8 space-y-4"}>
                    {
                        eventi.length > 0 ? (
                            eventi.map((evento, index) => (
                                <BannerEventoCommessa
                                    key={index}
                                    evento={evento}
                                    handleDelete={handleDelete}
                                    index={index}
                                />
                            ))
                        ) : (
                            <div>Non ci sono eventi</div>
                        )
                    }
                </div>
            </div>
            <Footer/>

        </div>
    );
};

export default EventiLavoro;
