import React, {useState} from 'react';
import {CalendarioMensile} from "./calendari/CalendarioMensile";
import {getEventiByName} from "../../../fetch/fetchEventi";
import {Link} from "react-router-dom";
import MainLayout from "../../../Layouts/MainLayout";
import {SearchIcon} from "lucide-react";


const CalendarioPage = () => {

    const [eventiRicercati, setEventiRicercati] = useState([])
    const [ricerca, setRicerca] = useState("")

    const cercaEventi = () => {
        getEventiByName(ricerca).then((r) => {
            setEventiRicercati(r.data)
        }).catch(
            (error) => {
                console.log(error)
            }
        )
    }

    return (
        <MainLayout margin={"mt-10"}>
            <CalendarioMensile/>
            <div className={"mt-4 px-8"}>
                <div className="sm:w-3/4 lg:w-5/6 mx-auto px-2 flex flex-col items-center">
                    <h1 className={"text-3xl text-center text-black uppercase font-bold "}>Ricerca Eventi</h1>
                    <div className={"w-20 h-1 bg-primary-blue mt-2"}/>
                </div>
                <div className={"my-4 flex flex-col justify-center items-center"}>
                    <div className={"flex w-full items-center justify-center gap-4"}>
                        <input
                            type="text"
                            placeholder={"Ricerca Evento"}
                            value={ricerca}
                            onChange={e => setRicerca(e.target.value)}
                            className={"border-2 rounded w-11/12 sm:w-3/5 outline-none px-4 py-2 "}
                        />
                        <SearchIcon
                            onClick={cercaEventi}
                            className={"w-10 h-10 text-white bg-primary-blue p-1 rounded cursor-pointer hover:bg-white hover:text-primary-blue transform duration-200 border-2 border-primary-blue"}
                        />
                    </div>
                    <div className={"flex flex-col w-full items-center justify-center space-y-4 mt-4"}>
                        {
                            eventiRicercati.map((e, i) => (
                                <div
                                    className={"flex-col space-y-4 sm:space-y-0 sm:flex-row w-4/5 flex justify-between items-center border-2 rounded px-4 py-2"}
                                    key={i}>
                                    <div className={"flex-1"}>
                                        {e.nome}
                                    </div>
                                    <Link
                                        to={`/info_evento/${e.id}`}
                                        className={"bg-my-green w-full sm:w-2/5 text-center text-white px-2 rounded hover:bg-white hover:text-my-green border-2 cursor-pointer border-my-green"}>
                                        Vedi
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default CalendarioPage;
