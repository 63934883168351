import React from 'react';
import {Link} from "react-router-dom";
import {getFullEventiGiorno} from "../../../../fetch/fetchEventi";
import {useQuery} from "react-query";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const CellaGiorno = ({day: {date, isCurrentMonth, isToday}, filtro, filtroSoc, phoneMode = false}) => {

    const tipiEvento = [
        {
            nome: "Appuntamento",
            colore: "bg-blue-200"
        },
        {
            nome: "Commessa",
            colore: "bg-green-200"
        },
        {
            nome: "Stipendio",
            colore: "bg-red-200"
        },
        {
            nome: "Fattura",
            colore: "bg-yellow-200"
        },
        {
            nome: "Tassa",
            colore: "bg-purple-200"
        },
        {
            nome: "FollowUp",
            colore: "bg-pink-200"
        },
        {
            nome: "Sopralluogo",
            colore: "bg-secondary-blue"
        },
        {
            nome: "Malattia",
            colore: "bg-red-100"
        },
        {
            nome: "Altro",
            colore: "bg-gray-200"
        },
        {
            nome: "Recurring",
            colore: "bg-pink-700"
        },
        {
            nome: "Evento networking",
            colore: "bg-pink-300"
        },
        {
            nome: "Nessun Filtro",
            colore: "bg-white"
        }
    ]

    const {data} = useQuery({
        queryKey: ['eventiGiorno', date],
        queryFn: () => getFullEventiGiorno(date).then(
            res => res.data
        )
    })

    return (
        !phoneMode ? (
            <Link
                to={`/scadenzario/${date}`}
                className={classNames(
                    isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                    'relative py-2 px-2'
                )}
            >
                {
                    <div
                        className={
                            isToday
                                ? 'flex h-6 w-6 items-center justify-center rounded-full bg-primary-blue     font-semibold text-white'
                                : undefined
                        }
                    >
                        {date.split('-').pop().replace(/^0/, '')}
                    </div>
                }
                {
                    data?.length > 0 && (
                        <div className="mt-2 flex flex-col gap-1">
                            {
                                data?.filter(evento =>
                                    evento.tipo_evento.nome.includes(filtro.valore) && evento.societa.nome.includes(filtroSoc.valore)
                                ).slice(0, 2).map((event) => (
                                    <Link
                                        to={event.tipo_evento.nome === "Fattura" ? `/movimenti/${event.id}` : event.tipo_evento.nome === "Recurring" ? `/recurrings` : `/info_evento/${event.id}`}
                                        key={event.id}>
                                        <div
                                            className={"group flex rounded px-1 " + tipiEvento.find((tipo) => tipo.nome === event.tipo_evento.nome).colore}>
                                            <div
                                                className={event.tipo_evento.nome === "Recurring" || event.tipo_evento.nome === "Sopralluogo" ? "flex-auto text-white truncate font-medium cursor-pointer" : "flex-auto truncate font-medium cursor-pointer"}>
                                                {event.nome}
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            }
                            {
                                data?.length > 2 &&
                                <div className="text-gray-500">+ {data?.length - 2} more</div>
                            }
                        </div>
                    )
                }
            </Link>
        ) : (
            <Link
                to={`/scadenzario/${date}`}
                type="button"
                className={classNames(
                    isCurrentMonth ? 'bg-white h-14 py-2 px-3' : 'bg-gray-50 h-14 py-2 px-3 focus:z-10',
                )}
            >
                <div className={
                    isToday
                        ? 'flex h-4 w-4 items-center justify-center rounded-full bg-primary-blue font-semibold text-white'
                        : undefined
                }>
                    {date.split('-').pop().replace(/^0/, '')}
                </div>
                {
                    data?.length > 0 && (
                        <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                            {
                                data?.map((event) => (
                                    <div key={event.id}
                                         className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"/>
                                ))
                            }
                        </div>
                    )
                }
            </Link>
        )

    );
};

export default CellaGiorno;
