import React, {useState} from 'react';
import BannerGrid from "../BannerGrid";
import MainLayout from "../../../Layouts/MainLayout";

const PagineEventiProfilo = () => {

    const gridSection = {
        eventi: [
            {
                title: 'Eventi di Oggi',
                description: 'In questa sezione puoi visualizzare gli eventi a cui parteciperai oggi',
                href: 'eventi/oggi',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"/>
                    </svg>

                ),
            },
            {
                title: 'Eventi Del Mese',
                description: 'In questa sezione puoi visualizzare gli eventi a cui parteciperai questo mese',
                href: 'eventi/mese',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
                    </svg>

                ),
            },
            {
                title: 'Tutti gli Eventi',
                description: 'In questa sezione puoi visualizzare tutti i tuoi eventi',
                href: 'eventi/tutti',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"/>
                    </svg>


                ),
            },
            {
                title: 'Eventi Da Completare',
                description: 'In questa sezione puoi visualizzare gli eventi che devi ancora completare',
                href: 'eventi/incompleti',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"/>
                    </svg>
                ),
            },
        ],
        task: [
            {
                title: 'Task di Oggi',
                description: 'In questa sezione puoi visualizzare i task a cui parteciperai oggi',
                href: 'task/oggi',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"/>
                    </svg>

                ),
            },
            {
                title: 'Task Del Mese',
                description: 'In questa sezione puoi visualizzare i task a cui parteciperai questo mese',
                href: 'task/mese',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
                    </svg>

                ),
            },
            {
                title: 'Tutti i Task',
                description: 'In questa sezione puoi visualizzare tutti i tuoi task',
                href: 'task/tutti',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"/>
                    </svg>


                ),
            },
            {
                title: 'Task Da Completare',
                description: 'In questa sezione puoi visualizzare i task che devi ancora completare',
                href: 'task/incompleti',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"/>
                    </svg>
                ),
            },
        ]
    }

    const [isEventi, setIsEventi] = useState(true)


    return (
        <MainLayout margin={"mt-16"}>
            <div className={"flex flex-col items-center justify-center mb-4"}>
                <div className={"flex sm:w-4/5 w-11/12 items-center justify-evenly mb-4"}>
                    <div className={"text-lg sm:text-3xl text-center text-black uppercase font-bold "}>
                        {
                            isEventi ? "I tuoi Eventi" : "I tuoi Task"
                        }
                    </div>
                </div>
                {
                    isEventi ? (
                        <div
                            className={"flex w-11/12 md:w-3/5 items-center justify-evenly bg-my-white rounded p-2 gap-4"}>
                            <div className={"flex flex-1 text-center p-2 rounded bg-white"}>
                                Eventi
                            </div>
                            <div className={"flex flex-1 text-center p-2 rounded cursor-pointer hover:bg-white"}
                                 onClick={() => setIsEventi(false)}
                            >
                                Task
                            </div>
                        </div>
                    ) : (
                        <div
                            className={"flex w-11/12 md:w-3/5 items-center justify-evenly bg-my-white rounded p-2 gap-4"}>
                            <div className={"flex flex-1 text-center p-2 rounded cursor-pointer hover:bg-white"}
                                 onClick={() => setIsEventi(true)}
                            >
                                Eventi
                            </div>
                            <div className={"flex flex-1 text-center p-2 rounded bg-white"}>
                                Task
                            </div>
                        </div>
                    )
                }
            </div>

            <BannerGrid gridSection={
                isEventi ? gridSection.eventi : gridSection.task
            }/>
        </MainLayout>
    );
};

export default PagineEventiProfilo;
