import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import HeaderSectionAdd from "../../../../../components/HeaderSectionAdd";
import BackButton from "../../../../../components/BackButton";
import {eliminaMovimento, getFattura, getMovimentiFattura} from "../../../../../fetch/fetchFattura";
import MainLayout from "../../../../../Layouts/MainLayout";
import HeaderSection from "../../../../../components/HeaderSection";
import {useQuery} from "react-query";
import {formatFloat} from "../../../../../utils/number-formatter";

const MovimentiPage = () => {

    const {id} = useParams()

    const [movimenti, setMovimenti] = useState([])

    const handleDeleteMovimento = (id) => {
        eliminaMovimento(id).then(
            () => support()
        ).catch(
            (error) => console.log(error)
        )
    }

    const support = () => {
        getMovimentiFattura(id).then(
            r => {
                setMovimenti(r.data)
                console.log(r.data)
            }
        ).catch(
            e => console.log(e)
        )
    }

    const {
        data: fattura = {
            numero_fattura: "",
            anagrafica: {
                nome: "",
                cognome: ""
            },
            data_emissione: "",
            data_scadenza: "",
            societa: {
                nome: ""
            },
            documento: "",
            importo_netto: 0,
            cassa_previdenziale: 0,
            iva: 0,
            ritenuta_acconto: 0,
            importo_saldato: 0,
            is_attiva: "",
            paese: "",
            is_incassata: ""
        }
    } = useQuery("fattura", () => getFattura(id).then(
        r => r.data
    ))

    useEffect(() => {
        support()
    }, [])


    return (
        <MainLayout>
            <div className={"flex-grow mt-10"}>
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <div className={"flex flex-col w-full items-center justify-center"}>
                    <HeaderSection title={"Fattura"}/>
                    <div className={"grid grid-cols-1 sm:grid-cols-3 gap-4 w-11/12 bg-my-white p-4 rounded my-8"}>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Numero fattura:</span> {fattura?.numero_fattura}
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span
                                className={"font-semibold"}>Anagrafica:</span> {fattura?.anagrafica.nome} {fattura?.anagrafica.cognome !== "N/A" ? fattura?.anagrafica.cognome : ""}
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Data Emissione:</span> {fattura?.data_emissione}
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Data Scadenza:</span> {fattura?.data_scadenza}
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Società:</span> {fattura?.societa.nome}
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <a href={fattura?.documento} target={"_blank"} rel={""} className={"font-semibold"}>Visualizza
                                Documento</a>
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span
                                className={"font-semibold"}>Lordo:</span> {formatFloat((fattura?.importo_netto + fattura?.cassa_previdenziale + fattura?.iva + fattura?.ritenuta_acconto))}€
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Saldato:</span> {formatFloat(fattura?.importo_saldato)}€
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Rimanente:</span> {
                            formatFloat((
                                    fattura?.importo_netto +
                                    fattura?.cassa_previdenziale +
                                    fattura?.iva +
                                    fattura?.ritenuta_acconto
                                )
                                - fattura?.importo_saldato)
                        } €
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Tipo:</span> {fattura?.is_attiva}
                        </div>
                        <div className={"bg-white rounded p-2"}>
                            <span className={"font-semibold"}>Paese:</span> {fattura?.paese}
                        </div>
                        <div>
                            {
                                fattura?.is_incassata === "Saldata" ? (
                                    <div className={"bg-my-green p-2  rounded w-full"}>
                                        {fattura?.is_incassata}
                                    </div>
                                ) : fattura?.is_incassata === "Parziale" ? (
                                    <div
                                        className={"bg-yellow-400 p-2 text-black rounded w-full"}>
                                        {fattura?.is_incassata}
                                    </div>
                                ) : (
                                    <div className={"bg-my-red p-2 rounded w-full"}>
                                        {fattura?.is_incassata}
                                    </div>
                                )
                            }
                        </div>

                    </div>


                    <HeaderSectionAdd titolo={"Movimenti Fattura"} link={`/movimenti/aggiungi/${id}`}/>
                    <div className={"w-11/12"}>
                        {
                            movimenti.length > 0 ? (
                                <div className={"flex flex-col w-full items-center justify-center gap-8 my-8"}>
                                    {
                                        movimenti.map((movimento, i) => (
                                            <div className={"p-4 bg-my-white rounded w-full"} key={i}>
                                                <div className="flex flex-col">
                                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                        <div
                                                            className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                            <div
                                                                className={"flex flex-col sm:flex-row justify-between items-center"}>
                                                                <div className={"font-bold text-3xl"}>
                                                                    Movimento n° {i + 1}
                                                                </div>
                                                                <div
                                                                    className={"grid grid-cols-2 sm:grid-cols-4 gap-4 text-center"}>
                                                                    <div
                                                                        onClick={() => handleDeleteMovimento(movimento.id)}
                                                                        className={"bg-my-red font-semibold text-white border-my-red border-2 px-4 p-2 rounded cursor-pointer hover:bg-my-white hover:text-my-red "}>
                                                                        Elimina
                                                                    </div>
                                                                    <Link to={`/movimenti/modifica/${movimento.id}`}
                                                                          className={"bg-yellow-500 font-semibold text-white border-yellow-500 border-2 px-4 p-2 rounded cursor-pointer hover:bg-my-white hover:text-yellow-500 "}>
                                                                        Modifica
                                                                    </Link>
                                                                </div>

                                                            </div>
                                                            <div className={"my-4"}>
                                                                <p className={"font-semibold"}>Descrizione: </p>
                                                                <p className={"italic"}>
                                                                    {movimento.descrizione}
                                                                </p>
                                                            </div>
                                                            <div className={"flex flex-col sm:flex-row justify-evenly"}>
                                                                <div className={"my-4"}>
                                                                    <p className={"font-semibold"}>Importo: </p>
                                                                    <p className={"italic"}>
                                                                        {movimento.importo} €
                                                                    </p>
                                                                </div>
                                                                <div className={"my-4"}>
                                                                    <p className={"font-semibold"}>Data: </p>
                                                                    <p className={"italic"}>
                                                                        {movimento.data}
                                                                    </p>
                                                                </div>
                                                                <div className={"my-4"}>
                                                                    <p className={"font-semibold"}>Metodo
                                                                        Pagamento: </p>
                                                                    <p className={"italic"}>
                                                                        {movimento.metodo_pagamento}
                                                                    </p>
                                                                </div>
                                                                <div className={"my-4"}>
                                                                    <p className={"font-semibold"}>Allegato</p>
                                                                    <a className={"italic"} href={movimento.allegato} target={"_blank"} rel={""}>
                                                                        {movimento.allegato ? "Apri" : "Nessun Allegato"}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            ) : (
                                <div className={"flex flex-col justify-center gap-8 my-8"}>
                                    <div className={"p-4 bg-my-white rounded"}>
                                        <div className="flex flex-col ">
                                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div
                                                    className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                    <div className={"flex justify-between items-center"}>
                                                        <div className={"font-bold text-3xl"}>
                                                            Nessun Movimento
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                </div>


            </div>
        </MainLayout>
    );
};

export default MovimentiPage;
