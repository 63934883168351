import React from 'react';
import {MdPreview} from "md-editor-rt";
import 'md-editor-rt/lib/style.css';

const MyMdEditorPreview = ({text, bold = false}) => {
    return (
        <MdPreview
            modelValue={text}
            language={"en-US"}
            previewTheme={"default"}
            style={{
                fontWeight: bold ? "bold" : "normal",
                background: "transparent",
                margin: "-10px -20px",
                width: "calc(100% + 40px)",
            }}
        />
    );
};

export default MyMdEditorPreview;
