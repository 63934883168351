import React, {Fragment, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {editBustePaga} from "../../../../../fetch/fetchBustaPaga";

export default function BustaPagaModal({open, setOpen, bustaPaga}) {

    const cancelButtonRef = useRef(null)

    const handlesSubmit = (e) => {
        e.preventDefault()

        const data = new FormData(e.target)
        editBustePaga(bustaPaga, data).then(() => {
            setOpen(false)
        }).catch(e => {
            console.log(e)
        })
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">

                                <form onSubmit={handlesSubmit}>
                                    <div>
                                        <div className="text-center">
                                            <Dialog.Title as="h3"
                                                          className="text-base font-semibold leading-6 text-gray-900">
                                                Dati Pagamento {bustaPaga}
                                            </Dialog.Title>
                                            <div className={"mt-2 grid grid-cols-1 gap-4"}>
                                                {/*Data Pagamento*/}
                                                <div className="col-span-2 lg:col-span-2">
                                                    <div className={"flex flex-col lg:flex-col"}>
                                                        <div className={"text-my-gray"}>
                                                            Data Pagamento
                                                        </div>
                                                        <div className="relative">
                                                            <input
                                                                name={"data_pagamento"}
                                                                type={"date"}
                                                                required
                                                                className="rounded-lg border flex-1 appearance-none border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*Conto Corrente*/}
                                                <div className="col-span-2 lg:col-span-2">
                                                    <div className={"flex flex-col lg:flex-col"}>
                                                        <div className={"text-my-gray"}>
                                                            Conto Corrente
                                                        </div>
                                                        <div className="relative">
                                                            <input
                                                                name={"conto_corrente"}
                                                                type={"text"}
                                                                required
                                                                className="rounded-lg border flex-1 appearance-none border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="submit"
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                        >
                                            Aggiungi
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Annulla
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
